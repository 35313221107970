import CustomButton from '../CustomButton/CustomButton';
import LabelResult from '../LabelResult/LabelResult';
import styles from './ScoreGuideModal.module.scss';

interface IPropsType {
    onSubmit: () => void;
}

function ScoreGuideModal({ onSubmit }: IPropsType) {
    return (
        <div className={styles.dimed}>
            <article className={styles.scoreGuideModal}>
                <ul className={styles.scores}>
                    <li className={styles.functionScore}>
                        <h5>기능점수</h5>
                        <ul>
                            <li>
                                <span>0-3점</span>
                                <LabelResult type={0} />
                            </li>
                            <li>
                                <span>4-6점</span>
                                <LabelResult type={1} />
                            </li>
                            <li>
                                <span>7-10점</span>
                                <LabelResult type={2} />
                            </li>
                        </ul>
                    </li>
                    <li className={styles.discomfortScore}>
                        <h5>불편점수</h5>
                        <ul>
                            <li>
                                <span>0-3점</span>
                                <LabelResult type={2} />
                            </li>
                            <li>
                                <span>4-6점</span>
                                <LabelResult type={1} />
                            </li>
                            <li>
                                <span>7-10점</span>
                                <LabelResult type={0} />
                            </li>
                        </ul>
                    </li>
                </ul>
                <div className={styles.modalSubmitWrap}>
                    <div onClick={onSubmit}>
                        <CustomButton>확인하기</CustomButton>
                    </div>
                </div>
            </article>
        </div>
    );
}
export default ScoreGuideModal;
