import PAGE_ROUTER from './pageList';

const METAVERSE_ROUTER: {
  [key: string]: string;
} = {
  'exercise-of-the-day': PAGE_ROUTER['피트니스'].subMenu?.['오늘의 운동']?.path || '/',
  'personal-counseling': PAGE_ROUTER['마인드테라피'].subMenu?.['1:1 상담']?.path || '/',
  'self-psychology-evaluation': PAGE_ROUTER['마인드테라피'].subMenu?.['이너힐링']?.path || '/',
  feed: PAGE_ROUTER['카페테리아'].subMenu?.['피드']?.path || '/',
  speech: PAGE_ROUTER['스피치'].subMenu?.['스피치']?.path || '/',
  'edit-avatar': PAGE_ROUTER['라커룸'].subMenu?.['아바타 꾸미기']?.path || '/',
  'my-wallet': PAGE_ROUTER['라커룸'].subMenu?.['내 지갑']?.path || '/',
  setting: PAGE_ROUTER['라커룸'].subMenu?.['설정']?.path || '/',
  'locker-room': PAGE_ROUTER['라커룸'].path,
  'inner-healing': '/mind-therapy/inner-healing',
  introduce: '/service-info',
};

export default METAVERSE_ROUTER;
