import { useState } from 'react';
import Checkbox from '../CheckBox/Checkbox';
import styles from './InnerHealingCompleteModal.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { axiosAuthInstance } from '../../api/axios';
import Loading from '../Loading/Loading';
import useUserData from '../../hooks/useUserData';

interface IPropsType {
  point: number;
}
function InnerHealingCompleteModal({ point }: IPropsType) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [feedCheck, setFeedCheck] = useState(false);
  const [feedMessage, setFeedMessage] = useState('');
  const userData = useUserData();

  const { mutateAsync, isLoading } = useMutation(() =>
    axiosAuthInstance.post('/api/cafeteria/feed', {
      userId: userData.data?.userId,
      feedType: 'I',
      contents: feedMessage || '이너힐링을 완료했어요!',
      innerHealingId: Number(id!.replace(/IHC/g, '')),
    })
  );

  async function handleNavigateFeed() {
    if (feedCheck) await mutateAsync();
    navigate('/cafeteria/feed', { replace: true });
  }
  async function handleSubmit() {
    if (feedCheck) await mutateAsync();
    navigate('/mind-therapy/inner-healing', { replace: true });
  }
  return (
    <>
      <div className={styles.dimed}>
        <div className={styles.fireCrackerMobile}>
          <img src={'../../fire_cracker.gif'} alt='폭죽' />
        </div>
        <div className={`${styles.fireCrackerMobile} ${styles.right}`}>
          <img src={'../../fire_cracker.gif'} alt='폭죽' />
        </div>
        <article className={styles.modal}>
          <div className={styles.modalContents}>
            {!!point && <span className={styles.point}>+{point}pt</span>}
            <div className={styles.crackerBg}></div>
            <h3>이너힐링을 완료했어요</h3>
            <div className={styles.feedWrap}>
              <label>
                <Checkbox isChecked={feedCheck} onClick={() => setFeedCheck((prev) => !prev)} />
                <span>카페테리아 피드 남기기</span>
              </label>
              <textarea
                value={feedMessage}
                placeholder='이너힐링을 완료했어요!'
                maxLength={40}
                onChange={(e) => {
                  const message = e.currentTarget.value;
                  setFeedMessage(message);
                }}
                onBlur={() => {
                  setFeedMessage((prev) => prev.slice(0, 40));
                }}
                disabled={!feedCheck}
                className={!feedCheck ? styles.disabled : feedMessage.length ? styles.hit : ''}
              ></textarea>
              <span className={styles.length}>
                {feedMessage.length < 40 ? feedMessage.length : 40}
                /40
              </span>
            </div>
          </div>
          <div className={styles.btns}>
            <button onClick={handleNavigateFeed}>피드 보러가기</button>
            <button onClick={handleSubmit}>확인하기</button>
          </div>
          <div className={styles.fireCracker}>
            <img src={'../../fire_cracker.gif'} alt='폭죽' />
          </div>
          <div className={`${styles.fireCracker} ${styles.right}`}>
            <img src={'../../fire_cracker.gif'} alt='폭죽' />
          </div>
        </article>
      </div>
      {isLoading && <Loading />}
    </>
  );
}
export default InnerHealingCompleteModal;
