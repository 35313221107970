import CustomButton from '../CustomButton/CustomButton';
import styles from './SmartringConnectionSuccessModal.module.scss';

interface IPropsType {
    onSubmit: () => void;
}

function SmartringConnectionSuccessModal({ onSubmit }: IPropsType) {
    return (
        <div className={styles.dimed}>
            <article className={styles.modal}>
                <div className={styles.modalContent}>
                    <div className={styles.bannerWrap}></div>
                    <h6>스마트링 연동 확인</h6>
                    <p>
                        모든 준비가 완료됐어요.
                        <br />
                        이제 운동을 시작해보세요.
                    </p>
                </div>
                <div className={styles.btnWrap}>
                    <div onClick={onSubmit}>
                        <CustomButton>운동 진행하기</CustomButton>
                    </div>
                </div>
            </article>
        </div>
    );
}
export default SmartringConnectionSuccessModal;
