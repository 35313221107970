import { ReactComponent as Right } from '../../assets/common/icons/ic_move_right.svg';

interface IPropsType {
    onClick?: () => void;
}
function IcMoveRightIcon({ onClick }: IPropsType) {
    return (
        <button
            style={{ width: '24px', height: '24px' }}
            onClick={() => {
                if (onClick) onClick();
            }}
        >
            <Right />
        </button>
    );
}
export default IcMoveRightIcon;
