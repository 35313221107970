interface ILockerroomNavType {
  [key: string]: {
    path: string;
    title: string;
  };
}
export const LOCKERROOM_SETTING_NAV_LIST_1: ILockerroomNavType = {
  'my-info': {
    path: '/locker-room/setting/my-info',
    title: '내 정보',
  },
  'kakao-alarm': {
    path: '/locker-room/setting/kakao-alarm',
    title: '카카오 알림톡 설정',
  },
  // 'exercise-venue': {
  //     path: '/locker-room/setting/exercise-venue',
  //     title: '운동장소 설정',
  // },
  notice: {
    path: '/locker-room/setting/notice',
    title: '공지사항',
  },
  faq: {
    path: '/locker-room/setting/faq',
    title: '자주 묻는 질문',
  },
  terms: {
    path: '/locker-room/setting/terms',
    title: '약관 보기',
  },
};
export const LOCKERROOM_SETTING_NAV_LIST_2: ILockerroomNavType = {
  'cancel-membership': {
    path: '/locker-room/setting/cancel-membership',
    title: '서비스 탈퇴',
  },
  logout: {
    path: '/locker-room/setting/logout',
    title: '로그아웃',
  },
};

export const LOCKERROOM_SETTING_NAV_LIST_3: ILockerroomNavType = {
  ...LOCKERROOM_SETTING_NAV_LIST_1,
  ...LOCKERROOM_SETTING_NAV_LIST_2,
};
