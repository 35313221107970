import styles from './MultipleButton.module.scss';

export interface IMultipleButtonType {
  falseText: string;
  trueText: string;
  falseFn?: () => void;
  trueFn?: () => void;
}

function MultipleButton({ falseText, trueText, falseFn, trueFn }: IMultipleButtonType) {
  return (
    <div className={styles.container}>
      <button
        onClick={() => {
          if (falseFn) falseFn();
        }}
      >
        {falseText}
      </button>
      <button
        onClick={() => {
          if (trueFn) trueFn();
        }}
      >
        {trueText}
      </button>
    </div>
  );
}
export default MultipleButton;
