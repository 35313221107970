import { useLocation, useNavigate, useParams } from 'react-router-dom';
import IcMoveRightIcon from '../../assetComponents/IcMoveRightIcon';
import Title from '../Title/Title';
import styles from './Terms.module.scss';
import PAGE_ROUTER from '../../../constant/pageList';
import TermsDetail from '../TermsDetail/TermsDetail';
import { useEffect, useState } from 'react';
import MobileHeader from '../../MobileHeader/MobileHeader';
import ITermsType from '../../../type/ITermsType';
import POLICY_DATA from '../../../constant/policy';

function Terms() {
    const navigate = useNavigate();
    const location = useLocation();
    const [title, setTitle] = useState('약관 보기');
    const { id } = useParams();

    useEffect(() => {
        if (!id) setTitle('약관 보기');
    }, [location]);
    return (
        <>
            <MobileHeader
                title={title}
                backBtn='arrow'
                backUrl={
                    id ? '-1' : PAGE_ROUTER.라커룸?.subMenu?.설정.path || '/'
                }
            />
            <section className={styles.container}>
                <Title title={title} />
                <div className={styles.contentWrap}>
                    {id ? (
                        <TermsDetail setTitle={setTitle} />
                    ) : (
                        <ul className={styles.termsList}>
                            {POLICY_DATA.filter(
                                (datum, idx) => idx === 0 || idx === 3
                            ).map((policy, idx) => (
                                <li
                                    key={policy.title}
                                    onClick={() => {
                                        navigate(
                                            `${PAGE_ROUTER.라커룸.subMenu?.설정?.subMenu?.약관?.path}/${policy.id}`
                                        );
                                    }}
                                >
                                    <h3>{policy.title}</h3>
                                    <IcMoveRightIcon />
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </section>
        </>
    );
}
export default Terms;
