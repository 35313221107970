import { useInView } from 'react-intersection-observer';

function useCostomInView() {
    const [ref, inView] = useInView();
    return {
        inView,
        div: (
            <div
                ref={ref}
                key={''}
                style={{
                    position: 'absolute',
                    height: '1px',
                    zIndex: 1,
                    bottom: 0,
                }}
            ></div>
        ),
    };
}
export default useCostomInView;
