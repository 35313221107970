import styles from './ComebackModal.module.scss';
import { ReactComponent as Clap } from '../../assets/comebackModal/pic_clap.svg';
import { useState } from 'react';
import PAGE_ROUTER from '../../constant/pageList';
import CustomConfirm from '../CustomConfirm/CustomConfirm';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { axiosAuthInstance } from '../../api/axios';
import IReturnSurveyQuestion from '../../type/IReturnSurveyQuestion';
import Loading from '../Loading/Loading';
import useUserData from '../../hooks/useUserData';

const ANSWER_ACTION: {
  [key: string]: {
    title: string;
    path: string;
    trueText: string;
  };
} = {
  ALARM_SET: {
    title: '운동 알림톡을 다시 설정할까요?',
    path: PAGE_ROUTER.라커룸?.subMenu?.설정?.subMenu?.['카카오 알림톡 설정']?.path || '/',
    trueText: '예, 설정할게요',
  },
  EXERCISE_ENVIRONMENT_CHANGE: {
    title: '운동 알림톡을 다시 설정할까요?',
    path: PAGE_ROUTER.라커룸?.subMenu?.설정?.subMenu?.['카카오 알림톡 설정']?.path || '/',
    trueText: '예, 설정할게요',
  },
  RE_EXAM: {
    title: '검사를 다시 진행할까요?',
    path: '/physical-check/physical-info/msk',
    trueText: '예, 다시 할게요',
  },
};

function ComebackModal() {
  const navigate = useNavigate();
  const userData = useUserData();

  const [action, setAction] = useState<'ALARM_SET' | 'EXERCISE_ENVIRONMENT_CHANGE' | 'RE_EXAM' | 'NOTHING'>();
  const [answered, setAnswered] = useState(false);
  function handleAnswer(answerCode: number) {
    setAnswered(true);
    mutate(answerCode);
  }

  const { data, isLoading } = useQuery<IReturnSurveyQuestion>(['less-exercise-question'], () =>
    axiosAuthInstance
      .get(`/api/fitness/survey/less-exercise/questions?userId=${userData.data?.userId}`)
      .then((res) => res.data)
  );
  const { mutate, isLoading: mutateLoading } = useMutation(
    (answerCode: number) =>
      axiosAuthInstance.post('/api/fitness/survey/less-exercise/answers', {
        userId: userData.data?.userId,
        answerList: [
          {
            surveyCode: data?.questionList[0]?.surveyCode,
            questionCode: data?.questionList[0]?.questionCode,
            answerCode: answerCode,
          },
        ],
      }),
    {
      onSuccess: (res) => {
        if (ANSWER_ACTION[res.data.action]) setAction(res.data.action);
        else navigate(window.location.pathname, { replace: true });
      },
    }
  );

  return isLoading || mutateLoading ? (
    <Loading />
  ) : answered ? (
    action &&
    ANSWER_ACTION[action] && (
      <CustomConfirm
        message={ANSWER_ACTION[action].title}
        falseText='아니요'
        trueText={ANSWER_ACTION[action].trueText}
        falseFn={() => navigate(window.location.pathname, { replace: true })}
        trueFn={() => {
          navigate(window.location.pathname, { replace: true });
          navigate(ANSWER_ACTION[action].path);
        }}
      ></CustomConfirm>
    )
  ) : (
    <div className={styles.container}>
      <article className={styles.modal}>
        <div className={styles.banner}>
          <Clap />
          <span>+200pt</span>
        </div>
        <p className={styles.welcomeSentence}>{data?.questionList[0]?.mainQuestion}</p>
        <p className={styles.question}>{data?.questionList[0]?.subQuestion}</p>
        <ul className={styles.answerList}>
          {data?.questionList[0]?.answerList.map((answer) => (
            <li key={answer.answerCode} onClick={() => handleAnswer(answer.answerCode)}>
              {answer.answerContent}
            </li>
          ))}
        </ul>
      </article>
    </div>
  );
}
export default ComebackModal;
