import styles from './SelectDiscomfort.module.scss';
import { ReactComponent as PersonMobile } from '../../../assets/mobile/image/pic_person.svg';
import { ReactComponent as Person } from '../../../assets/web/image/pic_person.svg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { axiosInstance } from '../../../api/axios';
import Loading from '../../Loading/Loading';
import useUserData from '../../../hooks/useUserData';

interface IPropsType {
  nextFn: () => void;
}

function SelectDiscomfort({ nextFn }: IPropsType) {
  const navigate = useNavigate();
  const userData = useUserData();

  const [isFront, setIsFront] = useState(false);

  function handlePrev() {
    navigate('/physical-check/physical-info/msk?index=1');
  }
  const { mutateAsync, isLoading } = useMutation(() =>
    axiosInstance.post('/api/fitness/user-info/body/uncomfortable-part', {
      userId: userData.data?.userId,
      uncomfortablePart: 'lb',
      rightOrLeft: 'none',
    })
  );
  async function handleNext() {
    await mutateAsync();
    nextFn();
  }
  return (
    <>
      <section className={styles.container}>
        <div className={styles.selectFrontBack}>
          <p className={styles.question}>어느 부위가 불편한가요?</p>
          <div className={styles.btns}>
            <button
              className={isFront ? styles.hit : ''}
              // onClick={() => setIsFront(true)}
            >
              앞
            </button>
            <button className={!isFront ? styles.hit : ''} onClick={() => setIsFront(false)}>
              뒤
            </button>
          </div>
        </div>
        <div className={styles.contentsWrap}>
          <div className={styles.discomfortWrap}>
            <PersonMobile className={styles.small} />
            <Person className={styles.big} />
            <div className={styles.discomfortBtns}>
              <button className={styles.neck}>목</button>
              <button className={styles.sholder}>어깨</button>
              <button className={styles.upperArm}>위 팔</button>
              <button className={styles.lowerArm}>아래 팔</button>
              <button className={styles.hand}>손, 손목</button>
              <button className={`${styles.waist} ${styles.hit}`}>허리</button>
              <button className={styles.hip}>엉덩이(고관절)</button>
              <button className={styles.knee}>허벅지, 무릎</button>
              <button className={styles.calf}>종아리</button>
              <button className={styles.foot}>발, 발목</button>
            </div>
          </div>
          <p className={styles.guideSentence}>・현재 진행중인 임상 서비스에서는 허리만 선택 가능합니다.</p>
        </div>
      </section>
      <div className={styles.submitBtnWrap}>
        <button className={styles.prev} onClick={handlePrev}>
          이전
        </button>
        <button className={styles.next} onClick={handleNext}>
          다음
        </button>
      </div>
      {isLoading && <Loading />}
    </>
  );
}
export default SelectDiscomfort;
