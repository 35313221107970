import styles from './Main.module.scss';
// import { ReactComponent as Logo } from '../../assets/logo/logo_dark.svg';
import logo from '../../assets/logo/logo_dark.png';
import { ReactComponent as KakaoLogo } from '../../assets/logo/logo_kakao.svg';
import { useNavigate } from 'react-router-dom';
import { KAKAO_AUTH_URL } from '../Kakao/Kakao';
import Fullpage from '../../components/Fullpage/Fullpage';
import { useEffect } from 'react';
import { useDispatch } from '../../store/store';
function Main() {
  const navigate = useNavigate();
  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'PrintScreen') {
        navigator.clipboard.writeText('');
        alert('Screenshots disabled!');
      }
    });
  }, []);
  return (
    <Fullpage>
      <main className={styles.container}>
        <div className={styles.contentWrap}>
          <h1 className={styles.logoWrap}>
            <img src={logo} alt='로고' />
            <span>함께 만드는 건강한 삶</span>
          </h1>
          <div className={styles.btnsWrap}>
            <button
              className={styles.serviceInfoBtn}
              onClick={() => {
                navigate('/service-info');
              }}
            >
              서비스 소개 영상 보기
            </button>
            <button className={styles.kakaoLoginBtn} onClick={() => (window.location.href = KAKAO_AUTH_URL)}>
              <KakaoLogo />
              <span>카카오 로그인</span>
            </button>
          </div>
        </div>
      </main>
    </Fullpage>
  );
}
export default Main;
