import styles from './Title.module.scss';

interface IPropsType {
    title: string;
    noUnderline?: boolean;
    isSticky?: boolean;
}
function Title({ title, noUnderline = false, isSticky = false }: IPropsType) {
    return (
        <div
            className={`${styles.titleWrap} ${
                noUnderline ? styles.noUnderline : ''
            } ${isSticky ? styles.sticky : ''}`}
        >
            {title}
        </div>
    );
}
export default Title;
