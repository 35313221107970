import styles from './LineChart.module.scss';
import React from 'react';
import { ParentSize } from '@visx/responsive';
import { scaleLinear } from '@visx/scale';
import { Group } from '@visx/group';
import { Line } from '@visx/shape';
import { Axis } from '@visx/axis';
import { Text } from '@visx/text';
import { ReactComponent as BalloonMobile } from '../../assets/mobile/icons/balloon.svg';
import { ReactComponent as Balloon } from '../../assets/web/icons/balloon.svg';
import clap from '../../assets/common/icons/pic_clap.png';

type dataNameType = 'hit' | 'solid' | 'normal';

export type lineChartDataType = {
  type: 'hit' | 'solid' | 'normal';
  value: number;
  xAxisLabel: string;
  xAxisSubLabel?: string;
  useBalloon?: boolean;
  balloonUnit?: string;
};
type graphDataType = {
  [key in dataNameType]: {
    strokeWidth: number;
    stroke: string;
    color: string;
    circleSize: number;
    line?: string;
  };
};

const graphData: graphDataType = {
  normal: {
    strokeWidth: 1,
    stroke: '#B4BAC8',
    color: '#5C5F63',
    circleSize: 10,
    line: '#B4BAC8',
  },
  hit: {
    strokeWidth: 2,
    stroke: '#4852EC',
    color: '#fff',
    circleSize: 12,
    line: '#B4B7ED',
  },
  solid: {
    strokeWidth: 2,
    stroke: '#B4B7ED',
    color: '#4852EC',
    circleSize: 10,
    line: 'none',
  },
};

interface IPropsType {
  yAxisValues: number[];
  min: number;
  max: number;
  data: lineChartDataType[];
  mobilePadding?: {
    left: number;
    right: number;
  };
  padding?: {
    //pc만 대응하는 값. 모바일 추후 필요시 리팩토링
    left: number;
    right: number;
  };
}
function LineChart({ yAxisValues, min, max, data, mobilePadding, padding }: IPropsType) {
  return (
    <>
      <article className={styles.container}>
        <ParentSize debounceTime={20}>
          {(parent) => {
            const isMobile = window.innerWidth < 1024;
            const width = parent.width;
            const height = parent.height;
            const margin = {
              top: isMobile ? 25 : 39,
              left: isMobile ? 28 : 38,
              right: 0,
              bottom: isMobile ? 63 : 79,
            };

            const innerWidth = width - margin.left - margin.right;
            const innerHeight = height - margin.top - margin.bottom;
            const xScale = scaleLinear({
              domain: [0, data.length - 1],
              range: [
                isMobile ? (mobilePadding ? mobilePadding.left : 36) : padding ? padding.left : 78,
                innerWidth - (isMobile ? (mobilePadding ? mobilePadding.right : 41) : padding ? padding.right : 78),
              ],
            });
            const yScale = scaleLinear({
              domain: [min, max],
              range: [innerHeight, 0],
            });
            const chartPaddingBottom = isMobile ? 19 : 24;
            return (
              <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
                {data.length > 0 && (
                  <>
                    <Group top={margin.top} left={margin.left}>
                      <Axis
                        scale={yScale}
                        orientation='left'
                        numTicks={5}
                        hideTicks
                        tickValues={yAxisValues}
                        hideAxisLine
                        tickFormat={(val) => val + ''}
                        tickLabelProps={{
                          fill: '#7F828A',
                          fontSize: isMobile ? 12 : 14,
                          textAnchor: 'middle',
                          verticalAnchor: 'middle',
                        }}
                      />
                    </Group>
                    <Group top={margin.top} left={margin.left}>
                      {data.slice(0, -1).map((datum, idx) => (
                        <Line
                          key={idx}
                          from={{
                            x: xScale(idx),
                            y: yScale(datum.value),
                          }}
                          to={{
                            x: xScale(idx + 1),
                            y: yScale(data[idx + 1].value),
                          }}
                          stroke={graphData[datum.type].line}
                          strokeWidth={3}
                        />
                      ))}
                      {data.map((datum, idx) => (
                        <Line
                          key={idx}
                          from={{
                            x: xScale(idx),
                            y: yScale(datum.value),
                          }}
                          to={{
                            x: xScale(idx),
                            y: yScale(0) + chartPaddingBottom,
                          }}
                          strokeDasharray={datum.type === 'solid' ? 4 : 0}
                          stroke={graphData[datum.type].stroke}
                          strokeWidth={graphData[datum.type].strokeWidth}
                        />
                      ))}

                      {data.map((datum, idx) => {
                        return (
                          <React.Fragment key={idx}>
                            {datum.useBalloon &&
                              (isMobile ? (
                                <BalloonMobile width={49} x={xScale(idx) - 24.5} y={yScale(datum.value) - 41} />
                              ) : (
                                <Balloon width={62} x={xScale(idx) - 31} y={yScale(datum.value) - 45} />
                              ))}
                            <Text
                              x={xScale(idx)}
                              y={yScale(datum.value) - (datum.useBalloon ? (isMobile ? 22 : 23) : 16)}
                              textAnchor='middle'
                              verticalAnchor='end'
                              fontSize={isMobile ? 16 : 20}
                              fontWeight={'700'}
                              fill={graphData[datum.type].color}
                            >
                              {datum.value + (datum.balloonUnit ?? '')}
                            </Text>
                            <circle
                              cx={xScale(idx)}
                              cy={yScale(datum.value)}
                              r={graphData[datum.type].circleSize / 2}
                              fill={graphData[datum.type].color}
                              stroke={datum.type === 'hit' ? '#4852EC' : 'none'}
                              strokeWidth={datum.type === 'hit' ? 4 : 0}
                              className={styles.glyph}
                            ></circle>
                          </React.Fragment>
                        );
                      })}
                    </Group>
                    <Group top={margin.top + yScale(0) + chartPaddingBottom + 1} left={margin.left}>
                      {data.map((datum, idx) => (
                        <React.Fragment key={idx}>
                          <Text
                            // key={'12' + idx}
                            y={isMobile ? 9 : 14}
                            x={xScale(idx)}
                            fontSize={isMobile ? 14 : 16}
                            textAnchor='middle'
                            verticalAnchor='start'
                            fontWeight={'400'}
                            fill='#000'
                          >
                            {datum.xAxisLabel}
                          </Text>
                          {datum.type === 'solid' ? (
                            // <Clap
                            //     y={isMobile ? 26 : 34}
                            //     x={xScale(idx) - 8}
                            //     fontSize={isMobile ? 12 : 14}
                            //     textAnchor='middle'
                            //     verticalAnchor='start'
                            // />
                            // <rect
                            // y={isMobile ? 26 : 34}
                            // x={xScale(idx) - 8}
                            //     width={'25px'}
                            //     height={'25px'}
                            //     className={styles.test}
                            //     fill={'none'}
                            // />
                            <image href={clap} height='25' width='25' y={isMobile ? 23 : 34} x={xScale(idx) - 12.5} />
                          ) : (
                            <Text
                              y={isMobile ? 28 : 37}
                              x={xScale(idx)}
                              fontSize={isMobile ? 12 : 14}
                              textAnchor='middle'
                              verticalAnchor='start'
                              fontWeight={'400'}
                              fill='#7F828A'
                            >
                              {datum.xAxisSubLabel}
                            </Text>
                          )}
                        </React.Fragment>
                      ))}
                    </Group>
                    <Group top={margin.top}>
                      <Line
                        from={{
                          x: 0,
                          y: yScale(0) + chartPaddingBottom,
                        }}
                        to={{
                          x: width,
                          y: yScale(0) + chartPaddingBottom,
                        }}
                        stroke='#e3e3e3'
                      />
                    </Group>
                  </>
                )}
              </svg>
            );
          }}
        </ParentSize>
      </article>
    </>
  );
}
export default LineChart;
