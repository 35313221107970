import MultipleButton from '../MultipleButton/MultipleButton';
import styles from './SmartringConnectionModal.module.scss';

interface IPropsType {
    trueFn: () => void;
    falseFn: () => void;
}
function SmartringConnectionModal({ trueFn, falseFn }: IPropsType) {
    return (
        <div className={styles.dimed}>
            <article className={styles.modal}>
                <div className={styles.modalContent}>
                    <div className={styles.smartringImageWrap}></div>
                    <p>
                        스마트링을 연동한 상태에서 운동하면 심박수, 활동량 등
                        자세한 기록을 확인할 수 있어요.
                    </p>
                    <br className={styles.mobileBreak} />
                    <p>운동 시 스마트링을 활용할까요?</p>
                </div>
                <div className={styles.btnWrap}>
                    <MultipleButton
                        trueText='예, 활용할게요'
                        falseText='아니요'
                        trueFn={trueFn}
                        falseFn={falseFn}
                    />
                </div>
            </article>
        </div>
    );
}
export default SmartringConnectionModal;
