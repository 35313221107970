import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Fullpage from '../../components/Fullpage/Fullpage';
import Header from '../../components/Header/Header';
import NavBar, { MAIN_CONTENTS } from '../../components/NavBar/NavBar';
import styles from './Health.module.scss';
import { useEffect, useState } from 'react';
import WelcomeModal from '../../components/WelcomeModal/WelcomeModal';
import PAGE_ROUTER from '../../constant/pageList';
import ComebackModal from '../../components/ComebackModal/ComebackModal';
import checkMetaverseOrigin from '../../common/checkMetaverseOrigin';
import METAVERSE_ROUTER from '../../constant/metaverseRouter';
import { axiosAuthInstance } from '../../api/axios';
import getFormattedTime from '../../common/getFormattedTime';
import useUserData from '../../hooks/useUserData';
import { useDispatch } from '../../store/store';
import { logout } from '../../store/jwt';

function Health() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { menu } = useParams() as { menu: string };
  const userData = useUserData();

  const [isComebackModalOpen, setIsCombackModalOpen] = useState(false);
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);

  useEffect(() => {
    const isJoined = new URL(document.URL).searchParams.get('is-joined');
    if (isJoined) setIsWelcomeModalOpen(true);
    else setIsWelcomeModalOpen(false);
    const isComeback = new URL(document.URL).searchParams.get('is-comeback');
    if (isComeback) setIsCombackModalOpen(true);
    else setIsCombackModalOpen(false);
  }, [location]);
  useEffect(() => {
    const NAMES = Object.values(PAGE_ROUTER).map((obj) => obj.name);
    if (!NAMES.includes(menu)) navigate('/health/locker-room');
  }, []);
  useEffect(() => {
    function handleMessage(e: MessageEvent) {
      if (!checkMetaverseOrigin(e.origin)) return;
      const PARAMS = new URLSearchParams(e.data);
      const PATH = PARAMS.get('path');
      if (PATH && METAVERSE_ROUTER[PATH]) {
        navigate(METAVERSE_ROUTER[PATH]);
      }
      const UNAUTHORIZED = PARAMS.get('unauthorized');
      if (UNAUTHORIZED == 'true') {
        dispatch(logout());
        navigate('/');
      }
    }
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const [counselingToday, setCounselingToday] = useState<null | string>('');
  useEffect(() => {
    (async () => {
      const data = await axiosAuthInstance.get(`/api/counselling/today?userId=${userData.data?.userId}`).then((res) => {
        return res.data;
      });
      setCounselingToday(data);
    })();
  }, []);

  const [speechToday, setSpeechToday] = useState<null | string>('');
  useEffect(() => {
    (async () => {
      const data = await axiosAuthInstance
        .get(`/api/speech/today?userId=${userData.data?.userId}`)
        .then((res) => res.data);
      setSpeechToday(data);
    })();
  }, []);

  function isNeedSchedulePopup(time: string) {
    try {
      const MINUTE = 1000 * 60;
      const [hours, minute, second] = time.split(':').map((datum) => Number(datum));
      const NOW = new Date().getTime();
      if (
        new Date().setHours(hours, minute, second, 0) - MINUTE * 30 <= NOW &&
        NOW <= new Date().setHours(hours, minute, second, 0) + MINUTE * 60
      )
        return true;
      else return false;
    } catch (err) {
      return false;
    }
  }
  function getTimePassed(time: string) {
    if (!time) return true;
    try {
      const MINUTE = 1000 * 60;
      const [hours, minute, second] = time.split(':').map((datum) => Number(datum));
      if (new Date().getTime() <= new Date().setHours(hours, minute, second, 0) + MINUTE * 60) return false;
      else return true;
    } catch (err) {
      return true;
    }
  }
  return (
    <Fullpage>
      <>
        <main className={styles.container}>
          <Header />
          <div className={styles.iframeArea}>
            <div className={styles.fixedArea}>
              <button className={styles.detailBtn}>{MAIN_CONTENTS[menu]?.title}</button>
              {MAIN_CONTENTS[menu]?.title === '마인드테라피' &&
                counselingToday &&
                isNeedSchedulePopup(counselingToday) && (
                  <div
                    className={styles.reservationInfoWrap}
                    onClick={() => {
                      navigate('/mind-therapy/personal-counseling?tab=1');
                    }}
                  >
                    <span>
                      {(() => {
                        const [hour, minute] = counselingToday.split(':');
                        return getFormattedTime(`${hour}:${minute}:00`);
                      })()}{' '}
                      상담
                    </span>
                    <button>보기</button>
                  </div>
                )}
              {MAIN_CONTENTS[menu]?.title === '스피치' && speechToday && isNeedSchedulePopup(speechToday) && (
                <div
                  className={styles.reservationInfoWrap}
                  onClick={() => {
                    navigate('/speech?tab=1');
                  }}
                >
                  <span>
                    {(() => {
                      const [hour, minute] = speechToday.split(':');
                      return `${Number(hour)}:${minute}`;
                    })()}{' '}
                    스피치
                  </span>
                  <button>보기</button>
                </div>
              )}
            </div>
            <iframe
              src={
                (MAIN_CONTENTS[menu]?.metabusUrl || '') +
                `&token=${window.btoa(localStorage.getItem('access-token') || '')}`
              }
              frameBorder='0'
              allow='autoplay; camera; microphone'
              title='main contents frame'
            ></iframe>
          </div>
          <NavBar
            counselingToday={getTimePassed(counselingToday || '') ? null : counselingToday}
            speechToday={getTimePassed(speechToday || '') ? null : speechToday}
          />
          <div className={styles.privacyBtn} onClick={() => navigate('/locker-room/setting/terms/4')}>
            <div className={styles.bg}></div>
            <span>개인정보 처리방침</span>
          </div>
        </main>
        {isWelcomeModalOpen && <WelcomeModal />}
        {isComebackModalOpen && <ComebackModal />}
      </>
    </Fullpage>
  );
}
export default Health;
