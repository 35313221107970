import styles from './ServiceInfo.module.scss';
import CustomPlayer from '../../components/CustomPlayer/CustomPlayer';
import Header from '../../components/Header/Header';
import { ReactComponent as Back } from '../../assets/mobile/icons/ic_back_b.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function ServiceInfo() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 500, behavior: 'smooth' });
    function handleChangeOrientation(e: Event) {
      setTimeout(() => {
        window.scrollTo({ top: 500, behavior: 'smooth' });
      }, 500);
    }
    window.addEventListener('orientationchange', handleChangeOrientation);
    return () => {
      window.removeEventListener('orientationchange', handleChangeOrientation);
    };
  }, []);

  useEffect(() => {
    function preventScroll(e: Event) {
      if (window.innerWidth < 1024) e.preventDefault();
    }
    window.addEventListener('scroll', preventScroll, { passive: false });
    window.addEventListener('touchmove', preventScroll, { passive: false });
    window.addEventListener('mousewheel', preventScroll, { passive: false });
    return () => {
      window.removeEventListener('scroll', preventScroll);
      window.removeEventListener('touchmove', preventScroll);
      window.removeEventListener('mousewheel', preventScroll);
    };
  }, []);

  const handleBack = () => {
    if (window.history.length > 1) navigate(-1);
    else navigate('/');
  };
  return (
    <>
      <div className={styles.virtualHeight}>
        <div className={`${styles.container} max-1024`}>
          <Header logoUrl='/' />
          <div className={styles.titleWrap}>
            <Back onClick={handleBack} />
            <h3>서비스 소개</h3>
          </div>
          <div className={styles.playerWrap}>
            <CustomPlayer
              autoPlay
              mute={false}
              url='https://dev-storage.togather.co.kr/videos/intro/introduction.mp4'
              title={'서비스 소개'}
              backFn={handleBack}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default ServiceInfo;
