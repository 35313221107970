import { Navigate } from 'react-router-dom';
import useCheckLogin from '../../hooks/useCheckLogin';
import { useEffect } from 'react';

function Authorization({ children }: { children: React.ReactChild }) {
    const isLoggedIn = useCheckLogin();

    return isLoggedIn ? children : <Navigate to='/' />;
}
export default Authorization;
