import { useState } from 'react';
import BarChart from '../../components/BarChart/BarChart';
import Header from '../../components/Header/Header';
import styles from './SelfPsychologyEvaluationResult.module.scss';
import CustomConfirm from '../../components/CustomConfirm/CustomConfirm';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { axiosAuthInstance } from '../../api/axios';
import Loading from '../../components/Loading/Loading';
import useUserData from '../../hooks/useUserData';
import { ISelfPsychologyEvaluationResult } from '../../type/ISelfPsychologyEvaluation';

function SelfPsychologyEvaluationResult() {
  const navigate = useNavigate();
  const userData = useUserData();
  const [retryModalOpen, setRetryModalOpen] = useState(false);
  const { data: resultData, isLoading } = useQuery<ISelfPsychologyEvaluationResult>(
    ['self-psychology-evaluation-result'],
    () =>
      axiosAuthInstance.get(`/api/inner-healing/self-assessments?userId=${userData?.data?.userId}`).then((res) => {
        const response: ISelfPsychologyEvaluationResult = res.data;
        if (response.firstHistory) response.firstHistory.sort((a, b) => a.questionCode - b.questionCode);
        if (response.latestHistory) response.latestHistory.sort((a, b) => a.questionCode - b.questionCode);
        return response;
      })
  );

  function getChartLegend() {
    if (!resultData) return [];

    const LEGEND_LIST = ['이번'];
    if (resultData.latestHistory?.length) LEGEND_LIST.unshift('처음');
    return LEGEND_LIST.map((legend, idx) => ({
      legend,
      date: (idx === 0 ? resultData.firstHistory![0].answeredDateTime : resultData.latestHistory![0].answeredDateTime)
        .slice(5, 10)
        .replace(/-/g, '.'),
    }));
  }
  return (
    <>
      <main className={styles.container}>
        <div className={styles.bg}>
          <Header isNavShow />
          <div className={styles.contentWrap}>
            <div className={styles.mobileHeader}>셀프 평가 결과</div>
            <div className={styles.menu}>
              <button onClick={() => navigate('/mind-therapy/personal-counseling')}>1:1 상담</button>
              <button className={styles.hit}>이너힐링</button>
            </div>
            <div className={styles.scrollableArea}>
              {resultData && (
                <>
                  <article className={styles.positiveWrap}>
                    <div className={styles.articleHeader}>
                      <span>긍정</span>
                      <ul>
                        {getChartLegend().map((datum, idx) => (
                          <li key={idx}>
                            <div className={styles.circle}></div>
                            <span>
                              {datum.legend} {datum.date}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className={styles.chartWrap}>
                      {(() => {
                        const data = resultData?.positiveQuestionCodes.sort().map((questionCode, idx) => {
                          const chartDatum: {
                            title: string;
                            score: number[];
                          } = {
                            title: '',
                            score: [],
                          };
                          if (resultData.firstHistory?.length)
                            chartDatum.score.push(resultData.firstHistory[idx].answerCode);
                          if (resultData.latestHistory?.length)
                            chartDatum.score.push(resultData.latestHistory[idx].answerCode);
                          return chartDatum;
                        });

                        const color = ['#D1F7F4', '#40D7EC'];
                        const fontColor = ['#5C5F63', '#40D7EC'];

                        return (
                          <BarChart
                            data={data}
                            xAxisLabels={['마음 챙김', '자존감', '대인관계']}
                            min={0}
                            max={10}
                            yAxisValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                            color={data[0].score.length === 2 ? color : [color[1]]}
                            fontColor={data[0].score.length === 2 ? fontColor : [fontColor[1]]}
                          />
                        );
                      })()}
                    </div>
                  </article>
                  <article className={styles.negativeWrap}>
                    <div className={styles.articleHeader}>
                      <span>부정</span>
                      <ul>
                        {getChartLegend().map((datum, idx) => (
                          <li key={idx}>
                            <div className={styles.circle}></div>
                            <span>
                              {datum.legend} {datum.date}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className={styles.chartWrap}>
                      {(() => {
                        const data = resultData?.positiveQuestionCodes.sort().map((questionCode, idx) => {
                          const chartDatum: {
                            title: string;
                            score: number[];
                          } = {
                            title: '',
                            score: [],
                          };
                          if (resultData.firstHistory?.length)
                            chartDatum.score.push(resultData.firstHistory[idx + 3].answerCode);
                          if (resultData.latestHistory?.length)
                            chartDatum.score.push(resultData.latestHistory[idx + 3].answerCode);
                          return chartDatum;
                        });

                        const color = ['#FFDFDD', '#FC6550'];
                        const fontColor = ['#5C5F63', '#FC6550'];

                        return (
                          <BarChart
                            data={data}
                            xAxisLabels={['우울', '불안', '분노']}
                            min={0}
                            max={10}
                            yAxisValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                            color={data[0].score.length === 2 ? color : [color[1]]}
                            fontColor={data[0].score.length === 2 ? fontColor : [fontColor[1]]}
                            reverse
                          />
                        );
                      })()}
                    </div>
                  </article>
                  <div className={styles.submitBtnWrap}>
                    <button onClick={() => setRetryModalOpen(true)}>평가 다시하기</button>
                    <button onClick={() => navigate('/mind-therapy/inner-healing')}>이너힐링 하러가기</button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
      {isLoading && <Loading />}
      {retryModalOpen && (
        <CustomConfirm
          message='평가를 다시 진행할까요?'
          falseText='아니요'
          falseFn={() => setRetryModalOpen(false)}
          trueText='예, 다시할게요'
          trueFn={() => navigate('/mind-therapy/inner-healing/self-psychology-evaluation/0')}
        />
      )}
    </>
  );
}
export default SelfPsychologyEvaluationResult;
