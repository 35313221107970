import CustomButton from '../CustomButton/CustomButton';
import styles from './HeartRateGuideModal.module.scss';

interface IPropsType {
    onSubmit: () => void;
}
function HeartRateGuideModal({ onSubmit }: IPropsType) {
    return (
        <div className={styles.dimed}>
            <article className={styles.modal}>
                <div className={styles.content}>
                    <h3>심박수란?</h3>
                    <p>
                        심박수(Heart Rate)는 스마트링에서 수집되어, 평소와 운동
                        상황을 비교해 그래프로 표시돼요.
                        <br />
                        <br />
                        스마트링 연결이나 데이터 수신 등의 문제가 있는 경우
                        심박수가 제대로
                        <br className={styles.mobile} />
                        표시되지 않을 수 있어요.
                    </p>
                </div>
                <div className={styles.submitBtnWrap}>
                    <div onClick={onSubmit}>
                        <CustomButton>확인하기</CustomButton>
                    </div>
                </div>
            </article>
        </div>
    );
}
export default HeartRateGuideModal;
