const UNCOMFORTABLE_PART_CODE: {
  [key: string]: {
    ko: string;
    en: string;
  };
} = {
  nk: {
    ko: '목',
    en: 'neck',
  },
  ul: {
    ko: '위팔',
    en: 'Upper Arm',
  },
  la: {
    ko: '아래팔',
    en: 'Lower arm',
  },
  wh: {
    ko: '손목, 손',
    en: 'Wrist, hand',
  },
  ub: {
    ko: '등',
    en: 'Upper back',
  },
  lb: {
    ko: '허리',
    en: 'Lower back',
  },
  ha: {
    ko: '사타구니(고관절)',
    en: 'Hip joint, groin, inguinal are',
  },
  hp: {
    ko: '엉덩이(고관절)',
    en: 'Hip joint, buttock',
  },
  tk: {
    ko: '허벅지, 무릎',
    en: 'Thigh, knee',
  },
  cl: {
    ko: '종아리',
    en: 'Calf',
  },
  af: {
    ko: '발목, 발',
    en: 'Ankle, foot',
  },
};

export default UNCOMFORTABLE_PART_CODE;
