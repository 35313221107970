import styles from './VerticalModal.module.scss';
import { ReactComponent as Vertical } from '../../assets/common/background/pic_vertical.svg';
import CustomButton from '../CustomButton/CustomButton';

interface IPropsType {
    onSubmit: () => void;
}

function VerticalModal({ onSubmit }: IPropsType) {
    return (
        <div className={styles.dimed}>
            <article className={styles.modal}>
                <div className={styles.imageWrap}>
                    <Vertical />
                </div>
                <p>다음 화면부터 세로로 진행돼요.</p>
                <div className={styles.modalSubmitBtn} onClick={onSubmit}>
                    <CustomButton>확인하기</CustomButton>
                </div>
            </article>
        </div>
    );
}
export default VerticalModal;
