import { useEffect } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';

function ErrorBoundaryComponent() {
    const navigate = useNavigate();
    const { resetBoundary } = useErrorBoundary();
    useEffect(() => {
        navigate('/health/locker-room');
        resetBoundary();
    }, []);
    return <div></div>;
}
export default ErrorBoundaryComponent;
