import Header from '../../components/Header/Header';
import MetaSpaceIcon from '../../components/assetComponents/MetaSpaceIcon';
import { ReactComponent as Refresh } from '../../assets/common/icons/ic_refresh.svg';
import styles from './Speech.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import SpeechFrame from '../../components/SpeechFrame/SpeechFrame';
import PAGE_ROUTER from '../../constant/pageList';
import useCostomInView from '../../hooks/useCustomInView';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { axiosAuthInstance } from '../../api/axios';
import ISpeechListType from '../../type/ISpeechListType';
import Loading from '../../components/Loading/Loading';
import useUserData from '../../hooks/useUserData';
import IMySpeechType from '../../type/IMySpeechType';

function Speech() {
  const userData = useUserData();
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState(Number(new URL(document.URL).searchParams.get('tab')) || 0);
  useEffect(() => {
    setTab(Number(new URL(document.URL).searchParams.get('tab')) || 0);
    if (scrollAreaRef.current) scrollAreaRef.current.scrollTo(0, 0);
  }, [location]);

  const scrollAreaRef = useRef<HTMLDivElement>(null);

  // 내 스피치 조회
  const { data } = useInfiniteQuery<{
    content: IMySpeechType[];
  }>(
    ['mySpeechData'],
    () => axiosAuthInstance.get(`/api/speech/my?userId=${userData.data?.userId}`).then((res) => res.data),
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: 0,
    }
  );
  // 내 스피치 조회

  const [intendedTodaySpeech, setIntendedTodaySpeech] = useState(false);

  useEffect(() => {
    if (!data) return;
    for (let i = 0; i < data.pages[0].content.length; i++) {
      const [TIMESTAMP, YEAR, MONTH, DATE] = (() => {
        const TODAY = new Date();
        return [TODAY.getTime(), TODAY.getFullYear(), TODAY.getMonth() + 1, TODAY.getDate()];
      })();
      const [SPEECH_TIMESTAMP, SPEECH_YEAR, SPEECH_MONTH, SPEECH_DATE] = (() => {
        const SPEECH_TIME = new Date(`${data.pages[0].content[i].speechDate} ${data.pages[0].content[i].endTime}`);
        return [SPEECH_TIME.getTime(), SPEECH_TIME.getFullYear(), SPEECH_TIME.getMonth() + 1, SPEECH_TIME.getDate()];
      })();
      if (YEAR === SPEECH_YEAR && MONTH === SPEECH_MONTH && DATE === SPEECH_DATE && SPEECH_TIMESTAMP > TIMESTAMP) {
        setIntendedTodaySpeech(true);
        return;
      }
    }
    setIntendedTodaySpeech(false);
  }, [data]);

  const [handleRefetch, setHandleRefetch] = useState(false);

  return (
    <main className={styles.container}>
      <div className={styles.bg}>
        <Header isNavShow />
        <div className={styles.contentWrap}>
          <div className={styles.mobileHeader}>
            <button className={styles.backBtn} onClick={() => navigate(PAGE_ROUTER.스피치?.path || '/')}>
              <MetaSpaceIcon />
            </button>
            <span>스피치</span>
            <button
              className={styles.refresh}
              onClick={() => {
                setHandleRefetch(true);
              }}
            >
              <Refresh />
            </button>
          </div>
          <div className={styles.menu}>
            <button className={styles.hit}>스피치</button>
          </div>
          <div className={styles.scrollableArea} ref={scrollAreaRef}>
            <ul className={styles.tabs}>
              <li onClick={() => navigate('?tab=0', { replace: true })} className={tab === 0 ? styles.hit : ''}>
                <span>스피치 신청</span>
              </li>
              <li
                onClick={() => navigate('?tab=1', { replace: true })}
                className={`${tab === 1 ? styles.hit : ''} ${intendedTodaySpeech ? styles.on : ''}`}
              >
                <span>내 스피치</span>
              </li>
            </ul>
            {tab === 0 && (
              <AvailableSpeech
                handleRefetch={handleRefetch}
                onRefetch={() => {
                  scrollAreaRef.current?.scrollTo(0, 0);
                  setHandleRefetch(false);
                }}
              />
            )}
            {tab === 1 && (
              <MySpeech
                handleRefetch={handleRefetch}
                onRefetch={() => {
                  scrollAreaRef.current?.scrollTo(0, 0);
                  setHandleRefetch(false);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
export default Speech;

interface ITabProps {
  handleRefetch: boolean;
  onRefetch: () => void;
}

function AvailableSpeech({ handleRefetch, onRefetch }: ITabProps) {
  const { inView, div } = useCostomInView();
  const userData = useUserData();
  const queryClient = useQueryClient();

  const {
    data: speechList,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery<{
    content: ISpeechListType[];
    last: boolean;
  }>(['speechList'], ({ pageParam = 0 }) => getAvailableSpeech(pageParam), {
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage.last) return allPages.length;
      else return false;
    },
    // staleTime: 1000 * 60,
  });
  useEffect(() => {
    if (!handleRefetch || !speechList) return;
    (async () => {
      queryClient.setQueryData(['speechList'], () => {
        return {
          pages: speechList.pages.slice(0, 1),
          pageParms: speechList.pageParams.slice(0, 1),
        };
      });
      await refetch();
      onRefetch();
    })();
  }, [handleRefetch]);

  useEffect(() => {
    if (inView && hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, speechList]);

  const getAvailableSpeech = async (page: number) => {
    let res = await axiosAuthInstance.get(`/api/speech/available?userId=${userData.data?.userId}&page=${page}&size=10`);
    return res.data;
  };
  function getTimePassed(time: string) {
    return new Date().getTime() >= new Date(`${time}`).getTime() ? true : false;
  }

  const [availableSpeech, setAvailableSpeech] = useState<ISpeechListType[]>([]);
  const [disabledSpeech, setDisabledSpeech] = useState<ISpeechListType[]>([]);

  useEffect(() => {
    if (!speechList) return;
    const list: ISpeechListType[] = [];
    speechList.pages.map((page) => list.push(...page.content));
    const available: ISpeechListType[] = [];
    const disabled: ISpeechListType[] = [];
    list.map((speech) => {
      if (!getTimePassed(`${speech.speechDate} ${speech.endTime}`) && !speech.speechCompleted) available.push(speech);
      else disabled.push(speech);
    });
    available.sort(
      (a, b) =>
        new Date(`${a.speechDate} ${a.startTime}`).getTime() - new Date(`${b.speechDate} ${b.startTime}`).getTime()
    );
    disabled.sort(
      (a, b) =>
        new Date(`${b.speechDate} ${b.startTime}`).getTime() - new Date(`${a.speechDate} ${a.startTime}`).getTime()
    );
    setAvailableSpeech(available);
    setDisabledSpeech(disabled);
  }, [speechList]);

  return (
    <>
      {speechList?.pages.length === 0 ? (
        <div className={styles.noResult}>
          <div>
            <p>아직 신청 가능한 스피치가 없어요</p>
            <div className={styles.banner}></div>
          </div>
        </div>
      ) : (
        <section className={styles.speechRequestWrap}>
          {!!availableSpeech.length && (
            <div className={styles.notYetSpeech}>
              <div className={styles.title}>
                <span>예정된 스피치</span>
              </div>
              <ul className={styles.speechList}>
                {availableSpeech.map((speech) => (
                  <li key={speech.speechId} className={styles.speech}>
                    <SpeechFrame
                      speech={speech}
                      refetch={() => {
                        refetch();
                      }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {!!disabledSpeech.length && (
            <div className={styles.pastSpeech}>
              <div className={styles.title}>
                <span>지난 스피치</span>
              </div>
              <ul className={styles.speechList}>
                {disabledSpeech.map((speech) => (
                  <li key={speech.speechId} className={styles.speech}>
                    <SpeechFrame
                      speech={speech}
                      isPastSpeech
                      refetch={() => {
                        refetch();
                      }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {!isFetching &&
            availableSpeech.length + disabledSpeech.length ===
              (() => {
                return speechList?.pages.map((page) => page.content.length).reduce((a, b) => a + b, 0);
              })() && // 해당 조건문 추가 이유는 데이터를 받아와서 sorting해서 상태값에 넣어주는 과정에 이미 isFetching이 false 처리되면서 간헐적으로 다음 페이지 연달아 조회가 돼서 넣음
            div}
        </section>
      )}
      {isLoading || (isFetching && <Loading />)}
    </>
  );
}

function MySpeech({ handleRefetch, onRefetch }: ITabProps) {
  const userData = useUserData();
  const queryClient = useQueryClient();

  const { inView, div } = useCostomInView();
  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage, refetch, isLoading } = useInfiniteQuery<{
    content: IMySpeechType[];
    last: boolean;
  }>(['mySpeechData'], ({ pageParam = 0 }) => getMySpeech(pageParam), {
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage.last) return allPages.length;
      else return false;
    },
  });

  useEffect(() => {
    if (!handleRefetch || !data) return;
    (async () => {
      queryClient.setQueryData(['data'], () => {
        return {
          pages: data.pages.slice(0, 1),
          pageParms: data.pageParams.slice(0, 1),
        };
      });
      await refetch();
      onRefetch();
    })();
  }, [handleRefetch]);
  useEffect(() => {
    if (inView && hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, data]);

  const getMySpeech = async (page: number) => {
    let res = await axiosAuthInstance.get(`/api/speech/my?userId=${userData.data?.userId}&size=10&page=${page}`);
    return res.data;
  };
  const [notCompletedSpeech, setNotCompletedSpeech] = useState<IMySpeechType[]>([]);
  const [completedSpeech, setCompletedSpeech] = useState<IMySpeechType[]>([]);

  useEffect(() => {
    if (!data) return;
    const notComplete: IMySpeechType[] = [];
    const complete: IMySpeechType[] = [];
    data.pages.map((page) => {
      page.content.map((speech) => {
        if (speech.speechCompleted || speech.mySpeechApplyStatus === 'C') complete.push(speech);
        else notComplete.push(speech);
      });
    });
    complete.sort(
      (a, b) =>
        new Date(`${b.speechDate} ${b.startTime}`).getTime() - new Date(`${a.speechDate} ${a.startTime}`).getTime()
    );
    setNotCompletedSpeech(notComplete);
    setCompletedSpeech(complete);
  }, [data]);
  return (
    <>
      {notCompletedSpeech.length === 0 && completedSpeech.length === 0 && !isFetching ? (
        <div className={`${styles.noResult} ${styles.noMySpeech}`}>
          <div>
            <p>신청한 스피치가 없어요</p>
            <div className={styles.banner}></div>
          </div>
        </div>
      ) : (
        <section className={styles.mySpeechWrap}>
          {!!notCompletedSpeech.length && (
            <div className={styles.notYetSpeech}>
              <div className={styles.title}>
                <span>예정된 스피치</span>
                <p>* 스피치 시작 5분 전부터 입장할 수 있어요.</p>
              </div>
              <ul className={styles.speechList}>
                {notCompletedSpeech.map((speech) => (
                  <li key={speech.speechId}>
                    <SpeechFrame
                      speech={speech}
                      refetch={() => {
                        refetch();
                      }}
                      isMySpeech
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {!!completedSpeech.length && (
            <div className={styles.pastSpeech}>
              <div className={styles.title}>
                <span>지난 스피치</span>
              </div>
              <ul className={styles.speechList}>
                {completedSpeech.map((speech) => (
                  <li key={speech.speechId}>
                    <SpeechFrame
                      speech={speech}
                      isMySpeech
                      isPastSpeech
                      refetch={() => {
                        refetch();
                      }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {!isFetching &&
            completedSpeech.length + notCompletedSpeech.length ===
              (() => {
                return data?.pages.map((page) => page.content.length).reduce((a, b) => a + b, 0);
              })() &&
            div}
        </section>
      )}
      {isLoading || (isFetching && <Loading />)}
    </>
  );
}
