import { useNavigate, useParams } from 'react-router-dom';
import IcMoveRightIcon from '../../assetComponents/IcMoveRightIcon';
import styles from './Notice.module.scss';
import NoticeDetail from '../NoticeDetail/NoticeDetail';
import Title from '../Title/Title';
import PAGE_ROUTER from '../../../constant/pageList';
import MobileHeader from '../../MobileHeader/MobileHeader';
import { useEffect } from 'react';
import { axiosAuthInstance } from '../../../api/axios';
import { useInfiniteQuery } from 'react-query';
import Loading from '../../Loading/Loading';
import INoticeType from '../../../type/INoticeType';
import useCostomInView from '../../../hooks/useCustomInView';

const NOTICE_DATA_PAGE_LENGTH = 10;
function Notice() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { inView, div } = useCostomInView();
  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery<{
    content: INoticeType[];
    last: boolean;
  }>(['noticeList'], ({ pageParam = 0 }) => getNoticeData(pageParam), {
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage.last) return allPages.length;
      else return false;
    },
    staleTime: 1000 * 60,
  });
  useEffect(() => {
    if (inView && hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, data]);

  const getNoticeData = async (page: number) => {
    let res = await axiosAuthInstance.get(`/api/board/notice?size=${NOTICE_DATA_PAGE_LENGTH}&page=${page}`);
    return res.data;
  };
  console.log(data);
  return (
    <>
      <MobileHeader
        title={'공지사항'}
        backBtn='arrow'
        backUrl={id ? '-1' : PAGE_ROUTER.라커룸?.subMenu?.설정.path || '/'}
      />
      <section className={styles.container}>
        <Title title='공지사항' isSticky={id ? true : false} />
        <div className={styles.contentWrap}>
          {id ? (
            <NoticeDetail id={+id} />
          ) : (
            <ul className={`${styles.noticeList}`}>
              {data?.pages.map((page, pageIdx) =>
                page.content.map((notice, idx) => (
                  <li
                    key={notice.id}
                    onClick={() =>
                      navigate(`${PAGE_ROUTER.라커룸.subMenu?.설정?.subMenu?.공지사항?.path}/${notice.id}`)
                    }
                  >
                    <div className={styles.noticeInfoWrap}>
                      <h3>{notice.title}</h3>
                      <span>{notice.noticeAt.split('T')[0]}</span>
                    </div>
                    <IcMoveRightIcon />
                  </li>
                ))
              )}
              {!isFetching && div}
            </ul>
          )}
        </div>
      </section>
      {isFetching && <Loading />}
    </>
  );
}
export default Notice;
