import Header from '../../components/Header/Header';
import styles from './MyWallet.module.scss';
import { ReactComponent as Avatar } from '../../assets/web/icons/avatar.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import PAGE_ROUTER from '../../constant/pageList';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as Open } from '../../assets/common/icons/ic_menu_open.svg';
import { ReactComponent as Close } from '../../assets/common/icons/ic_menu_close.svg';
import { ReactComponent as Notice } from '../../assets/common/icons/ic_notice.svg';
import { ReactComponent as Point } from '../../assets/web/icons/pic_point_55.svg';
import { ReactComponent as PointOff } from '../../assets/web/icons/pic_point_55_off.svg';
import { ReactComponent as Ticket } from '../../assets/web/icons/pic_ticket_55.svg';
import { ReactComponent as TicketOff } from '../../assets/web/icons/pic_ticket_55_off.svg';
import MetaSpaceIcon from '../../components/assetComponents/MetaSpaceIcon';
import CustomButton from '../../components/CustomButton/CustomButton';
import LockerRoomNavigateModal from '../../components/LockerRoomNavigateModal/LockerRoomNavigateModal';
import { useInfiniteQuery, useQuery } from 'react-query';
import { axiosAuthInstance } from '../../api/axios';
import IWalletHistoryType from '../../type/IWalletHistoryType';
import useCostomInView from '../../hooks/useCustomInView';
import Loading from '../../components/Loading/Loading';
import { queryClient } from '../..';
import useUserData from '../../hooks/useUserData';

const WALLET_HISTORY_PAGE_LENGTH = 10;

function MyWallet() {
  const userData = useUserData();

  const navigate = useNavigate();
  const location = useLocation();
  const [headerOpen, setHeaderOpen] = useState(false);
  const [tab, setTab] = useState(Number(new URL(document.URL).searchParams.get('tab')) || 0);
  const query = queryClient;
  const [isNoticeOpen, setIsNoticeOpen] = useState(false);

  useEffect(() => {
    let newTab = Number(new URL(document.URL).searchParams.get('tab')) || 0;
    if (newTab !== 0 && newTab !== 1) {
      navigate('/locker-room/my-wallet');
    }
    setTab(newTab);
    query.setQueryData(['wallet', tab === 0 ? 'ticket' : 'point'], () => undefined);
  }, [location]);

  const { data: assets, isLoading } = useQuery<{
    [key in 'P' | 'T']: number;
  }>(['myPoint'], () =>
    axiosAuthInstance.get(`/api/locker-room/currencies/accumulation?userId=${userData.data?.userId}`).then((res) => {
      let newData = res.data;
      return newData.reduce(
        (
          acc: {
            [key in 'p' | 'T']: { accumulation: number };
          },
          val: { currencyType: 'P' | 'T'; accumulation: number }
        ) => ({
          ...acc,
          [val.currencyType]: val.accumulation,
        }),
        {}
      );
    })
  );

  const {
    data: assetList,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<{
    content: IWalletHistoryType[];
    last: boolean;
  }>(['wallet', tab === 0 ? 'point' : 'ticket'], ({ pageParam = 0 }) => getHistoryData(pageParam), {
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage.last) return allPages.length;
      else return false;
    },
  });

  async function getHistoryData(page: number) {
    let res = await axiosAuthInstance.get(
      `/api/locker-room/currencies?size=${WALLET_HISTORY_PAGE_LENGTH}&page=${page}&tab=${
        tab === 0 ? 'points' : 'ticket'
      }&userId=${userData.data?.userId}`
    );
    return res.data;
  }

  const { inView, div } = useCostomInView();

  useEffect(() => {
    if (inView && hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage();
      console.log('test');
    }
  }, [inView, assetList]);
  return (
    <>
      <main className={styles.container}>
        <Header isNavShow />
        <nav className={`${styles.lockerRoomNav} max-1024`}>
          <div className={styles.left}>
            <button className={styles.hit}>내 지갑</button>
            <button onClick={() => navigate(PAGE_ROUTER.라커룸?.subMenu?.설정.path || '/', { replace: true })}>
              설정
            </button>
          </div>
          <button
            className={styles.avatarBtn}
            onClick={() => navigate(PAGE_ROUTER.라커룸?.subMenu?.['아바타 꾸미기']?.path || '/')}
          >
            <Avatar />
            <span>아바타 꾸미기</span>
          </button>
        </nav>
        <div className={`${styles.contentArea} max-1024`}>
          <header className={styles.mobileHeader}>
            <button className={styles.backBtn} onClick={() => navigate('/health/locker-room')}>
              <MetaSpaceIcon isLight />
            </button>
            <button className={styles.menuOpenBtn} onClick={() => setHeaderOpen((prev) => !prev)}>
              <span>내 지갑</span>
              {headerOpen ? <Close /> : <Open />}
            </button>
            <button className={styles.noticeBtn} onClick={() => setIsNoticeOpen(true)}>
              <Notice />
            </button>
          </header>
          <div className={styles.tabWrap}>
            <button className={styles.noticeBtn} onClick={() => setIsNoticeOpen(true)}>
              <Notice />
            </button>
            <ul>
              <li
                className={`${styles.pointTab} ${tab === 0 ? styles.hit : ''}`}
                onClick={() => navigate('?tab=0', { replace: true })}
              >
                <div className={styles.iconWrap}>{tab === 0 ? <Point /> : <PointOff />}</div>
                <div className={styles.tabInfo}>
                  <span>보유 포인트</span>
                  <span>{(assets?.P || 0).toLocaleString()} pt</span>
                </div>
              </li>
              <li
                className={`${styles.ticketTab} ${tab === 1 ? styles.hit : ''}`}
                onClick={() => navigate('?tab=1', { replace: true })}
              >
                <div className={styles.iconWrap}>{tab === 1 ? <Ticket /> : <TicketOff />}</div>
                <div className={styles.tabInfo}>
                  <span>보유 티켓</span>
                  <span>{(assets?.T || 0).toLocaleString()} 장</span>
                </div>
              </li>
            </ul>
          </div>
          <div className={styles.walletDataTableWrap}>
            <table className={styles.walletDataTable}>
              <thead>
                <tr>
                  <th>사용내역</th>
                  <th>획득/사용</th>
                  <th>보유</th>
                </tr>
              </thead>
              <tbody>
                {(() => {
                  return assetList?.pages.map((page) =>
                    page.content.map((data, idx) => (
                      <tr key={idx}>
                        <td>
                          <div>
                            <span>{data.body}</span>
                            <span>{data.accountingDateTime.slice(0, 10)}</span>
                          </div>
                        </td>
                        <td>
                          {data.accounting > 0 ? '+' : '-'} {Math.abs(data.accounting).toLocaleString()}
                          {tab === 0 ? 'pt' : '장'}
                        </td>
                        <td>
                          {data.accumulation.toLocaleString()}
                          {tab === 0 ? 'pt' : '장'}
                        </td>
                      </tr>
                    ))
                  );
                })()}
                <tr style={{ height: 0 }}>
                  <td
                    style={{
                      height: 0,
                      borderBottom: 'none',
                    }}
                  >
                    {!isFetching && div}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </main>
      {isNoticeOpen && <InfoModal tab={tab === 0 ? 'point' : 'ticket'} setIsNoticeOpen={setIsNoticeOpen} />}
      {headerOpen && <LockerRoomNavigateModal setNavigateModalOpen={setHeaderOpen} />}
      {(isFetching || isLoading) && <Loading />}
    </>
  );
}
export default MyWallet;

const POINT_STRING = `포인트는 검사와 운동 과정에서 적립되고,
내 아바타를
꾸미기 위해<br class='${styles.pcBr} test' />
포인트를 사용할 수 있어요.
<br />
<br />
포인트 적립 기준은 다음과 같아요.
<br />
<br />
▪︎ 가입 완료 : 1,000pt<br />
▪︎ 동작검사 완료 : 300pt<br />
▪︎ 오늘의 운동 완료 : 100pt<br />
▪︎ 목표 점수 달성 : 1,000pt<br />
▪︎ 운동 복귀 : 200pt<br />
▪︎ 이너힐링 콘텐츠 완료 : 50pt
`;
const TICKET_STRING = `
티켓은 관리자에 의해 적립되고,<br />
1:1 상담을 이용해 티켓을 사용할 수 있어요.
<br /><br />
티켓 적립 기준은 다음과 같아요.<br /><br />
 ▪︎ 가입 완료 : 4장<br />
 ▪︎ 관리자 수동 지급<br />
 ▪︎ 소속기관 자동 지급
`;

interface IInfoModalProps {
  tab: 'point' | 'ticket';
  setIsNoticeOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
function InfoModal({ tab, setIsNoticeOpen }: IInfoModalProps) {
  const [activeTab, setActiveTab] = useState(tab);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <ul className={styles.tabList}>
          <li className={activeTab === 'point' ? styles.hit : ''} onClick={() => setActiveTab('point')}>
            <button>포인트</button>
          </li>
          <li className={activeTab === 'ticket' ? styles.hit : ''} onClick={() => setActiveTab('ticket')}>
            <button>티켓</button>
          </li>
        </ul>
        <div className={styles.modalContentWrap}>
          <p
            className={styles.modalContent}
            dangerouslySetInnerHTML={{
              __html: activeTab === 'point' ? POINT_STRING : TICKET_STRING,
            }}
          ></p>
        </div>
        <div className={styles.submitBtnWrap} onClick={() => setIsNoticeOpen(false)}>
          <CustomButton>확인하기</CustomButton>
        </div>
      </div>
    </div>
  );
}
