import { useNavigate } from 'react-router';
import CustomButton from '../../components/CustomButton/CustomButton';
import Header from '../../components/Header/Header';
import styles from './NotFound.module.scss';
import { useEffect } from 'react';

function NotFound() {
    const navigate = useNavigate();
    function handleSubmit() {
        navigate('/health/fitness');
    }
    useEffect(() => {
        navigate('/health/locker-room');
    }, []);
    return '';
    // <main className={styles.container}>
    //     <Header />
    //     <div className={styles.contentWrap}>
    //         <div className={styles.warningImageWrap}></div>
    //         <h5>페이지 조회 불가</h5>
    //         <p>불편을 드려 죄송해요.</p>
    //         <div
    //             className={styles.innerSubmitBtnWrap}
    //             onClick={handleSubmit}
    //         >
    //             <CustomButton>홈으로 이동하기</CustomButton>
    //         </div>
    //     </div>
    //     <div className={styles.submitBtnWrap}>
    //         <div onClick={handleSubmit}>
    //             <CustomButton>홈으로 이동하기</CustomButton>
    //         </div>
    //     </div>
    // </main>
}
export default NotFound;
