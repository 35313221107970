import styles from './MetabolismCheckResult.module.scss';
import CustomButton from '../../components/CustomButton/CustomButton';
import Header from '../../components/Header/Header';
import StampBackground from '../../components/StampBackground/StampBackground';
import Stamp from '../../components/Stamp/Stamp';
import { useNavigate } from 'react-router-dom';
import LineChart, { lineChartDataType } from '../../components/LineChart/LineChart';
import { useQuery } from 'react-query';
import { axiosAuthInstance } from '../../api/axios';
import IMetabolismCheckResult from '../../type/IMetabolismCheckResult';
import useRecentWeek from '../../hooks/useRecentWeek';
import dateFormatter from '../../common/dateFormatter';
import Loading from '../../components/Loading/Loading';
import CheckCompleteModal from '../../components/CheckCompleteModal/CheckCompleteModal';
import { useEffect, useState } from 'react';
import getStampSentence from '../../common/getStampSentence';
import useUserData from '../../hooks/useUserData';
import MetabolismCheckResultContent from '../../components/MetabolismCheckResultContent/MetabolismCheckResultContent';

function MetabolismCheckResult() {
  const navigate = useNavigate();
  const userData = useUserData();

  function handleSubmit() {
    if (!data) return;
    if (checkType === 'initial') {
      localStorage.setItem('check-compensation-point', String(data.achievingCompensationPoint.toLocaleString()));
      navigate('/physical-check/resetting/alert');
    } else setCompensation((prev) => ({ ...prev, modalOpen: true }));
  }

  const { data, isLoading } = useQuery<IMetabolismCheckResult>(['metabolism-check-result'], () =>
    axiosAuthInstance.get(`/api/fitness/survey/initial-result?userId=${userData.data?.userId}`).then((res) => res.data)
  );
  const [compensation, setCompensation] = useState({
    modalOpen: false,
    point: 0,
  });
  const [checkType, setCheckType] = useState<'initial' | 'middle'>(); //initial:첫/재 검사, middle:중간검사
  useEffect(() => {
    if (!data) return;
    setCompensation((prev) => ({
      ...prev,
      point: data.achievingCompensationPoint,
    }));
    setCheckType(data.activities.easy.length > 1 ? 'middle' : 'initial');
    // setCheckType('initial');
  }, [data]);

  return (
    <>
      <main className={styles.container}>
        <div className={styles.bg}>
          <Header isNavShow />
          <div className={styles.contentWrap}>
            <header className={styles.mobileHeader}>검사 결과</header>
            <div className={styles.menu}>
              <button>오늘의 운동</button>
            </div>
            <div className={styles.scrollableArea}>
              <h6 className={styles.sectionTitle}>
                {data ? data.currentExamDateTime.slice(0, 10).replace(/-/g, '. ') : ' '}
              </h6>
              <div className={styles.resultContentWrap}>{data && <MetabolismCheckResultContent data={data} />}</div>
            </div>
            <div className={styles.submitBtnWrap}>
              <div onClick={handleSubmit}>
                <CustomButton height={56}>확인하기</CustomButton>
              </div>
            </div>
          </div>
        </div>
      </main>
      {compensation.modalOpen && (
        <CheckCompleteModal
          point={String(compensation.point.toLocaleString())}
          onSubmit={() => {
            navigate('/fitness/exercise-of-the-day');
          }}
        />
      )}
      {isLoading && <Loading />}
    </>
  );
}

export default MetabolismCheckResult;
