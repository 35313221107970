import styles from './MobileHeader.module.scss';
import { ReactComponent as MetaBack } from '../../assets/mobile/icons/meta_space.svg';
import { ReactComponent as Back } from '../../assets/mobile/icons/ic_back_b.svg';
import { useNavigate } from 'react-router-dom';

interface IPropsType {
    title: string;
    backBtn: 'meta' | 'arrow';
    backUrl?: string;
    backFn?: () => void;
}

function MobileHeader({ title, backBtn, backUrl, backFn }: IPropsType) {
    const navigate = useNavigate();
    return (
        <header className={styles.container}>
            <button
                className={styles.backBtn}
                onClick={() => {
                    if (backUrl)
                        isNaN(+backUrl) ? navigate(backUrl) : navigate(-1);
                    else if (backFn) backFn();
                }}
            >
                {backBtn === 'meta' ? <MetaBack /> : <Back />}
            </button>
            <button className={styles.menuOpenBtn}>
                <span>{title}</span>
            </button>
        </header>
    );
}
export default MobileHeader;
