import { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import styles from './MovementReview.module.scss';
import { ReactComponent as Back } from '../../assets/mobile/icons/ic_back_b.svg';
import { ReactComponent as Vertical } from '../../assets/common/background/pic_vertical.svg';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MOVEMENT_DATA from '../../constant/movementData';
import { useMutation, useQueryClient } from 'react-query';
import { axiosAuthInstance } from '../../api/axios';
import Loading from '../../components/Loading/Loading';
import MskResultType from '../../type/MskResultType';
import Checkbox from '../../components/CheckBox/Checkbox';
import UNCOMFORTABLE_PART_CODE from '../../constant/uncomfortablePartCode';
import useUserData from '../../hooks/useUserData';
import { useDispatch } from '../../store/store';

const BUTTON_LOCATION_VALUE = [
  { top: '136px', left: '0' },
  { top: '93px', left: '17px' },
  { top: '56px', left: '45px' },
  { top: '26px', left: '82px' },
  { top: '7px', left: '125px' },
  { top: '0px', left: '173px' },
  { top: '7px', left: '221px' },
  { top: '26px', left: '264px' },
  { top: '56px', left: '301px' },
  { top: '93px', left: '329px' },
  { top: '136px', left: '346px' },
];
function MovementReview() {
  const navigate = useNavigate();
  const userData = useUserData();
  const location = useLocation();
  const { movementId } = useParams() as { movementId: string };
  const { menu } = useParams() as { menu: 'function' | 'discomfort' };
  const [completeModalOpen, setCompleteModalOpen] = useState(false); //pc에선 submit시 complete modal 노출
  const [verticalModalOpen, setVerticalModalOpen] = useState(false); //모바일은 세로 진행 안내 모달 노출

  const [score, setScore] = useState<{
    function: undefined | number;
    discomfort: undefined | number;
  }>({
    function: undefined,
    discomfort: undefined,
  });

  const { mutateAsync, isLoading } = useMutation(() =>
    axiosAuthInstance.post('/api/fitness/exam/evaluation', {
      userId: userData.data?.userId,
      gestureCode: movementId,
      uncomfortablePart: 'lb',
      functionScore: score.function,
      painScore: score.discomfort,
    })
  );
  async function handleSubmit() {
    if (isNaN(Number(score[menu]))) return;
    if (menu === 'function')
      navigate(`/physical-check/movement-review/${movementId}/discomfort`, {
        state: location.state,
      });
    else {
      await mutateAsync();
      if (location.state.isLast) {
        if (window.innerWidth >= 1024) setCompleteModalOpen(true);
        else setVerticalModalOpen(true);
      } else {
        navigate('/physical-check/msk?index=3');
      }
    }
  }

  //사용자가 임의로 url을 조작하여 잘못된 param으로 접속시 대응
  useEffect(() => {
    if (!Object.keys(MOVEMENT_DATA).includes(movementId) || (menu !== 'function' && menu !== 'discomfort'))
      navigate('/fitness/exercise-of-the-day');
  }, [location]);
  //사용자가 임의로 url을 조작하여 잘못된 param으로 접속시 대응

  // function 입력하지 않은 상태로 discomfort 페이지 에 있을 시 function으로 redirect
  useEffect(() => {
    if (menu === 'discomfort' && isNaN(Number(score.function)))
      navigate(`/physical-check/movement-review/${movementId}/function`, {
        state: {
          isLast: location.state.isLast,
        },
      });
  }, []);
  // function 입력하지 않은 상태로 discomfort 페이지 에 있을 시 function으로 redirect

  // isLast 값 없이 해당 페이지 도달 시 정상적인 접속 아니므로 오늘의 운동 페이지로 이동
  useEffect(() => {
    if (typeof location.state?.isLast !== 'boolean') navigate('/fitness/exercise-of-the-day');
  }, []);
  // isLast 값 없이 해당 페이지 도달 시 정상적인 접속 아니므로 오늘의 운동 페이지로 이동

  const {
    data: mskResultData,
    mutateAsync: getResult,
    isLoading: resultDataLoading,
  } = useMutation<MskResultType>(() =>
    axiosAuthInstance.get(`/api/fitness/exam/initial-result?userId=${userData.data?.userId}`).then((res) => res.data)
  );

  const queryClient = useQueryClient();
  const [goalCongratulationModalOpen, setGoalCongratulationModalOpen] = useState(false);
  const [goalChangeModalOpen, setGoalChangeModalOpen] = useState(false);
  async function handleCheckResult() {
    const data: MskResultType = await getResult();
    if (data.examTypeCode === 'M') {
      queryClient.setQueryData(['msk-result'], data);
      if (data.hasGoalAchieved) setGoalCongratulationModalOpen(true);
      else if (data.isGoalAdjustmentRequired) setGoalChangeModalOpen(true);
      else navigate('/physical-check/result/msk');
    } else navigate('/physical-check/result/msk');
  }
  return (
    <>
      {Object.keys(MOVEMENT_DATA).includes(movementId) && (menu === 'function' || menu === 'discomfort') && (
        <main className={styles.container}>
          <div className={styles.bg}>
            <Header isNavShow />
            <div className={styles.contentWrap}>
              <div className={styles.menu}>
                <button>오늘의 운동</button>
              </div>
              <div className={styles.scrollableArea}>
                <div className={styles.tab}>검사</div>
                <h5 className={styles.movementName}>{MOVEMENT_DATA[movementId].title}</h5>
                <div className={styles.movementScoreWrap}>
                  <button className={styles.backBtn} onClick={() => navigate(-1)}></button>
                  <div
                    className={`${styles.movementThumbnailWrap} ${menu === 'discomfort' ? styles.discomfort : ''} ${
                      !isNaN(Number(score[menu])) ? `score${score[menu]}` : ''
                    } ${`movement${MOVEMENT_DATA[movementId].movement_number}`}`}
                  ></div>
                  <div className={styles.score}>
                    <p>
                      {menu === 'function'
                        ? '방금 전 동작을 했을 때 어디까지 움직일 수 있었나요?'
                        : '방금 전 동작을 했을 때 얼마나 불편했나요?'}
                    </p>
                    <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
                      <div className={!isNaN(Number(score[menu])) ? styles.hit : ''}>
                        {isNaN(Number(score[menu]))
                          ? '아래 숫자를 선택해주세요'
                          : MOVEMENT_DATA[movementId][menu][score[menu]!].title}
                      </div>
                      <button className={!isNaN(Number(score[menu])) ? styles.hit : ''} onClick={handleSubmit}>
                        <Back />
                      </button>
                    </form>

                    <div className={styles.scoreSelectWrap}>
                      <div className={styles.scoreSelect}>
                        <div
                          className={`${styles.face} ${
                            !isNaN(Number(score[menu])) ? MOVEMENT_DATA[movementId][menu][score[menu]!].color : ''
                          }`}
                        ></div>
                        <div
                          className={`${styles.stick} ${isNaN(Number(score[menu])) ? 'stick0' : `stick${score[menu]}`}`}
                        ></div>
                        <div className={`${styles.progress} ${menu}`}></div>
                        <ul className={styles.btns}>
                          {new Array(11).fill('').map((val, index) => (
                            <li
                              key={index}
                              className={`button${index} ${score[menu] === index ? styles.hit : ''}`}
                              style={{
                                top: BUTTON_LOCATION_VALUE[index].top,
                                left: BUTTON_LOCATION_VALUE[index].left,
                              }}
                            >
                              <button
                                onClick={() =>
                                  setScore((prev) => ({
                                    ...prev,
                                    [menu]: index,
                                  }))
                                }
                              >
                                {index}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className={styles.guide}>
                        <span>{menu === 'function' ? '동작 불가' : '불편 없음'}</span>
                        <span>{menu === 'function' ? '동작 가능' : '매우 불편함'}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.submitBtnWrap}>
                  <div onClick={handleSubmit}>
                    <CustomButton isActive={!isNaN(Number(score[menu]))}>다음</CustomButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
      {(completeModalOpen || verticalModalOpen) && (
        <div className={styles.dimed}>
          {completeModalOpen ? (
            <article className={styles.completeModal}>
              <p>이제 검사 결과를 확인해보세요!</p>
              <div
                onClick={() => {
                  setCompleteModalOpen(false);
                  handleCheckResult();
                }}
              >
                <CustomButton>확인하기</CustomButton>
              </div>
            </article>
          ) : (
            <article className={styles.verticalModal}>
              <Vertical />
              <p>다음 화면부터 세로로 진행돼요.</p>
              <div
                onClick={() => {
                  setVerticalModalOpen(false);
                  handleCheckResult();
                }}
              >
                <CustomButton>확인하기</CustomButton>
              </div>
            </article>
          )}
        </div>
      )}
      {(isLoading || resultDataLoading) && <Loading />}
      {mskResultData?.examTypeCode === 'M' && goalCongratulationModalOpen && (
        <GoalCongratulationModal
          uncomfortablePartCode={mskResultData.uncomfortablePartCode}
          isVisible={!goalChangeModalOpen}
          point={mskResultData?.goalAchievingCompensationPoint || 0}
          onSubmit={() => {
            if (mskResultData.isGoalAdjustmentRequired) setGoalChangeModalOpen(true);
            else navigate('/physical-check/result/msk');
          }}
        />
      )}
      {goalChangeModalOpen && (
        <GoalChangedModal
          onSubmit={() => {
            navigate('/physical-check/result/msk');
          }}
        />
      )}
    </>
  );
}
export default MovementReview;

interface ICongratulationModalProps {
  uncomfortablePartCode: string;
  isVisible: boolean;
  point: number;
  onSubmit: () => void;
}
function GoalCongratulationModal({ uncomfortablePartCode, isVisible, point, onSubmit }: ICongratulationModalProps) {
  const [isChecked, setIsChecked] = useState(true);
  const userData = useUserData();

  const { mutateAsync, isLoading } = useMutation(() =>
    axiosAuthInstance.post('/api/cafeteria/feed', {
      userId: userData.data?.userId,
      feedType: 'G',
      contents: `${UNCOMFORTABLE_PART_CODE[uncomfortablePartCode].ko} 운동 목표를 달성했어요! 🎉`,
    })
  );
  async function handleSubmit() {
    await mutateAsync();
    onSubmit();
  }
  return (
    <>
      <div className={`${styles.goalCongratulationModal} ${!isVisible ? styles.unvisible : ''}`}>
        <article className={styles.modal}>
          <div className={styles.modalContent}>
            <span className={styles.point}>+{point.toLocaleString()}pt</span>
            <div className={styles.crackerBg}></div>
            <h6>목표 달성</h6>
            <p>목표를 달성했어요. 축하해요!</p>
          </div>
          <div className={styles.modalBottom}>
            <label onClick={() => setIsChecked((prev) => !prev)}>
              <Checkbox isChecked={isChecked} />
              <span>카페테리아에 목표 달성 자랑하기</span>
            </label>
            <div className={styles.modalSubmitBtn} onClick={handleSubmit}>
              <CustomButton height={56}>결과 확인하기</CustomButton>
            </div>
          </div>
          <div className={styles.fireCracker}>
            <img src='../../../fire_cracker.gif' alt='폭죽' />
          </div>
          <div className={`${styles.fireCracker} ${styles.right}`}>
            <img src='../../../fire_cracker.gif' alt='폭죽' />
          </div>
        </article>
      </div>
      {isLoading && <Loading />}
    </>
  );
}

function GoalChangedModal({ onSubmit }: { onSubmit: () => void }) {
  return (
    <div className={styles.goalChangedModal}>
      <article className={styles.modal}>
        <p>
          운동 진행상황에 맞춰
          <br />
          목표가 조정 됐어요.
        </p>
        <div className={styles.modalSubmitBtn}>
          <div onClick={onSubmit}>
            <CustomButton>확인하기</CustomButton>
          </div>
        </div>
      </article>
    </div>
  );
}
