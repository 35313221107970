import { useState } from 'react';
import CustomButton from '../../CustomButton/CustomButton';
import styles from './KakaoAlert.module.scss';
import TimerAlertModal from '../../TimerAlertModal/TimerAlertModal';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { axiosAuthInstance } from '../../../api/axios';
import Loading from '../../Loading/Loading';
import dateFormatter from '../../../common/dateFormatter';
import CheckCompleteModal from '../../CheckCompleteModal/CheckCompleteModal';
import useUserData from '../../../hooks/useUserData';

function KakaoAlert() {
  const navigate = useNavigate();
  const userData = useUserData();

  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);
  const [exerciseAlertTime, setExerciseAlertTime] = useState({
    isAm: false,
    hour: 8,
    minute: 0,
  });

  const { mutateAsync, isLoading } = useMutation(() =>
    axiosAuthInstance.post(`/api/locker-room/notification?userId=${userData.data?.userId}`, {
      isKakaoAlertReceive: true,
      isExerciseAlertReceive: true,
      exerciseAlertReceiveTime: (() => {
        let hour = exerciseAlertTime.hour + (!exerciseAlertTime.isAm && exerciseAlertTime.hour !== 12 ? 12 : 0);
        if (hour === 12 && exerciseAlertTime.isAm) hour = 0;
        return `${dateFormatter(hour)}:${dateFormatter(exerciseAlertTime.minute)}:00`;
      })(),
    })
  );
  const [compensation, setCompensation] = useState({
    modalOpen: false,
    point: localStorage.getItem('check-compensation-point') || '',
  });

  async function handleSubmit() {
    await mutateAsync();
    setCompensation((prev) => ({ ...prev, modalOpen: true }));
  }
  return (
    <>
      <div className={styles.tab}>검사</div>
      <h4 className={styles.title}>운동 알람 설정</h4>
      <div className={styles.alertSettingWrap}>
        <p>몇 시에 알림톡을 보내드릴까요?</p>
        <div className={styles.alertTimeWrap}>
          <div>
            <span>{exerciseAlertTime.isAm ? '오전' : '오후'}&nbsp;</span>
            <span>
              {exerciseAlertTime.hour}:{('0' + exerciseAlertTime.minute).slice(-2)}
            </span>
          </div>
          <button onClick={() => setIsOpenAlertModal(true)}>변경</button>
        </div>
      </div>
      <div className={styles.submitBtnWrap}>
        <div onClick={handleSubmit}>
          <CustomButton height={56}>확인하기</CustomButton>
        </div>
      </div>
      {isOpenAlertModal && (
        <TimerAlertModal
          setIsOpenAlertModal={setIsOpenAlertModal}
          exerciseAlertTime={exerciseAlertTime}
          setExerciseAlertTime={setExerciseAlertTime}
        />
      )}
      {compensation.modalOpen && (
        <CheckCompleteModal point={compensation.point} onSubmit={() => navigate('/fitness/exercise-of-the-day')} />
      )}
      {isLoading && <Loading />}
    </>
  );
}
export default KakaoAlert;
