import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import MobileHeader from '../../components/MobileHeader/MobileHeader';
import styles from './MetabolismCheck.module.scss';
import { useEffect, useState } from 'react';
import ActivityLevelCheck from '../../components/MetabolismCheckList/ActivityLevelCheck/ActivityLevelCheck';
import ExerciseLevelReview from '../../components/MetabolismCheckList/ExerciseLevelReview/ExerciseLevelReview';
import SelectExerciseLevel from '../../components/MetabolismCheckList/SelectExerciseLevel/SelectExerciseLevel';
import { useMutation } from 'react-query';
import { axiosAuthInstance } from '../../api/axios';
import Loading from '../../components/Loading/Loading';
import useUserData from '../../hooks/useUserData';

const METABOLISM_CHECK_ORDER = ['운동 난이도 평가', '활동량 검사', '운동 난이도 선택'];

function MetabolismCheck() {
  const { state } = useLocation();

  // examType 없으면 오늘의운동 페이지 이동
  useEffect(() => {
    if (!state?.examType) navigate('/fitness/exercise-of-the-day');
  }, [state]);

  const navigate = useNavigate();
  const userData = useUserData();
  const location = useLocation();
  const [stopModalOpen, setStopModalOpen] = useState(false);

  const [index, setIndex] = useState(Number(new URL(window.location.href).searchParams.get('index') || '0'));

  useEffect(() => {
    let newIndex = Number(new URL(window.location.href).searchParams.get('index') || 0);
    // if (!newIndex) navigate('?index=0');
    setIndex(newIndex);
  }, [location]);

  async function handleNext() {
    if (isLoading) return;
    if (state.examType === 'M') {
      if (index === 0) {
        await mutateAsync();
        navigate(`?index=${index + 1}`, { state });
      } else if (index === 1) {
        navigate('/physical-check/result/metabolism');
      }
    } else {
      if (index === 1) navigate(`?index=${index + 1}`, { state });
      else if (index === 2) {
        await mutateAsync();
        navigate('/physical-check/result/metabolism');
      }
    }
    // else mutate();
  }

  const { mutateAsync, isLoading } = useMutation(() =>
    axiosAuthInstance.post('/api/fitness/survey/exercise-difficulty/evaluation', {
      examTypeCode: state?.examType,
      ...(state.examType === 'I' || state.examType === 'R' //운동 난이도 선택 (첫/재 검사만)
        ? {
            level: Number(sessionStorage.getItem('select-exercise-level')),
          }
        : {}),
      ...(state.examType === 'M' // 운동 난이도 평가(중간검사만)
        ? {
            levelAdjustedValue: (() => {
              const VAL = Number(sessionStorage.getItem('exercise-level-review'));
              return VAL === 0 ? -1 : VAL === 1 || VAL === 2 ? 0 : VAL === 3 ? 1 : VAL === 4 ? 2 : null;
            })(),
          }
        : {}),
      userId: Number(userData.data?.userId),
    })
  );
  return (
    <>
      <main className={styles.container}>
        <div className={styles.bg}>
          <Header isNavShow />
          <div className={styles.contentWrap}>
            <MobileHeader title={METABOLISM_CHECK_ORDER[index]} backBtn='arrow' />
            <div className={styles.menu}>
              <button>오늘의 운동</button>
            </div>
            <div className={styles.wrap}>
              <div className={styles.tab}>검사</div>
              <div className={styles.scrollableArea}>
                <h4 className={styles.title}>{METABOLISM_CHECK_ORDER[index]}</h4>

                {index === 0 ? (
                  <ExerciseLevelReview nextFn={handleNext} />
                ) : index === 1 ? (
                  <ActivityLevelCheck nextFn={handleNext} />
                ) : index === 2 ? (
                  <SelectExerciseLevel nextFn={handleNext} />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      {isLoading && <Loading />}
    </>
  );
}
export default MetabolismCheck;
