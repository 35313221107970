import { useNavigate } from 'react-router-dom';
// import Header from '../../components/Header/Header';
import checkMetaverseOrigin from '../../common/checkMetaverseOrigin';
import { useDispatch } from '../../store/store';
import styles from './EditAvatar.module.scss';
import { logout } from '../../store/jwt';
import { useEffect } from 'react';
// import PAGE_ROUTER from '../../constant/pageList';
// import { ReactComponent as Avatar } from '../../assets/web/icons/avatar.svg';

function EditAvatar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    function handleMessage(e: MessageEvent) {
      if (!checkMetaverseOrigin(e.origin)) return;
      console.log(e.data);
      const PARAMS = new URLSearchParams(e.data);
      const UNAUTHORIZED = PARAMS.get('unauthorized');
      if (UNAUTHORIZED == 'true') {
        dispatch(logout());
        navigate('/');
      }
    }
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  return (
    <main className={styles.container}>
      {/* <Header isNavShow />
      <nav className={`${styles.lockerRoomNav} max-1024`}>
        <div className={styles.left}>
          <button onClick={() => navigate(PAGE_ROUTER.라커룸?.subMenu?.['내 지갑']?.path || '/')}>내 지갑</button>
          <button onClick={() => navigate(PAGE_ROUTER.라커룸?.subMenu?.설정.path || '/')}>설정</button>
        </div>
        <button
          className={`${styles.avatarBtn} ${styles.hit}`}
          onClick={() => navigate(PAGE_ROUTER.라커룸?.subMenu?.['아바타 꾸미기']?.path || '/')}
        >
          <Avatar />
          <span>아바타 꾸미기</span>
        </button>
      </nav>
      <div className={`${styles.contentArea} max-1024`}>
        <div className={styles.iframeWrap}> */}
      <iframe
        src={`${process.env.REACT_APP_METAVERSE_DOMAIN}?status=khavatar&token=${window.btoa(
          localStorage.getItem('access-token') || ''
        )}`}
        allowTransparency
        frameBorder='0'
      ></iframe>
      {/* </div>
      </div> */}
    </main>
  );
}
export default EditAvatar;
