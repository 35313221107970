import Header from '../Header/Header';
import MobileHeader from '../MobileHeader/MobileHeader';
import styles from './Frame.module.scss';

interface IPropsType {
    menuTitle: string;
    useMobileHeader?: boolean;
    mobileHeaderTitle?: string;
    mobileBackBtn?: () => void;
    children: React.ReactNode;
    isHorizontal?: boolean;
}
function Frame({
    menuTitle,
    useMobileHeader,
    mobileHeaderTitle,
    mobileBackBtn,
    children,
    isHorizontal,
}: IPropsType) {
    return (
        <main
            className={`${styles.container} ${
                isHorizontal ? styles.horizontal : ''
            }`}
        >
            <div className={styles.bg}>
                <Header isNavShow />
                <div className={styles.contentWrap}>
                    {useMobileHeader && mobileHeaderTitle && mobileBackBtn && (
                        <MobileHeader
                            title={mobileHeaderTitle}
                            backBtn='arrow'
                            backFn={mobileBackBtn}
                        />
                    )}
                    <div className={styles.menu}>
                        <button>{menuTitle}</button>
                    </div>
                    <div className={styles.scrollableArea}>{children}</div>
                </div>
            </div>
        </main>
    );
}
export default Frame;
