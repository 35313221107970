interface IMovementDataType {
  [key: string]: {
    title: string;
    movement_number: number;
    function: { color: string; title: string }[];
    discomfort: { color: string; title: string }[];
  };
}
const MOVEMENT_DATA: IMovementDataType = {
  ev_lbfl: {
    title: '몸통 숙이기',
    movement_number: 1,
    function: [
      {
        color: 'red',
        title: '거의 따라하지 못해요',
      },
      {
        color: 'red',
        title: '거의 따라하지 못해요',
      },
      {
        color: 'red',
        title: '거의 따라하지 못해요',
      },
      {
        color: 'orange',
        title: '어느정도 가능하지만, 힘들어요',
      },
      {
        color: 'orange',
        title: '어느정도 가능하지만, 힘들어요',
      },
      {
        color: 'orange',
        title: '어느정도 가능하지만, 힘들어요',
      },
      {
        color: 'sky',
        title: '어느정도 따라할 수 있어요',
      },
      {
        color: 'sky',
        title: '어느정도 따라할 수 있어요',
      },
      {
        color: 'sky',
        title: '어느정도 따라할 수 있어요',
      },
      {
        color: 'blue',
        title: '쉽게 따라할 수 있어요',
      },
      {
        color: 'blue',
        title: '쉽게 따라할 수 있어요',
      },
    ],
    discomfort: [
      {
        color: 'blue',
        title: '거의 불편하지 않아요',
      },
      {
        color: 'blue',
        title: '거의 불편하지 않아요',
      },
      {
        color: 'blue',
        title: '거의 불편하지 않아요',
      },
      {
        color: 'sky',
        title: '조금 불편하지만, 참을만해요',
      },
      {
        color: 'sky',
        title: '조금 불편하지만, 참을만해요',
      },
      {
        color: 'sky',
        title: '조금 불편하지만, 참을만해요',
      },
      {
        color: 'orange',
        title: '불편하고 힘들어요',
      },
      {
        color: 'orange',
        title: '불편하고 힘들어요',
      },
      {
        color: 'orange',
        title: '불편하고 힘들어요',
      },
      {
        color: 'red',
        title: '너무 불편해서 참을 수 없어요',
      },
      {
        color: 'red',
        title: '너무 불편해서 참을 수 없어요',
      },
    ],
  },
  ev_lbex: {
    title: '몸통 뒤로 젖히기',
    movement_number: 2,
    function: [
      {
        color: 'red',
        title: '거의 따라하지 못해요',
      },
      {
        color: 'red',
        title: '거의 따라하지 못해요',
      },
      {
        color: 'red',
        title: '거의 따라하지 못해요',
      },
      {
        color: 'orange',
        title: '어느정도 가능하지만, 힘들어요',
      },
      {
        color: 'orange',
        title: '어느정도 가능하지만, 힘들어요',
      },
      {
        color: 'orange',
        title: '어느정도 가능하지만, 힘들어요',
      },
      {
        color: 'sky',
        title: '어느정도 따라할 수 있어요',
      },
      {
        color: 'sky',
        title: '어느정도 따라할 수 있어요',
      },
      {
        color: 'sky',
        title: '어느정도 따라할 수 있어요',
      },
      {
        color: 'blue',
        title: '쉽게 따라할 수 있어요',
      },
      {
        color: 'blue',
        title: '쉽게 따라할 수 있어요',
      },
    ],
    discomfort: [
      {
        color: 'blue',
        title: '거의 불편하지 않아요',
      },
      {
        color: 'blue',
        title: '거의 불편하지 않아요',
      },
      {
        color: 'blue',
        title: '거의 불편하지 않아요',
      },
      {
        color: 'sky',
        title: '조금 불편하지만, 참을만해요',
      },
      {
        color: 'sky',
        title: '조금 불편하지만, 참을만해요',
      },
      {
        color: 'sky',
        title: '조금 불편하지만, 참을만해요',
      },
      {
        color: 'orange',
        title: '불편하고 힘들어요',
      },
      {
        color: 'orange',
        title: '불편하고 힘들어요',
      },
      {
        color: 'orange',
        title: '불편하고 힘들어요',
      },
      {
        color: 'red',
        title: '너무 불편해서 참을 수 없어요',
      },
      {
        color: 'red',
        title: '너무 불편해서 참을 수 없어요',
      },
    ],
  },
  ev_lbrr: {
    title: '몸통 오른쪽으로 돌리기',
    movement_number: 3,
    function: [
      {
        color: 'red',
        title: '거의 따라하지 못해요',
      },
      {
        color: 'red',
        title: '거의 따라하지 못해요',
      },
      {
        color: 'red',
        title: '거의 따라하지 못해요',
      },
      {
        color: 'orange',
        title: '어느정도 가능하지만, 힘들어요',
      },
      {
        color: 'orange',
        title: '어느정도 가능하지만, 힘들어요',
      },
      {
        color: 'orange',
        title: '어느정도 가능하지만, 힘들어요',
      },
      {
        color: 'sky',
        title: '어느정도 따라할 수 있어요',
      },
      {
        color: 'sky',
        title: '어느정도 따라할 수 있어요',
      },
      {
        color: 'sky',
        title: '어느정도 따라할 수 있어요',
      },
      {
        color: 'blue',
        title: '쉽게 따라할 수 있어요',
      },
      {
        color: 'blue',
        title: '쉽게 따라할 수 있어요',
      },
    ],
    discomfort: [
      {
        color: 'blue',
        title: '거의 불편하지 않아요',
      },
      {
        color: 'blue',
        title: '거의 불편하지 않아요',
      },
      {
        color: 'blue',
        title: '거의 불편하지 않아요',
      },
      {
        color: 'sky',
        title: '조금 불편하지만, 참을만해요',
      },
      {
        color: 'sky',
        title: '조금 불편하지만, 참을만해요',
      },
      {
        color: 'sky',
        title: '조금 불편하지만, 참을만해요',
      },
      {
        color: 'orange',
        title: '불편하고 힘들어요',
      },
      {
        color: 'orange',
        title: '불편하고 힘들어요',
      },
      {
        color: 'orange',
        title: '불편하고 힘들어요',
      },
      {
        color: 'red',
        title: '너무 불편해서 참을 수 없어요',
      },
      {
        color: 'red',
        title: '너무 불편해서 참을 수 없어요',
      },
    ],
  },
  ev_lblr: {
    title: '몸통 왼쪽으로 돌리기',
    movement_number: 4,
    function: [
      {
        color: 'red',
        title: '거의 따라하지 못해요',
      },
      {
        color: 'red',
        title: '거의 따라하지 못해요',
      },
      {
        color: 'red',
        title: '거의 따라하지 못해요',
      },
      {
        color: 'orange',
        title: '어느정도 가능하지만, 힘들어요',
      },
      {
        color: 'orange',
        title: '어느정도 가능하지만, 힘들어요',
      },
      {
        color: 'orange',
        title: '어느정도 가능하지만, 힘들어요',
      },
      {
        color: 'sky',
        title: '어느정도 따라할 수 있어요',
      },
      {
        color: 'sky',
        title: '어느정도 따라할 수 있어요',
      },
      {
        color: 'sky',
        title: '어느정도 따라할 수 있어요',
      },
      {
        color: 'blue',
        title: '쉽게 따라할 수 있어요',
      },
      {
        color: 'blue',
        title: '쉽게 따라할 수 있어요',
      },
    ],
    discomfort: [
      {
        color: 'blue',
        title: '거의 불편하지 않아요',
      },
      {
        color: 'blue',
        title: '거의 불편하지 않아요',
      },
      {
        color: 'blue',
        title: '거의 불편하지 않아요',
      },
      {
        color: 'sky',
        title: '조금 불편하지만, 참을만해요',
      },
      {
        color: 'sky',
        title: '조금 불편하지만, 참을만해요',
      },
      {
        color: 'sky',
        title: '조금 불편하지만, 참을만해요',
      },
      {
        color: 'orange',
        title: '불편하고 힘들어요',
      },
      {
        color: 'orange',
        title: '불편하고 힘들어요',
      },
      {
        color: 'orange',
        title: '불편하고 힘들어요',
      },
      {
        color: 'red',
        title: '너무 불편해서 참을 수 없어요',
      },
      {
        color: 'red',
        title: '너무 불편해서 참을 수 없어요',
      },
    ],
  },
};

export default MOVEMENT_DATA;
