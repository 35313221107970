import Header from '../../components/Header/Header';
import styles from './JoinPolicy.module.scss';
import { ReactComponent as Back } from '../../assets/common/icons/ic_back_b.svg';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useState } from 'react';
import CustomConfirm from '../../components/CustomConfirm/CustomConfirm';
import IcMoveRightIcon from '../../components/assetComponents/IcMoveRightIcon';
import POLICY_DATA from '../../constant/policy';

function JoinPolicy() {
    const navigate = useNavigate();
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

    function join() {
        navigate('/health/fitness?is-joined=true');
    }
    return (
        <>
            <main className={styles.container}>
                <Header />
                <div className={`${styles.bg} max-1024`}>
                    <div className={styles.contentWrap}>
                        <h3 className={styles.subTitle}>
                            <Back onClick={() => setIsCancelModalOpen(true)} />
                            <span>약관 동의</span>
                        </h3>
                        <p className={styles.guide}>
                            서비스 이용을 위해 약관을 동의해주세요.
                        </p>
                        <section className={styles.policiesWrap}>
                            {POLICY_DATA.filter((data, idx) => idx < 3).map(
                                (policy, idx) => (
                                    <article
                                        key={policy.title}
                                        onClick={() =>
                                            navigate(
                                                `/join/policy/${policy.id}`
                                            )
                                        }
                                    >
                                        <h5>
                                            <span>
                                                [
                                                {policy.isRequired
                                                    ? '필수'
                                                    : '선택'}
                                                ]{' '}
                                            </span>
                                            <span>{policy.title}</span>
                                        </h5>
                                        <IcMoveRightIcon />
                                    </article>
                                )
                            )}
                        </section>
                        <div className={styles.agreeBtn} onClick={join}>
                            <CustomButton>전체 약관 동의하기</CustomButton>
                        </div>
                    </div>
                </div>
            </main>
            {isCancelModalOpen && (
                <CustomConfirm
                    message={'가입을 취소할까요?'}
                    falseText='아니요'
                    trueText='예, 취소할게요'
                    falseFn={() => {
                        setIsCancelModalOpen(false);
                    }}
                    trueFn={() => {
                        navigate('/');
                    }}
                />
            )}
        </>
    );
}
export default JoinPolicy;
