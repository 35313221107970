import { useEffect, useState } from 'react';

function useRecentWeek() {
  const [week, setWeek] = useState<{ year: number; month: number; date: number }[]>([]);
  useEffect(() => {
    const yesterday = new Date(new Date().getTime() - 1000 * 60 * 60 * 24);
    const recentWeek = [...new Array(6)].reduce(
      (acc: string[]) => {
        const prev = new Date(yesterday.setDate(yesterday.getDate() - 1));
        return [
          {
            year: prev.getFullYear(),
            month: prev.getMonth() + 1,
            date: prev.getDate(),
          },
          ...acc,
        ];
      },
      [
        {
          year: yesterday.getFullYear(),
          month: yesterday.getMonth() + 1,
          date: yesterday.getDate(),
        },
      ]
    );
    setWeek(recentWeek);
  }, []);
  return week;
}
export default useRecentWeek;
