import styles from './WelcomeModal.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore from 'swiper';
import CustomButton from '../CustomButton/CustomButton';
import { useEffect, useState } from 'react';
import { ReactComponent as Back } from '../../assets/web/icons/ic_arrow_back.svg';
import { ReactComponent as Next } from '../../assets/web/icons/ic_arrow_next.svg';
import { ReactComponent as Logo } from '../../assets/logo/logo_light.svg';
import { useNavigate } from 'react-router-dom';

const WELCOME_MODAL_DATA = [
    '불편한 부위와 동작을<br />확인할 수 있어요',
    '내 몸에 딱 맞는<br />운동 프로그램을 추천해드려요',
    '이너힐링 콘텐츠로<br />마음의 평화를 찾아보세요',
    '내 얼굴은 공개하지 않고<br />비대면으로 상담할 수 있어요',
];

function WelcomeModal() {
    const navigate = useNavigate();

    const [swiper, setSwiper] = useState<SwiperCore>();
    const [activeIdx, setActiveIdx] = useState(0);

    const [childSwiper, setChildSwiper] = useState<SwiperCore>();
    const [childActiveIdx, setChildActiveIdx] = useState(0);

    function handleSubmit() {
        if (childActiveIdx < WELCOME_MODAL_DATA.length - 1)
            childSwiper?.slideNext();
        else {
            navigate(window.location.pathname, { replace: true });
        }
    }
    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (window.innerWidth < 1024) {
            timeout = setTimeout(() => {
                if (swiper) swiper?.slideNext();
            }, 3050);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [swiper]);

    function handlePrev() {
        if (childSwiper?.activeIndex === 0) swiper?.slidePrev();
        else childSwiper?.slidePrev();
    }
    function handleNext() {
        if (swiper?.activeIndex === 0) swiper.slideNext();
        else childSwiper?.slideNext();
    }
    return (
        <div className={styles.container}>
            <button
                className={`${styles.slideControlBtn} ${
                    activeIdx === 1 || childActiveIdx !== 0 ? styles.show : ''
                }`}
                onClick={handlePrev}
            >
                <Back />
            </button>
            <article className={styles.modal}>
                <Swiper
                    className={styles.welcomeSlider}
                    slidesPerView={1}
                    onSwiper={setSwiper}
                    resistance={true}
                    resistanceRatio={0}
                    onSlideChange={(swiper) => {
                        setActiveIdx(swiper.activeIndex);
                    }}
                >
                    <SwiperSlide>
                        <div className={styles.thumbnail}>
                            <div className={styles.slideContentWrap}>
                                <div className={styles.slideContent}>
                                    <Logo />
                                    <h3>서비스 가입을 환영해요!</h3>
                                    <p>축하 포인트 1,000pt를 지급해드렸어요</p>
                                </div>
                            </div>
                            <div className={styles.progressBar}></div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={styles.childSliderWrap}>
                            <Swiper
                                nested
                                className={styles.childSlider}
                                slidesPerView={1}
                                onSwiper={setChildSwiper}
                                onSlideChange={(swiper) => {
                                    setChildActiveIdx(swiper.activeIndex);
                                }}
                            >
                                {WELCOME_MODAL_DATA.map((data, idx) => (
                                    <SwiperSlide key={data}>
                                        <div
                                            className={`${styles.slide} ${
                                                'slide' + idx
                                            }`}
                                        >
                                            <div
                                                className={
                                                    styles.slideImageWrap
                                                }
                                            ></div>
                                            <p
                                                className={styles.guideText}
                                                dangerouslySetInnerHTML={{
                                                    __html: data,
                                                }}
                                            ></p>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <ul className={styles.pagination}>
                                {new Array(WELCOME_MODAL_DATA.length)
                                    .fill('')
                                    .map((value, index) => (
                                        <li
                                            key={index}
                                            className={
                                                index === childActiveIdx
                                                    ? styles.hit
                                                    : ''
                                            }
                                            onClick={() =>
                                                childSwiper?.slideTo(index)
                                            }
                                        ></li>
                                    ))}
                            </ul>
                            <div
                                className={styles.buttonWrap}
                                onClick={handleSubmit}
                            >
                                <CustomButton>
                                    {childActiveIdx <
                                    WELCOME_MODAL_DATA.length - 1
                                        ? '다음'
                                        : '입장하기'}
                                </CustomButton>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </article>
            <button
                className={`${styles.slideControlBtn} ${
                    childActiveIdx !== WELCOME_MODAL_DATA.length - 1
                        ? styles.show
                        : ''
                }`}
                onClick={handleNext}
            >
                <Next />
            </button>
        </div>
    );
}
export default WelcomeModal;
