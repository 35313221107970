import styles from './ExerciseFacility.module.scss';
import { ReactComponent as Company } from '../../../assets/common/icons/company.svg';
import { ReactComponent as House } from '../../../assets/common/icons/house.svg';
import { useEffect, useState } from 'react';
import CustomButton from '../../CustomButton/CustomButton';
import CustomConfirm from '../../CustomConfirm/CustomConfirm';
import Title from '../Title/Title';
import MobileHeader from '../../MobileHeader/MobileHeader';
import PAGE_ROUTER from '../../../constant/pageList';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { axiosAuthInstance } from '../../../api/axios';
import IExerciseEnvironmentType from '../../../type/IExerciseEnvironmentType';
import Loading from '../../Loading/Loading';
import useUserData from '../../../hooks/useUserData';

function ExerciseFacility() {
  const userData = useUserData();

  const [selectedFacility, setSelectedFacility] = useState<number>();
  const [isOpenModal, setIsOpenModal] = useState(false);

  function updateExerciseEnvironment(environment: string) {
    return axiosAuthInstance.post('/api/locker-room/exercise/environment', {
      userId: userData.data?.userId,
      environment,
    });
  }
  const { mutate } = useMutation(updateExerciseEnvironment, {
    onSuccess: (res, environment) => {
      queryClient.setQueryData(['exercise-environment'], {
        ...data,
        environment,
      });
    },
  });

  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery<IExerciseEnvironmentType>(['exercise-environment'], () =>
    axiosAuthInstance.get(`/api/locker-room/exercise/environment/${userData.data?.userId}`).then((res) => res.data)
  );
  useEffect(() => {
    if (!data) return;
    setSelectedFacility(data.environment === 'HOME' ? 0 : 1);
  }, [data]);
  console.log(data);
  return (
    <>
      <MobileHeader title={'운동장소 설정'} backBtn='arrow' backUrl={PAGE_ROUTER.라커룸?.subMenu?.설정.path || '/'} />
      <section className={styles.container}>
        <Title title='운동장소 설정' />
        <div className={styles.sizeFicker}>
          <p className={styles.question}>운동할 장소를 선택해주세요.</p>
          <ul className={styles.facilityList}>
            <li onClick={() => setSelectedFacility(0)} className={selectedFacility === 0 ? styles.hit : ''}>
              <button>
                <House />
                <span>집에서</span>
              </button>
            </li>
            <li onClick={() => setSelectedFacility(1)} className={selectedFacility === 1 ? styles.hit : ''}>
              <button>
                <Company />
                <span>회사에서</span>
              </button>
            </li>
          </ul>
          <p className={styles.guide}>* 운동장소 변경사항은 다음 프로그램부터 적용돼요.</p>
          <div className={styles.submitBtnWrap} onClick={() => setIsOpenModal(true)}>
            <CustomButton>확인하기</CustomButton>
          </div>
        </div>
      </section>
      {isOpenModal && (
        <SaveModal
          setIsOpenModal={setIsOpenModal}
          onSubmit={() => {
            mutate(selectedFacility === 0 ? 'HOME' : 'COMPANY');
          }}
        />
      )}
      {isLoading && <Loading />}
    </>
  );
}

interface ISaveModalProps {
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: () => void;
}
function SaveModal({ setIsOpenModal, onSubmit }: ISaveModalProps) {
  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <CustomConfirm
          message='운동장소를 저장할까요?'
          falseText='아니요'
          trueText='예, 저장할게요'
          falseFn={() => setIsOpenModal(false)}
          trueFn={() => {
            setIsOpenModal(false);
            onSubmit();
          }}
        />
      </div>
    </div>
  );
}
export default ExerciseFacility;
