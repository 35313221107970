import styles from './Stamp.module.scss';

interface IPropsType {
    isActive: boolean;
    date: string;
    index: number;
}

function Stamp({ isActive, date, index }: IPropsType) {
    return (
        <div className={styles.container}>
            <div className={`${styles.stamp} ${isActive ? styles.hit : ''}`}>
                <span className={styles.date}>{date}</span>
            </div>
            <span className={styles.index}>{index}회</span>
        </div>
    );
}
export default Stamp;
