import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import styles from './SelfPsychologyEvaluation.module.scss';
import MobileHeader from '../../components/MobileHeader/MobileHeader';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as Character } from '../../assets/common/background/evaluation_character.svg';
import { useMutation, useQuery } from 'react-query';
import { axiosAuthInstance } from '../../api/axios';
import Loading from '../../components/Loading/Loading';
import useUserData from '../../hooks/useUserData';
import { ISelfPsychologyEvaluationQuestion } from '../../type/ISelfPsychologyEvaluation';

const POSITIVE_CHARACTER_COLOR = [
  'blue',
  'blue',
  'sky',
  'sky',
  'sky',
  'orange',
  'orange',
  'orange',
  'red',
  'red',
  'red',
];
const NEGATIVE_CHARACTER_COLOR = [
  'red',
  'red',
  'red',
  'orange',
  'orange',
  'orange',
  'sky',
  'sky',
  'sky',
  'blue',
  'blue',
];

function SelfPsychologyEvaluation() {
  const navigate = useNavigate();
  const { index } = useParams();
  const userData = useUserData();

  const location = useLocation();
  const formRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!formRef.current) return;
    formRef.current.scrollTo(0, 0);
  }, [location]);

  const [answers, setAnswers] = useState<(number | null)[]>(
    JSON.parse(sessionStorage.getItem('self-psychology-evaluation-answer') || '[null, null, null, null, null, null]') ||
      new Array(QUESTION_LIST.length).fill(null)
  );
  useEffect(() => {
    const IDX = Number(index);
    if (IDX === 0) {
      sessionStorage.removeItem('self-psychology-evaluation-answer');
      setAnswers(new Array(QUESTION_LIST.length).fill(null));
    } else sessionStorage.setItem('self-psychology-evaluation-answer', JSON.stringify(answers));
  }, [location]);

  function handleBackBtn() {
    if (index === '0') navigate('/health/mind-therapy');
    else navigate('/mind-therapy/inner-healing/self-psychology-evaluation/0', { replace: true });
  }

  function handlePrev() {
    const IDX = Number(index);
    navigate(`/mind-therapy/inner-healing/self-psychology-evaluation/${IDX - 1}`);
  }
  async function handleNext() {
    if (!question) return;

    const IDX = Number(index);
    if (IDX !== 0 && typeof answers[IDX - 1] !== 'number') return;
    if (IDX === QUESTION_LIST.length) {
      await mutateAsync();
      navigate(`/mind-therapy/inner-healing/self-psychology-evaluation/result`);
    } else navigate(`/mind-therapy/inner-healing/self-psychology-evaluation/${IDX + 1}`);
  }

  const { data: question, isLoading } = useQuery<ISelfPsychologyEvaluationQuestion>(
    ['self-psychology-evaluation-question', 0],
    () => axiosAuthInstance.get('/api/inner-healing/self-assessment/questions').then((res) => res.data)
  );

  useEffect(() => {
    if (!question) return;
    const IDX = Number(index);
    if (isNaN(IDX) || IDX < 0 || IDX > 7 || answers.slice(0, IDX - 1).includes(null))
      navigate('/mind-therapy/inner-healing/self-psychology-evaluation/0', {
        replace: true,
      });
  }, [question]);

  const { mutateAsync, isLoading: isSaving } = useMutation(() => {
    const DATA = {
      userId: userData.data?.userId,
      answers: (() => {
        return question?.questionList.map((datum, idx) => ({
          surveyCode: datum.surveyCode,
          questionCode: datum.questionCode,
          answerCode: 10 - (answers[idx] || 0),
        }));
      })(),
    };
    return axiosAuthInstance.post('/api/inner-healing/self-assessment', DATA);
  });

  return (
    <>
      <main className={styles.container}>
        <div className={styles.bg}>
          <Header isNavShow />
          <div className={styles.contentWrap}>
            <MobileHeader title={'심리 셀프 평가'} backBtn='meta' backFn={handleBackBtn} />
            <div className={styles.menu}>
              <button onClick={() => navigate('/mind-therapy/personal-counseling')}>1:1 상담</button>
              <button className={styles.hit}>이너힐링</button>
            </div>
            <div className={styles.scrollableArea}>
              {index === '0' ? (
                <>
                  <div className={styles.guide}>
                    <h2 className={styles.title}>심리 셀프 평가</h2>
                    <h3>마음 챙김 잘하고 계신가요?</h3>
                    <p>심리 셀프 평가를 진행할게요.</p>
                    <div className={styles.bgWrap}></div>
                    <div className={styles.submitBtnWrap}>
                      <div onClick={handleNext}>
                        <CustomButton height={56}>셀프 평가하기</CustomButton>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                (() => {
                  const IDX = Number(index);
                  return (
                    <div className={styles.evaluation}>
                      <h2 className={styles.title}>심리 셀프 평가</h2>

                      <h3>{question?.questionList[IDX - 1].mainQuestion}</h3>
                      <p>(아래 숫자를 선택해주세요.)</p>
                      <div className={styles.formWrap} ref={formRef}>
                        <form onSubmit={(e) => e.preventDefault()}>
                          <div className={styles.left}>
                            <div className={`${styles.bar} ${Number(index) > 3 ? styles.negative : ''}`}></div>
                            {typeof answers[IDX - 1] === 'number' && (
                              <div
                                className={`${styles.character} ${`answer${answers[IDX - 1]}`}
                                                         ${
                                                           IDX > 3
                                                             ? styles[NEGATIVE_CHARACTER_COLOR[answers[IDX - 1] || '0']]
                                                             : styles[POSITIVE_CHARACTER_COLOR[answers[IDX - 1] || '0']]
                                                         }
/                                                        `}
                              >
                                <Character />
                              </div>
                            )}
                          </div>
                          <div className={styles.right}>
                            <ul>
                              {QUESTION_LIST[IDX - 1].answer.map((answer, idx) => (
                                <li
                                  key={answer}
                                  className={`${answers[IDX - 1] === idx ? styles.hit : ''}`}
                                  onClick={() =>
                                    setAnswers((prev) => {
                                      let newVal = [...prev];
                                      newVal[IDX - 1] = idx;
                                      return newVal;
                                    })
                                  }
                                >
                                  {answer}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </form>
                      </div>
                      <div className={styles.submitBtnWrap}>
                        {IDX === 1 ? (
                          <div onClick={handleNext} className={styles.customBtnWrap}>
                            <CustomButton height={56} isActive={typeof answers[IDX - 1] === 'number'}>
                              다음
                            </CustomButton>
                          </div>
                        ) : (
                          <>
                            <button className={styles.prev} onClick={handlePrev}>
                              이전
                            </button>
                            <button
                              onClick={handleNext}
                              className={`${styles.next} ${typeof answers[IDX - 1] === 'number' ? styles.hit : ''}`}
                            >
                              다음
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })()
              )}
            </div>
          </div>
        </div>
      </main>
      {(isLoading || isSaving) && <Loading />}
    </>
  );
}
export default SelfPsychologyEvaluation;

const QUESTION_LIST = [
  {
    question: '평소 얼마나 마음이 평온한가요?',
    answer: ['10 (매우 평온)', '9', '8', '7', '6', '5', '4', '3', '2', '1', '0 (매우 산란)'],
  },
  {
    question: '나 자신에 대해 얼마나 만족하나요?',
    answer: ['10 (매우 만족)', '9', '8', '7', '6', '5', '4', '3', '2', '1', '0 (전혀 만족 못함)'],
  },
  {
    question: '현재 대인관계에 대해 얼마나 만족하나요?',
    answer: ['10 (매우 만족)', '9', '8', '7', '6', '5', '4', '3', '2', '1', '0 (전혀 만족 못함)'],
  },
  {
    question: '최근 2주간 얼마나 우울했나요?',
    answer: ['10 (매우 우울)', '9', '8', '7', '6', '5', '4', '3', '2', '1', '0 (전혀 우울하지 않음)'],
  },
  {
    question: '최근 2주간 얼마나 긴장되고 초조했나요?',
    answer: ['10 (자주 그러함)', '9', '8', '7', '6', '5', '4', '3', '2', '1', '0 (전혀 그런 적 없음)'],
  },
  {
    question: '평소 얼마나 자주 화가 나나요?',
    answer: ['10 (매우 화남)', '9', '8', '7', '6', '5', '4', '3', '2', '1', '0 (거의 화나지 않음)'],
  },
];
