import styles from './Header.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PAGE_ROUTER from '../../constant/pageList';
import logo from '../../assets/logo/logo_dark.png';
import useCheckLogin from '../../hooks/useCheckLogin';

function Header({ isNavShow, isFixed, logoUrl }: { isNavShow?: boolean; isFixed?: boolean; logoUrl?: string }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isLoggedIn = useCheckLogin();
  return (
    <>
      <header className={`${styles.container} ${isFixed ? styles.fix : ''}`}>
        <div className={`${styles.contentArea} max-1024`}>
          <button
            className={styles.logoBtn}
            onClick={() =>
              logoUrl ? navigate(logoUrl) : isLoggedIn ? navigate(PAGE_ROUTER['피트니스'].path) : navigate('/')
            }
          >
            <img src={logo} alt='logo' />
          </button>
          <nav className={`${styles.navigation} ${!isNavShow ? styles.hidden : ''}`}>
            <ul>
              {Object.keys(PAGE_ROUTER).map((menu) => (
                <li
                  key={menu}
                  className={
                    pathname.includes(PAGE_ROUTER[menu]?.name) ||
                    PAGE_ROUTER[menu]?.headerActiveConditions.filter((condition) => pathname.includes(condition))
                      .length > 0
                      ? styles.hit
                      : ''
                  }
                >
                  <Link to={PAGE_ROUTER[menu]?.path}>{menu}</Link>
                  {PAGE_ROUTER[menu]?.subMenu && (
                    <article className={styles.submenuListWrap}>
                      <ul className={styles.submenuList}>
                        {Object.keys(PAGE_ROUTER[menu].subMenu!).map((subMenu) => (
                          <li key={subMenu}>
                            <Link to={PAGE_ROUTER[menu]?.subMenu?.[subMenu]?.path || '/'}>{subMenu}</Link>
                          </li>
                        ))}
                      </ul>
                    </article>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
}
export default Header;
