import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './Counselor.module.scss';
import Header from '../../components/Header/Header';
import MobileHeader from '../../components/MobileHeader/MobileHeader';
import PAGE_ROUTER from '../../constant/pageList';
import { useEffect, useState } from 'react';
import CounselingApply from '../../components/CounselingApply/CounselingApply';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useQuery } from 'react-query';
import { ICounselorData } from '../../type/ICounselorData';
import { axiosAuthInstance } from '../../api/axios';
import Loading from '../../components/Loading/Loading';
import counselorDefault from '../../assets/common/background/counselor_default.png';

function Counselor() {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [tab, setTab] = useState(Number(new URL(document.URL).searchParams.get('tab')) || 0);
  useEffect(() => {
    setTab(Number(new URL(document.URL).searchParams.get('tab')) || 0);
  }, [location]);

  const { data, isLoading } = useQuery<ICounselorData>(['counselor', id], () =>
    axiosAuthInstance.get(`/api/counselling/counsellor?counsellorId=${id}`).then((res) => res.data)
  );

  const { data: slotCounts, isLoading: slotCountsLoading } = useQuery<number>(['slotCounts', id], () =>
    axiosAuthInstance.get(`/api/counselling/slot/count?counsellorId=${id}`).then((res) => res.data)
  );
  return (
    <>
      <main className={styles.container}>
        <Header isNavShow />
        <MobileHeader
          title={`${data?.userName || ''} 상담사`}
          backBtn='arrow'
          backUrl='/mind-therapy/personal-counseling'
        />
        <nav className={`${styles.feedNav} max-1024`}>
          <div className={styles.left}>
            <button
              onClick={() => navigate(PAGE_ROUTER.마인드테라피?.subMenu?.['1:1 상담']?.path || '/', { replace: true })}
              className={styles.hit}
            >
              1:1 상담
            </button>
            <button
              onClick={() => navigate(PAGE_ROUTER.마인드테라피?.subMenu?.이너힐링.path || '/', { replace: true })}
            >
              이너힐링
            </button>
          </div>
        </nav>
        <div className={`${styles.scroll} max-1024`}>
          {data && (
            <>
              <article className={`${styles.counselorInfoWrap} max-1024`}>
                <h3 className={styles.counselorName}>{data.userName} 상담사</h3>
                <div className={styles.avatarWrap}>
                  <img src={data.profileImageUrl || counselorDefault} alt='아바타' />
                </div>
                {data.myKeyword && (
                  <ul className={styles.hashtagList}>
                    {data?.myKeyword.split(',').map((tag) => <li key={tag}>{tag.replace(/#/g, '')}</li>)}
                  </ul>
                )}
              </article>
              <div className={`${styles.contentWrap} max-1024`}>
                <ul className={styles.tabList}>
                  <li
                    className={tab === 0 ? styles.hit : ''}
                    onClick={() =>
                      navigate(`/mind-therapy/personal-counseling/counselor/${id}?tab=0`, {
                        replace: true,
                      })
                    }
                  >
                    상담신청
                  </li>
                  <li
                    className={tab === 1 ? styles.hit : ''}
                    onClick={() =>
                      navigate(`/mind-therapy/personal-counseling/counselor/${id}?tab=1`, {
                        replace: true,
                      })
                    }
                  >
                    소개
                  </li>
                </ul>
                {tab === 0 ? (
                  <>
                    {!!slotCounts && (
                      <div className={styles.applyWrap}>
                        <CounselingApply counselorData={data} />
                      </div>
                    )}
                    {slotCounts === 0 && (
                      <div className={styles.noResult}>
                        <div>
                          <p>현재 상담 가능한 일정이 없어요</p>
                          <div className={styles.bannerWrap}></div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className={styles.introduceWrap}>
                    <ul className={styles.introduceList}>
                      {data?.myAnnouncement && (
                        <li>
                          <h4>공지사항</h4>
                          <p dangerouslySetInnerHTML={{ __html: data?.myAnnouncement || '' }}></p>
                        </li>
                      )}
                      <li>
                        <h4>상담사 소개</h4>
                        <p dangerouslySetInnerHTML={{ __html: data?.introMessage || '' }}></p>
                      </li>
                    </ul>
                    <div className={styles.submitBtnWrap}>
                      <div
                        onClick={() =>
                          navigate(`/mind-therapy/personal-counseling/counselor/${id}?tab=0`, { replace: true })
                        }
                      >
                        <CustomButton>상담 신청하기</CustomButton>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </main>
      {isLoading || (slotCountsLoading && <Loading />)}
    </>
  );
}
export default Counselor;
