import Frame from '../../components/Frame/Frame';
import styles from './Exercise.module.scss';
import ExerciseDescription from '../../components/Exercise/ExerciseDescription/ExerciseDescription';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ExercisePlay from '../../components/Exercise/ExercisePlay/ExercisePlay';
import ExerciseRest from '../../components/Exercise/ExerciseRest/ExerciseRest';
import { IUnitType } from '../../type/ITimelineDataType';

const EXCERCISE_PROCESS_ORDER = ['guide', 'play', 'rest'];
function Exercise() {
  const navigate = useNavigate();
  const { unitIndex } = useParams() as { unitIndex: string };
  const { sessionId } = useParams() as { sessionId: string };

  const { state } = useLocation() as {
    state: {
      unitList: IUnitType[];
      useSmartring: 'Y' | 'N';
    };
  };
  const unitListHistory = Object.values(window.history.state.unitList || {}).slice(0, -1);
  const useSmartringHistory = window.history.state.useSmartring;
  const [unitList] = useState<IUnitType[]>(state?.unitList || unitListHistory);
  const [useSmartring] = useState<'Y' | 'N'>(state?.useSmartring === 'Y' || useSmartringHistory === 'Y' ? 'Y' : 'N');

  useEffect(() => {
    if (!unitList || isNaN(Number(unitIndex))) navigate('/fitness/exercise-of-the-day');
  }, []);

  const { menu } = useParams() as { menu: 'guide' | 'play' | 'rest' };
  useEffect(() => {
    if (!EXCERCISE_PROCESS_ORDER.includes(menu)) navigate('/fitness/exercise-of-the-day');
  }, []);

  function handleExercise(repeatCount: number) {
    navigate(`/exercise/${unitIndex}/play/${sessionId}?play_count=${repeatCount}`, {
      state: {
        unitList,
        useSmartring,
      },
    });
  }
  function handleRest(repeatCount: number) {
    navigate(`/exercise/${unitIndex}/rest/${sessionId}?play_count=${repeatCount}`, {
      state: {
        unitList,
        useSmartring,
      },
    });
  }

  function handleNext() {
    const isLastExercise = Number(unitIndex) === unitList.length - 1;

    const PROCESS_INDEX = EXCERCISE_PROCESS_ORDER.findIndex((val) => val === menu);

    if (isLastExercise && PROCESS_INDEX === 1) navigate(`/exercise/result/${sessionId}`);
    else if (PROCESS_INDEX === 1)
      navigate(`/exercise/${Number(unitIndex) + 1}/${EXCERCISE_PROCESS_ORDER[0]}/${sessionId}`, {
        state: {
          unitList,
          useSmartring,
        },
      });
    else
      navigate(`/exercise/${unitIndex}/${EXCERCISE_PROCESS_ORDER[1]}/${sessionId}`, {
        state: {
          unitList,
          useSmartring,
        },
      });
  }
  return (
    <Frame menuTitle='오늘의 운동' isHorizontal>
      <div className={styles.tab}>운동</div>
      {unitList &&
        (menu === 'guide' ? (
          <ExerciseDescription nextFn={handleNext} unit={unitList[Number(unitIndex)]} useSmartring={useSmartring} />
        ) : menu === 'play' ? (
          <ExercisePlay
            nextFn={handleNext}
            unitList={unitList}
            unit={unitList[Number(unitIndex)]}
            isLastUnit={Number(unitIndex) === unitList.length - 1}
            useSmartring={useSmartring}
            handleRest={handleRest}
          />
        ) : menu === 'rest' ? (
          <ExerciseRest nextFn={handleExercise} />
        ) : (
          ''
        ))}
    </Frame>
  );
}
export default Exercise;
