import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function useCheckLogin() {
    const location = useLocation();
    const [isLoggedIn, setIsLoggedIn] = useState(
        localStorage.getItem('access-token') ? true : false
    );
    useEffect(() => {
        setIsLoggedIn(localStorage.getItem('access-token') ? true : false);
    }, [location]);

    return isLoggedIn;
}
export default useCheckLogin;
