import CustomButton from '../../CustomButton/CustomButton';
import styles from './ExerciseRest.module.scss';
import { ReactComponent as Back } from '../../../assets/mobile/icons/ic_back_b.svg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from '../../../store/store';
import { useNavigate } from 'react-router-dom';

interface IPropsType {
  nextFn: (playCount: number) => void;
}
function ExerciseRest({ nextFn }: IPropsType) {
  const navigate = useNavigate();

  const [count, setCount] = useState(15);
  const [transitionDuration, setTransitionDuration] = useState(0.5);
  const [exerciseStartModalOpen, setExerciseStartModalOpen] = useState(false);

  const modalTimeout = useRef<NodeJS.Timeout>();
  const timerTimeout = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (count <= 0) {
      modalTimeout.current = setTimeout(() => {
        nextFn(playedCount.current);
      }, 1000);
      return;
    }
    setTransitionDuration(0.5);
    timerTimeout.current = setTimeout(() => {
      setCount((prev) => prev - 0.5);
    }, 500);
    return () => {
      clearTimeout(timerTimeout.current);
      clearTimeout(modalTimeout.current);
    };
  }, [count]);

  function handleMoreRest() {
    clearTimeout(modalTimeout.current);
    clearTimeout(timerTimeout.current);
    setTransitionDuration(0);
    setExerciseStartModalOpen(true);
  }

  function endRest() {
    setExerciseStartModalOpen(false);
    setTransitionDuration(0.5);
    timerTimeout.current = setTimeout(() => {
      setCount((prev) => prev - 0.5);
    }, 500);
  }

  function handleSubmit() {
    nextFn(playedCount.current);
  }

  const playedCount = useRef(
    (() => {
      let count = Number(new URLSearchParams(window.location.search).get('play_count') || 0);
      if (isNaN(count)) count = 0;
      return count;
    })()
  );

  return (
    <>
      <section className={styles.container}>
        <header className={styles.header}>
          <button onClick={() => navigate(-1)}>
            <Back />
          </button>
          <span>휴식 중</span>
        </header>
        <div className={styles.content}>
          <div className={styles.timerWrap}>
            <h6>남은 휴식시간</h6>
            <div className={styles.timer}>
              <div className={styles.background}>{Math.ceil(count)}</div>
              <CircularProgressbar
                value={15 - count}
                minValue={0}
                maxValue={15}
                strokeWidth={14}
                className={styles.circularPrgoressbar}
                // background
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  // rotation: 0.25,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: 'round',

                  // Text size
                  textSize: '16px',

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: transitionDuration,

                  // Can specify path transition in more detail, or remove it entirely
                  pathTransition: 'linear',

                  // Colors
                  pathColor: `#4852EC`,
                  trailColor: 'rgba(72, 82, 236, 0.30)',
                  // backgroundColor: 'white',
                })}
              ></CircularProgressbar>
            </div>
            <p>・운동 진행 중 어지러움, 심한 불편이 있으면 운동을 중단하고 전문가와 상담하세요.</p>
          </div>
          <div className={`${styles.submitBtnWrap} ${styles.inner}`}>
            <div onClick={handleSubmit}>
              <CustomButton>운동하기</CustomButton>
            </div>
          </div>
          <button className={styles.moreRestBtn} onClick={handleMoreRest}>
            더 쉬기
          </button>
        </div>
        <div className={`${styles.submitBtnWrap} ${styles.outer}`}>
          <div onClick={handleSubmit}>
            <CustomButton>운동하기</CustomButton>
          </div>
        </div>
      </section>
      {exerciseStartModalOpen && (
        <div className={styles.dimed}>
          <article className={styles.modal}>
            <p>
              충분히 휴식했다면
              <br />
              돌아가기를 누르세요.
            </p>
            <div className={styles.modalSubmitBtn}>
              <div onClick={endRest}>
                <CustomButton>돌아가기</CustomButton>
              </div>
            </div>
          </article>
        </div>
      )}
    </>
  );
}
export default ExerciseRest;
