import styles from './StampBackground.module.scss';

interface IPropsType {
    circleBgColor: string;
}
function StampBackground({ circleBgColor }: IPropsType) {
    return (
        <div className={styles.stampBackground}>
            <div
                className={styles.circle}
                style={{ backgroundColor: circleBgColor }}
            >
                <div className={styles.borderFaker}></div>
                <div className={`${styles.borderFaker} ${styles.bottom}`}></div>
            </div>
            <div className={styles.border}></div>
            <div
                className={`${styles.circle} ${styles.right}`}
                style={{ backgroundColor: circleBgColor }}
            >
                <div className={styles.borderFaker}></div>
                <div className={`${styles.borderFaker} ${styles.bottom}`}></div>
            </div>
        </div>
    );
}
export default StampBackground;
