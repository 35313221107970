import { useNavigate } from 'react-router-dom';
import PAGE_ROUTER from '../../../constant/pageList';
import CustomButton from '../../CustomButton/CustomButton';
import MobileHeader from '../../MobileHeader/MobileHeader';
import Title from '../Title/Title';
import styles from './MyInfo.module.scss';
import { useQuery } from 'react-query';
import Loading from '../../Loading/Loading';
import IUserInfoType from '../../../type/IUserInfoType';
import { axiosAuthInstance } from '../../../api/axios';
import useUserData from '../../../hooks/useUserData';

function MyInfo() {
  const userData = useUserData();

  const navigate = useNavigate();
  const { data: userInfo, isLoading } = useQuery<IUserInfoType>(['my-info'], () =>
    axiosAuthInstance.get(`/api/locker-room/my-info/${userData.data?.userId}`).then((res) => res.data)
  );
  return (
    <>
      <MobileHeader title={'내 정보'} backBtn='arrow' backUrl={PAGE_ROUTER.라커룸?.subMenu?.설정.path || '/'} />
      <section className={styles.container}>
        <Title title='내 정보' />
        <div className={styles.contentWrap}>
          <ul className={styles.userInfoList}>
            <li>
              <span>이름</span>
              <span>{userInfo?.userName}</span>
            </li>
            <li>
              <span>전화번호</span>
              <span>{userInfo?.phoneNumber}</span>
            </li>
            <li>
              <span>성별</span>
              <span>{userInfo?.gender === 'F' ? '여성' : userInfo?.gender === 'M' ? '남성' : ''}</span>
            </li>
            <li>
              <span>나이</span>
              <span>{userInfo && userInfo.age + '세'}</span>
            </li>
            <li>
              <span>키</span>
              <span>{((userInfo && userInfo.height) || 0) + 'cm'}</span>
            </li>
            <li>
              <span>몸무게</span>
              <span>{((userInfo && userInfo.weight) || 0) + 'kg'}</span>
            </li>
          </ul>
          <div className={styles.submitBtnWRap} onClick={() => navigate(PAGE_ROUTER.라커룸?.subMenu?.설정.path || '/')}>
            <CustomButton height={56}>확인하기</CustomButton>
          </div>
        </div>
      </section>
      {isLoading && <Loading />}
    </>
  );
}
export default MyInfo;
