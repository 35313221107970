import { useNavigate, useParams } from 'react-router-dom';
import styles from './CounselingDetail.module.scss';
import { useEffect } from 'react';
import checkMetaverseOrigin from '../../common/checkMetaverseOrigin';
import { useDispatch } from '../../store/store';
import { logout } from '../../store/jwt';
function CounselingDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    function handleMessage(e: MessageEvent) {
      if (!checkMetaverseOrigin(e.origin)) return;
      const PARAMS = new URLSearchParams(e.data);
      const PATH = PARAMS.get('path');
      const UNAUTHORIZED = PARAMS.get('unauthorized');
      if (UNAUTHORIZED == 'true') {
        dispatch(logout());
        navigate('/');
      }
    }
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  return (
    <main className={styles.container}>
      <iframe
        src={`${process.env.REACT_APP_METAVERSE_DOMAIN}?status=khconv&room_key=${id}&user_type=user&token=${window.btoa(
          localStorage.getItem('access-token') || ''
        )}`}
        allow='autoplay; camera; microphone'
        frameBorder='0'
      ></iframe>
    </main>
  );
}
export default CounselingDetail;
