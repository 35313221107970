import { useState } from 'react';
import styles from './TimerAlertModal.module.scss';
import MultipleButton from '../MultipleButton/MultipleButton';
import { ReactComponent as Top } from '../../assets/common/icons/ic_arrow_top.svg';
import { ReactComponent as Bottom } from '../../assets/common/icons/ic_arrow_bottom.svg';

interface IPropsType {
    setIsOpenAlertModal: React.Dispatch<React.SetStateAction<boolean>>;
    exerciseAlertTime: { isAm: boolean; hour: number; minute: number };
    setExerciseAlertTime: React.Dispatch<
        React.SetStateAction<{ isAm: boolean; hour: number; minute: number }>
    >;
}
function TimerAlertModal({
    setIsOpenAlertModal,
    exerciseAlertTime,
    setExerciseAlertTime,
}: IPropsType) {
    const [modalAlertTime, setModalAlertTime] = useState({
        isAm: exerciseAlertTime.isAm,
        hour: exerciseAlertTime.hour || 9,
        minute: exerciseAlertTime.minute,
    });

    const setHour = (type: boolean) => {
        if (type)
            setModalAlertTime((prev) => ({
                ...prev,
                hour: prev.hour === 12 ? 1 : prev.hour + 1,
            }));
        else
            setModalAlertTime((prev) => ({
                ...prev,
                hour: prev.hour === 1 ? 12 : prev.hour - 1,
            }));
    };
    const setMinute = (type: boolean) => {
        if (type)
            setModalAlertTime((prev) => ({
                ...prev,
                minute: prev.minute === 50 ? 0 : prev.minute + 10,
            }));
        else
            setModalAlertTime((prev) => ({
                ...prev,
                minute: prev.minute === 0 ? 50 : prev.minute - 10,
            }));
    };
    return (
        <div
            className={styles.alertModalContainer}
            onClick={() => setIsOpenAlertModal(false)}
        >
            <div
                className={styles.alertModal}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={styles.timeWrap}>
                    <div>
                        <button
                            onClick={() =>
                                setModalAlertTime((prev) => ({
                                    ...prev,
                                    isAm: !prev.isAm,
                                }))
                            }
                        >
                            <Top />
                        </button>
                        <span>{modalAlertTime.isAm ? '오전' : '오후'}</span>
                        <button
                            onClick={() =>
                                setModalAlertTime((prev) => ({
                                    ...prev,
                                    isAm: !prev.isAm,
                                }))
                            }
                        >
                            <Bottom />
                        </button>
                    </div>
                    <div>
                        <button onClick={() => setHour(true)}>
                            <Top />
                        </button>
                        <span>{modalAlertTime.hour}</span>
                        <button onClick={() => setHour(false)}>
                            <Bottom />
                        </button>
                    </div>
                    <div>
                        <button onClick={() => setMinute(true)}>
                            <Top />
                        </button>
                        <span>{('0' + modalAlertTime.minute).slice(-2)}</span>
                        <button onClick={() => setMinute(false)}>
                            <Bottom />
                        </button>
                    </div>
                </div>
                <div className={styles.submitBtnWrap}>
                    <MultipleButton
                        falseText='취소하기'
                        trueText='저장하기'
                        falseFn={() => setIsOpenAlertModal(false)}
                        trueFn={() => {
                            setExerciseAlertTime(modalAlertTime);
                            setIsOpenAlertModal(false);
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
export default TimerAlertModal;
