import CancelMembership from '../components/LockerRoom/CancelMembership/CancelMembership';
import ExerciseFacility from '../components/LockerRoom/ExerciseFacility/ExerciseFacility';
import Faq from '../components/LockerRoom/Faq/Faq';
import KakaoAlert from '../components/LockerRoom/KakaoAlert/KakaoAlert';
import Logout from '../components/LockerRoom/Logout/Logout';
import MyInfo from '../components/LockerRoom/MyInfo/MyInfo';
import Notice from '../components/LockerRoom/Notice/Notice';
import Terms from '../components/LockerRoom/Terms/Terms';

interface IReturnType {
    [key: string]: {
        path: string;
        title: string;
        component: JSX.Element;
    };
}
export const useLockerRoomData: () => IReturnType = () => {
    return {
        'my-info': {
            path: '/locker-room/setting/my-info',
            title: '내 정보',
            component: <MyInfo />,
        },
        'kakao-alarm': {
            path: '/locker-room/setting/kakao-alarm',
            title: '카카오 알림톡 설정',
            component: <KakaoAlert />,
        },
        'exercise-venue': {
            path: '/locker-room/setting/exercise-venue',
            title: '운동장소 설정',
            component: <ExerciseFacility />,
        },
        notice: {
            path: '/locker-room/setting/notice',
            title: '공지사항',
            component: <Notice />,
        },
        faq: {
            path: '/locker-room/setting/faq',
            title: '자주 묻는 질문',
            component: <Faq />,
        },
        terms: {
            path: '/locker-room/setting/terms',
            title: '약관 보기',
            component: <Terms />,
        },
        'cancel-membership': {
            path: '/locker-room/setting/cancel-membership',
            title: '서비스 탈퇴',
            component: <CancelMembership />,
        },
        logout: {
            path: '/locker-room/setting/logout',
            title: '로그아웃',
            component: <Logout />,
        },
    };
};
