import { useState } from 'react';
import styles from './LabelResult.module.scss';

interface IPropsType {
    type: 0 | 1 | 2;
}
function LabelResult(props: IPropsType) {
    const [type] = useState(
        props.type === 0
            ? {
                  title: '나쁨',
                  class: 'bad',
              }
            : props.type === 1
              ? {
                    title: '보통',
                    class: 'commonly',
                }
              : props.type === 2
                ? {
                      title: '좋음',
                      class: 'good',
                  }
                : {
                      title: '',
                      class: '',
                  }
    );
    return (
        <div className={`${styles.container} ${styles[type.class]}`}>
            {type.title}
        </div>
    );
}
export default LabelResult;
