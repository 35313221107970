import { useState } from 'react';
import CustomButton from '../CustomButton/CustomButton';
import styles from './Counseling.module.scss';
import CustomConfirm from '../CustomConfirm/CustomConfirm';
import { useNavigate } from 'react-router-dom';
import getFormattedTime from '../../common/getFormattedTime';
import IMyCounseling from '../../type/IMyCounselingData';
import { useMutation, useQueryClient } from 'react-query';
import { axiosAuthInstance } from '../../api/axios';
import useUserData from '../../hooks/useUserData';

interface IPropsType {
  counseling: IMyCounseling;
  isPastCounseling?: boolean;
}
function Counseling({ counseling, isPastCounseling = false }: IPropsType) {
  const navigate = useNavigate();
  const userData = useUserData();
  const queryClient = useQueryClient();
  const [counselingInfoOpen, setCounselingInfoOpen] = useState(false);
  const [cancelConfirmOpen, setCancelConfirmOpen] = useState(false);
  const [cancelCompleteModalOpen, setCancelCompleteModalOpen] = useState(false);
  const [counselorCancelReasonOpen, setCounselorCancelReasonOpen] = useState(false);

  const { mutateAsync: cancel } = useMutation(() =>
    axiosAuthInstance.delete('/api/counselling/cancel', {
      data: {
        userId: userData.data?.userId,
        counsellorId: counseling.counselorId,
        targetDate: counseling.counselingDate,
        targetTime: counseling.counselingTime.slice(0, 5),
      },
    })
  );

  async function handleCancel() {
    setCancelConfirmOpen(false);
    await cancel();
    setCancelCompleteModalOpen(true);
  }

  return (
    <>
      <article className={styles.container}>
        <div className={styles.contents}>
          <div className={styles.top}>
            {typeof counseling.counselingSequence === 'number' && (
              <div className={styles.times}>{counseling.counselingSequence}회차</div>
            )}
            {!isPastCounseling && !counseling.isCanceled && <div className={styles.status}>예정</div>}
            {(isPastCounseling || counseling.isCanceled) && (
              <div className={`${styles.status} ${counseling.isCanceled === 'Y' ? styles.canceled : styles.completed}`}>
                {counseling.isCanceled === 'Y' ? '취소' : '완료'}
              </div>
            )}
          </div>
          <div className={styles.bottom}>
            <ul className={styles.counselingDetail}>
              <li>
                <span>상담사</span>
                <span>{counseling.counselorName}</span>
                <button
                  onClick={() =>
                    navigate(`/mind-therapy/personal-counseling/counselor/${counseling.counselorId}?tab=1`)
                  }
                ></button>
              </li>
              <li>
                <span>상담일</span>
                <span>{counseling.counselingDate}</span>
              </li>
              <li>
                <span>상담시간</span>
                <span>{getFormattedTime(counseling.counselingTime)} (1시간)</span>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.btnsWrap}>
          {!isPastCounseling
            ? (() => {
                const now = new Date().getTime();
                const counselingTime = new Date(`${counseling.counselingDate} ${counseling.counselingTime}`).getTime();
                const isPossible = counselingTime - now < 1000 * 60 * 5;
                return isPossible ? (
                  <button
                    className={styles.hit}
                    onClick={() => {
                      navigate(`/mind-therapy/personal-counseling/detail/${counseling.counselingId}`);
                    }}
                  >
                    상담 입장하기
                  </button>
                ) : (
                  <>
                    <button onClick={() => setCancelConfirmOpen(true)}>신청 취소하기</button>
                    <button className={styles.hit} onClick={() => setCounselingInfoOpen(true)}>
                      신청정보 보기
                    </button>
                  </>
                );
              })()
            : (() => {
                const isCanceledFromCounselor = counseling.isCanceled === 'Y' && counseling.cancelUserType === 'C';
                // cosnt counselorCancelMeesage = counse

                return (
                  <>
                    {/* <button onClick={() => setCancelConfirmOpen(true)}>신청 취소하기</button> */}
                    {isCanceledFromCounselor && (
                      <button onClick={() => setCounselorCancelReasonOpen(true)}>취소안내 확인</button>
                    )}
                    <button className={styles.hit} onClick={() => setCounselingInfoOpen(true)}>
                      신청정보 보기
                    </button>
                  </>
                );
              })()}
        </div>
      </article>
      {counselingInfoOpen && (
        <InformationModal
          title='내가 작성한 상담 요청사항'
          content={counseling.requestedMessage}
          onSubmit={() => setCounselingInfoOpen(false)}
        />
      )}
      {counselorCancelReasonOpen && (
        <InformationModal
          title='상담사 취소 안내'
          content={counseling.cancelReason || ''}
          onSubmit={() => setCounselorCancelReasonOpen(false)}
        />
      )}
      {cancelConfirmOpen && (
        <CustomConfirm
          message='1:1상담을 취소할까요?'
          falseText='아니요'
          trueText='예, 취소할게요'
          falseFn={() => setCancelConfirmOpen(false)}
          trueFn={handleCancel}
        />
      )}
      {cancelCompleteModalOpen && (
        <CancelCompleteModal
          onSubmit={() => {
            queryClient.invalidateQueries(['myCounseling']);
            setCancelCompleteModalOpen(false);
          }}
        />
      )}
    </>
  );
}
export default Counseling;

function InformationModal({ title, content, onSubmit }: { title: string; content: string; onSubmit: () => void }) {
  return (
    <div className={styles.dimed}>
      <article className={styles.infoModal}>
        <div className={styles.content}>
          <h4>{title}</h4>
          <div className={styles.modalContentWrap}>
            <p
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            ></p>
          </div>
        </div>
        <div className={styles.btnWrap}>
          <div onClick={onSubmit}>
            <CustomButton>확인하기</CustomButton>
          </div>
        </div>
      </article>
    </div>
  );
}

function CancelCompleteModal({ onSubmit }: { onSubmit: () => void }) {
  return (
    <div className={styles.dimed}>
      <article className={styles.cancelModal}>
        <div className={styles.content}>
          <div className={styles.banner}></div>
          <h4>1:1 상담 취소 완료</h4>
        </div>
        <div className={styles.btnWrap}>
          <div onClick={onSubmit}>
            <CustomButton>확인하기</CustomButton>
          </div>
        </div>
      </article>
    </div>
  );
}
