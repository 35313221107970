import { useLocation, useNavigate } from 'react-router-dom';
import CustomButton from '../CustomButton/CustomButton';
import styles from './LockerRoomNavigateModal.module.scss';
import PAGE_ROUTER from '../../constant/pageList';

interface INavigateModalProps {
  isMindTherapy?: boolean;
  setNavigateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
function LockerRoomNavigateModal({ isMindTherapy, setNavigateModalOpen }: INavigateModalProps) {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(PAGE_ROUTER.마인드테라피?.subMenu?.['내 지갑']?.path, location.pathname);
  return (
    <div className={styles.navigateModalContainer} onClick={() => setNavigateModalOpen(false)}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <h5>{isMindTherapy ? '마인드테라피' : '라커룸'}</h5>
        <p>이동할 메뉴를 선택해주세요</p>
        <ul>
          {isMindTherapy ? (
            <>
              <li>
                <button
                  onClick={() => {
                    if (!PAGE_ROUTER.마인드테라피?.subMenu?.['1:1 상담']?.path.includes(location.pathname))
                      navigate(PAGE_ROUTER.마인드테라피?.subMenu?.['1:1 상담']?.path || '/');
                    setNavigateModalOpen(false);
                  }}
                >
                  1:1 상담
                </button>
              </li>
              <li
                onClick={() => {
                  if (!'/mind-therapy/inner-healing'.includes(location.pathname))
                    navigate('/mind-therapy/inner-healing');
                  setNavigateModalOpen(false);
                }}
              >
                <button>이너힐링</button>
              </li>
            </>
          ) : (
            <>
              <li>
                <button
                  onClick={() => {
                    if (!PAGE_ROUTER.라커룸?.subMenu?.['아바타 꾸미기']?.path.includes(location.pathname))
                      navigate(PAGE_ROUTER.라커룸?.subMenu?.['아바타 꾸미기']?.path || '/');
                    setNavigateModalOpen(false);
                  }}
                >
                  아바타 꾸미기
                </button>
              </li>
              <li
                onClick={() => {
                  if (!PAGE_ROUTER.라커룸?.subMenu?.['내 지갑']?.path.includes(location.pathname))
                    navigate(PAGE_ROUTER.라커룸?.subMenu?.['내 지갑']?.path || '/');
                  setNavigateModalOpen(false);
                }}
              >
                <button>내 지갑</button>
              </li>
              <li
                onClick={() => {
                  if (!PAGE_ROUTER.라커룸?.subMenu?.설정?.path.includes(location.pathname))
                    navigate(PAGE_ROUTER.라커룸?.subMenu?.설정?.path || '/');
                  setNavigateModalOpen(false);
                }}
              >
                <button>설정</button>
              </li>
            </>
          )}
        </ul>
        <div className={styles.closeBtnWrap} onClick={() => setNavigateModalOpen(false)}>
          <CustomButton>닫기</CustomButton>
        </div>
      </div>
    </div>
  );
}
export default LockerRoomNavigateModal;
