import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IErrorControlType {
    showErrorModal: boolean;
    submitFn: ((fn: () => void) => void) | null;
}

const initialState: IErrorControlType = {
    showErrorModal: false,
    submitFn: null,
};

export const errorControlSlice = createSlice({
    name: 'errorControl',
    initialState,
    reducers: {
        setErrorControl(state, action: PayloadAction<IErrorControlType>) {
            return action.payload;
        },
    },
});

export const { setErrorControl } = errorControlSlice.actions;

export default errorControlSlice;
