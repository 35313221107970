const DAY = [
    {
        ko: '월',
        eng: 'MON',
    },
    {
        ko: '화',
        eng: 'TUE',
    },
    {
        ko: '수',
        eng: 'WED',
    },
    {
        ko: '목',
        eng: 'THU',
    },
    {
        ko: '금',
        eng: 'FRI',
    },
    {
        ko: '토',
        eng: 'SAT',
    },
    {
        ko: '일',
        eng: 'SUN',
    },
];
export default DAY;
