import Pie from '@visx/shape/lib/shapes/Pie';
import styles from './DonutChart.module.scss';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { Text } from '@visx/text';

const colors = ['#EF6664', '#F49E64', '#F7C567', '#FCF081'].reverse();

interface IPropstype {
    data: { order: number; value: number; intensity: string }[];
}
function DonutChart({ data }: IPropstype) {
    return (
        <article className={styles.container}>
            <ParentSize>
                {(parent) => {
                    const isMobile = window.innerWidth < 1024;
                    const width = parent.width;
                    const height = parent.height;

                    return (
                        <svg
                            width={width}
                            height={height}
                            viewBox={`0 0 ${width} ${height}`}
                        >
                            <Group top={height / 2} left={width / 2}>
                                {/* 실제 차트 */}
                                <Pie
                                    data={data}
                                    outerRadius={width / 2}
                                    innerRadius={
                                        width / 2 - (isMobile ? 23 : 38)
                                    }
                                    cornerRadius={20}
                                    pieSort={(a, b) => {
                                        return a.order;
                                    }}
                                    pieValue={(d) => d.value}
                                >
                                    {({ arcs, path, pie }) => (
                                        <>
                                            <g>
                                                {new Array(...arcs)
                                                    .reverse()
                                                    .map((arc, i) => {
                                                        arc.startAngle = 0;
                                                        return (
                                                            <g
                                                                key={`pie-arc-${i}`}
                                                            >
                                                                <path
                                                                    d={
                                                                        path(
                                                                            arc
                                                                        ) as
                                                                            | string
                                                                            | undefined
                                                                    }
                                                                    fill={`${colors[i]}`}
                                                                    stroke={`${colors[i]}`}
                                                                />
                                                            </g>
                                                        );
                                                    })}
                                            </g>
                                        </>
                                    )}
                                </Pie>
                                {/* 실제 차트 */}

                                {/* 저강도, 중강도 등의 라벨 및 라벨 상자 */}
                                <Pie
                                    data={data}
                                    outerRadius={width / 2}
                                    innerRadius={50}
                                    cornerRadius={20}
                                    pieSort={(a, b) => {
                                        return a.order;
                                    }}
                                    pieValue={(d) => d.value}
                                >
                                    {({ arcs, path, pie }) => (
                                        <>
                                            <g>
                                                {new Array(...arcs)
                                                    .reverse()
                                                    .map((arc, i) => {
                                                        const reverseData =
                                                            new Array(
                                                                ...data
                                                            ).reverse();
                                                        const [
                                                            centroidX,
                                                            centroidY,
                                                        ] = path.centroid(arc);
                                                        const squareWidth =
                                                            14 *
                                                                reverseData[i]
                                                                    .intensity
                                                                    .length +
                                                            (isMobile
                                                                ? 14
                                                                : 19);
                                                        const squareHeight =
                                                            isMobile
                                                                ? 26
                                                                : 34.5;
                                                        const squareLocation =
                                                            isMobile
                                                                ? 1.1
                                                                : 1.2;

                                                        const halfAngle = 3.1415926535897936;
                                                        const center =
                                                            (arc.startAngle +
                                                                arc.endAngle) /
                                                            2;
                                                        const isLeft =
                                                            center > halfAngle;

                                                        return (
                                                            <Group key={i}>
                                                                <rect
                                                                    x={
                                                                        centroidX *
                                                                            squareLocation -
                                                                        (isLeft
                                                                            ? squareWidth
                                                                            : 0)
                                                                    }
                                                                    y={
                                                                        centroidY *
                                                                            squareLocation -
                                                                        squareHeight /
                                                                            2
                                                                    }
                                                                    rx={8}
                                                                    fill='#fff'
                                                                    width={
                                                                        squareWidth
                                                                    }
                                                                    height={
                                                                        squareHeight
                                                                    }
                                                                />
                                                                <Text
                                                                    x={
                                                                        centroidX *
                                                                            squareLocation -
                                                                        (isLeft
                                                                            ? squareWidth /
                                                                              2
                                                                            : (-1 *
                                                                                  squareWidth) /
                                                                              2)
                                                                    }
                                                                    y={
                                                                        centroidY *
                                                                        squareLocation
                                                                    }
                                                                    textAnchor='middle'
                                                                    verticalAnchor='middle'
                                                                    fontSize={
                                                                        16
                                                                    }
                                                                    fontWeight={
                                                                        700
                                                                    }
                                                                >
                                                                    {
                                                                        reverseData[
                                                                            i
                                                                        ]
                                                                            .intensity
                                                                    }
                                                                </Text>
                                                            </Group>
                                                        );
                                                    })}
                                            </g>
                                        </>
                                    )}
                                </Pie>
                                {/* 저강도, 중강도 등의 라벨 및 라벨 상자 */}
                            </Group>
                        </svg>
                    );
                }}
            </ParentSize>
        </article>
    );
}
export default DonutChart;
