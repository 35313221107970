import { useNavigate, useParams } from 'react-router-dom';
import styles from './TermsDetail.module.scss';
import PAGE_ROUTER from '../../../constant/pageList';
import { useEffect, useState } from 'react';
import POLICY_DATA from '../../../constant/policy';
import ITermsDataType from '../../../type/ITermsDataType';

interface IPropsType {
    setTitle: React.Dispatch<React.SetStateAction<string>>;
}
function TermsDetail({ setTitle }: IPropsType) {
    const navigate = useNavigate();
    const { id } = useParams();

    const [terms, setTerms] = useState<ITermsDataType>();
    useEffect(() => {
        let termsData: ITermsDataType | undefined = undefined;
        for (let i = 0; i < POLICY_DATA.length; i++) {
            if (POLICY_DATA[i].id === Number(id)) {
                termsData = POLICY_DATA[i];
                break;
            }
        }
        if (!termsData || id === '2' || id === '3')
            navigate(
                PAGE_ROUTER.라커룸.subMenu?.설정?.subMenu?.약관?.path || '/'
            );
        else {
            setTerms(termsData);
            setTitle(termsData.title);
        }
    }, []);

    return (
        <>
            <section className={styles.container}>
                <p
                    className={styles.termsContent}
                    dangerouslySetInnerHTML={{
                        __html: terms?.content || '',
                    }}
                ></p>
                <div className={styles.returnBtnWrap}>
                    <button
                        onClick={() =>
                            navigate(
                                PAGE_ROUTER.라커룸.subMenu?.설정?.subMenu?.약관
                                    ?.path || '/'
                            )
                        }
                    >
                        목록보기
                    </button>
                </div>
            </section>
        </>
    );
}
export default TermsDetail;
