import dateFormatter from '../../common/dateFormatter';
import getStampSentence from '../../common/getStampSentence';
import useRecentWeek from '../../hooks/useRecentWeek';
import IMetabolismCheckResult from '../../type/IMetabolismCheckResult';
import LineChart, { lineChartDataType } from '../LineChart/LineChart';
import Stamp from '../Stamp/Stamp';
import StampBackground from '../StampBackground/StampBackground';
import styles from './MetabolismCheckResultContent.module.scss';

interface IPropsType {
  data: IMetabolismCheckResult;
}

function MetabolismCheckResultContent({ data }: IPropsType) {
  const week = useRecentWeek();
  return (
    <>
      <main className={styles.container}>
        <section className={styles.userInfoSection}>
          <div className={styles.userInfoWrap}>
            <div className={styles.sex}>
              <span>성별</span>
              <span>{data?.gender === 'M' ? '남성' : data?.gender === 'F' ? '여성' : ''}</span>
            </div>
            <div className={styles.age}>
              <span>나이</span>
              <span>{data?.age}세</span>
            </div>
          </div>
        </section>
        <section className={styles.chartSection}>
          <h5 className={styles.sectionHeader}>최근 일주일 활동량</h5>
          <div className={styles.chartList}>
            <article className={styles.chartWrap}>
              <h6>고강도 운동일</h6>
              <div>
                {(() => {
                  const activities = data?.activities.hard;
                  const graphData: lineChartDataType[] = activities
                    ? activities.reduce((acc: lineChartDataType[], cur, idx) => {
                        const type = idx === activities.length - 1 ? 'hit' : 'normal';
                        const value = Number(cur.examDays);
                        const xAxisLabel =
                          idx === activities.length - 1 ? '이번' : idx === activities.length - 2 ? '지난번' : '처음';
                        const xAxisSubLabel = `${cur.examDateTime.slice(5, 10).replace(/-/g, '.')}`;
                        return [
                          ...acc,
                          {
                            type,
                            value,
                            xAxisLabel,
                            xAxisSubLabel,
                            useBalloon: type === 'hit',
                            balloonUnit: '일',
                          },
                        ];
                      }, [])
                    : [];
                  const dataLength = activities?.length || 0;
                  return (
                    <LineChart
                      yAxisValues={[0, 1, 2, 3, 4, 5, 6, 7]}
                      min={0}
                      max={7}
                      data={graphData}
                      padding={
                        dataLength >= 2
                          ? {
                              left: 38,
                              right: 46,
                            }
                          : undefined
                      }
                    />
                  );
                })()}
              </div>
            </article>
            <article className={styles.chartWrap}>
              <h6>중강도 운동일</h6>
              <div>
                {(() => {
                  const activities = data?.activities.medium;
                  const graphData: lineChartDataType[] = activities
                    ? activities.reduce((acc: lineChartDataType[], cur, idx) => {
                        const type = idx === activities.length - 1 ? 'hit' : 'normal';
                        const value = Number(cur.examDays);
                        const xAxisLabel =
                          idx === activities.length - 1 ? '이번' : idx === activities.length - 2 ? '지난번' : '처음';
                        const xAxisSubLabel = `${cur.examDateTime.slice(5, 10).replace(/-/g, '.')}`;
                        return [
                          ...acc,
                          {
                            type,
                            value,
                            xAxisLabel,
                            xAxisSubLabel,
                            useBalloon: type === 'hit',
                            balloonUnit: '일',
                          },
                        ];
                      }, [])
                    : [];
                  const dataLength = activities?.length || 0;
                  return (
                    <LineChart
                      yAxisValues={[0, 1, 2, 3, 4, 5, 6, 7]}
                      min={0}
                      max={7}
                      data={graphData}
                      padding={
                        dataLength >= 2
                          ? {
                              left: 38,
                              right: 46,
                            }
                          : undefined
                      }
                    />
                  );
                })()}
              </div>
            </article>
            <article className={styles.chartWrap}>
              <h6>저강도 운동일</h6>
              <div>
                {(() => {
                  const activities = data?.activities.easy;
                  const graphData: lineChartDataType[] = activities
                    ? activities.reduce((acc: lineChartDataType[], cur, idx) => {
                        const type = idx === activities.length - 1 ? 'hit' : 'normal';
                        const value = Number(cur.examDays);
                        const xAxisLabel =
                          idx === activities.length - 1 ? '이번' : idx === activities.length - 2 ? '지난번' : '처음';
                        const xAxisSubLabel = `${cur.examDateTime.slice(5, 10).replace(/-/g, '.')}`;
                        return [
                          ...acc,
                          {
                            type,
                            value,
                            xAxisLabel,
                            xAxisSubLabel,
                            useBalloon: type === 'hit',
                            balloonUnit: '일',
                          },
                        ];
                      }, [])
                    : [];
                  const dataLength = activities?.length || 0;
                  return (
                    <LineChart
                      yAxisValues={[0, 1, 2, 3, 4, 5, 6, 7]}
                      min={0}
                      max={7}
                      data={graphData}
                      padding={
                        dataLength >= 2
                          ? {
                              left: 38,
                              right: 46,
                            }
                          : undefined
                      }
                    />
                  );
                })()}
              </div>
            </article>
          </div>
        </section>
        {data?.examType === 'M' && (
          <section className={styles.stampSection}>
            <h5>최근 일주일 운동 스탬프</h5>
            <p>
              {data
                ? getStampSentence(
                    //   data.recent1WeekActivity.length
                    (() => {
                      const obj: {
                        [key: string]: '';
                      } = {};
                      data.recent1WeekActivity.map((datum) => {
                        obj[datum] = '';
                      });
                      return Object.keys(obj).length;
                    })()
                  )
                : ' '}
            </p>
            <div className={styles.stampWrap}>
              <StampBackground circleBgColor='#e3e7f6' />
              <ul className={styles.stampList}>
                {(() => {
                  return week.map((date, idx) => (
                    <li key={idx}>
                      <Stamp
                        isActive={(() => {
                          const dateString = `${date.year}-${dateFormatter(date.month)}-${dateFormatter(date.date)}`;
                          return data?.recent1WeekActivity.includes(dateString) || false;
                        })()}
                        date={`${dateFormatter(date.month)}.${dateFormatter(date.date)}`}
                        index={idx + 1}
                      />
                    </li>
                  ));
                })()}
              </ul>
            </div>
          </section>
        )}
      </main>
    </>
  );
}
export default MetabolismCheckResultContent;
