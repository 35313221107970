import Header from '../../components/Header/Header';
import styles from './Error.module.scss';
import { ReactComponent as ErrorLogo } from '../../assets/common/icons/pic_error.svg';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Error() {
    const [redirectPath] = useState(
        new URL(document.URL).searchParams.get('redirect-path')
    );
    const navigate = useNavigate();
    function handleSubmit() {
        navigate(redirectPath || '/');
    }
    return (
        <main className={styles.container}>
            <Header isFixed />
            <article className={styles.contentWrap}>
                <div className={styles.iconWrap}>
                    <ErrorLogo />
                </div>
                <div className={styles.messageWrap}>
                    <h3>페이지 오류</h3>
                    <p>잠시 후 다시 시도하세요.</p>
                </div>
            </article>
            <div className={styles.submitBtnWrap} onClick={handleSubmit}>
                <CustomButton>다시 시도하기</CustomButton>
            </div>
        </main>
    );
}
export default Error;
