import Header from '../../components/Header/Header';
import styles from './InnerHealing.module.scss';
import { ReactComponent as ChartBar } from '../../assets/mobile/icons/chart_bar.svg';
import { ReactComponent as Next } from '../../assets/common/icons/ic_16_arr_next.svg';
// import { ReactComponent as Heart } from '../../assets/common/icons/ic_heart_2.svg';
import MetaSpaceIcon from '../../components/assetComponents/MetaSpaceIcon';
import playBtn from '../../assets/common/icons/btn_video_contents.png';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Open } from '../../assets/common/icons/ic_menu_open.svg';
import useUserData from '../../hooks/useUserData';
import { useQuery } from 'react-query';
import { axiosAuthInstance } from '../../api/axios';
import IInnerhealingListData from '../../type/IInnerhealingListData';
import Loading from '../../components/Loading/Loading';
import LockerRoomNavigateModal from '../../components/LockerRoomNavigateModal/LockerRoomNavigateModal';
import dateFormatter from '../../common/dateFormatter';

function InnerHealing() {
  const navigate = useNavigate();
  const location = useLocation();
  const [headerMenuOpen, setHeaderMenuOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [dropdown, setDropdown] = useState(() => {
    const query = new URL(window.location.href).searchParams.get('tab') || '';
    return FILTER_LIST.slice(2).includes(query) ? query : '';
  });

  const [tab, setTab] = useState(() => {
    const query = new URL(window.location.href).searchParams.get('tab') || '';
    return FILTER_LIST.includes(query) ? query : FILTER_LIST[0];
  });

  useEffect(() => {
    setTab(() => {
      const query = new URL(window.location.href).searchParams.get('tab') || '';
      return FILTER_LIST.includes(query) ? query : FILTER_LIST[0];
    });
  }, [location]);

  const userData = useUserData();

  const { data, isLoading } = useQuery<IInnerhealingListData[]>(['inner-healing', tab], () =>
    axiosAuthInstance
      .get(`/api/inner-healing/contents?category=${tab}&userId=${userData.data?.userId}`)
      .then((res) => res.data)
  );

  const [navigateModalOpen, setNavigateModalOpen] = useState(false);
  return (
    <>
      <main className={styles.container}>
        <div className={styles.bg}>
          <Header isNavShow />

          <div className={styles.contentWrap}>
            <div className={styles.mobileHeader}>
              <button className={styles.backBtn} onClick={() => navigate('/health/mind-therapy')}>
                <MetaSpaceIcon />
              </button>
              <div className={styles.center} onClick={() => setNavigateModalOpen(true)}>
                <span>이너힐링</span>
                <Open
                  style={{
                    transform: `rotate(${navigateModalOpen ? '180deg' : '0'})`,
                  }}
                />
              </div>
              <button
                className={styles.setBtn}
                onClick={() => {
                  setHeaderMenuOpen((prev) => !prev);
                }}
                onBlur={(e) => {
                  if (e.relatedTarget === null) setHeaderMenuOpen(false);
                }}
                tabIndex={1}
              >
                <ChartBar />
                {headerMenuOpen && (
                  <ul className={styles.headerMenu}>
                    <li>
                      <button
                        onClick={() => {
                          navigate('/mind-therapy/inner-healing/self-psychology-evaluation/result');
                        }}
                      >
                        셀프평가 결과보기
                      </button>
                    </li>
                  </ul>
                )}
              </button>
            </div>
            <div className={styles.menu}>
              <button onClick={() => navigate('/mind-therapy/personal-counseling', { replace: true })}>1:1 상담</button>
              <button className={styles.hit}>이너힐링</button>
              <button
                className={`${styles.set}`}
                onClick={() => {
                  // setHeaderMenuOpen((prev) => !prev);
                  navigate('/mind-therapy/inner-healing/self-psychology-evaluation/result');
                }}
                // onBlur={(e) => {
                //   if (e.relatedTarget === null) setHeaderMenuOpen(false);
                // }}
                // tabIndex={1}
              >
                <span>셀프평가 결과보기</span>
                <Next />
                {/* {headerMenuOpen && (
                  <ul className={styles.headerMenu}>
                    <li>
                      <button
                        onClick={() => {
                          navigate('/mind-therapy/inner-healing/self-psychology-evaluation/result');
                        }}
                      >
                        셀프 평가 결과보기
                      </button>
                    </li>
                  </ul>
                )} */}
              </button>
            </div>
            <div className={styles.scrollableArea}>
              <ul className={styles.filter}>
                {FILTER_LIST.slice(0, 2).map((filter) => (
                  <li
                    key={filter}
                    onClick={() => navigate(`/mind-therapy/inner-healing?tab=${filter}`, { replace: true })}
                    className={tab === filter ? styles.hit : ''}
                  >
                    <span>{CATEGORY[filter]}</span>
                  </li>
                ))}
                {(() => {
                  const isActive = FILTER_LIST.slice(2).includes(tab);
                  return (
                    <li
                      className={isActive ? styles.hit : ''}
                      onClick={() => setFilterOpen((prev) => !prev)}
                      onBlur={() => setFilterOpen(false)}
                      tabIndex={1}
                    >
                      <span>{dropdown ? CATEGORY[dropdown] : '카테고리'}</span>
                      <Open
                        style={{
                          transform: `rotate(${filterOpen ? '180deg' : '0'})`,
                        }}
                      />
                      {filterOpen && (
                        <ul className={styles.subFilter}>
                          {FILTER_LIST.slice(2).map((filter) => (
                            <li
                              key={filter}
                              onClick={() => {
                                navigate(`/mind-therapy/inner-healing?tab=${filter}`, { replace: true });
                                setDropdown(filter);
                              }}
                            >
                              <span>{CATEGORY[filter]}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  );
                })()}
              </ul>
              <ul className={styles.innerHealingList}>
                {data?.map((datum) => (
                  <li key={datum.innerHealingId} className={styles.innerHealing}>
                    <div
                      className={styles.thumbnailWrap}
                      onClick={() => {
                        navigate(`/mind-therapy/inner-healing/IHC${dateFormatter(datum.innerHealingId)}`);
                      }}
                    >
                      <img src={datum.thumbnailUrl} alt={datum.description} className={styles.thumbnail} />
                      <img src={playBtn} alt='play' className={styles.playBtn} />
                      {datum.isCompleted && (
                        <div className={styles.isViewed} onClick={(e) => e.stopPropagation()}>
                          시청완료
                        </div>
                      )}
                    </div>
                    {/* <ul className={styles.tags}>
                                            {datum.isRecomended && (
                                                <li
                                                    className={styles.recommend}
                                                >
                                                    <Heart />
                                                    <span>추천</span>
                                                </li>
                                            )}
                                            <li className={styles.category1}>
                                                <span>{datum.category1}</span>
                                            </li>
                                            <li className={styles.category2}>
                                                <span>{datum.category2}</span>
                                            </li>
                                        </ul> */}
                    <div className={styles.titleWrap}>
                      <div className={styles.mark}>{datum.category}</div>
                      <h4>{datum.title}</h4>
                    </div>
                    <p>{datum.description}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </main>
      {isLoading && <Loading />}
      {navigateModalOpen && <LockerRoomNavigateModal isMindTherapy setNavigateModalOpen={setNavigateModalOpen} />}
    </>
  );
}
export default InnerHealing;

const FILTER_LIST = [
  'ALL',
  'RECOMMEND',
  'MINDFULNESS',
  'RELATIONSHIP',
  'SELF_ESTEEM',
  'DEPRESSION',
  'ANXIETY',
  'ANGER',
];

const CATEGORY: {
  [key: string]: string;
} = {
  ALL: '전체',
  RECOMMEND: '추천',
  MINDFULNESS: '마음 챙김',
  RELATIONSHIP: '대인관계',
  SELF_ESTEEM: '자존감',
  DEPRESSION: '우울',
  ANXIETY: '불안',
  ANGER: '분노',
};
