import MultipleButton, { IMultipleButtonType } from '../MultipleButton/MultipleButton';
import styles from './CustomConfirm.module.scss';

type IPropsType = {
  message: string;
  subMessage?: string;
} & IMultipleButtonType;
function CustomConfirm({ message, subMessage, falseText, trueText, falseFn, trueFn }: IPropsType) {
  return (
    <div className={styles.container}>
      <form className={styles.confirmForm} onSubmit={(e) => e.preventDefault()}>
        <div className={styles.contentWrap}>
          <p className={styles.question} dangerouslySetInnerHTML={{ __html: message }}></p>
          {subMessage && <p className={styles.subMessage} dangerouslySetInnerHTML={{ __html: subMessage }}></p>}
        </div>
        <div className={styles.btnsWrap}>
          <MultipleButton trueText={trueText} falseText={falseText} trueFn={trueFn} falseFn={falseFn}></MultipleButton>
        </div>
      </form>
    </div>
  );
}
export default CustomConfirm;
