import { useEffect, useState } from 'react';
import styles from './InnerHealingDetail.module.scss';
import CustomConfirm from '../../components/CustomConfirm/CustomConfirm';
import { useNavigate, useParams } from 'react-router-dom';
import InnerHealingCompleteModal from '../../components/InnerHealingCompleteModal/InnerHealingCompleteModal';
import checkMetaverseOrigin from '../../common/checkMetaverseOrigin';
import METAVERSE_ROUTER from '../../constant/metaverseRouter';
import { useMutation } from 'react-query';
import { axiosAuthInstance } from '../../api/axios';
import Loading from '../../components/Loading/Loading';
import { useDispatch } from '../../store/store';
import { logout } from '../../store/jwt';

function InnerHealingDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [exitModalOpen, setExitModalOpen] = useState(false);
  const [completeModalOpen, setCompleteModalOpen] = useState(false);
  const [gotPoint, setGotPoint] = useState(0);

  const { mutateAsync, isLoading } = useMutation<{
    reward: {
      points: number;
      ticket: number;
    };
  }>(() =>
    axiosAuthInstance
      .post(`/api/inner-healing/contents/complete?innerHealingId=${id?.replace(/IHC/g, '')}`)
      .then((res) => res.data)
  );
  useEffect(() => {
    async function handleMessage(e: MessageEvent) {
      if (!checkMetaverseOrigin(e.origin)) return;
      const PARAMS = new URLSearchParams(e.data);
      const IS_BACK_CLICKED = PARAMS.get('isBackClicked');
      if (IS_BACK_CLICKED == 'true') setExitModalOpen(true);
      const IS_FINISHED = PARAMS.get('isFinished');
      if (IS_FINISHED == 'true') {
        const result = await mutateAsync();
        setGotPoint(result.reward.points);
        setCompleteModalOpen(true);
      }
      const UNAUTHORIZED = PARAMS.get('unauthorized');
      if (UNAUTHORIZED == 'true') {
        dispatch(logout());
        navigate('/');
      }
    }
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  return (
    <>
      <main className={styles.container}>
        <iframe
          src={`${process.env.REACT_APP_METAVERSE_DOMAIN}?status=khhealing&token=${localStorage.getItem(
            'access-token'
          )}&contentId=${id}`}
          frameBorder='0'
          allow='autoplay; camera; microphone'
          title='main contents frame'
        ></iframe>
      </main>
      {exitModalOpen && (
        <CustomConfirm
          message='이너힐링을 나갈까요?'
          falseText='아니요'
          trueText='예, 나갈게요'
          falseFn={() => setExitModalOpen(false)}
          trueFn={() => navigate(-1)}
        />
      )}
      {completeModalOpen && <InnerHealingCompleteModal point={gotPoint} />}
      {isLoading && <Loading />}
    </>
  );
}
export default InnerHealingDetail;
