import styles from './CheckBox.module.scss';

interface IPropsType {
    isChecked: boolean;
    onClick?: () => void;
}
function Checkbox({ isChecked, onClick }: IPropsType) {
    return (
        <button
            onClick={() => {
                if (!onClick) return;
                onClick();
            }}
            className={`${styles.container} ${isChecked ? styles.hit : ''}`}
        ></button>
    );
}
export default Checkbox;
