import styles from './Loading.module.scss';
import { ReactComponent as LoadingMobile } from '../../assets/mobile/icons/loading_mobile.svg';
import { ReactComponent as LoadingWeb } from '../../assets/web/icons/loading_web.svg';
import { useEffect, useState } from 'react';

interface IPropsType {
  useAbsolute?: boolean;
}
function Loading({ useAbsolute }: IPropsType) {
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  useEffect(() => {
    function setWidth() {
      setBrowserWidth(window.innerWidth);
    }
    window.addEventListener('resize', setWidth);
    return () => {
      window.removeEventListener('resize', setWidth);
    };
  }, []);
  return (
    <div className={styles.loading} style={{ position: useAbsolute ? 'absolute' : 'fixed' }}>
      {browserWidth >= 1024 ? <LoadingWeb /> : <LoadingMobile />}
    </div>
  );
}
export default Loading;
