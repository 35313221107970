import CustomButton from '../CustomButton/CustomButton';
import styles from './TotalScoreGuideModal.module.scss';

interface IPropsType {
    onSubmit: () => void;
}
function TotalScoreGuideModal({ onSubmit }: IPropsType) {
    return (
        <div className={styles.dimed}>
            <article className={styles.totalScoreGuideModal}>
                <h6>종합 점수란?</h6>
                <p>
                    종합 점수는 검사한 동작의 <br />
                    기능점수와 불편점수를 기반으로 계산돼요.
                </p>
                <div onClick={onSubmit}>
                    <CustomButton>확인하기</CustomButton>
                </div>
            </article>
        </div>
    );
}
export default TotalScoreGuideModal;
