import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function useBackDetect(state?: unknown): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const [detected, setDetected] = useState(false);
  useEffect(() => {
    window.history.pushState(state, '', '');
    const popState = (e: PopStateEvent) => {
      e.preventDefault();
      setDetected(true);
    };
    window.addEventListener('popstate', popState);
    return () => {
      window.removeEventListener('popstate', popState);
    };
  }, []);
  return [detected, setDetected];
}
export default useBackDetect;
