import { useEffect, useState } from 'react';
import styles from './FeedFrame.module.scss';
import { ReactComponent as Edit } from '../../assets/common/icons/ic_edit.svg';
import CustomConfirm from '../CustomConfirm/CustomConfirm';
import SystemAlarm from '../SystemAlarm/SystemAlarm';
import { ReactComponent as Heart } from '../../assets/web/icons/heart.svg';
import { FeedDetailType } from '../../type/IFeedDataType';
import { createPortal } from 'react-dom';
import { axiosAuthInstance } from '../../api/axios';
import { useMutation, useQueryClient } from 'react-query';
import SpeechProfile from '../../assets/common/background/speech_feed_profile.png';
import temporaryAvatar from '../../assets/temporary/avatar.jpg';
import durationFormatter from '../../common/durationFormatter';

interface IPropsType {
  userId: string;
  feed: FeedDetailType;
  isEditable?: boolean;
  editingFeedId?: number;
  handleEditingFeedId?: (id: number | undefined) => void;
}
function FeedFrame({ userId, feed, isEditable = false, editingFeedId, handleEditingFeedId }: IPropsType) {
  const queryClient = useQueryClient();
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [isDeleted, setIsdeleted] = useState(false);
  const [isLike, setIsLike] = useState(feed.isLiked);
  const [showLikeAnimation, setShowLikeAnimation] = useState(false);

  useEffect(() => {
    if (isFirstRender) setIsFirstRender(false);
    else if (isLike) setShowLikeAnimation(true);
    else setShowLikeAnimation(false);
  }, [isLike]);

  const [isEditMode, setIsEditMode] = useState(false);

  const [editMessage, setEditMessage] = useState(feed.feedMessage);

  useEffect(() => {
    if (!isEditMode) setEditMessage(feed.feedMessage);
  }, [isEditMode]);
  useEffect(() => {
    if (!isEditable) setIsEditMode(false);
  }, [isEditable]);

  //editmode 버튼 리스트 관련
  const [noMultipleEditOpen, setNoMultipleEditOpen] = useState(false);
  function handleEditMode() {
    if (!handleEditingFeedId) return;
    if (editingFeedId !== undefined && editingFeedId !== feed.feedId) {
      setNoMultipleEditOpen(true);
      return;
    } else {
      handleEditingFeedId(feed.feedId);
      setIsEditMode(true);
    }
  }
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { mutate: mutateDelete } = useMutation(
    () =>
      axiosAuthInstance.delete('/api/cafeteria/feed', {
        data: {
          userId: Number(userId),
          feedId: feed.feedId,
        },
      }),
    {
      onSuccess: () => {
        setDeleteAlarmOpen(true);
        setIsdeleted(true);
        // queryClient.invalidateQueries(['feed', 'my']);
      },
    }
  );

  function handleDelete() {
    mutateDelete();
    setDeleteModalOpen(false);
    setIsEditMode(false);
    if (handleEditingFeedId) handleEditingFeedId(undefined);
  }

  const { mutate: mutateUpdate } = useMutation(() =>
    axiosAuthInstance.patch('/api/cafeteria/feed', {
      userId: Number(userId),
      feedId: feed.feedId,
      contents: editMessage,
    })
  );
  function handleSave() {
    mutateUpdate();
    feed.feedMessage = editMessage;
    setIsEditMode(false);
    if (handleEditingFeedId) handleEditingFeedId(undefined);
  }
  function handleCancel() {
    if (handleEditingFeedId) handleEditingFeedId(undefined);
    setIsEditMode(false);
  }
  //editmode 버튼 리스트 관련

  function handleLike() {
    if (isLike) feed.likes--;
    else feed.likes++;
    setIsLike((prev) => !prev);
    axiosAuthInstance.post('/api/cafeteria/feed/like', {
      userId: Number(userId),
      feedId: feed.feedId,
      likeYn: isLike ? 'N' : 'Y',
    });
  }

  //시스템알림
  const [deleteAlarmOpen, setDeleteAlarmOpen] = useState(false);
  //시스템알림

  const isSpeechFeed = feed.feedType === 'S';
  const isGoalFeed = feed.feedType === 'G';

  return (
    <>
      <article
        key={feed.feedId}
        className={`${styles.container} ${styles[feed.feedType]} ${isDeleted ? styles.deleted : ''}`}
      >
        <div className={styles.profile}>
          <div className={styles.avatarWrap}>
            <img
              src={
                isSpeechFeed ? SpeechProfile : feed.avatarProfileImagePath ?? temporaryAvatar //feed.avatarProfileImagePath
              }
              alt='아바타'
            />
          </div>
          <div className={styles.postInfo}>
            <span>{isSpeechFeed ? 'TOGATHER' : feed.userName}</span>
            <span>
              {feed.modifiedDateTime && feed.modifiedDateTime !== feed.createdDateTime
                ? `${feed.modifiedDateTime.slice(0, 10)} (수정됨)`
                : feed.createdDateTime.slice(0, 10)}
            </span>
          </div>
          {isEditable &&
            (!isEditMode ? (
              <button className={styles.editModeBtn} onClick={handleEditMode}>
                <Edit />
              </button>
            ) : (
              <ul className={styles.editBtnList}>
                <li className={styles.delete}>
                  <button onClick={() => setDeleteModalOpen(true)}>삭제</button>
                </li>
                {!isGoalFeed && !isSpeechFeed && (
                  <>
                    <li className={styles.gap}></li>
                    <li className={styles.save}>
                      <button onClick={handleSave}>저장</button>
                    </li>
                  </>
                )}
                <li className={styles.cancel}>
                  <button onClick={handleCancel}>취소</button>
                </li>
              </ul>
            ))}
        </div>
        {!isEditMode || isGoalFeed || isSpeechFeed ? (
          <p className={styles.message}>{feed.feedMessage}</p>
        ) : (
          <form className={styles.editForm} onSubmit={(e) => e.preventDefault()}>
            <textarea
              value={editMessage}
              maxLength={40}
              onChange={(e) => {
                const message = e.currentTarget.value;
                setEditMessage(message);
              }}
              onBlur={() => {
                setEditMessage((prev) => prev.slice(0, 40));
              }}
            ></textarea>
            <div className={styles.textLengthInfo}>
              <span>
                {editMessage.length < 40 ? editMessage.length : 40}
                /40
              </span>
            </div>
          </form>
        )}
        <div className={`${styles.cardContent} ${isGoalFeed || isSpeechFeed ? styles.showBanner : ''}`}>
          {isGoalFeed || isSpeechFeed ? (
            <div className={styles.cardWrap}></div>
          ) : feed.feedType === 'E' ? (
            <div className={styles.programWrap}>
              <span>허리 운동 프로그램</span>
              <ul className={styles.programList}>
                {feed.unitList?.map((unit) => (
                  <li key={unit.unitCode}>
                    {unit.unitName} 총 {durationFormatter(unit.setVideoPlayTime * unit.durationCount)} (
                    {unit.setPerUnit}
                    {unit.repsKind === 'H' ? '초' : '회'} {unit.durationCount}세트)
                  </li>
                ))}
              </ul>
            </div>
          ) : feed.feedType === 'I' ? (
            <div className={styles.programWrap}>
              <span>이너힐링</span>
              <ul className={styles.programList}>
                <li>{feed.innerHealingTitle}</li>
              </ul>
            </div>
          ) : (
            ''
          )}
          <div className={`${styles.likeBtnWrap} ${isLike ? styles.hit : ''}`}>
            {feed.likes > 0 && <span>{feed.likes}</span>}
            <button onClick={handleLike}></button>
            {showLikeAnimation && (
              <div className={styles.heart}>
                <Heart />
              </div>
            )}
          </div>
        </div>
      </article>
      {deleteModalOpen &&
        createPortal(
          <CustomConfirm
            message='피드를 삭제할까요?'
            falseText='아니요'
            trueText='예, 삭제할게요'
            falseFn={() => {
              setDeleteModalOpen(false);
            }}
            trueFn={handleDelete}
          />,
          document.body
        )}
      {deleteAlarmOpen &&
        createPortal(
          <SystemAlarm message='피드를 삭제했어요' icon='check' setIsOpen={setDeleteAlarmOpen} />,
          document.body
        )}
      {noMultipleEditOpen &&
        createPortal(
          <SystemAlarm message='동시에 다른 피드를 수정할 수 없어요' setIsOpen={setNoMultipleEditOpen} />,
          document.body
        )}
    </>
  );
}
export default FeedFrame;
