import { useState } from 'react';
import styles from './ExerciseLevelReview.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';

const ANSWER_LIST = ['너무 힘들었어요', '힘들었어요', '적당했어요', '쉬웠어요', '너무 쉬웠어요'];

interface IPropsType {
  nextFn: () => void;
}
function ExerciseLevelReview({ nextFn }: IPropsType) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [answer, setAnswer] = useState(JSON.parse(sessionStorage.getItem('exercise-level-review') || '-1'));
  return (
    <>
      <section className={styles.container}>
        <h5 className={styles.title}>
          <p>이번에 진행한 운동 프로그램은 얼마나 힘들었나요?</p>
          <p>(선택한 정도에 따라 운동 프로그램을 추천해드려요.)</p>
        </h5>
        <ul className={styles.answerList}>
          {ANSWER_LIST.map((answerString, idx) => (
            <li key={answerString} className={answer === idx ? styles.hit : ''} onClick={() => setAnswer(idx)}>
              {answerString}
            </li>
          ))}
        </ul>
      </section>
      <div className={styles.submitBtnWrap}>
        <button className={styles.prev} onClick={() => navigate('/physical-check/metabolism', { state })}>
          이전
        </button>
        <button
          className={`${styles.next} ${[0, 1, 2, 3, 4].includes(answer) ? styles.hit : ''}`}
          onClick={() => {
            if (![0, 1, 2, 3, 4].includes(answer)) return;
            sessionStorage.setItem('exercise-level-review', answer);
            nextFn();
          }}
        >
          다음
        </button>
      </div>
    </>
  );
}
export default ExerciseLevelReview;
