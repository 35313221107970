import { ReactComponent as MetaBack } from '../../assets/mobile/icons/meta_space.svg';
import { ReactComponent as MetaBackLight } from '../../assets/mobile/icons/meta_space_light.svg';

interface IPropsType {
    isLight?: boolean;
}
function MetaSpaceIcon({ isLight = false }: IPropsType) {
    return isLight ? <MetaBackLight /> : <MetaBack />;
}
export default MetaSpaceIcon;
