import { useEffect, useState } from 'react';
import styles from './CounselingApply.module.scss';
import CustomButton from '../CustomButton/CustomButton';
import { ReactComponent as Error } from '../../assets/common/icons/ic_error.svg';
import CustomConfirm from '../CustomConfirm/CustomConfirm';
import MultipleButton from '../MultipleButton/MultipleButton';
import { ICounselorData } from '../../type/ICounselorData';
import { useMutation, useQuery } from 'react-query';
import dateFormatter from '../../common/dateFormatter';
import { axiosAuthInstance } from '../../api/axios';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../Loading/Loading';
import getFormattedTime from '../../common/getFormattedTime';
import IMyCounseling from '../../type/IMyCounselingData';
import useUserData from '../../hooks/useUserData';

interface IPropsType {
  counselorData: ICounselorData;
}

function CounselingApply({ counselorData }: IPropsType) {
  const navigate = useNavigate();
  const userData = useUserData();

  const { id } = useParams();

  const { data: assets } = useQuery<{
    [key in 'P' | 'T']: number;
  }>(['myPoint'], () =>
    axiosAuthInstance.get(`/api/locker-room/currencies/accumulation?userId=${userData.data?.userId}`).then((res) => {
      let newData = res.data;
      return newData.reduce(
        (
          acc: {
            [key in 'p' | 'T']: { accumulation: number };
          },
          val: { currencyType: 'P' | 'T'; accumulation: number }
        ) => ({
          ...acc,
          [val.currencyType]: val.accumulation,
        }),
        {}
      );
    })
  );
  const [calendar, setCalendar] = useState(() => {
    const date = new Date();
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
    };
  });
  const [calendarDays, setCalendarDays] = useState<number[]>([]);

  useEffect(() => {
    const FIRST_DATE = new Date(`${calendar.year}-${dateFormatter(calendar.month)}-01`);
    const FIRST_DATE_MONTH = FIRST_DATE.getMonth() + 1;
    const DAY = FIRST_DATE.getDay() === 0 ? 7 : FIRST_DATE.getDay(); //일요일이면 0 대신 7
    const NEW_DAYS: number[] = [];
    for (let i = DAY - 1; i > 0; i--) {
      const DATE = new Date(new Date(FIRST_DATE).setDate(FIRST_DATE.getDate() - i)).setHours(0, 0, 0, 0);
      NEW_DAYS.push(DATE);
    }

    let num = 0;
    while (true) {
      const DATE = new Date(new Date(FIRST_DATE).setDate(FIRST_DATE.getDate() + num));
      if (DATE.getMonth() + 1 !== FIRST_DATE_MONTH) break;
      NEW_DAYS.push(DATE.setHours(0, 0, 0, 0));
      num++;
    }

    for (let i = NEW_DAYS.length; i < 42; i++) {
      const DATE = new Date(new Date(FIRST_DATE).setDate(FIRST_DATE.getDate() + num));
      NEW_DAYS.push(DATE.setHours(0, 0, 0, 0));
      num++;
    }

    setCalendarDays(NEW_DAYS);
  }, [calendar]);

  const [selectedDate, setSelectedDate] = useState(
    `${calendar.year}-${dateFormatter(calendar.month)}-${dateFormatter(new Date().getDate())}`
  );
  useEffect(() => {
    setSelectedTime('');
  }, [selectedDate]);
  const [selectedTime, setSelectedTime] = useState('');

  const [comment, setComment] = useState('');

  const [applyModalOpen, setApplyModalOpen] = useState(false);
  const [applyCompleteModalOpen, setApplyCompleteModalOpen] = useState(false);
  const [applyFailModalMessage, setApplyFailModalMessage] = useState('');

  const { data: availableReservation, isLoading } = useQuery<{
    counselorId: number;
    availableDate: string;
    availableTimes: string[];
  }>(['availableReservationTimes', selectedDate], () =>
    axiosAuthInstance
      .get(`/api/counselling/reservation/available?counsellorId=${id}&date=${selectedDate}`)
      .then((res) => res.data)
  );

  const { mutateAsync, isLoading: isRequesting } = useMutation(() =>
    axiosAuthInstance.post('/api/counselling/reservation', {
      userId: userData.data?.userId,
      counsellorId: Number(id),
      targetDate: selectedDate,
      targetTime: selectedTime,
      requestContent: comment,
    })
  );

  function checkDuplication() {
    const counselings =
      myCounseling?.reservedCounseling.filter(
        (counseling) => counseling.counselingDate === selectedDate && counseling.counselingTime === `${selectedTime}:00`
      ) || [];
    if (counselings.length) setDuplicateData(counselings); //시간이 겹치는 상담이 있으면 겹침 모달 노출 아니면 신청처리
    else handleSubmit();
  }

  async function handleSubmit() {
    try {
      const result = await mutateAsync();
      const errorCode = result.data?.fault?.errorCodeNumber;
      if (errorCode)
        setApplyFailModalMessage(
          errorCode === 1104 ? '상담 신청이 마감되었어요.' : errorCode === 1071 ? '보유한 티켓이 없어요.' : ''
        );
      else setApplyCompleteModalOpen(true);
    } catch (err) {
      console.log(err);
    }
  }

  function handleToday() {
    const TODAY = new Date();
    setCalendar({ year: TODAY.getFullYear(), month: TODAY.getMonth() + 1 });
    setSelectedDate(`${TODAY.getFullYear()}-${dateFormatter(TODAY.getMonth() + 1)}-${dateFormatter(TODAY.getDate())}`);
    setSelectedTime('');
  }

  const { data: myCounseling } = useQuery<{
    reservedCounseling: IMyCounseling[];
    completedCounseling: IMyCounseling[];
  }>(
    ['myCounseling'],
    () => axiosAuthInstance.get(`/api/counselling/my?userId=${userData.data?.userId}`).then((res) => res.data),
    {
      staleTime: 60000,
    }
  );

  const [duplicateData, setDuplicateData] = useState<IMyCounseling[]>([]);

  const { data: availableDates } = useQuery<{
    availableDates: string[];
  }>(['available-dates', calendar.year, calendar.month], () =>
    axiosAuthInstance
      .get(
        `/api/counselling/reservation/available/monthly?counsellorId=${id}&date=${`${calendar.year}-${dateFormatter(
          calendar.month
        )}-01`}`
      )
      .then((res) => res.data)
  );
  return (
    <>
      <section className={styles.container}>
        <article className={styles.reservation}>
          <div className={styles.calendarWrap}>
            <div className={styles.calendarSetWrap}>
              <div className={styles.calendarSet}>
                <button
                  onClick={() => {
                    const year = calendar.month === 1 ? calendar.year - 1 : calendar.year;
                    const month = calendar.month === 1 ? 12 : calendar.month - 1;
                    setCalendar({ year, month });
                  }}
                ></button>
                <span> {`${calendar.year}. ${calendar.month}`}</span>
                <button
                  onClick={() => {
                    const year = calendar.month === 12 ? calendar.year + 1 : calendar.year;
                    const month = calendar.month === 12 ? 1 : calendar.month + 1;
                    setCalendar({ year, month });
                  }}
                ></button>
              </div>
              <button className={styles.todayBtn} onClick={handleToday}>
                오늘
              </button>
            </div>
            <div className={styles.calendar}>
              <ul className={styles.days}>
                {WEEK.map((week, idx) => (
                  <li key={week} className={`${styles.week} ${idx === 6 ? styles.sunday : ''}`}>
                    <span>{week}</span>
                  </li>
                ))}
                {(() => {
                  const today = new Date(new Date().setHours(0, 0, 0, 0));
                  const [selectedYear, selectedMonth, selectedDates] = selectedDate.split('-');

                  return calendarDays.map((timestamp, idx) => {
                    const [year, month, date] = (() => {
                      const DATE = new Date(timestamp);
                      return [DATE.getFullYear(), DATE.getMonth() + 1, DATE.getDate()];
                    })();
                    const isSelected =
                      Number(selectedYear) === year &&
                      Number(selectedMonth) === month &&
                      Number(selectedDates) === date;
                    const unavailableDate = !availableDates?.availableDates.includes(
                      `${year}-${dateFormatter(month)}-${dateFormatter(date)}`
                    );
                    const isPast =
                      today.getTime() >
                      new Date(`${year}-${dateFormatter(month)}-${dateFormatter(date)}`).setHours(0, 0, 0, 0);
                    const isToday =
                      today.getFullYear() === year && today.getMonth() + 1 === month && today.getDate() === date;

                    const isOtherMonth = month !== calendar.month;
                    return (
                      <li
                        key={idx}
                        onClick={() => {
                          if (isPast || unavailableDate) return;
                          setCalendar({
                            year,
                            month,
                          });
                          setSelectedDate(`${year}-${dateFormatter(month)}-${dateFormatter(date)}`);
                        }}
                        className={`${styles.date} ${isPast || unavailableDate ? styles.past : ''} ${
                          isOtherMonth ? styles.otherMonth : ''
                        } ${isToday && !isSelected ? styles.today : ''} ${isSelected ? styles.selected : ''}
                                  `}
                      >
                        <span>{date}</span>
                      </li>
                    );
                  });
                })()}
              </ul>
            </div>
          </div>
          <div className={styles.reservationTimeWrap}>
            <span className={styles.title}>예약 가능 시간</span>
            <div className={styles.time}>
              <div className={styles.morning}>
                <span>오전</span>
                <ul>
                  {MORNING_TIME.map((time, idx) => {
                    const now = new Date();
                    const hour = time.split(':')[0];
                    const isPast = now.getTime() >= new Date(`${selectedDate} ${hour}:00:00`).getTime();
                    const isAvailable = availableReservation?.availableTimes.includes(`${hour}:00:00`);
                    return (
                      <li
                        key={idx}
                        className={`${!isAvailable || isPast ? styles.full : ''} ${
                          selectedTime === `${hour}:00` ? styles.hit : ''
                        }`}
                        onClick={() => {
                          if (!isAvailable || isPast) return;
                          setSelectedTime(`${hour}:00`);
                        }}
                      >
                        {time}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className={styles.afternoon}>
                <span>오후</span>
                <ul>
                  {AFTERNOON_TIME.map((time, idx) => {
                    const now = new Date();
                    const hour = Number(time.split(':')[0]) + (idx === 0 ? 0 : 12);
                    const isPast = now.getTime() >= new Date(`${selectedDate} ${hour}:00:00`).getTime();
                    const isAvailable = availableReservation?.availableTimes.includes(`${hour}:00:00`);

                    return (
                      <li
                        key={idx}
                        className={`${!isAvailable || isPast ? styles.full : ''} ${
                          selectedTime === `${hour}:00` ? styles.hit : ''
                        }`}
                        onClick={() => {
                          if (!isAvailable || isPast) return;
                          setSelectedTime(`${hour}:00`);
                        }}
                      >
                        {time}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </article>
        <article className={styles.commentWrap}>
          <p>상담 요청사항을 입력해주세요.</p>
          <textarea
            value={comment}
            onChange={(e) => setComment(e.currentTarget.value.slice(0, 300))}
            className={comment.length ? styles.hit : ''}
            placeholder={counselorData.requestMessage}
          ></textarea>
          <div className={styles.commentInfo}>
            {comment.length > 0 && comment.length < 30 && (
              <div className={styles.warning}>
                <Error />
                <span>30자 이상 입력해주세요</span>
              </div>
            )}
            <span className={styles.commentLength}>({comment.length}/300)</span>
          </div>
        </article>
        <div className={styles.submitBtnWrap}>
          <div className={styles.backBtnWrap} onClick={() => navigate('/mind-therapy/personal-counseling')}>
            <button>상담사 목록보기</button>
          </div>
          <div
            onClick={() => {
              if (comment.length < 30 || !assets?.T || !selectedDate || !selectedTime || isRequesting) return;
              setApplyModalOpen(true);
            }}
          >
            <CustomButton isActive={assets?.T && comment.length >= 30 && selectedDate && selectedTime ? true : false}>
              <>상담 예약하기 (보유티켓 {String(assets?.T || 0)}개)</>
            </CustomButton>
          </div>
        </div>
      </section>
      {applyModalOpen && (
        <CustomConfirm
          message='1:1 상담을 신청할까요?'
          falseText='아니요'
          trueText='예, 신청할게요'
          falseFn={() => setApplyModalOpen(false)}
          trueFn={() => {
            checkDuplication();
            setApplyModalOpen(false);
          }}
        />
      )}
      {duplicateData.length > 0 && (
        <ApplyDuplicationModal
          data={duplicateData}
          trueFn={() => {
            handleSubmit();
            setDuplicateData([]);
          }}
          falseFn={() => {
            setDuplicateData([]);
          }}
        />
      )}
      {applyCompleteModalOpen && (
        <ApplyCompleteModal
          counselor={counselorData.userName}
          counselingDate={selectedDate}
          counselingTime={selectedTime}
          onSubmit={() => {
            navigate('/mind-therapy/personal-counseling?tab=1');
          }}
        />
      )}
      {applyFailModalMessage && (
        <ApplyFailModal message={applyFailModalMessage} onSubmit={() => setApplyFailModalMessage('')} />
      )}
      {isLoading && <Loading />}
    </>
  );
}
export default CounselingApply;

const WEEK = ['월', '화', '수', '목', '금', '토', '일'];
const DAYS = [
  26, 27, 28, 29, 30, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28, 29, 30, 31, 1, 2, 3, 4, 5, 6,
];

const MORNING_TIME = ['09:00 ~ 10:00', '10:00 ~ 11:00', '11:00 ~ 12:00'];
const AFTERNOON_TIME = ['12:00 ~ 1:00', '1:00 ~ 2:00', '2:00 ~ 3:00', '3:00 ~ 4:00', '4:00 ~ 5:00', '5:00 ~ 6:00'];

function ApplyDuplicationModal({
  data,
  trueFn,
  falseFn,
}: {
  data: IMyCounseling[];
  trueFn: () => void;
  falseFn: () => void;
}) {
  return (
    <div className={styles.dimed}>
      <article className={styles.applyDuplicationModal}>
        <div className={styles.content}>
          <p>이미 예약된 상담이 있어요.</p>
          <p>그래도 1:1 상담을 신청할까요?</p>
          <div className={styles.reservationInfoWrap}>
            <div className={styles.reservationInfo}>
              <span>1:1 상담</span>
              {data.length > 1 && <div>+ 외 {data.length - 1}건</div>}
            </div>
            <h4 className={styles.counselor}>{data[0].counselorName} 상담사</h4>
            <span className={styles.reservationDate}>
              {data[0].counselingDate} {getFormattedTime(data[0].counselingTime)}
            </span>
          </div>
        </div>
        <div className={styles.modalSubmit}>
          <MultipleButton falseText='아니요' trueText='예, 신청할게요' falseFn={falseFn} trueFn={trueFn} />
        </div>
      </article>
    </div>
  );
}

function ApplyCompleteModal({
  counselor,
  counselingDate,
  counselingTime,
  onSubmit,
}: {
  counselor: string;
  counselingDate: string;
  counselingTime: string;
  onSubmit: () => void;
}) {
  return (
    <div className={styles.dimed}>
      <article className={styles.applyCompleteModal}>
        <div className={styles.content}>
          <div className={styles.banner}></div>
          <h5>1:1 상담 신청 완료</h5>
          <ul className={styles.reservationInfoList}>
            <li>
              <span>상담사</span>
              <span>{counselor} 상담사</span>
            </li>
            <li>
              <span>상담일</span>
              <span>{counselingDate}</span>
            </li>
            <li>
              <span>상담시간</span>
              <span>{getFormattedTime(`${counselingTime}:00`)} (1시간)</span>
            </li>
          </ul>
          <p>* 상담 시작 5분 전부터 입장할 수 있어요.</p>
        </div>
        <div className={styles.modalSubmit}>
          <div onClick={onSubmit}>
            <CustomButton>확인하기</CustomButton>
          </div>
        </div>
      </article>
    </div>
  );
}

function ApplyFailModal({ message, onSubmit }: { message: string; onSubmit: () => void }) {
  return (
    <div className={styles.dimed}>
      <article className={styles.applyFailModal}>
        <div className={styles.content}>
          <h4>❗️1:1 상담 신청 불가</h4>
          <p>{message}</p>
        </div>
        <div className={styles.modalSubmit}>
          <div onClick={onSubmit}>
            <CustomButton>확인하기</CustomButton>
          </div>
        </div>
      </article>
    </div>
  );
}
