import Header from '../../components/Header/Header';
import styles from './MskCheckResult.module.scss';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { axiosAuthInstance } from '../../api/axios';
import Loading from '../../components/Loading/Loading';
import MskResultType from '../../type/MskResultType';
import CheckCompleteModal from '../../components/CheckCompleteModal/CheckCompleteModal';
import useUserData from '../../hooks/useUserData';
import MskCheckResultContent from '../../components/MskCheckResultContent/MskCheckResultContent';

function MskCheckResult() {
  const navigate = useNavigate();
  const userData = useUserData();

  const [checkCompleteModalOpen, setCheckCompleteModalOpen] = useState(false);

  function handleSubmit() {
    if (data?.examTypeCode === 'M') {
      setCheckCompleteModalOpen(true);
    } else {
      localStorage.setItem('check-compensation-point', String(data?.examCompletedPoint.toLocaleString()));
      navigate('/physical-check/resetting/alert');
    }
  }

  const { data, isLoading } = useQuery<MskResultType>(
    ['msk-result'],
    () =>
      axiosAuthInstance.get(`/api/fitness/exam/initial-result?userId=${userData.data?.userId}`).then((res) => res.data),
    { staleTime: 20000 }
  );

  return (
    <>
      <main className={styles.container}>
        <div className={styles.bg}>
          <Header isNavShow />
          <div className={styles.contentWrap}>
            <header className={styles.mobileHeader}>검사 결과</header>
            <div className={styles.menu}>
              <button>오늘의 운동</button>
            </div>
            <div className={styles.scrollableArea}>
              {data && (
                <>
                  <h4 className={styles.testDate}>{data.currentExamDateTime.slice(0, 10).replace(/-/g, '. ')}</h4>
                  <MskCheckResultContent data={data} />
                  <div className={styles.submitBtnWrap}>
                    <div onClick={handleSubmit}>
                      <CustomButton height={56}>확인하기</CustomButton>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </main>

      {isLoading && <Loading />}
      {checkCompleteModalOpen && (
        <CheckCompleteModal
          point={String(data?.examCompletedPoint.toLocaleString())}
          onSubmit={() => navigate('/fitness/exercise-of-the-day')}
        />
      )}
    </>
  );
}
export default MskCheckResult;
