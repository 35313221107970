interface IPageInfoType {
  [key: string]: {
    name: string;
    path: string;
    subMenu?: {
      [key: string]: {
        path: string;
        subMenu?: {
          [key: string]: {
            path: string;
          };
        };
      };
    };
    headerActiveConditions: string[];
  };
}
const PAGE_ROUTER: IPageInfoType = {
  피트니스: {
    name: 'fitness',
    path: '/health/fitness',
    subMenu: {
      '오늘의 운동': {
        path: '/fitness/exercise-of-the-day',
      },
    },
    headerActiveConditions: ['fitness', 'physical-check', 'exercise'],
  },
  마인드테라피: {
    name: 'mind-therapy',
    path: '/health/mind-therapy',
    subMenu: {
      '1:1 상담': {
        path: '/mind-therapy/personal-counseling',
      },
      이너힐링: {
        path: '/mind-therapy/inner-healing/check',
      },
    },
    headerActiveConditions: ['mind-therapy'],
  },
  카페테리아: {
    name: 'cafeteria',
    path: '/health/cafeteria',
    subMenu: {
      피드: {
        path: '/cafeteria/feed',
      },
    },
    headerActiveConditions: ['cafeteria'],
  },
  스피치: {
    name: 'speech',
    path: '/health/speech',
    subMenu: {
      스피치: {
        path: '/speech',
      },
    },
    headerActiveConditions: ['speech'],
  },
  라커룸: {
    name: 'locker-room',
    path: '/health/locker-room',
    subMenu: {
      '아바타 꾸미기': {
        path: '/locker-room/edit-avatar',
      },
      '내 지갑': {
        path: '/locker-room/my-wallet',
      },
      설정: {
        path: '/locker-room/setting',
        subMenu: {
          '카카오 알림톡 설정': {
            path: '/locker-room/setting/kakao-alarm',
          },
          '운동장소 설정': {
            path: '/locker-room/setting/exercise-venue',
          },
          공지사항: {
            path: '/locker-room/setting/notice',
          },
          약관: {
            path: '/locker-room/setting/terms',
          },
          자주묻는질문: {
            path: '/locker-room/setting/faq',
          },
        },
      },
    },
    headerActiveConditions: ['locker-room'],
  },
};

export default PAGE_ROUTER;
