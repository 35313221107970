import CustomButton from '../CustomButton/CustomButton';
import styles from './WhirlModal.module.scss';
import modalImage01 from '../../assets/common/background/pic_sick.png';

interface IPropsType {
    onSubmit: () => void;
}
function WhirlModal({ onSubmit }: IPropsType) {
    return (
        <div className={styles.dimed}>
            <article className={styles.modal}>
                <div className={styles.imageWrap}>
                    <img src={modalImage01} alt='어지러움' />
                </div>
                <p>
                    검사 진행 중 어지럽거나 심한 불편을 느끼면, 운동을 중단하고
                    전문가와 상담하세요.
                </p>
                <div className={styles.modalSubmitBtn} onClick={onSubmit}>
                    <CustomButton>확인하기</CustomButton>
                </div>
            </article>
        </div>
    );
}
export default WhirlModal;
