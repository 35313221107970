import { useState } from 'react';
import { useDispatch, useSelector } from '../store/store';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { IJwtTokenType, logout } from '../store/jwt';

function useUserData() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDataState = useSelector((state) => state.user);
  const [userData, setUserData] = useState(
    (() => {
      try {
        if (userDataState.data) return userDataState;
        const ACCESS_TOKEN = localStorage.getItem('access-token');

        const LOGIN_TIMESTAMP = Number(localStorage.getItem('login-timestamp'));
        const EXPIRE_IN = Number(localStorage.getItem('access-token-expire-in'));

        if (
          !ACCESS_TOKEN ||
          !LOGIN_TIMESTAMP ||
          !EXPIRE_IN ||
          new Date().getTime() > LOGIN_TIMESTAMP + EXPIRE_IN * 1000
        ) {
          navigate('/');
          dispatch(logout());
          return { data: null };
        }
        const info: IJwtTokenType = jwtDecode(ACCESS_TOKEN);
        return { data: info };
      } catch (err) {
        navigate('/');
        return { data: null };
      }
    })()
  );
  return userData;
}
export default useUserData;
