import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import MobileHeader from '../../components/MobileHeader/MobileHeader';
import styles from './ExerciseResetting.module.scss';
import { useEffect, useState } from 'react';
import ExerciseFacility from '../../components/ExerciseResetting/ExerciseFacility/ExerciseFacility';
import Alert from '../../components/ExerciseResetting/Alert/Alert';
import KakaoAlert from '../../components/ExerciseResetting/KakaoAlert/KakaoAlert';
import CustomConfirm from '../../components/CustomConfirm/CustomConfirm';
const PARAM_DATA = {
  'exercise-facility': {
    title: '운동 장소 설정',
  },
  alert: {
    title: '운동 알람',
  },
  'kakao-alert': {
    title: '운동 알람 설정',
  },
};
function ExerciseResetting() {
  const navigate = useNavigate();
  const { menu } = useParams<{
    menu: 'exercise-facility' | 'alert' | 'kakao-alert';
  }>();
  useEffect(() => {
    if (menu !== 'exercise-facility' && menu !== 'alert' && menu !== 'kakao-alert')
      navigate('/fitness/exercise-of-the-day');
  }, []);
  const [stopModalOpen, setStopModalOpen] = useState(false);
  return (
    <>
      <main className={styles.container}>
        <div className={styles.bg}>
          <Header isNavShow />
          <div className={styles.contentWrap}>
            <MobileHeader title={PARAM_DATA[menu!]?.title} backBtn='arrow' backFn={() => setStopModalOpen(true)} />
            <div className={styles.menu}>
              <button>오늘의 운동</button>
            </div>
            <div className={styles.scrollableArea}>
              {menu === 'exercise-facility' ? (
                <ExerciseFacility />
              ) : menu === 'alert' ? (
                <Alert />
              ) : menu === 'kakao-alert' ? (
                <KakaoAlert />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </main>
      {stopModalOpen && (
        <CustomConfirm
          message='검사를 그만할까요?'
          falseText='아니요'
          trueText='예, 그만할게요'
          falseFn={() => setStopModalOpen(false)}
          trueFn={() => navigate('/fitness/exercise-of-the-day')}
        />
      )}
    </>
  );
}
export default ExerciseResetting;
