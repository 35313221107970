import styles from './CheckIntroduce.module.scss';
import { ReactComponent as Time } from '../../../assets/common/icons/ic_time.svg';
import { ReactComponent as Check } from '../../../assets/common/icons/check.svg';
import CustomButton from '../../CustomButton/CustomButton';
import { Navigate, useLocation } from 'react-router-dom';
import IMskCheckType from '../../../type/IMskCheckType';
import durationFormatter from '../../../common/durationFormatter';
import { useState } from 'react';
import ActionGuide from '../ActionGuide/ActionGuide';
interface IPropsType {
  nextFn: () => void;
  data: IMskCheckType;
}
function CheckIntroduce({ nextFn, data }: IPropsType) {
  const [guideModalOpen, setGuideModalOpen] = useState(false);
  return data ? (
    <>
      <section className={styles.container}>
        <p className={styles.title}>
          {data.uncomfortablePartName} 상태를 확인하기 위해 {data.examParts.length}개의 동작을 검사해볼게요.
        </p>
        <ul className={styles.movementList}>
          {data.examParts.map((part) => (
            <li className={`${styles.movement} ${part.isExamCompleted ? styles.hit : ''}`} key={part.gestureCode}>
              <div className={styles.thumbnailWrap}>
                <img src={part.guideImage} alt={part.gestureName} />
              </div>
              <div className={styles.movementInfoWrap}>
                <div className={styles.duration}>
                  <Time />
                  <span>총 {durationFormatter(part.gestureDuration)}</span>
                </div>
                <h4 className={styles.movementTitle}>{part.gestureName}</h4>
              </div>
              {part.isExamCompleted && (
                <div className={styles.completeMarkWrap}>
                  <Check />
                  <span>완료</span>
                </div>
              )}
            </li>
          ))}
        </ul>
      </section>
      <div className={styles.submitBtnWrap}>
        <div
          onClick={() => {
            setGuideModalOpen(true);
          }}
        >
          <CustomButton height={56}>시작하기</CustomButton>
        </div>
      </div>
      {guideModalOpen && <ActionGuide nextFn={nextFn} />}
    </>
  ) : (
    ''
  );
}

export default CheckIntroduce;
