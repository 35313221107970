import Header from '../../components/Header/Header';
import styles from './JoinPolicyDetail.module.scss';
import { ReactComponent as Back } from '../../assets/mobile/icons/ic_back.svg';
import { useNavigate, useParams } from 'react-router-dom';
import POLICY_DATA from '../../constant/policy';
import { useEffect, useState } from 'react';
import ITermsDataType from '../../type/ITermsDataType';

function JoinPolicyDetail() {
    const navigate = useNavigate();
    const { id } = useParams() as { id: string };

    const [terms, setTerms] = useState<ITermsDataType>();
    useEffect(() => {
        let termsData: ITermsDataType | undefined = undefined;
        for (let i = 0; i < POLICY_DATA.length; i++) {
            if (POLICY_DATA[i].id === Number(id)) {
                termsData = POLICY_DATA[i];
                break;
            }
        }
        if (!termsData || id === '4') navigate('/join/policy');
        setTerms(termsData);
    }, []);
    return (
        <main className={styles.container}>
            <Header />
            <div className={`${styles.bg} max-1024`}>
                <div className={styles.contentWrap}>
                    <h3 className={styles.subTitle}>
                        <Back onClick={() => navigate('/join/policy')} />
                        <span>{terms?.title}</span>
                    </h3>
                    <section
                        className={styles.policyDetailWrap}
                        dangerouslySetInnerHTML={{
                            __html: terms?.content || '',
                        }}
                    ></section>
                </div>
            </div>
        </main>
    );
}
export default JoinPolicyDetail;
