import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IJwtTokenType {
  userId: string;
  userType: 'MSK' | 'MTA';
}
const initialState: {
  data: IJwtTokenType | null;
} = {
  data: null,
};
export const jwtSlice = createSlice({
  name: 'navigate',
  initialState,
  reducers: {
    login(state, action: PayloadAction<IJwtTokenType>) {
      state.data = { ...action.payload };
    },
    logout(state) {
      state.data = null;
      localStorage.removeItem('access-token');
      localStorage.removeItem('refresh-token');
      localStorage.removeItem('access-token-expire-in');
      localStorage.removeItem('refresh-token-expire-in');
      localStorage.removeItem('refresh-token-key');
    },
  },
});

export const { login, logout } = jwtSlice.actions;

export default jwtSlice;
