import { scaleLinear } from '@visx/scale';
import styles from './BarChart.module.scss';
import { ParentSize } from '@visx/responsive';
import { Group } from '@visx/group';
import { Axis } from '@visx/axis';
import { Line, Bar } from '@visx/shape';
import { Text } from '@visx/text';
import React from 'react';
import { ReactComponent as Good } from '../../assets/common/icons/mental_graph_good.svg';
import { ReactComponent as Bad } from '../../assets/common/icons/mental_graph_bad.svg';

interface IPropsType {
  data: {
    title: string;
    score: number[];
  }[];
  min: number;
  max: number;
  yAxisValues: number[];
  xAxisLabels: string[];
  color: string[];
  fontColor: string[];
  reverse?: boolean;
}

function BarChart({
  data,
  min,
  max,
  yAxisValues,
  xAxisLabels,
  color,
  fontColor,
  reverse, //부정 차트 위아래 바뀜
}: IPropsType) {
  return (
    <article className={styles.container}>
      {!reverse && (
        <ParentSize>
          {(parent) => {
            const isMobile = window.innerWidth < 1024;
            const isMultiBar = data[0].score.length === 2;
            const width = parent.width;
            const height = isMobile ? (isMultiBar ? 254 : 240) : isMultiBar ? 329 : 304;
            const margin = {
              top: isMobile ? (isMultiBar ? 37 : 21) : isMultiBar ? 49 : 22,
              left: isMobile ? 30 : 63,
              right: 0,
              bottom: isMobile ? 38 : 48,
            };

            const innerWidth = width - margin.left - margin.right;
            const innerHeight = height - margin.top - margin.bottom;
            const barWidth = isMobile ? 21 : 56;
            const barGap = isMobile ? 14.5 : 43;

            const xScale = scaleLinear({
              domain: [0, data.length - 1],
              range: [isMobile ? 34 : 101, innerWidth - (isMobile ? 36 : 102) - barWidth],
            });
            const yScale = scaleLinear({
              domain: [min, max],
              range: [innerHeight, 0],
            });
            return (
              <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
                <Group top={margin.top} left={25}>
                  <Axis
                    scale={yScale}
                    orientation='left'
                    numTicks={10}
                    hideTicks
                    tickValues={yAxisValues}
                    hideAxisLine
                    tickFormat={(val) => val + ''}
                    tickLabelProps={{
                      fill: '#7F828A',
                      fontSize: isMobile ? 12 : 14,
                      textAnchor: 'middle',
                      verticalAnchor: 'middle',
                    }}
                  />
                </Group>
                <Group top={margin.top} left={margin.left}>
                  {yAxisValues.map((val, idx) => (
                    <Line
                      key={idx}
                      from={{
                        x: 0,
                        y: yScale(val),
                      }}
                      to={{
                        x: innerWidth,
                        y: yScale(val),
                      }}
                      strokeDasharray={idx === 0 ? 0 : 3}
                      stroke='#E5E3EC'
                    />
                  ))}
                </Group>
                <Group top={margin.top} left={margin.left}>
                  {data.map((datum, dataIdx) => {
                    return (
                      <>
                        {datum.score.map((score, scoreIdx) => {
                          const barHeight = innerHeight - yScale(score) - 1;
                          const barY = yScale(max - (max - score)) + 0.5;
                          let barX = xScale(dataIdx);
                          const IS_GOOD =
                            datum.score[0] < datum.score[1]
                              ? 'good'
                              : datum.score[0] > datum.score[1]
                                ? 'bad'
                                : 'normal';
                          const markWidth = 24;
                          let markX = barX;
                          const markY = barY - (isMobile ? 24 : 34) - markWidth;
                          if (isMultiBar) {
                            barX = scoreIdx === 0 ? barX - barGap / 2 - barWidth / 2 : barX + barGap / 2 + barWidth / 2;
                            markX = markX + barGap / 2 + barWidth - markWidth / 2;
                          }

                          return (
                            <React.Fragment key={dataIdx + `${scoreIdx}`}>
                              <Bar
                                key={dataIdx + `${scoreIdx}`}
                                width={barWidth}
                                height={barHeight}
                                x={barX}
                                y={barY}
                                rx={4}
                                fill={color[scoreIdx]}
                              />
                              <Text
                                x={barX + barWidth / 2}
                                y={barY - (isMobile ? 6 : 12)}
                                textAnchor='middle'
                                fill={fontColor[scoreIdx]}
                                fontWeight={datum.score.length === 1 ? 700 : scoreIdx === 0 ? '400' : '700'}
                                fontSize={
                                  isMobile
                                    ? datum.score.length === 1
                                      ? 16
                                      : scoreIdx === 0
                                        ? 14
                                        : 16
                                    : datum.score.length === 1
                                      ? 20
                                      : scoreIdx === 0
                                        ? 17
                                        : 20
                                }
                              >
                                {`${score}점`}
                              </Text>
                              {scoreIdx === 1 &&
                                (IS_GOOD === 'good' ? (
                                  <Good x={markX} y={markY} />
                                ) : IS_GOOD === 'bad' ? (
                                  <Bad x={markX} y={markY} />
                                ) : (
                                  ''
                                ))}
                            </React.Fragment>
                          );
                        })}
                      </>
                    );
                  })}
                </Group>
                <Group top={margin.top + innerHeight} left={margin.left}>
                  {data.map((datum, dataIdx) => {
                    const X = xScale(dataIdx) + barWidth / 2;
                    return (
                      <React.Fragment key={dataIdx}>
                        <Text
                          verticalAnchor='start'
                          textAnchor='middle'
                          y={isMobile ? 11 : 17}
                          x={X}
                          fontSize={isMobile ? 14 : 16}
                        >
                          {xAxisLabels[dataIdx]}
                        </Text>
                      </React.Fragment>
                    );
                  })}
                </Group>
              </svg>
            );
          }}
        </ParentSize>
      )}
      {reverse && (
        <ParentSize>
          {(parent) => {
            const isMobile = window.innerWidth < 1024;
            const isMultiBar = data[0].score.length === 2;
            const width = parent.width;
            const height = isMobile ? (isMultiBar ? 263 : 240) : isMultiBar ? 358 : 304;
            const margin = {
              top: isMobile ? 32 : 48,
              left: isMobile ? 30 : 63,
              right: 0,
              bottom: isMobile ? (isMultiBar ? 53 : 21) : isMultiBar ? 69 : 22,
            };

            const innerWidth = width - margin.left - margin.right;
            const innerHeight = height - margin.top - margin.bottom;
            const barWidth = isMobile ? 21 : 56;
            const barGap = isMobile ? 14.5 : 43;

            const xScale = scaleLinear({
              domain: [0, data.length - 1],
              range: [isMobile ? 34 : 101, innerWidth - (isMobile ? 36 : 102) - barWidth],
            });
            const yScale = scaleLinear({
              domain: [max, min],
              range: [innerHeight, 0],
            });
            return (
              <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
                <Group top={margin.top} left={25}>
                  <Axis
                    scale={yScale}
                    orientation='left'
                    numTicks={10}
                    hideTicks
                    tickValues={yAxisValues}
                    hideAxisLine
                    tickFormat={(val) => val + ''}
                    tickLabelProps={{
                      fill: '#7F828A',
                      fontSize: isMobile ? 12 : 14,
                      textAnchor: 'middle',
                      verticalAnchor: 'middle',
                    }}
                  />
                </Group>
                <Group top={margin.top} left={margin.left}>
                  {yAxisValues.map((val, idx) => (
                    <Line
                      key={idx}
                      from={{
                        x: 0,
                        y: yScale(val),
                      }}
                      to={{
                        x: innerWidth,
                        y: yScale(val),
                      }}
                      strokeDasharray={idx === 0 ? 0 : 3}
                      stroke='#E5E3EC'
                    />
                  ))}
                </Group>
                <Group top={margin.top} left={margin.left}>
                  {data.map((datum, dataIdx) => {
                    return (
                      <>
                        {datum.score.map((score, scoreIdx) => {
                          const barHeight = innerHeight - yScale(max - score) - 1;
                          const barY = yScale(0);
                          let barX = xScale(dataIdx);
                          const IS_GOOD =
                            datum.score[0] > datum.score[1]
                              ? 'good'
                              : datum.score[0] < datum.score[1]
                                ? 'bad'
                                : 'normal';
                          const markWidth = 24;
                          let markX = barX;
                          const markY = barY + barHeight + (isMobile ? 24 : 34);
                          if (isMultiBar) {
                            barX = scoreIdx === 0 ? barX - barGap / 2 - barWidth / 2 : barX + barGap / 2 + barWidth / 2;
                            markX = markX + barGap / 2 + barWidth - markWidth / 2;
                          }

                          return (
                            <React.Fragment key={dataIdx + `${scoreIdx}`}>
                              <Bar
                                key={dataIdx + `${scoreIdx}`}
                                width={barWidth}
                                height={barHeight}
                                x={barX}
                                y={barY}
                                rx={4}
                                fill={color[scoreIdx]}
                              />
                              <Text
                                x={barX + barWidth / 2}
                                y={barY + barHeight + (isMobile ? 6 : 11)}
                                textAnchor='middle'
                                verticalAnchor='start'
                                fill={fontColor[scoreIdx]}
                                fontWeight={datum.score.length === 1 ? 700 : scoreIdx === 0 ? '400' : '700'}
                                fontSize={
                                  isMobile
                                    ? datum.score.length === 1
                                      ? 16
                                      : scoreIdx === 0
                                        ? 14
                                        : 16
                                    : datum.score.length === 1
                                      ? 20
                                      : scoreIdx === 0
                                        ? 17
                                        : 20
                                }
                              >
                                {`${score}점`}
                              </Text>
                              {scoreIdx === 1 &&
                                (IS_GOOD === 'good' ? (
                                  <Good x={markX} y={markY} />
                                ) : IS_GOOD === 'bad' ? (
                                  <Bad x={markX} y={markY} />
                                ) : (
                                  ''
                                ))}
                            </React.Fragment>
                          );
                        })}
                      </>
                    );
                  })}
                </Group>
                <Group top={0} left={margin.left}>
                  {data.map((datum, dataIdx) => {
                    const X = xScale(dataIdx) + barWidth / 2;
                    return (
                      <React.Fragment key={dataIdx}>
                        <Text
                          verticalAnchor='start'
                          textAnchor='middle'
                          y={isMobile ? 12 : 19}
                          x={X}
                          fontSize={isMobile ? 14 : 16}
                        >
                          {xAxisLabels[dataIdx]}
                        </Text>
                      </React.Fragment>
                    );
                  })}
                </Group>
              </svg>
            );
          }}
        </ParentSize>
      )}
    </article>
  );
}
export default BarChart;
