import { useNavigate } from 'react-router-dom';
import styles from './NoticeDetail.module.scss';
import PAGE_ROUTER from '../../../constant/pageList';
import { useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import INoticeType from '../../../type/INoticeType';

interface IPropsType {
    id: number;
}

function NoticeDetail({ id }: IPropsType) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [notice, setNotice] = useState<INoticeType>();

    useEffect(() => {
        const cacheData:
            | {
                  pages: {
                      content: INoticeType[];
                  }[];
              }
            | undefined = queryClient.getQueryData(['noticeList']);
        if (!cacheData) {
            navigate(
                PAGE_ROUTER.라커룸.subMenu?.설정?.subMenu?.공지사항?.path ||
                    '/health/locker-room'
            );
            return;
        }
        page: for (let p = 0; p < cacheData.pages.length; p++) {
            for (let i = 0; i < cacheData.pages[p].content.length; i++) {
                console.log(id, cacheData.pages[p].content[i].id);
                if (id === cacheData.pages[p].content[i].id) {
                    setNotice(cacheData.pages[p].content[i]);
                    break page;
                }
            }
        }
    }, []);
    return (
        <section className={styles.container}>
            <div className={styles.noticeInfoWrap}>
                <h3>{notice?.title}</h3>
                <span>{notice?.noticeAt.split('T')[0]}</span>
            </div>
            <p
                className={styles.noticeDetailWrap}
                dangerouslySetInnerHTML={{ __html: notice?.contents || '' }}
            ></p>
            <div className={styles.returnBtnWrap}>
                <button
                    onClick={() =>
                        navigate(
                            PAGE_ROUTER.라커룸.subMenu?.설정?.subMenu?.공지사항
                                ?.path || '/'
                        )
                    }
                >
                    목록보기
                </button>
            </div>
        </section>
    );
}
export default NoticeDetail;
