import CustomButton from '../CustomButton/CustomButton';
import styles from './IntensityGuideModal.module.scss';

interface IPropsType {
    onSubmit: () => void;
}

function IntensityGuideModal({ onSubmit }: IPropsType) {
    return (
        <div className={styles.dimed}>
            <article className={styles.modal}>
                <div className={styles.content}>
                    <h5>운동강도란?</h5>
                    <p>
                        운동강도는 스마트링에서 수집된 심박수(HR)를 활용해서
                        표시돼요.
                        <br />
                        <br />
                        운동강도는 운동의 활동량을 나타낼 뿐 운동을 평가하는
                        의미가 아니에요.
                    </p>
                </div>
                <div className={styles.submitBtnWrap}>
                    <div onClick={onSubmit}>
                        <CustomButton>확인하기</CustomButton>
                    </div>
                </div>
            </article>
        </div>
    );
}
export default IntensityGuideModal;
