import CustomButton from '../CustomButton/CustomButton';
import styles from './CheckCompleteModal.module.scss';

interface IPropsType {
  point?: string;
  onSubmit: () => void;
}
function CheckCompleteModal({ point, onSubmit }: IPropsType) {
  return (
    <div className={styles.dimed}>
      <article className={styles.modal}>
        <div className={styles.contentWrap}>
          <div className={styles.bannerWrap}>{point !== '0' && point && <span>+ {point}pt</span>}</div>
          <h3>검사 완료!</h3>
          <p>
            검사 결과를 바탕으로 추천된
            <br className={styles.pcBr} /> 운동
            <br className={styles.mobileBr} />
            프로그램을 확인해보세요.
          </p>
        </div>
        <div className={styles.submitWrap}>
          <div onClick={onSubmit}>
            <CustomButton>확인하기</CustomButton>
          </div>
        </div>
      </article>
    </div>
  );
}
export default CheckCompleteModal;
