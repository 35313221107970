import { useEffect, useState } from 'react';
import styles from './SpeechFrame.module.scss';
import CustomButton from '../CustomButton/CustomButton';
import MultipleButton from '../MultipleButton/MultipleButton';
import CustomConfirm from '../CustomConfirm/CustomConfirm';
import { useNavigate } from 'react-router-dom';
import ISpeechListType from '../../type/ISpeechListType';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { axiosAuthInstance } from '../../api/axios';
import getFormattedTime from '../../common/getFormattedTime';
import useUserData from '../../hooks/useUserData';
import Loading from '../Loading/Loading';
import IMySpeechType from '../../type/IMySpeechType';

interface IPropsType {
  speech: ISpeechListType | IMySpeechType;
  removeButton?: boolean;
  isPastSpeech?: boolean;
  refetch: () => void;
  isMySpeech?: boolean;
}
function SpeechFrame({ speech, removeButton, isPastSpeech = false, refetch, isMySpeech = false }: IPropsType) {
  const navigate = useNavigate();
  const userData = useUserData();

  const [applyModalOpen, setApplyModalOpen] = useState(false);
  const [applyDuplicationModalOpen, setApplyDuplicationModalOpen] = useState(false);
  const [applyCompleteModalOpen, setApplyCompleteModalOpen] = useState(false);
  const [applyFailModalOpen, setApplyFailModalOpen] = useState(false);

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [cancelCompleteModalOpen, setCancelCompleteModalOpen] = useState(false);
  const STATUSBAR_TYPE = {
    Y: 'type1',
    E: 'type2',
    C: 'type3',
  };

  function checkEnterable() {
    const START_TIME = new Date(`${speech.speechDate} ${speech.startTime}`).getTime();
    const END_TIME = new Date(`${speech.speechDate} ${speech.endTime}`).getTime();
    const NOW = new Date().getTime();
    if (START_TIME - 1000 * 60 * 5 < NOW && END_TIME > NOW) return true;
    else return false;
  }

  // 1:신청하기 2:이미 신청한 스피치에요 3:마감된 스피치에요 4:완료된 스피치에요
  // 5:신청 취소하기 6:스피치 입장하기 7:버튼 없음
  function handleUpdateStatus() {
    if (isMySpeech) {
      if (speech.speechCompleted || isPastSpeech) return 7;
      else if (checkEnterable()) return 6;
      else return 5;
    }
    if (speech.speechCompleted || isPastSpeech) return 4;
    else if ('amIParticipant' in speech && speech.amIParticipant === 'Y') return 2;
    else if (speech.maxParticipants === speech.participants) return 3;
    else return 1;
  }
  const [status, setStatus] = useState(1);
  useEffect(() => {
    setStatus(handleUpdateStatus());
  }, [speech]);

  const { mutateAsync: handleApply, isLoading } = useMutation(() =>
    axiosAuthInstance
      .post(`/api/speech/apply?userId=${userData.data?.userId}&speechId=${speech.speechId}`)
      .then((res) => res.data)
  );

  // 내 스피치 조회
  const { data, refetch: refetchMyData } = useInfiniteQuery<{
    content: IMySpeechType[];
  }>(
    ['mySpeechData'],
    () => axiosAuthInstance.get(`/api/speech/my?userId=${userData.data?.userId}`).then((res) => res.data),
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: 0,
    }
  );
  // 내 스피치 조회
  const [duplicateSpeech, setDuplicateSpeech] = useState<IMySpeechType[]>();

  function checkDuplication() {
    //true 반환하면 중복 false 반환하면 통과
    if (!data) return;
    const startTime = new Date(`${speech.speechDate} ${speech.startTime}`).getTime();
    const endTime = new Date(`${speech.speechDate} ${speech.endTime}`).getTime();
    const speechList = data.pages[0].content.filter((speechDatum) => {
      const datumStartTime = new Date(`${speechDatum.speechDate} ${speechDatum.startTime}`).getTime();
      const datumEndTime = new Date(`${speechDatum.speechDate} ${speechDatum.endTime}`).getTime();
      if (
        (startTime >= datumStartTime && startTime < datumEndTime) ||
        (endTime > datumStartTime && endTime <= datumEndTime) ||
        (startTime === datumStartTime && endTime === datumEndTime)
      )
        return true;
      else return false;
    });

    setDuplicateSpeech(speechList);
    return !!speechList.length;
  }

  async function handleSubmit() {
    const result = await handleApply();
    const errorCode = result.data?.fault?.errorCodeNumber;
    if (errorCode) setApplyFailModalOpen(true);
    else setApplyCompleteModalOpen(true);
  }

  const { mutateAsync: handleCancel } = useMutation(() =>
    axiosAuthInstance.post(`/api/speech/cancel?userId=${userData.data?.userId}&speechId=${speech.speechId}`)
  );

  return (
    <>
      <article className={styles.speech}>
        <div className={styles.speechDetail}>
          {'mySpeechApplyStatus' in speech && isMySpeech && (
            <div className={`${styles.statusBar} ${styles[STATUSBAR_TYPE[speech.mySpeechApplyStatus]]}`}>
              {speech.mySpeechApplyStatus === 'Y' ? '완료' : speech.mySpeechApplyStatus === 'E' ? '예정' : '취소'}
            </div>
          )}
          <h4>{speech.speechTitle}</h4>
          <ul>
            <li>
              <span>발표자</span>
              <span>{speech.counselorName}</span>
              {/* <button
                onClick={() => navigate(`/mind-therapy/personal-counseling/counselor/${speech.counselorId}`)}
              ></button> */}
            </li>
            <li>
              <span>진행일</span>
              <span>{speech.speechDate}</span>
            </li>
            <li>
              <span>진행시간</span>
              <span>
                {getFormattedTime(speech.startTime)} ~ {getFormattedTime(speech.endTime)}
              </span>
            </li>
            <li>
              <span>참여인원</span>
              <span>
                {speech.participants} / {speech.maxParticipants} 명
              </span>
            </li>
          </ul>
        </div>
        {!removeButton && (
          <>
            {status === 1 && (
              <button className={`${styles.submitBtn} ${styles.hit}`} onClick={() => setApplyModalOpen(true)}>
                신청하기
              </button>
            )}
            {status === 2 && <button className={styles.submitBtn}>이미 신청한 스피치에요</button>}
            {status === 3 && <button className={styles.submitBtn}>마감된 스피치에요</button>}
            {status === 4 && <button className={styles.submitBtn}>완료된 스피치에요</button>}
            {status === 5 && (
              <button className={`${styles.submitBtn} ${styles.cancelBtn}`} onClick={() => setCancelModalOpen(true)}>
                신청 취소하기
              </button>
            )}
            {status === 6 && (
              <button
                className={`${styles.submitBtn} ${styles.hit}`}
                onClick={() => {
                  navigate(`/speech/detail/${speech.speechId}`);
                }}
              >
                스피치 입장하기
              </button>
            )}
          </>
        )}
      </article>
      {applyModalOpen && (
        <CustomConfirm
          message='스피치를 신청할까요?'
          falseText='아니요'
          trueText='예, 신청할게요'
          falseFn={() => setApplyModalOpen(false)}
          trueFn={async () => {
            setApplyModalOpen(false);
            //내 스피치에서 예정된 스피치들과 비교하여 시간 겹치는지 확인 후 겹치면 applyduplicationModal open처리 작업 필요
            try {
              if (checkDuplication()) {
                setApplyDuplicationModalOpen(true);
                return;
              }
              handleSubmit();
            } catch (err) {}
          }}
        />
      )}
      {applyDuplicationModalOpen && duplicateSpeech && (
        <ApplyDuplicationModal
          trueFn={async () => {
            setApplyDuplicationModalOpen(false);
            handleSubmit();
          }}
          falseFn={() => {
            setApplyDuplicationModalOpen(false);
          }}
          data={duplicateSpeech}
        />
      )}
      {applyCompleteModalOpen && (
        <ApplyCompleteModal
          data={speech}
          onSubmit={() => {
            refetch();
            refetchMyData();
            setApplyCompleteModalOpen(false);
          }}
        />
      )}
      {applyFailModalOpen && <ApplyFailModal onSubmit={() => setApplyFailModalOpen(false)} />}
      {cancelModalOpen && (
        <CustomConfirm
          message='스피치를 취소할까요?'
          falseText='아니요'
          trueText='예, 취소할게요'
          falseFn={() => setCancelModalOpen(false)}
          trueFn={async () => {
            setCancelModalOpen(false);
            await handleCancel();
            setCancelCompleteModalOpen(true);
          }}
        />
      )}
      {cancelCompleteModalOpen && (
        <CancelCompleteModal
          onSubmit={() => {
            refetch();
            refetchMyData();
            setCancelCompleteModalOpen(false);
          }}
        />
      )}
      {isLoading && <Loading />}
    </>
  );
}
export default SpeechFrame;

function ApplyDuplicationModal({
  trueFn,
  falseFn,
  data,
}: {
  trueFn: () => void;
  falseFn: () => void;
  data: IMySpeechType[];
}) {
  return (
    <div className={styles.dimed}>
      <article className={styles.applyDuplicationModal}>
        <div className={styles.content}>
          <p>이미 예약된 스피치가 있어요.</p>
          <p>그래도 스피치를 신청할까요?</p>
          <div className={styles.reservationInfoWrap}>
            <div className={styles.reservationInfo}>
              <span>스피치</span>
              {data.length > 1 && <div>+ 외 {data.length - 1}건</div>}
            </div>
            <h4 className={styles.counselor}>{data[0].speechTitle}</h4>
            <span className={styles.reservationDate}>
              {data[0].speechDate} {getFormattedTime(data[0].startTime)}
            </span>
          </div>
        </div>
        <div className={styles.modalSubmit}>
          <MultipleButton falseText='아니요' trueText='예, 신청할게요' falseFn={falseFn} trueFn={trueFn} />
        </div>
      </article>
    </div>
  );
}

function ApplyCompleteModal({ onSubmit, data }: { onSubmit: () => void; data: ISpeechListType | IMySpeechType }) {
  return (
    <div className={styles.dimed}>
      <article className={styles.applyCompleteModal}>
        <div className={styles.content}>
          <div className={styles.banner}></div>
          <h5>스피치 신청 완료</h5>
          <ul className={styles.reservationInfoList}>
            <li>
              <span>발표자</span>
              <span>{data.counselorName} 상담사</span>
            </li>
            <li>
              <span>진행일</span>
              <span>{data.speechDate}</span>
            </li>
            <li>
              <span>진행시간</span>
              <span>
                {getFormattedTime(data.startTime)} ~ {getFormattedTime(data.endTime)}
              </span>
            </li>
          </ul>
          <p>* 스피치 시작 5분 전부터 입장할 수 있어요.</p>
        </div>
        <div className={styles.modalSubmit}>
          <div onClick={onSubmit}>
            <CustomButton>확인하기</CustomButton>
          </div>
        </div>
      </article>
    </div>
  );
}

function ApplyFailModal({ onSubmit }: { onSubmit: () => void }) {
  return (
    <div className={styles.dimed}>
      <article className={styles.applyFailModal}>
        <div className={styles.content}>
          <h4>❗️1:1 상담 신청 불가</h4>
          <p>스피치 참여 인원이 마감되었어요.</p>
        </div>
        <div className={styles.modalSubmit}>
          <div onClick={onSubmit}>
            <CustomButton>확인하기</CustomButton>
          </div>
        </div>
      </article>
    </div>
  );
}

function CancelCompleteModal({ onSubmit }: { onSubmit: () => void }) {
  return (
    <div className={styles.dimed}>
      <article className={styles.speechCompleteModal}>
        <div className={styles.content}>
          <div className={styles.banner}></div>
          <h4>스피치 취소 완료</h4>
        </div>
        <div className={styles.modalSubmit}>
          <div onClick={onSubmit}>
            <CustomButton>확인하기</CustomButton>
          </div>
        </div>
      </article>
    </div>
  );
}
