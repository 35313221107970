import { Link, useLocation } from 'react-router-dom';
import styles from './NavBar.module.scss';
import fitness from '../../assets/common/nav/btn_nav_01.png';
import mindTherapy from '../../assets/common/nav/btn_nav_02.png';
import cafeteria from '../../assets/common/nav/btn_nav_03.png';
import speech from '../../assets/common/nav/btn_nav_04.png';
import lockerRoom from '../../assets/common/nav/btn_nav_05.png';
import fitnessActive from '../../assets/common/nav/btn_nav_01_active.png';
import mindTherapyActive from '../../assets/common/nav/btn_nav_02_active.png';
import cafeteriaActive from '../../assets/common/nav/btn_nav_03_active.png';
import speechActive from '../../assets/common/nav/btn_nav_04_active.png';
import lockerRoomActive from '../../assets/common/nav/btn_nav_05_active.png';
import getFormattedTime from '../../common/getFormattedTime';

interface IPropsType {
  counselingToday: string | null;
  speechToday: string | null;
}

interface IContentType {
  [key: string]: {
    logo: string;
    logoActive: string;
    path: string;
    title: string;
    metabusUrl?: string;
  };
}
export const MAIN_CONTENTS: IContentType = {
  fitness: {
    logo: fitness,
    logoActive: fitnessActive,
    path: '/health/fitness',
    title: '피트니스',
    metabusUrl: `${process.env.REACT_APP_METAVERSE_DOMAIN}?status=khfitness`,
  },
  'mind-therapy': {
    logo: mindTherapy,
    logoActive: mindTherapyActive,
    path: '/health/mind-therapy',
    title: '마인드테라피',
    metabusUrl: `${process.env.REACT_APP_METAVERSE_DOMAIN}?status=khtherapy`,
  },
  cafeteria: {
    logo: cafeteria,
    logoActive: cafeteriaActive,
    path: '/health/cafeteria',
    title: '카페테리아',
    metabusUrl: `${process.env.REACT_APP_METAVERSE_DOMAIN}?status=khcafeteria`,
  },
  speech: {
    logo: speech,
    logoActive: speechActive,
    path: '/health/speech',
    title: '스피치',
    metabusUrl: `${process.env.REACT_APP_METAVERSE_DOMAIN}?status=khspeech`,
  },
  'locker-room': {
    logo: lockerRoom,
    logoActive: lockerRoomActive,
    path: '/health/locker-room',
    title: '라커룸',
    metabusUrl: `${process.env.REACT_APP_METAVERSE_DOMAIN}?status=khlocker`,
  },
};

function NavBar({ counselingToday, speechToday }: IPropsType) {
  const { pathname } = useLocation();
  return (
    <nav className={styles.container}>
      <ul>
        {Object.keys(MAIN_CONTENTS).map((content) => {
          const [hour, minute, second] = (counselingToday || '').split(':');
          const [speechHour, speechMinute, speechSecond] = (speechToday || '').split(':');

          return (
            <li
              className={MAIN_CONTENTS[content].path === pathname ? styles.hit : ''}
              key={MAIN_CONTENTS[content].path}
            >
              {content === 'mind-therapy' && counselingToday && (
                <div className={styles.reservationInfoWrap}>
                  <span>{getFormattedTime(`${hour}:${minute}:${second}`)}</span>
                </div>
              )}
              {content === 'speech' && speechToday && (
                <div className={styles.reservationInfoWrap}>
                  <span>{getFormattedTime(`${speechHour}:${speechMinute}:${speechSecond}`)}</span>
                </div>
              )}
              <Link to={MAIN_CONTENTS[content].path}>
                {MAIN_CONTENTS[content].path === pathname ? (
                  <img src={MAIN_CONTENTS[content].logoActive} alt='아이콘' />
                ) : (
                  <img src={MAIN_CONTENTS[content].logo} alt='아이콘' />
                )}
                <span>{MAIN_CONTENTS[content].title}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
export default NavBar;
