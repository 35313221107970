import Header from '../../components/Header/Header';
import styles from './Setting.module.scss';
import { ReactComponent as Avatar } from '../../assets/web/icons/avatar.svg';
import {
  LOCKERROOM_SETTING_NAV_LIST_1,
  LOCKERROOM_SETTING_NAV_LIST_2,
  LOCKERROOM_SETTING_NAV_LIST_3,
} from '../../constant/lockerroomSettingNavList.ts';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as Open } from '../../assets/common/icons/ic_menu_open.svg';
import { ReactComponent as Close } from '../../assets/common/icons/ic_menu_close.svg';
import IcMoveRightIcon from '../../components/assetComponents/IcMoveRightIcon.tsx';
import { useLockerRoomData } from '../../hooks/useLockerRoomData.tsx';
import MyInfo from '../../components/LockerRoom/MyInfo/MyInfo.tsx';
import PAGE_ROUTER from '../../constant/pageList.ts';
import MetaSpaceIcon from '../../components/assetComponents/MetaSpaceIcon.tsx';
import LockerRoomNavigateModal from '../../components/LockerRoomNavigateModal/LockerRoomNavigateModal.tsx';

function Setting() {
  const [headerOpen, setHeaderOpen] = useState(false);
  const navData = useLockerRoomData();
  const { menu } = useParams() as { menu: string };
  const navigate = useNavigate();
  const navShow = !menu || menu === 'cancel-membership' || menu === 'logout';
  const detailWrapRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (!detailWrapRef.current) return;
    detailWrapRef.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    if (menu && !navData[menu]?.component) navigate('/health/locker-room');
  }, []);
  return (
    <>
      <main className={styles.container}>
        <Header isNavShow />
        <nav className={`${styles.lockerRoomNav} max-1024`}>
          <div className={styles.left}>
            <button onClick={() => navigate(PAGE_ROUTER.라커룸?.subMenu?.['내 지갑']?.path || '/', { replace: true })}>
              내 지갑
            </button>
            <button className={styles.hit}>설정</button>
          </div>
          <button
            className={styles.avatarBtn}
            onClick={() => navigate(PAGE_ROUTER.라커룸?.subMenu?.['아바타 꾸미기']?.path || '/')}
          >
            <Avatar />
            <span>아바타 꾸미기</span>
          </button>
        </nav>
        <div className={`${styles.contentArea} max-1024`}>
          {navShow && (
            <header className={styles.mobileHeader}>
              <button className={styles.backBtn} onClick={() => navigate('/health/locker-room')}>
                <MetaSpaceIcon />
              </button>
              <button className={styles.menuOpenBtn} onClick={() => setHeaderOpen((prev) => !prev)}>
                <span>설정</span>
                {headerOpen ? <Close /> : <Open />}
              </button>
            </header>
          )}
          <div className={`${styles.contents} ${menu ? styles.onContent : ''} ${navShow ? styles.navShow : ''}`}>
            <nav className={styles.nav}>
              <ul>
                {Object.keys(LOCKERROOM_SETTING_NAV_LIST_1).map((key) => (
                  <li
                    key={key}
                    onClick={() => navigate(LOCKERROOM_SETTING_NAV_LIST_1[key].path, { replace: true })}
                    className={
                      LOCKERROOM_SETTING_NAV_LIST_3[menu]?.title === LOCKERROOM_SETTING_NAV_LIST_3[key].title ||
                      (!menu && key === 'my-info')
                        ? styles.hit
                        : ''
                    }
                  >
                    <span>{LOCKERROOM_SETTING_NAV_LIST_1[key].title}</span>
                    <IcMoveRightIcon />
                  </li>
                ))}
                <li className={styles.breakLine}></li>
                {Object.keys(LOCKERROOM_SETTING_NAV_LIST_2).map((key) => (
                  <li
                    key={key}
                    onClick={() => navigate(LOCKERROOM_SETTING_NAV_LIST_2[key].path, { replace: true })}
                    className={`${styles.other} ${
                      LOCKERROOM_SETTING_NAV_LIST_3[menu]?.title === LOCKERROOM_SETTING_NAV_LIST_3[key].title
                        ? styles.hit
                        : ''
                    }`}
                  >
                    <span>{LOCKERROOM_SETTING_NAV_LIST_2[key].title}</span>
                    <IcMoveRightIcon />
                  </li>
                ))}
              </ul>
            </nav>
            <div className={styles.detailWrap} ref={detailWrapRef}>
              {!menu ? <MyInfo /> : navData[menu]?.component}
            </div>
            {/* {menu && navData[menu]?.component} */}
          </div>
        </div>
      </main>
      {headerOpen && <LockerRoomNavigateModal setNavigateModalOpen={setHeaderOpen} />}
    </>
  );
}
export default Setting;
