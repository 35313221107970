import styles from './ExerciseDescription.module.scss';
import { useEffect, useState } from 'react';
import Checkbox from '../../CheckBox/Checkbox';
import CustomConfirm from '../../CustomConfirm/CustomConfirm';
import { ReactComponent as Back } from '../../../assets/mobile/icons/ic_back_b.svg';
import CustomButton from '../../CustomButton/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import { IUnitType } from '../../../type/ITimelineDataType';
import { axiosAuthInstance } from '../../../api/axios';
import useUserData from '../../../hooks/useUserData';
import { useMutation } from 'react-query';
import Loading from '../../Loading/Loading';
import { useDispatch, useSelector } from '../../../store/store';

interface IPropsType {
  nextFn: () => void;
  unit: IUnitType;
  useSmartring: 'Y' | 'N';
}
function ExerciseDescription({ nextFn, unit, useSmartring }: IPropsType) {
  const { sessionId } = useParams();
  const { unitIndex } = useParams() as { unitIndex: string };

  const userData = useUserData();
  const navigate = useNavigate();
  const [isAutoProgress, setIsAutoProgress] = useState(true);
  const [autoSecond, setAutoSecond] = useState(15);
  const [autoCancelModalOpen, setAutoCancelModalOpen] = useState(false);
  const [autoProgressModalOpen, setAutoProgressModalOpen] = useState(false);
  const [stopModalOpen, setStopModalOpen] = useState(false);

  useEffect(() => {
    if (isAutoProgress && !autoCancelModalOpen && autoSecond > 0)
      setTimeout(() => {
        setAutoSecond((prev) => prev - 1);
      }, 1000);
  }, [autoSecond, autoCancelModalOpen]);

  useEffect(() => {
    if (isAutoProgress) setAutoSecond(15);
  }, [isAutoProgress]);

  useEffect(() => {
    if (isAutoProgress && autoSecond === 0)
      setTimeout(async () => {
        if (useSmartring === 'Y' && unitIndex === '0') await smartringConnection('C');
        nextFn();
      }, 1000);
  }, [autoSecond]);

  const { mutateAsync: smartringConnection, isLoading: smartringLoading } = useMutation<
    {
      result: 'SUCCESS';
    },
    null,
    'C' | 'D'
  >((status) =>
    axiosAuthInstance
      .post(`/api/fhir/smart-ring/subscribe?userId=${userData.data?.userId}`, {
        sessionId,
        connectionSet: status,
      })
      .then((res) => res.data)
  );

  const { mutateAsync: smartringExit } = useMutation(() =>
    axiosAuthInstance.post(`/api/fhir/observation/exit?sessionId=${sessionId}`)
  );
  return (
    <>
      <section className={styles.container}>
        <header className={styles.header}>
          <button onClick={() => setStopModalOpen(true)}>
            <Back />
          </button>
          <span>{unit.unitNameKorean}</span>
        </header>
        <div className={styles.description}>
          <div className={styles.thumbnailWrap}>
            <video playsInline src={unit.guideVideoUrl} autoPlay muted></video>
            {/* <img src={bg} alt='고개 숙이기' /> */}
          </div>
          <div className={styles.right}>
            <p className='custom-scroll' dangerouslySetInnerHTML={{ __html: unit.description }}></p>
            <div
              className={styles.autoProgress}
              onClick={() => {
                if (isAutoProgress) setAutoCancelModalOpen(true);
                else setAutoProgressModalOpen(true);
              }}
            >
              <Checkbox isChecked={isAutoProgress} />
              <span>자동 운동진행</span>
            </div>
            <div
              className={styles.submitBtnWrap}
              onClick={async () => {
                if (useSmartring === 'Y' && unitIndex === '0') await smartringConnection('C');
                nextFn();
              }}
            >
              <CustomButton>{`진행하기${isAutoProgress ? ` (${autoSecond}초 후 자동진행)` : ''}`}</CustomButton>
            </div>
          </div>
        </div>

        <div className={styles.submitWrap}>
          <div
            className={styles.autoProgress}
            onClick={() => {
              if (isAutoProgress) setAutoCancelModalOpen(true);
              else setAutoProgressModalOpen(true);
            }}
          >
            <Checkbox isChecked={isAutoProgress} />
            <span>자동 운동진행</span>
          </div>
          <div className={styles.submitBtnWrap}>
            <div
              onClick={async () => {
                if (useSmartring === 'Y' && unitIndex === '0') await smartringConnection('C');
                nextFn();
              }}
            >
              <CustomButton>{`진행하기${isAutoProgress ? ` (${autoSecond}초 후 자동진행)` : ''}`}</CustomButton>
            </div>
          </div>
        </div>
      </section>
      {autoCancelModalOpen && (
        <CustomConfirm
          message='운동 자동진행을 취소할까요?'
          falseText='아니요'
          trueText='예, 취소할게요'
          falseFn={() => setAutoCancelModalOpen(false)}
          trueFn={() => {
            setAutoCancelModalOpen(false);
            setIsAutoProgress(false);
          }}
        />
      )}
      {autoProgressModalOpen && (
        <CustomConfirm
          message='운동을 자동으로 진행할까요?'
          falseText='아니요'
          trueText='예, 진행할게요'
          falseFn={() => setAutoProgressModalOpen(false)}
          trueFn={() => {
            setAutoProgressModalOpen(false);
            setIsAutoProgress(true);
          }}
        />
      )}
      {stopModalOpen && (
        <CustomConfirm
          message='운동을 그만할까요?'
          falseText='아니요'
          trueText='예, 그만할게요'
          subMessage={useSmartring === 'Y' ? '지금까지 저장된 <br />스마트링 정보를 잃을 수 있어요.' : ''}
          falseFn={() => setStopModalOpen(false)}
          trueFn={() => {
            setStopModalOpen(false);
            if (useSmartring === 'Y') {
              smartringExit();
            }
            navigate('/fitness/exercise-of-the-day');
          }}
        />
      )}
      {smartringLoading && <Loading />}
    </>
  );
}
export default ExerciseDescription;
