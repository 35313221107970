import { useEffect, useState } from 'react';
import styles from './SelectExerciseLevel.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import IExerciseLevelQuestion from '../../../type/IExerciseLevelQuestion';
import { axiosAuthInstance } from '../../../api/axios';
import { useQuery } from 'react-query';
import Loading from '../../Loading/Loading';

interface IPropsType {
  nextFn: () => void;
}
function SelectExerciseLevel({ nextFn }: IPropsType) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [answer, setAnswer] = useState(Number(JSON.parse(sessionStorage.getItem('select-exercise-level') || '-1')));

  const { data, isLoading } = useQuery<IExerciseLevelQuestion>(['exercise-level-question'], () =>
    axiosAuthInstance.get('/api/fitness/survey/exercise-difficulty/initial-level?examType=I').then((res) => res.data)
  );

  const [levelList, setLevelList] = useState<number[]>([]);
  useEffect(() => {
    if (!data) return;
    setLevelList(data.answerList.map((answerData) => answerData.level));
  }, [data]);

  return (
    <>
      <section className={styles.container}>
        <h5 className={styles.title}>
          <p>어느 정도로 운동을 하고 싶나요?</p>
          <p>(선택한 정도에 따라 운동 프로그램을 추천해드려요.)</p>
        </h5>
        <ul className={styles.answerList}>
          {data?.answerList.map((answerData) => (
            <li
              key={answerData.level}
              className={answer === answerData.level ? styles.hit : ''}
              onClick={() => setAnswer(answerData.level)}
            >
              {answerData.answer}
            </li>
          ))}
        </ul>
      </section>
      <div className={styles.submitBtnWrap}>
        <button className={styles.prev} onClick={() => navigate('/physical-check/metabolism?index=1', { state })}>
          이전
        </button>
        <button
          className={`${styles.next} ${levelList.includes(answer) ? styles.hit : ''}`}
          onClick={() => {
            if (!levelList.includes(answer)) return;
            sessionStorage.setItem('select-exercise-level', String(answer));
            nextFn();
          }}
        >
          다음
        </button>
      </div>
      {isLoading && <Loading />}
    </>
  );
}
export default SelectExerciseLevel;
