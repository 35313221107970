import { useNavigate } from 'react-router-dom';
import styles from './Logout.module.scss';
import Title from '../Title/Title';
import CustomConfirm from '../../CustomConfirm/CustomConfirm';
import PAGE_ROUTER from '../../../constant/pageList';
import { useDispatch } from 'react-redux';
import { logout } from '../../../store/jwt';

// const LOGOUT_URL = `https://kauth.kakao.com/oauth/logout?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&logout_redirect_uri=${window.location.origin}`;
function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleLogout() {
    dispatch(logout());
    navigate('/');
    // window.location.href = LOGOUT_URL;
  }
  return (
    <>
      <section className={styles.mobileContainer}>
        <CustomConfirm
          message='로그아웃 할까요?'
          falseText='아니요'
          trueText='예, 나갈게요'
          trueFn={handleLogout}
          falseFn={() => {
            navigate(PAGE_ROUTER.라커룸?.subMenu?.설정.path || '/');
          }}
        />
      </section>
      <section className={styles.container}>
        <Title title='로그아웃' />
        <p>아래 버튼 클릭 시 바로 로그아웃 돼요.</p>
        <div className={styles.logoutBtnWrap}>
          <button onClick={handleLogout}>로그아웃하기</button>
        </div>
      </section>
    </>
  );
}
export default Logout;
