import ITermsDataType from '../type/ITermsDataType';

const POLICY_DATA: ITermsDataType[] = [
  {
    id: 1,
    title: '서비스 이용약관',
    isRequired: true,
    content: `
        <h3 class="header">제1조 (목적)</h3>
        <div class="content">
            본 약관은 주식회사 카카오헬스케어(이하 “회사”)가 제공하는
            TOGATHER(투개더) 서비스(이하 “서비스”)를 이용함에 있어 회원과 회사
            간의 권리･의무 및 책임사항과 기타 필요한 사항을 규정함을 목적으로
            합니다.
        </div>
        <h3 class="header">제2조 (약관의 게시 및 개정)</h3>
        <div class="content">
            1. 회사는 본 약관의 내용을 회원이 알기 쉽도록 서비스의 화면 내에
            별도의 연결 메뉴를 제공하여 게시합니다.
        </div>
        <div class="content">
            2. 회사는 본 약관을 관련 법령을 위배하지 않는 범위 내에서 개정할 수
            있습니다. 본 약관이 개정되는 경우 회사는 개정약관의 시행일자와 개정
            사유를 명시하여 서비스 내 공지사항 등을 통해 개정약관의 시행일자 7일
            전부터 적용일자 전일까지 공지합니다. 단, 개정 내용이 회원에게 불리한
            경우에는 그 시행일자 30일 전부터 시행일 이후 상당한 기간 동안 이를
            공지하고, 카카오계정에 등록된 이메일 주소로 이메일 발송 또는
            카카오톡 메시지 등으로 명확히 개별 통지합니다.
        </div>
        <div class="content">
            3. 회사가 전항에 따라 공지 또는 통지하면서 공지일 또는 통지일로부터
            개정약관 시행일 7일 후까지 거부의사를 표시하지 않으면 개정에 동의한
            것으로 본다는 내용을 명확하게 전달하였음에도 회원이 해당기간 내
            명시적으로 거부의사를 표명하지 않았을 경우 회원이 개정약관에 동의한
            것으로 봅니다.
        </div>
        <div class="content">
            4. 회원이 개정약관에 동의하지 않는 경우 회사는 해당 회원에게
            개정약관의 내용을 적용할 수 없으며, 해당 회원은 서비스 이용계약을
            해지할 수 있습니다. 다만 기존 약관을 적용할 수 없는 특별한 사정이
            있는 경우 회사는 해당 회원과의 서비스 이용계약을 해지할 수 있습니다.
        </div>
        <h3 class="header">제3조 (약관 외 준칙)</h3>
        <div class="content">
            회사는 본 약관에서 정한 사항을 기반으로 하여 보다 세부적인 사항은
            별도의 운영정책이나 가이드라인(이하 총칭하여 “세부지침”)에서 정할 수
            있습니다. 본 약관과 세부지침의 내용이 충돌하는 경우 세부지침에서
            정한 바에 따릅니다.
        </div>
        <h3 class="header">제4조 (서비스 이용계약의 성립)</h3>
        <div class="content">
            1. 회원이 되고자 하는 자가 본 약관에 동의하면 회사가 신청자의
            카카오계정 정보 등을 확인한 후 서비스 이용 신청을 승낙함으로써
            서비스 이용계약이 성립됩니다. 서비스를 이용하기 위해서는
            카카오계정이 필요합니다.
        </div>
        <div class="content">
            2. 회사는 다음 각 호의 경우에는 이용자의 서비스 이용 신청을 승낙하지
            않거나 유보할 수 있습니다.
        </div>
        <div class="content">
            <p style="padding: 0 0 0 20px">
                (1) 다른 사람의 명의 등 타인의 개인정보를 도용하여 서비스 이용
                신청을 하는 경우
            </p>
            <p style="padding: 0 0 0 20px">
                (2) 허위의 정보를 기재하거나 회사가 요청하는 내용을 기재하지
                않은 경우
            </p>
            <p style="padding: 0 0 0 20px">
                (3) 만 19세 미만의 이용자가 서비스 이용 신청을 하는 경우
            </p>
            <p style="padding: 0 0 0 20px">
                (4) 일정 기간 동안에 회원 가입, 탈퇴를 반복하는 등 정상적인
                서비스 이용을 위해 서비스 이용 신청을 한 것이 아니라고 판단되는
                경우
            </p>
            <p style="padding: 0 0 0 20px">
                (5) 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가
                있는 경우
            </p>
            <p style="padding: 0 0 0 20px">
                (6) 본 약관 및/또는 세부지침을 위반하여 서비스 이용제한 조치
                등을 받은 자가 이용제한기간 내 임의로 서비스 이용계약을 해지하고
                재가입을 신청하는 경우
            </p>
        </div>
        <h3 class="header">제5조 (개인정보의 보호)</h3>
        <div class="content">
            회사는 회원의 개인정보를 회원이 사전 동의한 목적과 범위 내에서만
            이용합니다. 보다 자세한 사항은 개인정보 처리방침을 참고해 주시기
            바랍니다.
        </div>
        <h3 class="header">제6조 (회사의 의무)</h3>
        <div class="content">
            1. 회사는 서비스를 1일 24시간 연중무휴 제공함을 원칙으로 합니다.
            다만 기술상의 이유 또는 정기점검 등 운영상의 이유로 서비스가 일시
            중지될 수 있습니다. 이러한 경우 사전 또는 사후에 공지합니다.
        </div>
        <div class="content">
            2. 회사는 회원의 건강 관리에 중요한 영향을 미치는 주요 건강정보를
            시의 적절하게 회원에게 알리기 위해 주요 건강정보에 대한 실시간 알림
            메시지를 카카오톡으로 발송합니다. 이러한 메시지는 회사의 서비스
            제공상 의무를 이행하기 위함으로 광고성 메시지에 해당하지 않으나
            메시지를 받지 않길 원하시는 경우 서비스 내 <카카오 알림톡 설정>에서
            변경하실 수 있습니다. 회사는 회원이 수신 동의를 하지 않은 영리
            목적의 광고성 전자우편, SMS 문자메시지 등을 발송하지 아니합니다.
        </div>
        <div class="content">
            3. 회사는 지속적이고 안정적인 서비스 제공에 적합하도록 서비스 제공
            및 보안과 관련된 설비 유지, 점검 또는 복구 등의 조치를 성실히
            이행합니다.
        </div>
        <h3 class="header">제7조 (회원의 의무)</h3>
        <div class="content">
            회원은 서비스를 이용함에 있어 다음 각 호에 해당하는 행위를 하여서는
            안 됩니다.
        </div>
        <div class="content">
            <p style="padding: 0 0 0 20px">
                (1) 서비스 이용 신청 및/또는 회원 정보 변경 시 허위 사실을
                기재하거나, 다른 사람의 개인정보 내지 회원정보(카카오계정,
                비밀번호 등)를 도용 또는 부정하게 사용하거나, 명의자의 허락 없이
                문자메시지(SMS) 인증 등을 수행하는 행위
            </p>
            <p style="padding: 0 0 0 20px">
                (2) 서비스 이용과정에서 개인정보주체 또는 회사가 허용한 범위를
                벗어나는 형태로 다른 회원의 개인정보를 수집, 저장, 공개하는 행위
            </p>
            <p style="padding: 0 0 0 20px">
                (3) 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙
                없이 복제 또는 유통시키거나 상업적으로 이용하는 행위
            </p>
            <p style="padding: 0 0 0 20px">
                (4) 회사에서 허용한 이용 방법과 다른 비정상적인 방법으로
                서비스를 이용하는 행위
            </p>
            <p style="padding: 0 0 0 20px">
                (5) 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을
                유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위
            </p>
            <p style="padding: 0 0 0 20px">
                (6) 서비스의 운영을 방해하거나 안정적 운영을 방해할 수 있는 행위
            </p>
            <p style="padding: 0 0 0 20px">
                (7) 회사의 동의 없이 서비스 또는 이에 포함된 소프트웨어의 일부를
                복사, 수정, 배포, 판매, 양도, 대여, 담보 제공하거나 타인에게 그
                이용을 허락하는 행위와 소프트웨어를 역설계하거나 소스 코드의
                추출을 시도하는 등 서비스를 복제, 분해 또는 모방하거나 기타
                변형하는 행위
            </p>
            <p style="padding: 0 0 0 20px">
                (8) 타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는
                행위
            </p>
            <p style="padding: 0 0 0 20px">
                (9) 다른 이용자의 개인정보를 수집, 저장, 공개하는 행위
            </p>
            <p style="padding: 0 0 0 20px">
                (10) 자기 또는 타인에게 재산상의 이익을 얻게 할 목적 또는
                타인에게 손해를 가하는 등 피해를 입힐 목적으로 허위의 정보를
                유통시키는 행위
            </p>
            <p style="padding: 0 0 0 20px">
                (11) 서비스 게시판 등에 음란물을 게재하거나 음란사이트를
                연결(링크)하는 행위 또는 서비스 게시판 등을 통해 공공질서 및
                미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게
                유포하는 행위
            </p>
            <p style="padding: 0 0 0 20px">
                (12) 수치심이나 혐오감 또는 공포심을 일으키는 말이나 음향,
                글이나 화상 또는 영상을 계속하여 상대방에게 도달하게 하여
                상대방의 일상적 생활을 방해하는 행위
            </p>
            <p style="padding: 0 0 0 20px">
                (13) 회사 또는 제 3자의 저작권 등 기타 권리를 침해하는
                행위(국내외 제3자의 저작권 등을 침해하는 행위로서 회사가 IP 접속
                차단 등 기술적인 조치를 통하여 타인에 대한 권리 침해 방지 조치를
                취하였음에도 불구하고, 이용자가 회사를 기망하는 수단과 방법 등을
                통하여 서비스에 접속하는 등 제3자의 저작권 등을 침해하는 행위를
                포함)
            </p>
            <p style="padding: 0 0 0 20px">
                (14) 서비스를 통하여 전송된 내용의 출처를 위장하는 행위
            </p>
            <p style="padding: 0 0 0 20px">
                (15) 영리 목적으로 서비스를 이용하는 행위(사전 동의 없이 서비스
                내 광고성 정보를 게시, 전송하는 등의 영리목적 무단이용)
            </p>
            <p style="padding: 0 0 0 20px">
                (16) 허위 또는 악의적 신고 남용 등 다른 이용자를 괴롭히는 행위
            </p>
            <p style="padding: 0 0 0 20px">
                (17) 서비스를 통하여 전송된 내용의 출처를 위장하는 행위
            </p>
            <p style="padding: 0 0 0 20px">
                (18) 서비스의 이용 권한, 기타 서비스 이용계약상의 지위를
                타인에게 양도·증여하거나 담보로 제공하는 행위
            </p>
            <p style="padding: 0 0 0 20px">
                (19) 서비스 이용과정에서 회사 또는 서비스와 관련된 제3자의
                업무를 방해하거나 타인(회사 포함)의 명예를 손상시키는 행위
            </p>
            <p style="padding: 0 0 0 20px">
                (20) 기타 현행 법령, 본 약관 및 운영정책 등 회사가 제공하는
                서비스 관련 세부지침을 위반하는 행위
            </p>
        </div>
        <h3 class="header">제8조 (서비스 이용 제한)</h3>
        <div class="content">
            1. 회사는 회원이 본 약관 및/또는 세부지침을 위반하는 경우 회원의
            위반 행위에 대해 조사할 수 있고, 회원의 서비스 이용의 일부 또는
            전부를 잠시 또는 상당기간 계속하여 제한할 수 있습니다.
        </div>
        <div class="content">
            2. 서비스 이용 제한은 위반 행위의 누적 정도에 따라 한시적 제한에서
            영구적 제한으로 단계적 제한하는 것을 원칙으로 하지만, 법령에서
            금지하는 명백한 불법행위나 타인의 권리침해로서 긴급한 위험 또는 피해
            차단이 요구되는 사안에 대해서는 위반 행위 횟수의 누적 정도와 상관
            없이 즉시 영구적으로 이용이 제한될 수 있습니다.
        </div>
        <div class="content">
            3. 회원은 회사의 서비스 이용 제한 조치와 관련하여 이의가 있는 경우
            고객센터를 통해 의견을 제기할 수 있습니다. 이 때 이의가 정당하다고
            인정되는 경우 회사는 즉시 해당 회원의 서비스 이용을 재개합니다.
        </div>
        <div class="content">
            4. 만 19세 미만의 회원은 서비스 가입 및 이용이 제한됩니다.
        </div>
        <h3 class="header">제9조 (서비스의 중단 및 변경)</h3>
        <div class="content">
            1. 회사는 아래 각 호의 경우 서비스의 전부 또는 일부의 제공을
            제한하거나 중지할 수 있습니다.
        </div>
        <div class="content">
            <p style="padding: 0 0 0 20px">
                (1) 서비스용 설비의 유지·보수 등을 위한 정기 또는 임시 점검 등
                운영상 필요가 있는 경우
            </p>
            <p style="padding: 0 0 0 20px">
                (2) 정전, 제반 설비의 장애, 통신망 장애 또는 이용량의 폭주
                등으로 정상적인 서비스 제공이 불가능한 경우
            </p>
            <p style="padding: 0 0 0 20px">
                (3) 관계사와의 계약 종료, 정부의 명령/규제, 서비스/회원 정책
                변경 등 회사의 제반 사정으로 서비스의 전부 또는 일부를 유지할 수
                없는 경우
            </p>
            <p style="padding: 0 0 0 20px">
                (4) 기타 천재지변, 국가비상사태 등 회사가 통제할 수 없는
                불가항력적 사유가 있는 경우
            </p>
        </div>
        <div class="content">
            2. 회사는 전항에 의한 서비스 중단의 경우 7일 이전에 회원에게 통지
            내지 공지합니다. 이 때 원만한 서비스 및 정책 변경 등을 위하여 서비스
            이용 시 재로그인 또는 추가적인 동의 절차 등이 필요할 수 있습니다.
            다만, 회사로서도 예측할 수 없거나 통제할 수 없는 사유(회사의 과실이
            없는 디스크 내지 서버 장애, 시스템 다운 등)로 인해 사전 통지 내지
            공지가 불가능한 경우에는 그러하지 아니합니다. 이러한 경우에도 회사는
            상황을 파악하는 즉시 최대한 빠른 시일 내에 서비스를 복구하도록
            노력하고, 2시간 이상 복구가 지연되는 경우 별도 통지로 회원에게
            안내합니다.
        </div>
        <h3 class="header">제10조 (회원에 대한 통지 및 공지)</h3>
        <div class="content">
            회원 전체에 대한 공지는 칠(7)일 이상 공지사항에 게시함으로써 효력이
            발생합니다. 회원에게 중대한 영향을 미치는 사항의 경우에는
            카카오계정에 등록된 이메일 주소로 이메일(이메일주소가 없는 경우
            서비스 내 전자쪽지 발송, 서비스 내 알림 메시지를 띄우는 등의 별도의
            전자적 수단) 발송 또는 카카오톡 메시지 또는 문자메시지 발송하는 방법
            등으로 개별 통지합니다.
        </div>
        <h3 class="header">제11조 (이용계약의 해지)</h3>
        <div class="content">
            <p>
                1. 회원이 서비스 이용계약을 해지하고자 하는 경우 ‘카카오헬스케어
                TOGATHER' 내 제공되는 ‘회원 탈퇴 안내’ 메뉴를 이용하여 탈퇴
                신청에 대한 정보를 확인할 수 있으며, 안내대로 탈퇴 신청 시
                회사는 법령이 정하는 바에 따라 신속히 처리합니다.
            </p>
            <p>
                2. 회원이 카카오계정에서 탈퇴하는 경우에는 본 서비스 이용계약도
                카카오계정 탈퇴시점에 자동으로 해지됩니다.
            </p>
            <p>
                3. 서비스 이용계약이 해지되는 경우 관련 법령 및 개인정보
                처리방침에 따라 회원의 일정 정보를 보유하는 경우를 제외하고는
                회원의 개인정보나 서비스 이용기록 등의 데이터는 모두 삭제됩니다.
            </p>
            <p>
                4. 회사는 회원이 본 약관에서 정한 금지행위를 하였을 경우 관련
                조항에서 정한 바에 따라 해당 회원에 대한 서비스 이용계약을
                해지할 수 있습니다. 회원은 회사의 서비스 이용계약 해지에 대하여
                고객센터를 통해 이의를 신청할 수 있습니다. 이 때 이의가
                정당하다고 인정되는 경우 회사는 즉시 해당 회원의 서비스 이용을
                재개합니다.
            </p>
        </div>
        <h3 class="header">제12조 (손해배상)</h3>
        <div class="content">
            1. 회사는 관련 법령상 허용되는 한도 내에서 서비스와 관련하여 본
            약관에 명시되지 않은 어떠한 구체적인 사항에 대한 약정이나 보증을
            하지 않습니다. 또한, 회사는 회사가 직접 작성 또는 제작하지 않은
            정보, 회원이 작성하는 등의 방법으로 서비스에 게재 내지 입력된 정보,
            자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며, 회사의
            고의 또는 과실 없이 발생된 회원의 손해에 대하여는 책임을 부담하지
            않습니다.
        </div>
        <div class="content">
            2. 회사는 회사의 고의 또는 과실로 인하여 회원이 손해를 입게 될 경우
            본 약관 및 관련 법령에 따라 회원의 손해를 배상합니다. 회사는 법률상
            허용되는 한도 내에서 간접 손해, 특별 손해, 결과적 손해, 징계적 손해
            및 징벌적 손해에 대한 책임을 부담하지 않습니다.
        </div>
        <h3 class="header">제13조 (분쟁해결))</h3>
        <div class="content">
            1. 회원은 아래 고객센터를 통하여 언제든지 서비스와 관련한 의견 및
            이의 신청 등을 할 수 있습니다. 서비스 이용과 관련하여 회사와 회원 간
            분쟁이 발생하면 회사는 분쟁 해결을 위해 회원과 성실히 협의합니다.
        </div>
        <div class="content">
            <p style="padding: 0 0 0 20px">
                - 고객센터 문의 : togather@kakaohealthcare.com
            </p>
        </div>
        <div class="content">
            2. 본 약관은 대한민국 법령에 따라 해석되고 이행됩니다. 전항에도
            불구하고 회원과 회사 간 분쟁이 해결되지 않는 경우 민사소송법상의
            주소지 관할법원에 소를 제기하기로 합니다.
        </div>
        <h3 class="header">[부칙]</h3>
        <div class="content">본 약관은 2024년 08월 01일부터 시행됩니다.</div>
    `,
  },
  {
    id: 2,

    title: '개인정보 수집 및 이용동의',
    isRequired: true,
    content: `
        <div class="content">
        (주)카카오헬스케어는 개인정보보호법에 의거하여 귀하(이용자)의
        개인정보를 수집 및 이용하고자 합니다.
    </div>
    <h3 class="header">1. 수집 및 이용 목적</h3>
    <div class="content">
        <ul class="list">
            <li>회원 식별, 가입의사 확인, 본인/연령 확인</li>
            <li>사용자 신체정보에 따른 개인화된 건강관리 서비스 제공</li>
            <li>서비스 개선, 신규 서비스 개발 및 제공</li>
            <li>문의사항 또는 불만처리, 공지사항 전달</li>
            <li>
                서비스의 원활한 운영에 지장을 주는 행위(계정 도용 및 부정
                이용 행위 등 포함)에 대한 방지 및 제재
            </li>
            <li>
                서비스 이용 기록, 접속 빈도 및 서비스 이용에 대한 통계,
                프라이버시 보호 측면의 서비스 환경 구축에 활용
            </li>
            <li>사업의 효과 평가 및 통계 분석</li>
            <li>만족도 조사와 서비스 안내</li>
        </ul>
    </div>
    <h3 class="header">2. 수집 항목</h3>
    <div class="content">
        <ul class="list">
            <li>
                카카오 계정으로부터의 정보
                <ul>
                    <li>이메일, 전화번호</li>
                </ul>
            </li>
            <li>
                서비스 내 등록 정보
                <ul>
                    <li>이름, 성별, 출생년도</li>
                </ul>
            </li>
            <li>
            상담/스피치 예약 정보
            <ul>
                <li>예약한 상담사/발표자, 예약 신청 일시</li>
            </ul>
        </li>
        </ul>
    </div>
    <h3 class="header">3. 보유 및 이용 기간</h3>
    <div class="content">
        <ul class="list">
            <li>서비스 탈퇴 시 까지</li>
        </ul>
    </div>
    <h3 class="header">4. 동의 거부 및 동의 거부 시 불이익에 대한 안내</h3>
    <div class="content">
        <ul class="list">
            <li>
                본 동의는 거부할 수 있으며, 거부 시 회원가입이 제한됩니다.
            </li>
        </ul>
    </div>
    <div class="content">
        기타 상세 내용은 개인정보 처리방침을 참고 바랍니다.
    </div> 
    `,
  },
  {
    id: 3,
    title: '민감정보 수집 및 이용 동의',
    isRequired: true,
    content: `
        <div class="content">
            (주)카카오헬스케어는 아래와 같이 귀하(이용자)의 민감정보를
            수집하고자 합니다.서비스 이용을 위한 목적으로만 민감정보를 수집하며,
            본래의 목적 범위를 초과하여 처리하지 않습니다.
        </div>
        <h3 class="header">1. 수집 및 이용 목적</h3>
        <div class="content">
            <ul class="list">
                <li>사용자 기록에 따른 개인화된 건강관리 서비스 제공</li>
                <li>문의사항 또는 불만처리, 공지사항 전달</li>
                <li>
                    상담/스피치 예약 및 예약 변경/취소, 확인 등 심리 상담 예약
                    서비스 제공
                </li>
                <li>사업의 효과평가 및 통계분석</li>
            </ul>
        </div>
        <h3 class="header">2. 수집 항목</h3>
        <div class="content">
            <ul class="list">
                <li>
                    <span> 신체 정보 </span>
                    <ul>
                        <li>키, 몸무게, 대사증후군</li>
                    </ul>
                </li>
                <li>
                    <span>운동 정보</span>
                    <ul>
                        <li>
                            <span>동작 검사 결과 정보</span>
                            <ul>
                                <li>
                                    검사 일시, 불편 부위, 기능 정도, 통증 정도,
                                    검사 동작, 검사 결과
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span>활동량 검사 결과 정보</span>
                            <ul>
                                <li>검사 일시, 검사 결과, 난이도 평가 결과</li>
                            </ul>
                        </li>
                        <li>
                            <span>운동 및 운동 결과 정보</span>
                            <ul>
                                <li>
                                    운동 수행 일시, 운동 프로그램 정보, 운동
                                    수행 결과
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span>스마트링 연동 정보</span>
                            <ul>
                                <li>
                                    기기 연동사용 여부, 운동 시 심박수(HR), 안정
                                    시 심박수(HR), IMU, 혈압(BP),
                                    SpO2(산소포화도)
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <span>상담 및 심리 정보</span>
                    <ul>
                        <li>
                            <span>심리 셀프 평가 결과 정보</span>
                            <ul>
                                <li>평가 일시, 평가 결과</li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <h3 class="header">3. 보유 및 이용 기간</h3>
        <div class="content">
            <ul class="list">
                서비스 탈퇴 시 까지
            </ul>
        </div>
        <h3 class="header">4. 동의 거부 및 동의 거부 시 불이익에 대한 안내</h3>
        <div class="content">
            <ul class="list">
                본 동의는 거부할 수 있으며, 거부 시 회원가입이 제한됩니다.
            </ul>
        </div>
`,
  },
  {
    id: 4,
    title: '개인정보 처리방침',
    isRequired: true,
    content: `
        <h1 style="font-size: 24px; text-align: left">개인정보 처리방침</h1>
        <div class="content" style="text-align: left">
            (시행일자 : 2024.08.01)
        </div>
        <div
            class="content"
            style="border: 1px solid black; padding: 10px 20px"
        >
            <h3 class="header">목차</h3>
            <p>1. 개인정보 수집 항목 별 목적과 보유기간</p>
            <p>2. 개인정보 처리 위탁</p>
            <p>3. 개인정보 제3자 제공</p>
            <p>4. 개인정보 파기 절차와 방법</p>
            <p>5. 이용자와 법정대리인의 권리·의무 및 그 행사방법</p>
            <p>6. 개인정보의 안전성 확보조치</p>
            <p>7. 개인정보의 자동 수집 장치의 설치/운영 및 거부에 관한 사항</p>
            <p>8. 개인정보 보호책임자 및 담당부서</p>
            <p>9. 변경 등 고지</p>
        </div>
        <div class="content">
            주식회사 카카오헬스케어(이하 “회사”)는 이용자의 ‘동의를 기반으로
            개인정보를 수집·이용 및 제공’ 하고 있으며, ‘이용자의 권리(개인정보
            자기결정권)를 적극적으로 보장’ 하며, 대한민국의 개인정보보호 관련
            법령 및 가이드라인을 준수하고 있습니다.<br />
            본 개인정보처리방침은 회사의 TOGATHER 서비스 이용을 하고자 하는
            정보주체(이하 “이용자”)에게만 적용되며, 회사의 다른 서비스의 경우
            해당 서비스의 개인정보처리방침에 따릅니다.
        </div>
        <h2 style="padding: 24px 0; font-size: 20px; text-align: left">
            [주요 개인정보 처리 표기]
        </h2>
        <div class="content">
            <table
                border="1px solid #E5E3EF"
                cellpadding="0"
                cellspacing="0"
                width="100%"
            >
                <tbody>
                    <tr>
                        <td
                            width="25%"
                            style="
                                text-align: center;
                                padding: 10px 0;
                                font-weight: bold;
                            "
                        >
                            개인정보
                        </td>
                        <td
                            width="25%"
                            style="
                                text-align: center;
                                padding: 10px 0;
                                font-weight: bold;
                            "
                        >
                            보유기간
                        </td>
                        <td
                            width="25%"
                            style="
                                text-align: center;
                                padding: 10px 0;
                                font-weight: bold;
                            "
                        >
                            민감정보
                        </td>
                        <td
                            width="25%"
                            style="
                                text-align: center;
                                padding: 10px 0;
                                font-weight: bold;
                            "
                        >
                            파기
                        </td>
                    </tr>
                    <tr>
                        <td width="25%" style="padding: 10px">
                            회사는 목적에 따른 최소한의 개인정보를 수집하여
                            처리하며 다음 목적 이외의 용도로는 이용하지 않습니다
                        </td>
                        <td width="25%" style="padding: 10px">
                            기본적인 개인정보는 탈퇴시까지 보관됩니다.
                        </td>
                        <td width="25%" style="padding: 10px">
                            회사는 목적에 따른 최소한의 개인정보를 수집하여
                            처리하며 다음 목적 이외의 용도로는 이용하지
                            않습니다.
                        </td>
                        <td width="25%" style="padding: 10px">
                            회사는 이용자의 개인정보에 대해 “개인정보의
                            처리목적이 달성” 된 후에는 지체없이 파기함을
                            약속합니다.
                        </td>
                    </tr>
                    <tr>
                        <td
                            width="25%"
                            style="
                                text-align: center;
                                padding: 10px 0;
                                font-weight: bold;
                            "
                        >
                            처리목적
                        </td>
                        <td
                            width="25%"
                            style="
                                text-align: center;
                                padding: 10px 0;
                                font-weight: bold;
                            "
                        >
                            정보주체의 권리의무
                        </td>
                        <td
                            width="25%"
                            style="
                                text-align: center;
                                padding: 10px 0;
                                font-weight: bold;
                            "
                        >
                            제3자 제공
                        </td>
                        <td
                            width="25%"
                            style="
                                text-align: center;
                                padding: 10px 0;
                                font-weight: bold;
                            "
                        >
                            고충처리 부서
                        </td>
                    </tr>
                    <tr>
                        <td width="25%" style="padding: 10px">
                            회원가입 및 서비스 상, 상담/스피치 예약 등의 서비스
                            서비스 이용을 위해 필요한 목적으로만 이용합니다.
                            개인정보 수집 시 반드시 이용자에게 처리 목적을
                            고지하여 동의를 받고 있습니다.
                        </td>
                        <td width="25%" style="padding: 10px">
                            회사는 개인정보 처리에 대한 정보주체의 권리 의무를
                            보장합니다. 이용자는 언제든지 제출한 자신의
                            개인정보를 조회, 수정, 삭제 및 처리 정지를 요구할
                            수도 있습니다.
                        </td>
                        <td width="25%" style="padding: 10px">
                            회사는 원칙적으로 사전에 이용자로부터의 동의 없이는
                            이용자의 개인정보를 제3자에게 제공하지 않습니다.
                        </td>
                        <td width="25%" style="padding: 10px">
                            개인정보보호 관련 문의, 불만, 조언이나 기타 사항은
                            개인정보보호책임자 및 담당부서로 연락해 주시기
                            바랍니다.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div
            class="content"
            style="
                padding-bottom: 12px;
                color: #5b5b5b;
                border-bottom: 1px solid black;
                margin-bottom: 12px;
            "
        >
            ※ 자세한 내용은 아래 개인정보 처리방침 상세 내용을 확인해주세요.
        </div>
        <h3 class="header">1. 개인정보 수집 항목 별 목적과 보유기간</h3>
        <div class="content">
            회사는 이용자의 개인정보를 목적 달성을 위한 기간 동안에만 제한적으로
            처리하고 있으며, 처리 목적이 달성되면 해당 이용자의 개인정보는 지체
            없이 파기됩니다. 다만 다음의 경우는 별도로 저장,파기하고 있습니다.
            (단, 단순 실수 번복, 재가입, 피해복구 신청 등을 위해 탈퇴 후 30일 간
            보관 후 삭제 및 파기 처리합니다.)회사는 목적에 따른 최소한의
            개인정보를 수집하여 처리하고 있습니다.
        </div>
        <div class="content">
            <table
                border="1px solid #E5E3EF"
                cellpadding="0"
                cellspacing="0"
                width="100%"
            >
                <thead>
                    <tr>
                        <th width="12%" style="padding: 10px 0">구분</th>
                        <th width="10%" style="padding: 10px 0">필수/선택</th>
                        <th width="30%" style="padding: 10px 0">수집 항목</th>
                        <th width="34%" style="padding: 10px 0">처리 목적</th>
                        <th width="14%" style="padding: 10px 0;">
                            보유/이용 기간
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="padding: 10px; vertical-align: middle; text-align:center;">
                            회원가입 및 서비스 상
                        </td>
                        <td style="padding: 10px; vertical-align: middle; text-align:center;">
                            필수
                        </td>
                        <td style="padding: 10px; vertical-align: middle">
                            <ul class="list">
                                <li>카카오 계정(이메일, 휴대전화번호)</li>
                                <li>이름</li>
                                <li>성별</li>
                                <li>내외국인 여부</li>
                                <li>출생년도</li>
                            </ul>
                        </td>
                        <td style="padding: 10px; vertical-align: middle">
                            <ul class="list">
                                <li>
                                    회원 식별, 가입의사 확인, 본인 및 연령 확인
                                </li>
                                <li>문의사항 또는 불만처리, 공지사항 전달</li>
                                <li>
                                    서비스의 원활한 운영에 지장을 주는 행위(계정
                                    도용 및 부정 이용 행위 등 포함)에 대한 방지
                                    및 제재
                                </li>
                                <li>
                                    서비스 이용 기록, 접속 빈도 및 서비스 이용에
                                    대한 통계, 프라이버시 보호 측면의 서비스
                                    환경 구축, 서비스 개선에 활용
                                </li>
                            </ul>
                        </td>
                        <td style="padding: 10px; vertical-align: middle">
                            회원탈퇴 시까지
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 10px; vertical-align: middle; text-align:center;">
                            신체 및 운동정보
                        </td>
                        <td style="padding: 10px; vertical-align: middle; text-align:center;">
                            선택
                        </td>
                        <td style="padding: 10px; vertical-align: middle">
                            <ul class="list">
                                <li>신체정보 (키, 몸무게, 대사증후군)</li>
                                <li>
                                    동작검사 결과정보 (검사 일시, 불편 부위,
                                    기능 정도, 통증 정도, 검사 동작, 검사 결과)
                                </li>
                                <li>
                                    활동량 검사 결과정보(검사 일시, 검사 결과,
                                    난이도 평가 결과)
                                </li>
                                <li>
                                    운동 및 운동 결과 정보(운동 수행 일시, 운동
                                    프로그램 정보, 운동 수행 결과)
                                </li>
                                <li>
                                    스마트링 연동 정보(기기 연동사용 여부, 운동
                                    시 심박수(HR), 안정 시 심박수(HR), IMU,
                                    혈압(BP), SpO2(산소포화도))
                                </li>
                            </ul>
                        </td>
                        <td style="padding: 10px; vertical-align: middle">
                            <ul class="list">
                                <li>
                                    사용자 기록에 따른 개인화된 건강관리 서비스
                                    제공
                                </li>
                                <li>신규 서비스 개발, 다양한 서비스 제공</li>
                                <li>
                                    서비스 이용 기록, 접속 빈도 및 서비스 이용에
                                    대한 통계, 프라이버시 보호 측면의 서비스
                                    환경 구축, 서비스 개선에 활용
                                </li>
                                <li>사업의 효과평가 및 통계분석</li>
                            </ul>
                        </td>
                        <td style="padding: 10px; vertical-align: middle">
                            회원탈퇴 시까지
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 10px; vertical-align: middle; text-align:center;">
                            상담 및 심리 정보
                        </td>
                        <td style="padding: 10px; vertical-align: middle; text-align:center;">
                            선택
                        </td>
                        <td style="padding: 10px; vertical-align: middle">
                            <ul class="list">
                                <li>
                                    심리 셀프 평가 결과 정보 (평가 일시, 평가
                                    결과)
                                </li>
                                <li>
                                    상담 / 스피치 예약 정보 (예약한
                                    상담사/발표자, 예약 신청 일시)
                                </li>
                                <li>
                                    상담 스피치 진행 정보 (상담사 요청사항,
                                    상담사 메모, 상담사의 6가지 감정기록,
                                    내담자/참여자 표정 데이터, 참여 여부)
                                </li>
                            </ul>
                        </td>
                        <td style="padding: 10px; vertical-align: middle">
                            <ul class="list">
                                <li>
                                    사용자 기록에 따른 개인화된 건강관리 서비스
                                    제공
                                </li>
                                <li>신규 서비스 개발, 다양한 서비스 제공</li>
                                <li>
                                    상담/스피치 예약 및 예약 변경/취소, 확인 등
                                    심리 상담 예약 서비스 제공
                                </li>
                                <li>사업의 효과평가 및 통계분석</li>
                                <li>예약 및 상담/ 스피치 이력 확인</li>
                            </ul>
                        </td>
                        <td style="padding: 10px; vertical-align: middle">
                            회원탈퇴 시까지
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="content">
            <p>
                또한 서비스 이용 과정에서 아래와 같은 정보들이 자동으로 생성되어
                수집될 수 있습니다.
            </p>
            <ul class="list">
                <li>
                    PC웹, 모바일 웹 이용 과정에서 단말기정보(OS, 화면사이즈,
                    디바이스 아이디, 폰기종, 단말기 모델명), IP주소, 쿠키,
                    방문일시, 부정이용기록, 서비스 이용 기록 등의 정보
                </li>
            </ul>
        </div>
        <div class="content">
            <p>
                회사가 이용자의 개인정보를 수집하는 경우, 원칙적으로 사전에
                이용자에게 해당 사실을 알리고 동의를 구하고 있으며, 다음과 같은
                방법으로 이용자의 개인정보를 수집합니다.
            </p>
            <ul class="list">
                <li>
                    회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에
                    대해 동의를 하고 직접 정보를 입력하는 경우
                </li>
                <li>
                    제휴 서비스 또는 단체 등으로부터 개인정보를 제공받은 경우
                </li>
                <li>
                    서비스 문의 또는 상담 과정에서 웹페이지, 메일, 팩스, 전화,
                    출력된 형태의 종이문서 등으로 제출하는 경우
                </li>
            </ul>
        </div>
        <div class="content">
            회사가 수집한 개인정보는 통계작성, 과학적 연구, 공익적 기록보존 등을
            위한 가명정보 처리 목적 내에서 가명처리 할 수 있습니다. 현재는
            가명정보 처리 사항이 없습니다. 가명정보 처리 현황에 대한 변동사항이
            있는 경우 지속적으로 안내드리겠습니다.
        </div>
        <h3 class="header">2. 개인정보 처리 위탁</h3>
        <div class="content">
            회사는 이용자에게 다양한 서비스를 제공하는 데에 반드시 필요한 업무
            중 일부를 외부 업체로 하여금 수행하도록 개인정보를 위탁하고
            있습니다. 그리고 위탁 받은 수탁업체가 개인정보보호 관계 법령을
            위반하지 않도록 관리·감독하고 있습니다.
        </div>
        <div class="content">
            <table
                border="1px solid #E5E3EF"
                cellpadding="0"
                cellspacing="0"
                width="100%"
            >
                <thead>
                    <tr>
                        <th
                            width="30%"
                            style="
                                padding: 10px 0;
                                text-align: center;
                                font-weight: bold;
                            "
                        >
                            수탁업체
                        </th>
                        <th
                            width="70%"
                            style="
                                padding: 10px 0;
                                text-align: center;
                                font-weight: bold;
                            "
                        >
                            위탁업무 내용
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="padding: 4px 0; text-align: center">
                            <a
                                href="https://policies.google.com/privacy?hl=ko"
                                target="_blank"
                            >
                                Google LLC
                            </a>
                        </td>
                        <td style="padding: 4px">
                            <ul class="list">
                                <li>
                                    IT Cloud 시스템(GCP) 제공•지원 (국내 Region)
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 4px 0; text-align: center">
                            <a
                                href="https://www.kakaocorp.com/policy/privacyPolicy/corporateSitePrivacyPolicy"
                                target="_blank"
                            >
                                주식회사 카카오
                            </a>
                        </td>
                        <td style="padding: 4px">
                            <ul class="list">
                                <li>
                                    카카오 채널 등록, 건강 및 서비스 이용 정보
                                    메세지 발송 등의 업무
                                </li>
                            </ul>
                        </td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
        <div class="content">
            현재는 국외 위탁에 따른 국외이전 사항이 없습니다. 국외 위탁에 따른
            국외이전 현황에 대한 변동사항이 있는 경우 지속적으로
            안내드리겠습니다.
        </div>
        <h3 class="header">3. 개인정보 제3자 제공</h3>
        <div class="content">
            회사는 원칙적으로 법령에 따른 동의 없이는 이용자의 개인정보를
            제3자에게 제공하지 않으며 다음의 경우는 예외로 하고 있습니다.
        </div>
        <div class="content">
            <p>① 사전에 이용자로부터 법령에 따른 동의를 받은 경우</p>
            <p>② 다른 법령의 규정에 의한 경우</p>
        </div>
        <div class="content">
            현재는 국내 제3자 제공 또는 국외 제3자 제공에 따른 국외이전 사항이
            없습니다. 제3자 제공 현황에 대한 변동사항이 있는 경우 법령에 따라
            필요 시 사전 동의 수행 및 고지를 통해 지속적으로 안내드리겠습니다.
        </div>
        <h3 class="header">4. 개인정보 파기 절차와 방법</h3>
        <div class="content">
            회사는 이용자의 개인정보에 대해 “개인정보의 처리목적이 달성”된
            후에는 해당 정보를 지체 없이 파기함을 약속합니다.
        </div>
        <div class="content">
            다만 회사의 내부 방침 및 기타 관계법령에 따른 정보는 목적이 달성된
            후 기존의 저장공간과 구분된 별도의 저장공간에 옮겨서일정기간 동안
            저장한 후 파기됩니다. 이때 별도 저장되는 개인정보는 법령에 의한
            경우가 아니고서는 절대 다른 용도로 이용되지 않습니다.
        </div>
        <div class="content">
            <p>① 회사 내부 방침에 의한 정보 보유</p>
            <table
                border="1px solid #E5E3EF"
                cellpadding="0"
                cellspacing="0"
                width="100%"
            >
                <thead>
                    <tr>
                        <th width="30%" style="padding: 10px 0">보유 근거</th>
                        <th width="50%" style="padding: 10px 0">보유 항목</th>
                        <th width="20%" style="padding: 10px 0">보유 기간</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="padding: 10px">
                            회원의 자격정지 및 이용제재 서비스 부정 이용 재발
                            방지 목적
                        </td>
                        <td style="padding: 10px">
                            내부식별정보, 서비스 부정이용기록 및 제재 사유, 일자
                        </td>
                        <td style="padding: 10px">탈퇴일로부터 3년</td>
                    </tr>
                    <tr>
                        <td style="padding: 10px">
                            안내메일 발송 및 CS문의 대응
                        </td>
                        <td style="padding: 10px">
                            카카오계정과 탈퇴안내 이메일 주소를 암호화하여 보관
                        </td>
                        <td style="padding: 10px">탈퇴일로부터 1년</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="content">
            <p>② 관계법령에 의한 정보 보유</p>
            <table
                border="1px solid #E5E3EF"
                cellpadding="0"
                cellspacing="0"
                width="100%"
            >
                <thead>
                    <tr>
                        <th width="38%" style="padding: 10px 0">근거 법령</th>
                        <th width="38%" style="padding: 10px 0">보유 항목</th>
                        <th width="24%" style="padding: 10px 0">보유 기간</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="padding: 10px">
                            전자상거래 등에서의 소비자보호에 관한 법률
                        </td>
                        <td style="padding: 10px">
                            계약 또는 청약철회 등에 관한 기록 (회원 식별정보,
                            계약•청약철회 기록 등)
                        </td>
                        <td style="padding: 10px">5년</td>
                    </tr>
                    <tr>
                        <td style="padding: 10px">
                            전자상거래 등에서의 소비자보호에 관한 법률
                        </td>
                        <td style="padding: 10px">
                            소비자의 불만 및 분쟁처리에 관한 기록
                        </td>
                        <td style="padding: 10px">3년</td>
                    </tr>
                    <tr>
                        <td style="padding: 10px">통신비밀보호법</td>
                        <td style="padding: 10px">
                            서비스 방문 기록(로그기록, IP 등)
                        </td>
                        <td style="padding: 10px">3개월</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="content">
            그리고 기간이 종료되면 재생할 수 없는 기술적인 방법을 이용하여
            완전하게 삭제하게 되고, 종이 문서에 기록·저장된 개인정보는
            분쇄하거나 소각하여 파기하게 됩니다.
        </div>
        <h3 class="header">5. 이용자의 권리·의무 및 행사방법</h3>
        <div class="content">
            이용자는 언제든지 제출한 자신의 개인정보에 대한 열람(조회), 정정,
            처리정지, 삭제요구 및 동의철회(탈퇴)를 요구할 수도 있습니다. 서비스
            내 설정을 통해 회원정보 수정이나 회원탈퇴를 할 수 있고, 고객센터를
            통해 서면, 전화 또는 이메일로 요청하시면 지체 없이 조치하고
            있습니다. 개인정보의 오류에 대한 정정을 요청한 경우 정정을 완료하기
            전까지 해당 개인정보를 이용 또는 제공하지 않습니다.
        </div>
        <h3 class="header">6. 개인정보의 안전성 확보조치</h3>
        <div class="content">
            회사는 이용자의 개인정보를 가장 소중한 가치로 여기고 개인정보를
            처리함에 있어서 다음과 같은 노력을 다하고 있습니다.
        </div>
        <div class="content">
            ① 이용자의 개인정보를 암호화하고 있습니다.<br />
            회사는 이용자의 개인정보를 암호화된 통신구간을 이용하여 전송하고,
            비밀번호 등 중요정보는 암호화하여 보관하고 있습니다.
        </div>
        <div class="content">
            ② 해킹이나 컴퓨터 바이러스로부터 보호하기 위하여 노력하고
            있습니다.<br />
            회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가
            유출되거나 훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에
            시스템을 설치하고 있습니다. 해커 등의 침입을 탐지하고 차단할 수 있는
            시스템을 설치하여 24시간 감시하고 있으며, 백신 프로그램을 설치하여
            시스템이 최신 악성코드나 바이러스에 감염되지 않도록 노력하고
            있습니다. 또한 새로운 해킹/보안 기술에 대해 지속적으로 연구하여
            서비스에 적용하고 있습니다.
        </div>
        <div class="content">
            ③ 소중한 개인정보에 접근할 수 있는 사람을 최소화하고 있습니다.<br />
            회사는 개인정보를 처리하는 임직원을 최소한으로 제한하고 있습니다.
            또한 개인정보를 보관하는 데이터베이스 시스템과 개인정보를 처리하는
            시스템에 대한 비밀번호의 생성과 변경, 그리고 접근할 수 있는 권한에
            대한 체계적인 기준을 마련하고 지속적인 감사를 실시하고 있습니다.
        </div>
        <div class="content">
            ④ 임직원에게 이용자의 개인정보 보호에 대해 정기적인 교육을 실시하고
            있습니다.<br />
            회사는 개인정보를 처리하는 모든 임직원들을 대상으로 개인정보보호
            의무와 보안에 대한 정기적인 교육과 캠페인을 실시하고 있습니다.
        </div>
        <h3 class="header">
            7. 개인정보의 자동 수집 장치의 설치/운영 및 거부에 관한 사항
        </h3>
        <div class="content">
            쿠키를 설치, 운영하고 있고 이용자는 이를 거부할 수 있습니다.<br />
            쿠키는 이용자에게 보다 빠르고 편리한 웹사이트 사용을 지원하고 맞춤형
            서비스를 제공하기 위해 사용됩니다.
        </div>
        <div class="content">
            ① 쿠키란 무엇일까요?<br />
            쿠키란 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에
            보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터에 저장됩니다.
        </div>
        <div class="content">
            ② 쿠키를 왜 사용하나요?<br />
            쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 보다
            빠른 웹 환경을 지원하며, 편리한 이용을 위해 서비스 개선에
            활용합니다. 이를 통해 이용자는 보다 손쉽게 서비스를 이용할 수 있게
            됩니다.
        </div>
        <div class="content">
            ③ 쿠키를 수집하지 못하게 거부하고 싶다면?<br />
            이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는
            웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가
            저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수도
            있습니다. 다만 쿠키 설치를 거부할 경우 웹 사용이 불편해지며 로그인이
            필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.
        </div>
        <div class="content">
            <ul class="list" style="padding: 0 40px">
                <li>
                    설정 방법의 예
                    <ul>
                        <li>
                            Internet Explorer의 경우 : 웹 브라우저 상단의 도구
                            메뉴 > 인터넷 옵션 > 개인정보 > 설정
                        </li>
                        <li>
                            Chrome의 경우 : 웹 브라우저 우측의 설정 메뉴 > 화면
                            하단의 고급 설정 표시 > 개인정보의 콘텐츠 설정 버튼
                            > 쿠키
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <h3 class="header">8. 개인정보 보호책임자 및 담당부서</h3>
        <div class="content">
            개인정보보호 관련 문의, 불만, 조언이나 기타 사항은
            개인정보보호책임자 및 담당부서로 연락해 주시기 바랍니다. 회사는
            이용자의 목소리에 귀 기울이고 신속하고 충분한 답변을 드릴 수 있도록
            최선을 다하겠습니다.
        </div>
        <div class="content">
            <table
                border="1px solid #E5E3EF"
                cellpadding="0"
                cellspacing="0"
                width="100%"
            >
                <thead>
                    <tr>
                        <th width="50%" style="padding: 10px 0">
                            개인정보 보호책임자
                        </th>
                        <th width="50%" style="padding: 10px 0">
                            개인정보보호 담당부서
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="padding: 10px">
                            <ul class="list">
                                <li>책임자 : 신수용</li>
                                <li>연락처 : privacy@kakaohealthcare.com</li>
                            </ul>
                        </td>
                        <td style="padding: 10px">
                            <ul class="list">
                                <li>담당부서 : 정보보안팀</li>
                                <li>연락처 : security@kakaohealthcare.com</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="content">
            또한 개인정보가 침해되어 이에 대한 신고나 상담이 필요하신 경우에는
            아래 기관에 문의하셔서 도움을 받으실 수 있습니다.
        </div>
        <div class="content">
        <table
        border="1px solid #E5E3EF"
        cellpadding="0"
        cellspacing="0"
        width="100%"
    >
        <thead>
            <tr>
                <th width="33.333%" style="padding: 10px 0">
                    개인정보침해 신고센터
                </th>
                <th width="33.333%" style="padding: 10px 0">
                    대검찰청 사이버수사과
                </th>
                <th width="33.333%" style="padding: 10px 0">
                경찰청 사이버수사국
            </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td style="padding: 10px">
                    <ul class="list">
                        <li>(국번없이)118</li>
                        <li>http://privacy.kisa.or.kr</li>
                    </ul>
                </td>
                <td style="padding: 10px">
                    <ul class="list">
                        <li>(국번없이)1301
                        </li>
                        <li>cid@spo.go.kr</li>
                    </ul>
                </td>
                <td style="padding: 10px">
                <ul class="list">
                    <li>(국번없이)182
                    </li>
                    <li>http://ecrm.cyber.go.kr</li>
                </ul>
            </td>
            </tr>
        </tbody>
    </table>
        </div>
        <h3 class='header'>
        9. 변경 등 고지
        <h3>
        <div class='content'>
        회사는 관련 법령이나 서비스의 변경사항을 반영하기 위해 개인정보 처리방침을 수정할 수 있으며, 수정되는 경우 최소 7일 전 변경 사항을 공지사항 등을 통해 이용자에게 설명 드리겠습니다. 다만 수집하는 개인정보의 항목, 이용 목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 사전 고지하도록 하겠습니다.                 
        </div>
        <div class='content'>
        <p>공고일자 : 2024. 08. 01</p>
        <p>시행일자 : 2024. 08. 01</p>
</div>
`,
  },
];
export default POLICY_DATA;
