import { useState } from 'react';
import styles from './ActivityLevelCheck.module.scss';
import { useMutation, useQuery } from 'react-query';
import { axiosAuthInstance } from '../../../api/axios';
import { IActivityLevelQuestionType } from '../../../type/activityLevelQuestion';
import Loading from '../../Loading/Loading';
import { useLocation, useNavigate } from 'react-router-dom';
import useUserData from '../../../hooks/useUserData';

interface IPropsType {
  nextFn: () => void;
}

function ActivityLevelCheck({ nextFn }: IPropsType) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const userData = useUserData();

  const [answerData, setAnswerData] = useState<
    {
      surveyCode: number;
      questionCode: number;
      answerCode: number;
    }[]
  >(JSON.parse(sessionStorage.getItem('activity-level-answer') || '[]'));

  function handleAnswer(surveyCode: number, questionCode: number, answerCode: number) {
    const DATA = [...answerData];
    const SAME_QUESTION_INDEX = DATA.findIndex(
      //같은 질문의 답변이 있는지 확인
      (answer) => answer.surveyCode === surveyCode && answer.questionCode === questionCode
    );
    const ANSWER_INDEX = DATA.findIndex(
      //질문, 답변이 정확히 일치하는 값이 있는지 확인
      (answer) =>
        answer.surveyCode === surveyCode && answer.questionCode === questionCode && answer.answerCode === answerCode
    );
    if (SAME_QUESTION_INDEX !== -1) {
      DATA.splice(SAME_QUESTION_INDEX, 1);
      if (SAME_QUESTION_INDEX !== ANSWER_INDEX) {
        DATA.push({
          surveyCode,
          questionCode,
          answerCode,
        });
      }
    } else {
      DATA.push({
        surveyCode,
        questionCode,
        answerCode,
      });
    }
    setAnswerData(DATA);
  }

  const { data: questions, isLoading: questionLoading } = useQuery<IActivityLevelQuestionType>(
    ['activityLevel', 'questions'],
    () => axiosAuthInstance.get('/api/fitness/survey/activity-level/questions').then((res) => res.data)
  );
  const { mutateAsync, isLoading: answerLoading } = useMutation(() =>
    axiosAuthInstance.post('/api/fitness/survey/activity-level/answers', {
      userId: userData.data?.userId,
      answerList: answerData,
      examTypeCode: state?.examType,
    })
  );
  async function handleNext() {
    if (questions?.questionList.length !== answerData.length) return;
    sessionStorage.setItem('activity-level-answer', JSON.stringify(answerData));
    await mutateAsync();
    nextFn();
  }
  return (
    <>
      <section className={styles.container}>
        <p className={styles.title}>최근 일주일 동안 며칠 운동했는지 아래 질문에 답해주세요.</p>
        <ul className={styles.questionList}>
          {questions?.questionList.map((question) => (
            <li key={question.questionCode} className={styles.question}>
              <h5>{question.mainQuestion}</h5>
              <span>{question.subQuestion}</span>
              <ul className={styles.answerList}>
                {new Array(...question.answerList)
                  .sort((a, b) => a.answerMarkingSequence - b.answerMarkingSequence)
                  .map((answer, idx) => (
                    <li
                      key={answer.answerCode}
                      className={
                        answerData.findIndex(
                          (val) =>
                            val.answerCode === answer.answerCode &&
                            val.surveyCode === question.surveyCode &&
                            val.questionCode === question.questionCode
                        ) !== -1
                          ? styles.hit
                          : ''
                      }
                      onClick={() => handleAnswer(question.surveyCode, question.questionCode, answer.answerCode)}
                    >
                      <button>{idx === 0 ? '없음' : `${answer.answerContent}일`}</button>
                    </li>
                  ))}
              </ul>
            </li>
          ))}
        </ul>
      </section>
      <div className={styles.submitBtnWrap}>
        <button
          className={styles.prev}
          onClick={() => navigate('/physical-check/physical-info/metabolism?index=1', { state })}
        >
          이전
        </button>
        <button
          className={`${styles.next} ${questions?.questionList.length === answerData.length ? styles.hit : ''}`}
          onClick={handleNext}
        >
          다음
        </button>
      </div>
      {(questionLoading || answerLoading) && <Loading />}
    </>
  );
}
export default ActivityLevelCheck;
