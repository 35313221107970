import styles from './HorizontalModal.module.scss';
import CustomButton from '../CustomButton/CustomButton';
import { ReactComponent as Horizontal } from '../../assets/common/background/pic_horizontal.svg';

interface IPropsType {
    onSubmit: () => void;
}
function HorizontalModal({ onSubmit }: IPropsType) {
    return (
        <div className={styles.dimed}>
            <article className={styles.modal}>
                <div className={styles.imageWrap}>
                    <Horizontal />
                </div>
                <p>다음 화면부터 가로로 진행돼요.</p>
                <div className={styles.modalSubmitBtn} onClick={onSubmit}>
                    <CustomButton>확인하기</CustomButton>
                </div>
            </article>
        </div>
    );
}
export default HorizontalModal;
