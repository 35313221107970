import UNCOMFORTABLE_PART_CODE from '../../constant/uncomfortablePartCode';
import MskResultType from '../../type/MskResultType';
import LabelResult from '../LabelResult/LabelResult';
import styles from './MskCheckResultContent.module.scss';
import { ReactComponent as Notice } from '../../assets/common/icons/ic_notice.svg';
import StampBackground from '../../components/StampBackground/StampBackground';
import Stamp from '../../components/Stamp/Stamp';
import LineChart, { lineChartDataType } from '../../components/LineChart/LineChart';
import getStampSentence from '../../common/getStampSentence';
import dateFormatter from '../../common/dateFormatter';
import getLineChartXaxis from '../../common/getLineChartXaxis';
import { useEffect, useState } from 'react';
import useRecentWeek from '../../hooks/useRecentWeek';
import ScoreGuideModal from '../ScoreGuideModal/ScoreGuideModal';
import TotalScoreGuideModal from '../TotalScoreGuideModal/TotalScoreGuideModal';

interface IPropsType {
  data: MskResultType;
}
function MskCheckResultContent({ data }: IPropsType) {
  const [maxIndex, setMaxIndex] = useState<number>();
  const [minIndex, setMinIndex] = useState<number>();
  useEffect(() => {
    if (!data) return;
    const values = {
      max: 0,
      min: data.examList[0].movementScore,
      maxIndex: 0,
      minIndex: 0,
    };
    for (let i = 0; i < data.examList.length; i++) {
      if (values.max < data.examList[i].movementScore) {
        values.max = data.examList[i].movementScore;
        values.maxIndex = i;
      } else if (values.min > data.examList[i].movementScore) {
        values.min = data.examList[i].movementScore;
        values.minIndex = i;
      }
    }
    setMaxIndex(values.maxIndex);
    setMinIndex(values.minIndex);
  }, [data]);

  const week = useRecentWeek();

  function getChartPadding(length: number) {
    const mobilePadding =
      length === 2
        ? {
            left: 43,
            right: 41,
          }
        : length === 3
          ? {
              left: 42,
              right: 40,
            }
          : {
              left: 38,
              right: 40,
            };
    const padding =
      length === 2 || length === 3
        ? {
            left: 84,
            right: 74,
          }
        : {
            left: 78,
            right: 76,
          };
    return { mobilePadding, padding };
  }

  const [scoreGuideModalOpen, setScoreGuideModalOpen] = useState(false);
  const [totalScoreGuideModalOpen, setTotalScoreGuideModalOpen] = useState(false);
  return (
    <>
      {(data.examTypeCode === 'I' || data.examTypeCode === 'R') && (
        <>
          <section className={styles.movementScoresWrap}>
            {/* <h4>{data.currentExamDateTime.slice(0, 10).replace(/-/g, '. ')}</h4> */}
            <ul className={styles.discomfortAreaList}>
              <li>
                <span>불편 부위</span>
                <span>{UNCOMFORTABLE_PART_CODE[data.uncomfortablePartCode].ko}</span>
              </li>
            </ul>
            <ul className={styles.movementTestList}>
              {data?.examList.map((exam, idx) => (
                <li className={styles.movementTest} key={exam.gestureCode}>
                  <div className={styles.top}>
                    <div className={styles.testHeader}>
                      <span>진행한 검사 동작</span>
                      {idx === maxIndex && <div className={styles.bestScore}>최고 점수</div>}
                      {idx === minIndex && <div className={styles.worstScore}>최저 점수</div>}
                    </div>
                    <h3 className={styles.testTitle}>{exam.gestureName}</h3>
                  </div>
                  <ul className={styles.scoreWrap}>
                    <li>
                      <span>기능</span>
                      <span>{exam.functionScore}점</span>
                    </li>
                    <li>
                      <span>불편</span>
                      <span>{exam.painScore}점</span>
                    </li>
                    <li>
                      <span>종합</span>
                      <span>{exam.movementScore}점</span>
                    </li>
                  </ul>
                </li>
              ))}
            </ul>
          </section>

          <section className={styles.targetMovementWrap}>
            <h4 className={styles.sectionHeader}>목표 동작</h4>
            <div className={styles.targetMovementContentsWrap}>
              <div className={styles.left}>
                <h5 className={styles.movementName}>{data?.examList[data?.targetGestureIndex - 1]?.gestureName}</h5>
                <div className={styles.movementImageWrap}>
                  <img src={data?.examList[data?.targetGestureIndex - 1].goalImage} alt='예시 동작 이미지' />
                </div>
                <div className={styles.subScoresWrap}>
                  <div className={styles.scoreWrap}>
                    <div className={styles.scoreInfo}>
                      <h6>기능점수</h6>
                      <button onClick={() => setScoreGuideModalOpen(true)}>
                        <Notice />
                      </button>
                    </div>
                    <div className={styles.score}>
                      <span>{data?.examList[data?.targetGestureIndex - 1].functionScore}</span>
                      <span>점</span>
                    </div>
                    <div className={styles.resultWrap}>
                      <LabelResult
                        type={(() => {
                          const SCORE = data?.examList[data?.targetGestureIndex - 1].functionScore;
                          const VALUE: 0 | 1 | 2 = SCORE <= 3 ? 0 : SCORE <= 6 ? 1 : 2;
                          return VALUE;
                        })()}
                      />
                    </div>
                  </div>
                  <div className={styles.scoreWrap}>
                    <div className={styles.scoreInfo}>
                      <h6>불편점수</h6>
                      <button onClick={() => setScoreGuideModalOpen(true)}>
                        <Notice />
                      </button>
                    </div>
                    <div className={styles.score}>
                      <span>{data?.examList[data?.targetGestureIndex - 1].painScore}</span>
                      <span>점</span>
                    </div>
                    <div className={styles.resultWrap}>
                      <LabelResult
                        type={(() => {
                          const SCORE = data?.examList[data?.targetGestureIndex - 1].painScore;
                          const VALUE: 0 | 1 | 2 = SCORE <= 3 ? 2 : SCORE <= 6 ? 1 : 0;
                          return VALUE;
                        })()}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.totalScoreInfoWrap}>
                <div className={styles.totalScoreInfo}>
                  <div className={styles.totalScore}>
                    <span>종합</span>
                    <span className={styles.score}>{data.examList[data.targetGestureIndex - 1].movementScore}</span>
                    <span>점</span>
                  </div>
                  <button onClick={() => setTotalScoreGuideModalOpen(true)}>
                    <Notice />
                  </button>
                </div>
                <div className={styles.totalScoreChartWrap}>
                  {(() => {
                    const chartData: lineChartDataType[] = [
                      {
                        type: 'hit',
                        value: data.examList[data.targetGestureIndex - 1].movementScore,
                        xAxisLabel: '이번',
                        xAxisSubLabel: `${data.currentExamDateTime.slice(5, 7)}.${data.currentExamDateTime.slice(
                          8,
                          10
                        )}`,
                        useBalloon: true,
                        balloonUnit: '점',
                      },
                      {
                        type: 'solid',
                        value: data.examList[data.targetGestureIndex - 1].goalMovementScore,
                        xAxisLabel: '목표',
                      },
                    ];

                    return <LineChart yAxisValues={[0, 25, 50, 75, 100]} min={0} max={100} data={chartData} />;
                  })()}
                </div>
                <div className={styles.feedbackWrap}>
                  <h6 className={styles.feedbackTitle}>피드백</h6>
                  <div
                    className={styles.feedbackContent}
                    dangerouslySetInnerHTML={{
                      __html: data.examComment,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {data.examTypeCode === 'M' && (
        <>
          <section className={styles.examInfoWrap}>
            {/* <h4 className={styles.examDate}>{data.currentExamDateTime.slice(0, 10).replace(/-/g, '. ')}</h4> */}
            <div className={styles.discomfortPart}>
              <span>불편 부위</span>
              <span>{UNCOMFORTABLE_PART_CODE[data.uncomfortablePartCode].ko}</span>
            </div>
            <div className={styles.targetMovement}>
              <span>목표 동작</span>
              <span>{data.examList[0].gestureName}</span>
            </div>
          </section>

          {(() => {
            const scoreData: {
              function: {
                score: number;
                date: string;
              }[];
              discomfort: {
                score: number;
                date: string;
              }[];
              total: {
                score: number;
                date: string;
              }[];
            } = {
              function: [],
              discomfort: [],
              total: [],
            };
            data.scoreList.map((score, idx) => {
              scoreData.function.push({
                score: score.functionScore,
                date: score.examDateTime,
              });
              scoreData.discomfort.push({
                score: score.painScore,
                date: score.examDateTime,
              });
              scoreData.total.push({
                score: score.movementScore,
                date: score.examDateTime,
              });
              if (data.scoreList.length - 1 === idx)
                scoreData.total.push({
                  score: score.goalMovementScore,
                  date: score.examDateTime,
                });
            });
            const FUNCTION_THIS_TIME_DATA = scoreData.function[scoreData.function.length - 1].score;
            const FUNCTION_LABEL_TYPE = FUNCTION_THIS_TIME_DATA <= 3 ? 0 : FUNCTION_THIS_TIME_DATA <= 6 ? 1 : 2;
            const DISCOMFORT_THIS_TIME_DATA = scoreData.discomfort[scoreData.discomfort.length - 1].score;
            const DISCOMFORT_LABEL_TYPE = DISCOMFORT_THIS_TIME_DATA <= 3 ? 2 : DISCOMFORT_THIS_TIME_DATA <= 6 ? 1 : 0;
            return (
              <section className={styles.scoreChartSection}>
                <h4 className={styles.sectionTitle}>결과 점수</h4>
                <article className={styles.article}>
                  <div className={styles.articleTop}>
                    <div className={styles.scoreInfo}>
                      <span>기능</span>
                      <span>{scoreData.function.slice(-1)[0].score}</span>
                      <span>점</span>
                    </div>
                    <LabelResult type={FUNCTION_LABEL_TYPE} />
                    <button onClick={() => setScoreGuideModalOpen(true)}>
                      <Notice />
                    </button>
                  </div>

                  <div className={styles.chartWrap}>
                    {(() => {
                      const { padding, mobilePadding } = getChartPadding(scoreData.function.length);
                      const chartData: lineChartDataType[] = [];
                      const dataLength = scoreData.function.length;

                      scoreData.function.map((functionData, idx) => {
                        const isThisTime = idx === dataLength - 1;

                        const type = isThisTime ? 'hit' : 'normal';
                        const value = functionData.score;
                        const xAxisLabel = getLineChartXaxis(idx, scoreData.function.length);
                        const xAxisSubLabel = functionData.date.slice(5, 10).replace(/-/g, '.');
                        const useBalloon = isThisTime ? true : false;
                        const balloonUnit = isThisTime ? '점' : '';
                        chartData.push({
                          type,
                          value,
                          xAxisLabel,
                          xAxisSubLabel,
                          useBalloon,
                          balloonUnit,
                        });
                      });
                      return (
                        <LineChart
                          yAxisValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                          min={0}
                          max={10}
                          padding={padding}
                          mobilePadding={mobilePadding}
                          data={chartData}
                        />
                      );
                    })()}
                  </div>
                </article>
                <article className={styles.article}>
                  <div className={styles.articleTop}>
                    <div className={styles.scoreInfo}>
                      <span>불편</span>
                      <span>{scoreData.discomfort.slice(-1)[0].score}</span>
                      <span>점</span>
                    </div>
                    <LabelResult type={DISCOMFORT_LABEL_TYPE} />
                    <button onClick={() => setScoreGuideModalOpen(true)}>
                      <Notice />
                    </button>
                  </div>

                  <div className={styles.chartWrap}>
                    {(() => {
                      const { padding, mobilePadding } = getChartPadding(2);
                      const chartData: lineChartDataType[] = [];
                      const dataLength = scoreData.discomfort.length;

                      scoreData.discomfort.map((discomfortData, idx) => {
                        const isThisTime = idx === dataLength - 1;

                        const type = isThisTime ? 'hit' : 'normal';
                        const value = discomfortData.score;
                        const xAxisLabel = getLineChartXaxis(idx, scoreData.discomfort.length);
                        const xAxisSubLabel = discomfortData.date.slice(5, 10).replace(/-/g, '.');
                        const useBalloon = isThisTime ? true : false;
                        const balloonUnit = isThisTime ? '점' : '';
                        chartData.push({
                          type,
                          value,
                          xAxisLabel,
                          xAxisSubLabel,
                          useBalloon,
                          balloonUnit,
                        });
                      });
                      return (
                        <LineChart
                          yAxisValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                          min={0}
                          max={10}
                          padding={padding}
                          mobilePadding={mobilePadding}
                          data={chartData}
                        />
                      );
                    })()}
                  </div>
                </article>
                <article className={styles.article}>
                  <div className={styles.articleTop}>
                    <div className={styles.scoreInfo}>
                      <span>종합</span>
                      <span>{scoreData.total.slice(-2)[0].score}</span>
                      <span>점</span>
                    </div>
                    <button onClick={() => setTotalScoreGuideModalOpen(true)}>
                      <Notice />
                    </button>
                  </div>

                  <div className={styles.chartWrap}>
                    {(() => {
                      const { padding, mobilePadding } = getChartPadding(2);
                      const chartData: lineChartDataType[] = [];
                      const dataLength = scoreData.total.length;

                      scoreData.total.map((totalData, idx) => {
                        const isThisTime = idx === dataLength - 2;

                        const type = isThisTime ? 'hit' : idx === dataLength - 1 ? 'solid' : 'normal';
                        const value = totalData.score;
                        const xAxisLabel = getLineChartXaxis(idx, scoreData.total.length, true);
                        const xAxisSubLabel = totalData.date.slice(5, 10).replace(/-/g, '.');
                        const useBalloon = isThisTime ? true : false;
                        const balloonUnit = isThisTime ? '점' : '';
                        chartData.push({
                          type,
                          value,
                          xAxisLabel,
                          xAxisSubLabel,
                          useBalloon,
                          balloonUnit,
                        });
                      });
                      return (
                        <LineChart
                          yAxisValues={[0, 25, 50, 75, 100]}
                          min={0}
                          max={100}
                          padding={padding}
                          mobilePadding={mobilePadding}
                          data={chartData}
                        />
                      );
                    })()}
                  </div>
                  <div className={styles.feedbackWrap}>
                    <h5>피드백</h5>
                    <div className={styles.feedbackDetail}>
                      <p dangerouslySetInnerHTML={{ __html: data.examComment }}></p>
                    </div>
                  </div>
                </article>
              </section>
            );
          })()}
        </>
      )}

      {data.examTypeCode === 'M' && (
        <section className={styles.stamp}>
          <h3 className={styles.stampTitle}>최근 일주일 운동 스탬프</h3>
          <p className={styles.stampSubTitle}>
            {data
              ? getStampSentence(
                  //   data.exerciseStampList
                  //       .length
                  (() => {
                    const obj: {
                      [key: string]: '';
                    } = {};
                    data.exerciseStampList.map((datum) => {
                      obj[datum] = '';
                    });
                    console.log(Object.keys(obj).length);
                    return Object.keys(obj).length;
                  })()
                )
              : ' '}
          </p>
          <div className={styles.stampWrap}>
            <StampBackground circleBgColor='#e3e7f6' />
            <ul className={styles.stampList}>
              {(() => {
                return week.map((date, idx) => (
                  <li key={idx}>
                    <Stamp
                      isActive={(() => {
                        const dateString = `${date.year}-${dateFormatter(date.month)}-${dateFormatter(date.date)}`;
                        return data.exerciseStampList.includes(dateString) || false;
                      })()}
                      date={`${dateFormatter(date.month)}.${dateFormatter(date.date)}`}
                      index={idx + 1}
                    />
                  </li>
                ));
              })()}
            </ul>
          </div>
        </section>
      )}
      {scoreGuideModalOpen && <ScoreGuideModal onSubmit={() => setScoreGuideModalOpen(false)} />}
      {totalScoreGuideModalOpen && <TotalScoreGuideModal onSubmit={() => setTotalScoreGuideModalOpen(false)} />}
    </>
  );
}
export default MskCheckResultContent;
