import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import store from './store/store';
import { navigateTo } from './store/navigateSlice';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: 1,
      onError: () => {
        // console.log('default  error handler ');
        store.dispatch(
          navigateTo({
            path: `/health/locker-room`,
          })
        );
        // store.dispatch(
        //     setErrorControl({ showErrorModal: true, submitFn: null })
        // );
      },
      onSuccess: (res) => {},
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <App />
    </Provider>
    {/* {window.location.href.includes('http://localhost:3000') && <ReactQueryDevtools />} */}
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
