import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosAuthInstance } from '../../api/axios';
import { useQuery } from 'react-query';
import Loading from '../../components/Loading/Loading';
import useUserData from '../../hooks/useUserData';
import { ISelfPsychologyEvaluationResult } from '../../type/ISelfPsychologyEvaluation';

function InnerHealingCheck() {
  const navigate = useNavigate();
  const userData = useUserData();

  const { data: resultData, isLoading } = useQuery<ISelfPsychologyEvaluationResult>(
    ['self-psychology-evaluation-result', '0'],
    () =>
      axiosAuthInstance
        .get(`/api/inner-healing/self-assessments?userId=${userData.data?.userId}`)
        .then((res) => res.data)
  );

  useEffect(() => {
    if (!resultData) return;
    console.log(resultData.firstHistory?.length);
    if (resultData.firstHistory?.length)
      navigate('/mind-therapy/inner-healing', {
        replace: true,
      });
    else
      navigate('/mind-therapy/inner-healing/self-psychology-evaluation/0', {
        replace: true,
      });
  }, [resultData]);
  return <>{isLoading && <Loading />}</>;
}
export default InnerHealingCheck;
