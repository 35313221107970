import { BrowserRouter, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import NotFound from './pages/NotFound/NotFound';
import Main from './pages/Main/Main';
import Kakao from './pages/Kakao/Kakao';
import { useEffect } from 'react';
import ServiceInfo from './pages/ServiceInfo/ServiceInfo';
import JoinPolicy from './pages/JoinPolicy/JoinPolicy';
import JoinPolicyDetail from './pages/JoinPolicyDetail/JoinPolicyDetail';
import Health from './pages/Health/Health';
import Setting from './pages/Setting/Setting';
import MyWallet from './pages/MyWallet/MyWallet';
import PAGE_ROUTER from './constant/pageList';
import Feed from './pages/Feed/Feed';
import Error from './pages/Error/Error';
import Navigator from './components/Navigator';
import TodaysExercise from './pages/TodaysExercise/TodaysExercise';
import PhysicalInfo from './pages/PhysicalInfo/PhysicalInfo';
import MskCheck from './pages/MskCheck/MskCheck';
import MovementReview from './pages/MovementReview/MovementReview';
import MskCheckResult from './pages/MskCheckResult/MskCheckResult';
import ExerciseResetting from './pages/ExerciseResetting/ExerciseResetting';
import MetabolismCheck from './pages/MetabolismCheck/MetabolismCheck';
import MetabolismCheckResult from './pages/MetabolismCheckResult/MetabolismCheckResult';
import Exercise from './pages/Exercise/Exercise';
import ExerciseResult from './pages/ExerciseResult/ExerciseResult';
import InnerHealingCheck from './pages/InnerHealingCheck/InnerHealingCheck';
import SelfPsychologyEvaluation from './pages/SelfPsychologyEvaluation/SelfPsychologyEvaluation';
import SelfPsychologyEvaluationResult from './pages/SelfPsychologyEvaluationResult/SelfPsychologyEvaluationResult';
import InnerHealing from './pages/InnerHealing/InnerHealing';
import InnerHealingDetail from './pages/InnerHealingDetail/InnerHealingDetail';
import PersonalCounseling from './pages/PersonalCounseling/PersonalCounseling';
import Counselor from './pages/Counselor/Counselor';
import Speech from './pages/Speech/Speech';
import Authorization from './components/Authorization/Authorization';
// import SettingDetail from './pages/SettingDetail/SettingDetail';
import ErrorBoundaryComponent from './components/ErrorBoundaryComponent';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from './store/store';
import { jwtDecode } from 'jwt-decode';
import { IJwtTokenType, login, logout } from './store/jwt';
import EditAvatar from './pages/EditAvatar/EditAvatar';
import { isMobile } from 'react-device-detect';
import { navigateTo } from './store/navigateSlice';
import CounselingDetail from './pages/CounselingDetail/CounselingDetail';
import SpeechDetail from './pages/SpeechDetail/SpeechDetail';
function App() {
  function setScreenHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  useEffect(() => {
    setScreenHeight();
    window.addEventListener('orientationchange', setScreenHeight);
    window.addEventListener('resize', setScreenHeight);
    return () => {
      window.addEventListener('orientationchange', setScreenHeight);
      window.removeEventListener('resize', setScreenHeight);
    };
  }, []);

  useEffect(() => {
    if (isMobile) document.body.className = 'mobile';
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    const TOKEN = localStorage.getItem('access-token');
    if (!TOKEN) return;
    try {
      const JWT = jwtDecode(TOKEN) as IJwtTokenType;
      dispatch(login(JWT));
    } catch (err) {
      dispatch(logout());
      dispatch(navigateTo({ path: '/' }));
    }
  }, []);

  const ROUTE_DATA = [
    { path: '/', element: <Main />, authorization: false },
    { path: '/health/:menu', element: <Health />, authorization: true },
    // 피트니스, 검사
    {
      path: PAGE_ROUTER.피트니스?.subMenu?.['오늘의 운동'].path,
      element: <TodaysExercise />,
      authorization: true,
    },
    {
      path: '/physical-check/physical-info/:category',
      element: <PhysicalInfo />,
      authorization: true,
    },
    {
      path: '/physical-check/msk',
      element: <MskCheck />,
      authorization: true,
    },
    {
      path: 'physical-check/metabolism',
      element: <MetabolismCheck />,
      authorization: true,
    },
    {
      path: '/physical-check/movement-review/:movementId/:menu',
      element: <MovementReview />,
      authorization: true,
    },
    {
      path: '/physical-check/result/msk',
      element: <MskCheckResult />,
      authorization: true,
    },
    {
      path: '/physical-check/result/metabolism',
      element: <MetabolismCheckResult />,
      authorization: true,
    },
    {
      path: '/physical-check/resetting/:menu',
      element: <ExerciseResetting />,
      authorization: true,
    },
    // 피트니스, 검사

    // 피트니스-운동
    {
      path: '/exercise/:unitIndex/:menu/:sessionId',
      element: <Exercise />,
      authorization: true,
    },
    {
      path: '/exercise/result/:sessionId',
      element: <ExerciseResult />,
      authorization: true,
    },
    // 피트니스-운동

    // 마인드테라피
    {
      path: PAGE_ROUTER.마인드테라피?.subMenu?.['이너힐링'].path,
      element: <InnerHealingCheck />,
      authorization: true,
    },
    {
      path: '/mind-therapy/inner-healing/self-psychology-evaluation/result',
      element: <SelfPsychologyEvaluationResult />,
      authorization: true,
    },
    {
      path: '/mind-therapy/inner-healing/self-psychology-evaluation/:index',
      element: <SelfPsychologyEvaluation />,
      authorization: true,
    },
    {
      path: '/mind-therapy/inner-healing',
      element: <InnerHealing />,
      authorization: true,
    },
    {
      path: '/mind-therapy/inner-healing/:id',
      element: <InnerHealingDetail />,
      authorization: true,
    },
    {
      path: '/mind-therapy/personal-counseling',
      element: <PersonalCounseling />,
      authorization: true,
    },
    {
      path: '/mind-therapy/personal-counseling/detail/:id',
      element: <CounselingDetail />,
      authorization: true,
    },
    {
      path: '/mind-therapy/personal-counseling/counselor/:id',
      element: <Counselor />,
      authorization: true,
    },
    // 마인드테라피

    // 카페테리아
    {
      path: PAGE_ROUTER.카페테리아?.subMenu?.피드.path,
      element: <Feed />,
      authorization: true,
    },
    // 카페테리아

    //스피치
    {
      path: PAGE_ROUTER.스피치?.subMenu?.스피치.path,
      element: <Speech />,
      authorization: true,
    },
    {
      path: '/speech/detail/:id',
      element: <SpeechDetail />,
      authorization: true,
    },
    //스피치

    //라커룸
    {
      path: PAGE_ROUTER.라커룸?.subMenu?.['내 지갑']?.path,
      element: <MyWallet />,
      authorization: true,
    },
    {
      path: PAGE_ROUTER.라커룸?.subMenu?.설정.path,
      element: <Setting />,
      authorization: true,
    },
    {
      path: PAGE_ROUTER.라커룸?.subMenu?.['아바타 꾸미기']?.path,
      element: <EditAvatar />,
      authorization: true,
    },
    {
      path: '/locker-room/setting/:menu',
      element: <Setting />,
      authorization: true,
    },
    {
      path: '/locker-room/setting/:menu/:id',
      element: <Setting />,
      authorization: true,
    },
    //라커룸

    {
      path: '/service-info',
      element: <ServiceInfo />,
      authorization: false,
    },
    {
      path: '/join/policy',
      element: <JoinPolicy />,
      authorization: false,
    },
    {
      path: '/join/policy/:id',
      element: <JoinPolicyDetail />,
      authorization: false,
    },
    {
      path: '/auth/kakao/callback',
      element: <Kakao />,
      authorization: false,
    },
    {
      path: '/error',
      element: <Error />,
      authorization: false,
    },
    {
      path: '*',
      element: <NotFound />,
      authorization: false,
    },
  ];

  useEffect(() => {
    if (window.navigator.userAgent.toLowerCase().match(/kakao/g)) {
      window.location.href = `kakaotalk://web/openExternal?url=${window.location.href}`;
    }
  }, []);
  return (
    <div>
      <BrowserRouter>
        <Navigator />
        <ErrorBoundary fallback={<ErrorBoundaryComponent />}>
          <Routes>
            {ROUTE_DATA.map((router) => (
              <Route
                key={router.path}
                path={router.path}
                element={
                  router.authorization ? (
                    <Authorization key={router.path}>{router.element}</Authorization>
                  ) : (
                    router.element
                  )
                }
              />
            ))}
          </Routes>
        </ErrorBoundary>
      </BrowserRouter>
    </div>
  );
}

export default App;
