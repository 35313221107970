import { useEffect } from 'react';
import { useDispatch, useSelector } from '../store/store';
import { useNavigate } from 'react-router-dom';
import { navigateTo } from '../store/navigateSlice';

function Navigator() {
    const navigate = useNavigate();
    const navigatePath = useSelector((state) => state.navigator);
    const dispatch = useDispatch();
    useEffect(() => {
        if (navigatePath.path) {
            navigate(navigatePath.path);
            dispatch(navigateTo({ path: null }));
        }
    }, [navigatePath]);
    return <></>;
}
export default Navigator;
