import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import MetaSpaceIcon from '../../components/assetComponents/MetaSpaceIcon';
import styles from './Feed.module.scss';
import PAGE_ROUTER from '../../constant/pageList';
import { useEffect, useState } from 'react';
import FeedFrame from '../../components/FeedFrame/FeedFrame';
import { MasonryInfiniteGrid } from '@egjs/react-infinitegrid';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useInView } from 'react-intersection-observer';
import Loading from '../../components/Loading/Loading';
import { axiosAuthInstance } from '../../api/axios';
import IFeedDataType from '../../type/IFeedDataType';
import { useSelector } from '../../store/store';
import useUserData from '../../hooks/useUserData';

const FEED_DATA_PAGE_LENGTH = 10;
function Feed() {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useUserData();
  const [tab, setTab] = useState(Number(new URL(document.URL).searchParams.get('tab')) || 0);
  useEffect(() => {
    setTab(Number(new URL(document.URL).searchParams.get('tab')) || 0);
  }, [location]);

  const { ref, inView } = useInView();
  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery<IFeedDataType>(
    ['feed', tab === 0 ? 'all' : 'my'],
    ({ pageParam = 0 }) => getFeedData(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage.last) return allPages.length;
        else return false;
      },
    }
  );
  useEffect(() => {
    if (inView && hasNextPage && !isFetching && !isFetchingNextPage) fetchNextPage();
  }, [inView]);
  const getFeedData = async (page: number) => {
    let res = await axiosAuthInstance.get(
      `/api/cafeteria/feeds?size=${FEED_DATA_PAGE_LENGTH}&page=${page}&tab=${tab === 0 ? 'all' : 'my'}&userId=${userData
        .data?.userId}`
    );
    return res.data;
  };

  const [editingFeedId, setEditingFeedId] = useState<number>();
  function handleEditingFeedId(id: number | undefined) {
    setEditingFeedId(id);
  }

  useEffect(() => {
    setEditingFeedId(undefined);
  }, [tab]);

  const { data: news } = useQuery<{
    userCount: number;
    sessionCount: number;
  }>(['feed', 'news'], () => axiosAuthInstance.get('/api/cafeteria/feed/news').then((res) => res.data));
  console.log(data?.pages);
  return (
    <>
      <main className={styles.container}>
        <Header isNavShow />
        <header className={styles.mobileHeader}>
          <button onClick={() => navigate(PAGE_ROUTER['카페테리아'].path)}>
            <MetaSpaceIcon />
          </button>
          <span>피드</span>
        </header>
        <nav className={`${styles.feedNav} max-1024`}>
          <div className={styles.left}>
            <button className={styles.hit}>피드</button>
          </div>
        </nav>
        <div className={`${styles.contentWrap} max-1024`}>
          <ul className={styles.tabList}>
            <li
              className={tab === 0 ? styles.hit : ''}
              onClick={() => navigate(`${PAGE_ROUTER.카페테리아?.subMenu?.피드.path}?tab=0`, { replace: true })}
            >
              전체
            </li>
            <li
              className={tab === 1 ? styles.hit : ''}
              onClick={() => navigate(`${PAGE_ROUTER.카페테리아?.subMenu?.피드.path}?tab=1`, { replace: true })}
            >
              내 피드
            </li>
          </ul>
          {tab === 0 && data?.pages.length && (
            <div className={styles.userCountInfoWrap}>
              <p>
                <span>최근 일주일동안 {news?.userCount.toLocaleString()}명이</span>
                <span>
                  <strong>
                    {news?.sessionCount.toLocaleString()}
                    번의 운동
                  </strong>
                  을 완료했어요!
                </span>
              </p>
            </div>
          )}
          <div className={styles.feedListWrap}>
            {data &&
              userData.data &&
              (tab === 0 ? (
                <>
                  {data?.pages.length && data.pages[0].content.length ? (
                    <MasonryInfiniteGrid gap={12} useResizeObserver observeChildren resizeDebounce={20}>
                      {data.pages.map((page) =>
                        page.content.map((feed) => (
                          <FeedFrame userId={userData.data?.userId || String(0)} feed={feed} key={feed.feedId} />
                        ))
                      )}
                    </MasonryInfiniteGrid>
                  ) : (
                    <div className={styles.noResult}>
                      <div>
                        <p>피드가 없어요</p>
                        <div className={styles.banner}></div>
                      </div>
                    </div>
                  )}
                </>
              ) : data.pages.length && data.pages[0].content.length ? (
                <>
                  <MasonryInfiniteGrid gap={12} useResizeObserver observeChildren resizeDebounce={20}>
                    {data.pages.map((page) =>
                      page.content.map((feed) => {
                        return (
                          <FeedFrame
                            userId={userData.data?.userId || String(0)}
                            feed={feed}
                            key={feed.feedId}
                            isEditable
                            editingFeedId={editingFeedId}
                            handleEditingFeedId={handleEditingFeedId}
                          />
                        );
                      })
                    )}
                  </MasonryInfiniteGrid>
                </>
              ) : (
                <div className={styles.noResult}>
                  <div>
                    <p>내 피드가 없어요</p>
                    <div className={styles.banner}></div>
                  </div>
                </div>
              ))}
            <div ref={ref}></div>
          </div>
        </div>
      </main>
      {(isFetching || !userData.data?.userId) && <Loading />}
      <div className='fixedContents'></div>
    </>
  );
}
export default Feed;
