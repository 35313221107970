import styles from './CheckAction.module.scss';
import { ReactComponent as Back } from '../../../assets/mobile/icons/ic_back_b.svg';
import CustomPlayer from '../../CustomPlayer/CustomPlayer';
import { useEffect, useRef, useState } from 'react';
import CustomConfirm from '../../CustomConfirm/CustomConfirm';
import { useNavigate } from 'react-router-dom';
import IMskCheckType from '../../../type/IMskCheckType';

interface IPropsType {
  handleValidationCheck: () => void;
  data: IMskCheckType;
  examGestureIndex: number;
}
function CheckAction({ handleValidationCheck, data, examGestureIndex }: IPropsType) {
  const [stopModalOpen, setStopModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    handleValidationCheck();
  }, []);
  const onEndTimeoutRef = useRef<null | NodeJS.Timeout>(null);
  return (
    <>
      <section className={styles.container}>
        <header className={styles.header}>
          <button onClick={() => setStopModalOpen(true)}>
            <Back />
          </button>
          <span>{data.examParts[examGestureIndex]?.gestureName}</span>
        </header>
        <div className={styles.videoWrap}>
          <CustomPlayer
            // timer
            autoPlay
            mute={false}
            title={data.examParts[examGestureIndex]?.gestureName}
            backFn={() => {
              setStopModalOpen(true);
            }}
            onPlay={() => {
              if (onEndTimeoutRef.current) clearTimeout(onEndTimeoutRef.current);
            }}
            onEnded={() => {
              onEndTimeoutRef.current = setTimeout(() => {
                navigate(`/physical-check/movement-review/${data.examParts[examGestureIndex].gestureCode}/function`, {
                  state: {
                    isLast: data.examParts.length - 1 === examGestureIndex,
                  },
                });
              }, 3000);
            }}
            url={data.examParts[examGestureIndex].mainVideo}
            useReplay
            timeControlable={false}
          />
        </div>
      </section>
      {stopModalOpen && (
        <CustomConfirm
          message='동작 안내 화면으로 돌아갈까요?'
          falseText='아니요'
          trueText='예, 돌아갈게요'
          falseFn={() => setStopModalOpen(false)}
          trueFn={() => navigate('?index=2')}
        />
      )}
    </>
  );
}
export default CheckAction;
