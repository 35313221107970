import styles from './SystemAlarm.module.scss';
import { ReactComponent as Check } from '../../assets/common/icons/checkList_on.svg';
import { useEffect, useState } from 'react';

interface IPropsType {
    icon?: 'check';
    message: string;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
function SystemAlarm({ icon, message, setIsOpen }: IPropsType) {
    const [close, setClose] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setClose(true);
        }, 3500);
    }, []);
    useEffect(() => {
        if (close)
            setTimeout(() => {
                setIsOpen(false);
            }, 500);
    }, [close]);
    return (
        <div className={`${styles.container} ${close ? styles.close : ''}`}>
            {icon === 'check' && <Check />}
            <span>{message}</span>
        </div>
    );
}
export default SystemAlarm;
