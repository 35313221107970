import { useState } from 'react';
import styles from './ActionGuide.module.scss';
import CustomButton from '../../CustomButton/CustomButton';
import WhirlModal from '../../WhirlModal/WhirlModal';
import HorizontalModal from '../../HorizontalModal/HorizontalModal';

interface IPropsType {
  nextFn: () => void;
}

function ActionGuide({ nextFn }: IPropsType) {
  const [index, setIndex] = useState(0);

  function handleCheck() {
    if (index === 0) {
      const BROWSER_WIDTH = window.innerWidth;
      const IS_VERTICAL = window.innerWidth < window.innerHeight;
      if (BROWSER_WIDTH < 1024 && IS_VERTICAL) setIndex(1);
      else nextFn();
    } else if (index === 1) nextFn();
  }

  return (
    <>
      {index === 0 && <WhirlModal onSubmit={handleCheck} />}
      {index === 1 && <HorizontalModal onSubmit={handleCheck} />}
    </>
  );
}
export default ActionGuide;
