import { useNavigate } from 'react-router-dom';
import CustomButton from '../../CustomButton/CustomButton';
import styles from './CancelMembership.module.scss';
import Title from '../Title/Title';
import PAGE_ROUTER from '../../../constant/pageList';

function CancelMembership() {
    const navigate = useNavigate();
    return (
        <>
            <section className={styles.mobileContainer}>
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <h3>서비스 탈퇴 안내</h3>
                        <p>
                            임상용 서비스이므로 탈퇴할 수 없어요. <br />
                            <br />
                            탈퇴를 원하는 경우,
                            <br />
                            임상 담당자에게 문의해주세요.
                        </p>
                    </div>
                    <div
                        className={styles.submitBtnWrap}
                        onClick={() =>
                            navigate(
                                PAGE_ROUTER.라커룸?.subMenu?.설정.path || '/'
                            )
                        }
                    >
                        <CustomButton>확인하기</CustomButton>
                    </div>
                </div>
            </section>
            <section className={styles.container}>
                <Title title='서비스 탈퇴 안내' />
                <div className={styles.guideWrap}>
                    <h5>임상용 서비스이므로 탈퇴할 수 없어요.</h5>
                    <p>탈퇴를 원하는 경우, 임상 담당자에게 문의해주세요.</p>
                </div>
                <div
                    className={styles.submitBtnWrap}
                    onClick={() =>
                        navigate(PAGE_ROUTER.라커룸?.subMenu?.설정.path || '/')
                    }
                >
                    <CustomButton>확인하기</CustomButton>
                </div>
            </section>
        </>
    );
}
export default CancelMembership;
