import { useEffect } from 'react';
import Title from '../Title/Title';
import styles from './Faq.module.scss';
import IcMoveRightIcon from '../../assetComponents/IcMoveRightIcon';
import { useNavigate, useParams } from 'react-router-dom';
import FaqDetail from '../FaqDetail/FaqDetail';
import MobileHeader from '../../MobileHeader/MobileHeader';
import PAGE_ROUTER from '../../../constant/pageList';
import { useInfiniteQuery } from 'react-query';
import { axiosAuthInstance } from '../../../api/axios';
import Loading from '../../Loading/Loading';
import IFaqType from '../../../type/IFaqType';
import useCostomInView from '../../../hooks/useCustomInView';

const FAQ_DATA_PAGE_LENGTH = 10;
const FAQ_TYPE_DATA = [
  {
    en: 'service',
    ko: '서비스',
  },
  {
    en: 'fitness',
    ko: '피트니스',
  },
  {
    en: 'mental',
    ko: '멘탈',
  },
];
function Faq() {
  const navigate = useNavigate();
  const { id } = useParams();

  const tab = (() => {
    const query = new URL(window.location.href).searchParams.get('tab');
    return query !== 'service' && query !== 'fitness' && query !== 'mental' ? 'service' : query;
  })();

  const { inView, div } = useCostomInView();
  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery<{
    content: IFaqType[];
    last: boolean;
  }>(['faq', tab], ({ pageParam = 0 }) => getFaqData(pageParam), {
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage.last) return allPages.length;
      else return false;
    },
    staleTime: 1000 * 60,
  });
  useEffect(() => {
    if (inView && hasNextPage && !isFetching && !isFetchingNextPage) fetchNextPage();
  }, [inView, data]);

  async function getFaqData(page: number) {
    let res = await axiosAuthInstance.get(`/api/board/faq?size=${FAQ_DATA_PAGE_LENGTH}&page=${page}&tab=${tab}`);
    return res.data;
  }

  return (
    <>
      <MobileHeader
        title={'자주 묻는 질문'}
        backBtn='arrow'
        backUrl={id ? '-1' : PAGE_ROUTER.라커룸?.subMenu?.설정.path || '/'}
      />
      <section className={styles.container}>
        <Title title='자주 묻는 질문' noUnderline={id ? false : true} isSticky={id ? true : false} />
        <div className={styles.contentWrap}>
          {id ? (
            <FaqDetail id={Number(id)} />
          ) : (
            <>
              <ul className={styles.faqTypeList}>
                {FAQ_TYPE_DATA.map((data) => (
                  <li key={data.en} className={data.en === tab ? styles.hit : ''}>
                    <button
                      onClick={() =>
                        navigate(`?tab=${data.en}`, {
                          replace: true,
                        })
                      }
                    >
                      {data.ko}
                    </button>
                  </li>
                ))}
              </ul>
              <ul className={styles.faqList}>
                {data?.pages?.map(
                  (page, idx) =>
                    page?.content?.map((datum) => (
                      <li
                        key={Math.random()}
                        onClick={() => navigate(`/locker-room/setting/faq/${datum.id}?tab=${tab}`)}
                      >
                        <h3>{datum.title}</h3>
                        <IcMoveRightIcon />
                      </li>
                    ))
                )}
                {!isFetching && div}
              </ul>
            </>
          )}
        </div>
      </section>
      {isFetching && <Loading />}
    </>
  );
}
export default Faq;
