import { useNavigate } from 'react-router-dom';
import PAGE_ROUTER from '../../../constant/pageList';
import styles from './FaqDetail.module.scss';
import IFaqType from '../../../type/IFaqType';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

const FAQ_STRING = `대한민국은 민주공화국이다. 국회는 의원의 자격을 심사하며, 의원을 징계할 수 있다. 국정감사 및 조사에 관한 절차 기타 필요한 사항은 법률로 정한다. 군인은 현역을 면한 후가 아니면 국무총리로 임명될 수 없다. 모든 국민은 언론·출판의 자유와 집회·결사의 자유를 가진다. 대통령은 법률이 정하는 바에 의하여 훈장 기타의 영전을 수여한다.

계엄을 선포한 때에는 대통령은 지체없이 국회에 통고하여야 한다. 감사원은 원장을 포함한 5인 이상 11인 이하의 감사위원으로 구성한다. 대통령의 선거에 관한 사항은 법률로 정한다. 국회의원은 국가이익을 우선하여 양심에 따라 직무를 행한다. 국가는 지역간의 균형있는 발전을 위하여 지역경제를 육성할 의무를 진다.`;

interface IPropsType {
    id: number;
}
function FaqDetail({ id }: IPropsType) {
    const tab = (() => {
        const query = new URL(window.location.href).searchParams.get('tab');
        return query !== 'service' && query !== 'fitness' && query !== 'mental'
            ? 'service'
            : query;
    })();
    const tabKo =
        tab === 'service'
            ? '서비스'
            : tab === 'fitness'
              ? '피트니스'
              : tab === 'mental'
                ? '멘탈'
                : '';
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [faq, setFaq] = useState<IFaqType>();

    useEffect(() => {
        const cacheData:
            | {
                  pages: {
                      content: IFaqType[];
                  }[];
              }
            | undefined = queryClient.getQueryData(['faq', tab]);
        if (!cacheData) {
            navigate(
                PAGE_ROUTER.라커룸.subMenu?.설정?.subMenu?.자주묻는질문?.path ||
                    '/health/locker-room'
            );
            return;
        }
        page: for (let p = 0; p < cacheData.pages.length; p++) {
            for (let i = 0; i < cacheData.pages[p].content.length; i++) {
                if (id === cacheData.pages[p].content[i].id) {
                    setFaq(cacheData.pages[p].content[i]);
                    break page;
                }
            }
        }
    }, []);
    console.log(tab);
    return (
        <section className={styles.container}>
            <h1 className={styles.title}>
                <div className={styles.faqType}>{tabKo}</div>
                <span>{faq?.title}</span>
            </h1>
            <p
                className={styles.content}
                dangerouslySetInnerHTML={{ __html: faq?.contents || '' }}
            ></p>
            <div className={styles.returnBtnWrap}>
                <button
                    onClick={() =>
                        navigate(
                            PAGE_ROUTER.라커룸.subMenu?.설정?.subMenu
                                ?.자주묻는질문?.path + `?tab=${tab}` || '/'
                        )
                    }
                >
                    목록보기
                </button>
            </div>
        </section>
    );
}
export default FaqDetail;
