import { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import MobileHeader from '../../components/MobileHeader/MobileHeader';
import styles from './MskCheck.module.scss';
import CustomConfirm from '../../components/CustomConfirm/CustomConfirm';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectDiscomfort from '../../components/MskCheckList/SelectDiscomfort/SelectDiscomfort';
import ActionGuide from '../../components/MskCheckList/ActionGuide/ActionGuide';
import ActionDescription from '../../components/MskCheckList/ActionDescription/ActionDescription';
import CheckAction from '../../components/MskCheckList/CheckAction/CheckAction';
import { useQuery } from 'react-query';
import { axiosAuthInstance } from '../../api/axios';
import CheckIntroduce from '../../components/MskCheckList/CheckIntroduce/CheckIntroduce';
import IMskCheckType from '../../type/IMskCheckType';
import Loading from '../../components/Loading/Loading';
import useUserData from '../../hooks/useUserData';

const MSK_CHECK_ORDER = ['불편 부위', '검사 동작 안내', '검사 동작 안내', '검사 동작 설명', '검사'];
const INDEX_LIST = [0, 1, 2, 3, 4];

function MskCheck() {
  const navigate = useNavigate();
  const userData = useUserData();
  const location = useLocation();
  const [stopModalOpen, setStopModalOpen] = useState(false);

  const [index, setIndex] = useState(Number(new URL(window.location.href).searchParams.get('index') || '0'));

  useEffect(() => {
    let newIndex = Number(new URL(window.location.href).searchParams.get('index') || 0);
    if (isNaN(newIndex) || !INDEX_LIST.includes(newIndex)) navigate('?index=0', { replace: true });
    setIndex(newIndex);
  }, [location]);

  function handleNext() {
    if (!data) return;
    sessionStorage.setItem('msk-check-session-validation', 'true');
    let nextIndex = index + 1;
    navigate(`?index=${nextIndex}`, { state: data });
  }

  const { data } = useQuery<IMskCheckType>(['mskCheckInfo'], () =>
    axiosAuthInstance.get(`api/fitness/exam/introduce/lb?userId=${userData.data?.userId}`).then((res) => {
      const result = res.data;
      result.examParts.sort((a, b) => a.order - b.order);
      return res.data;
    })
  );
  const [examGestureIndex, setExamGestureIndex] = useState<number>();
  useEffect(() => {
    if (!data) return;
    for (let i = 0; i < data.examParts.length; i++) {
      if (!data.examParts[i].isExamCompleted) {
        setExamGestureIndex(i);
        return;
      }
    }
  }, [data]);

  function handleValidationCheck() {
    if (!sessionStorage.getItem('msk-check-session-validation')) navigate('/fitness/exercise-of-the-day');
  }
  return (
    <>
      <main className={`${styles.container} ${index === 3 || index === 4 ? styles.horizontalPage : ''}`}>
        <div className={styles.bg}>
          <Header isNavShow />
          <div className={styles.contentWrap}>
            {examGestureIndex === undefined || !data ? (
              <Loading />
            ) : (
              <>
                {index !== 3 && index !== 4 && (
                  <MobileHeader title={MSK_CHECK_ORDER[index]} backBtn='arrow' backFn={() => setStopModalOpen(true)} />
                )}
                <div className={styles.menu}>
                  <button>오늘의 운동</button>
                </div>
                <div className={styles.scrollableArea}>
                  <div className={styles.tab}>
                    <div>검사</div>
                  </div>
                  {index === 0 ? (
                    <SelectDiscomfort nextFn={handleNext} />
                  ) : index === 1 ? (
                    <CheckIntroduce nextFn={handleNext} data={data} />
                  ) : index === 2 ? (
                    <ActionDescription
                      handleValidationCheck={handleValidationCheck}
                      nextFn={handleNext}
                      onBackClick={() => setStopModalOpen(true)}
                      examGestureIndex={examGestureIndex}
                      data={data}
                    />
                  ) : index === 3 ? (
                    <CheckAction
                      handleValidationCheck={handleValidationCheck}
                      examGestureIndex={examGestureIndex}
                      data={data}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </main>
      {stopModalOpen && (
        <CustomConfirm
          message='검사를 그만할까요?'
          falseText='아니요'
          trueText='예, 그만할게요'
          falseFn={() => setStopModalOpen(false)}
          trueFn={() => navigate('/fitness/exercise-of-the-day')}
        />
      )}
    </>
  );
}
export default MskCheck;
