import styles from './SmartringConnectionFailModal.module.scss';

interface IPropsType {
    onCancel: () => void;
    onSubmit: () => void;
}
function SmartringConnectionFailModal({ onCancel, onSubmit }: IPropsType) {
    return (
        <div className={styles.dimed}>
            <article className={styles.modal}>
                <div className={styles.modalContent}>
                    <div className={styles.bannerWrap}></div>
                    <h6>스마트링 연동 실패</h6>
                    <p>
                        연동된 스마트링을 확인하지 못했어요.
                        <br />
                        스마트링 연동을 다시 시도할까요?
                    </p>
                </div>
                <div className={styles.btnWrap}>
                    <button onClick={onCancel}>그냥 운동할게요</button>
                    <button onClick={onSubmit}>다시 시도할게요</button>
                </div>
            </article>
        </div>
    );
}
export default SmartringConnectionFailModal;
