import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Kakao.module.scss';
import CustomButton from '../../components/CustomButton/CustomButton';
import Header from '../../components/Header/Header';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { axiosInstance } from '../../api/axios';
import { useDispatch } from '../../store/store';
import { IJwtTokenType, login } from '../../store/jwt';

const KAKAO_REDIRECT_URI = `${window.location.origin}/auth/kakao/callback`;
export const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${KAKAO_REDIRECT_URI}&response_type=code&scope=account_email,phone_number`;
function Kakao() {
  const navigate = useNavigate();
  const [onFail, setOnFail] = useState(false);
  const [onUnauthorized, setOnUnauthorized] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const code = new URL(document.URL).searchParams.get('code') || '';
    const QUERY_STRING = new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: process.env.REACT_APP_KAKAO_REST_API_KEY || '',
      redirect_uri: KAKAO_REDIRECT_URI,
      code,
    }).toString();
    (async () => {
      try {
        const KAKAO_TOKEN = await axios
          .post(`https://kauth.kakao.com/oauth/token?${QUERY_STRING}`)
          .then((res) => res.data.access_token);
        let code = 0;
        const response: {
          accessToken: string;
          refreshToken: string;
          accessTokenExpireIn: string;
          refreshTokenExpireIn: string;
          refreshTokenKey: string;
          isFirstLogin: boolean;
          isReturnUser: boolean;
        } = await axiosInstance.post(`/api/auth/kakao/login?kakaoAccessToken=${KAKAO_TOKEN}`).then((res) => {
          code = res.data.code;
          return res.data.data;
        });
        if (code === 1097) {
          setOnUnauthorized(true);
          return;
        } else if (code !== 200) {
          setOnFail(true);
          return;
        }

        const BASE64 = window.atob(response.accessToken);
        localStorage.setItem('access-token', BASE64);
        localStorage.setItem('refresh-token', response.refreshToken);
        localStorage.setItem('access-token-expire-in', response.accessTokenExpireIn);
        localStorage.setItem('refresh-token-expire-in', response.refreshTokenExpireIn);
        localStorage.setItem('refresh-token-key', response.refreshTokenKey);
        localStorage.setItem('login-timestamp', String(new Date().getTime()));
        const JWT = jwtDecode(BASE64) as IJwtTokenType;
        dispatch(login(JWT));
        if (response.isFirstLogin) navigate('/join/policy', { replace: true });
        else if (response.isReturnUser)
          navigate('/health/fitness?is-comeback=true', {
            replace: true,
          });
        else
          navigate('/health/fitness', {
            replace: true,
          });
      } catch (e) {
        setOnFail(true);
      }
    })();
  }, []);
  return (
    <>
      {(onFail || onUnauthorized) && <Header />}
      {onFail && (
        <div className={styles.dimed}>
          <article className={styles.modal}>
            <div className={styles.content}>
              <h4>❗️카카오 소셜로그인 실패</h4>
              <p>서비스 이용을 위해 다시 시도해주세요.</p>
            </div>
            <div className={styles.submitBtnWrap}>
              <div
                onClick={() => {
                  navigate('/', { replace: true });
                }}
              >
                <CustomButton>확인하기</CustomButton>
              </div>
            </div>
          </article>
        </div>
      )}
      {onUnauthorized && (
        <div className={styles.dimed}>
          <article className={styles.modal}>
            <div className={styles.content}>
              <h4>❗️서비스 이용 불가</h4>
              <p style={{ width: '100%' }}>등록되지 않은 사용자에요.</p>
            </div>
            <div className={styles.submitBtnWrap}>
              <div onClick={() => navigate('/', { replace: true })}>
                <CustomButton>확인하기</CustomButton>
              </div>
            </div>
          </article>
        </div>
      )}
    </>
  );
}
export default Kakao;
