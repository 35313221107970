import { useState } from 'react';
import styles from './Alert.module.scss';
import CustomButton from '../../CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
import CheckCompleteModal from '../../CheckCompleteModal/CheckCompleteModal';

function Alert() {
  const navigate = useNavigate();
  const [isAlarmOn, setIsAlarmOn] = useState<0 | 1>(); //0은 알림 끔, 1은 킴
  const [compensation, setCompensation] = useState({
    modalOpen: false,
    point: localStorage.getItem('check-compensation-point') || '0',
  });

  function handleSubmit() {
    if (isAlarmOn !== 0 && isAlarmOn !== 1) return;
    if (isAlarmOn) navigate('/physical-check/resetting/kakao-alert');
    else setCompensation((prev) => ({ ...prev, modalOpen: true }));
  }
  return (
    <>
      <div className={styles.tab}>검사</div>
      <h4 className={styles.title}>운동 알람 설정</h4>
      <div className={styles.alarmSettingWrap}>
        <p>운동할 시간에 카카오 알림톡을 보내드릴까요?</p>
        <ul>
          <li className={isAlarmOn === 1 ? styles.hit : ''} onClick={() => setIsAlarmOn(1)}>
            <span>알림을 보내주세요</span>
          </li>
          <li className={isAlarmOn === 0 ? styles.hit : ''} onClick={() => setIsAlarmOn(0)}>
            <span>나중에 할게요</span>
          </li>
        </ul>
      </div>
      <div className={styles.submitBtnWrap}>
        <div onClick={handleSubmit}>
          <CustomButton height={56} isActive={!(isAlarmOn !== 0 && isAlarmOn !== 1)}>
            확인하기
          </CustomButton>
        </div>
      </div>
      {compensation.modalOpen && (
        <CheckCompleteModal point={compensation.point} onSubmit={() => navigate('/fitness/exercise-of-the-day')} />
      )}
    </>
  );
}
export default Alert;
