import { useEffect, useState } from 'react';
import styles from './KakaoAlert.module.scss';
import Title from '../Title/Title';
import MobileHeader from '../../MobileHeader/MobileHeader';
import PAGE_ROUTER from '../../../constant/pageList';
import TimerAlertModal from '../../TimerAlertModal/TimerAlertModal';
import { axiosAuthInstance } from '../../../api/axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import INotificationType from '../../../type/INotificationType';
import Loading from '../../Loading/Loading';
import useUserData from '../../../hooks/useUserData';

function KakaoAlert() {
  const userData = useUserData();

  const queryClient = useQueryClient();
  const { data: alert, isLoading } = useQuery<INotificationType>(
    ['notification'],
    () =>
      axiosAuthInstance.get(`/api/locker-room/notification?userId=${userData.data?.userId}`).then((res) => {
        res.data.isKakaoAlertReceive = res.data.isKakaoAlertReceive === 'Y' ? true : false;
        res.data.isExerciseAlertReceive = res.data.isExerciseAlertReceive === 'Y' ? true : false;
        return res.data;
      })
    // {
    //     onSuccess: (data) => {
    //         const time = data.exerciseAlertReceiveTime.split(':');
    //         const hour = Number(time[0]);
    //         const minute = Number(time[1]);
    //         setIsActiveKakaoAlert(data.isKakaoAlertReceive);
    //         setIsActiveExerciseAlert(data.isExerciseAlertReceive);
    //         setExerciseAlertTime({
    //             isAm: hour < 12,
    //             hour: hour >= 12 ? hour - 12 : hour,
    //             minute,
    //         });
    //     },
    // }
  );
  const [isActivekakaoAlert, setIsActiveKakaoAlert] = useState(alert?.isKakaoAlertReceive || false);
  const [isActiveExerciseAlert, setIsActiveExerciseAlert] = useState(alert?.isExerciseAlertReceive || false);
  const [exerciseAlertTime, setExerciseAlertTime] = useState(() => {
    if (!alert) {
      return {
        isAm: true,
        hour: 9,
        minute: 0,
      };
    } else {
      const time = alert.exerciseAlertReceiveTime.split(':');
      const hour = Number(time[0]);
      const minute = Number(time[1]);
      return {
        isAm: hour < 12,
        hour: hour >= 12 ? hour - 12 : hour,
        minute,
      };
    }
  });

  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);

  // if문에 isFirst 들어가는 이유는,
  // 해당 useEffect는 처음 data를 가져왔을 때만 작동하도록 코드이기 떄문
  useEffect(() => {
    if (!alert || isFirst > 1) return;
    setIsActiveKakaoAlert(alert.isKakaoAlertReceive);
    setIsActiveExerciseAlert(alert.isExerciseAlertReceive);
    setExerciseAlertTime(() => {
      const time = alert.exerciseAlertReceiveTime.split(':');
      const hour = Number(time[0]);
      const minute = Number(time[1]);
      return {
        isAm: hour < 12,
        hour: hour >= 12 ? hour - 12 : hour,
        minute,
      };
    });
  }, [alert]);

  const { mutate } = useMutation(() =>
    axiosAuthInstance.post(`/api/locker-room/notification?userId=${userData.data?.userId}`, {
      isKakaoAlertReceive: isActivekakaoAlert,
      isExerciseAlertReceive: isActiveExerciseAlert,
      exerciseAlertReceiveTime: (() => {
        let hour = exerciseAlertTime.hour + (!exerciseAlertTime.isAm && exerciseAlertTime.hour !== 12 ? 12 : 0);
        if (hour === 12 && exerciseAlertTime.isAm) hour = 0;
        return `${('0' + hour).slice(-2)}:${('0' + exerciseAlertTime.minute).slice(-2)}:00`;
      })(),
    })
  );

  //카카오알림, 운동알림, 알림시간 변경시 변경사항 저장 단, 처음 변경은 서버 데이터로 인한 변경으로, post x
  const [isFirst, setIsFirst] = useState(0);
  useEffect(() => {
    if (isFirst === 0 || isFirst === 1) {
      setIsFirst((prev) => prev + 1);
      return;
    }
    queryClient.setQueryData(['notification'], () => ({
      isKakaoAlertReceive: isActivekakaoAlert,
      isExerciseAlertReceive: isActiveExerciseAlert,
      exerciseAlertReceiveTime: (() => {
        let hour = exerciseAlertTime.hour + (!exerciseAlertTime.isAm && exerciseAlertTime.hour !== 12 ? 12 : 0);
        if (hour === 12 && exerciseAlertTime.isAm) hour = 0;
        return `${('0' + hour).slice(-2)}:${('0' + exerciseAlertTime.minute).slice(-2)}:00`;
      })(),
    }));
    mutate();
  }, [isActivekakaoAlert, isActiveExerciseAlert, exerciseAlertTime]);

  return (
    <>
      <MobileHeader
        title={'카카오 알림톡 설정'}
        backBtn='arrow'
        backUrl={PAGE_ROUTER.라커룸?.subMenu?.설정.path || '/'}
      />
      <section className={styles.container}>
        <Title title='카카오 알림톡 설정' />
        <div className={styles.contentWrap}>
          <ul className={styles.settingList}>
            <li>
              <article className={styles.settingWrap}>
                <div className={styles.articleInfo}>
                  <span>카카오 알림톡 받기</span>
                  <button
                    className={isActivekakaoAlert ? styles.hit : ''}
                    onClick={() => setIsActiveKakaoAlert((prev) => !prev)}
                  >
                    <div></div>
                  </button>
                </div>
                <p className={styles.explanation}>
                  서비스 이용에 필요한 알림톡(중간검사, 미완료 운동, 운동 시작 후 리마인드 등)을 보내드려요.
                </p>
              </article>
            </li>
            {isActivekakaoAlert && (
              <li>
                <article className={styles.settingWrap}>
                  <div className={styles.articleInfo}>
                    <span>운동 알림톡 받기</span>
                    <button
                      className={isActiveExerciseAlert ? styles.hit : ''}
                      onClick={() => setIsActiveExerciseAlert((prev) => !prev)}
                    >
                      <div></div>
                    </button>
                  </div>
                  <p className={styles.explanation}>운동할 시간에 알림톡을 보내드려요.</p>
                  <div className={styles.alertTimeWrap}>
                    <div>
                      <span>{exerciseAlertTime.isAm ? '오전' : '오후'}</span>{' '}
                      <span>
                        {exerciseAlertTime.hour}:{('0' + exerciseAlertTime.minute).slice(-2)}
                      </span>
                    </div>
                    <button onClick={() => setIsOpenAlertModal(true)}>변경</button>
                  </div>
                </article>
              </li>
            )}
          </ul>
          <p className={styles.guide}>
            ・카카오톡 TOGATHER 채널에서 알림톡이 차단된 경우 검사/운동/상담 등의 알림톡을 받을 수 없어요.
          </p>
        </div>
      </section>
      {isOpenAlertModal && (
        <TimerAlertModal
          setIsOpenAlertModal={setIsOpenAlertModal}
          exerciseAlertTime={exerciseAlertTime}
          setExerciseAlertTime={setExerciseAlertTime}
        />
      )}
      {isLoading && <Loading />}
    </>
  );
}

export default KakaoAlert;
