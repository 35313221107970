import CustomButton from '../../CustomButton/CustomButton';
import styles from './ExerciseFacility.module.scss';
import { ReactComponent as Company } from '../../../assets/common/icons/company.svg';
import { ReactComponent as House } from '../../../assets/common/icons/house.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { axiosAuthInstance } from '../../../api/axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import IExerciseEnvironmentType from '../../../type/IExerciseEnvironmentType';
import Loading from '../../Loading/Loading';
import useUserData from '../../../hooks/useUserData';
function ExerciseFacility() {
  const navigate = useNavigate();
  const userData = useUserData();
  const [location, setLocation] = useState<0 | 1>(); //0 : 집, 1: 회사

  function handleSubmit() {
    if (location !== 0 && location !== 1) return;
    navigate('/physical-check/resetting/alert');
    mutate(location === 0 ? 'HOME' : 'COMPANY');
  }

  function updateExerciseEnvironment(environment: string) {
    return axiosAuthInstance.post('/api/locker-room/exercise/environment', {
      userId: userData.data?.userId,
      environment,
    });
  }
  const queryClient = useQueryClient();

  const { mutate } = useMutation(updateExerciseEnvironment, {
    onSuccess: (res, environment) => {
      queryClient.setQueryData(['exercise-environment'], {
        userId: userData.data?.userId,
        environment,
      });
    },
  });

  return (
    <>
      <div className={styles.tab}>검사</div>
      <h4 className={styles.title}>운동장소 설정</h4>
      <div className={styles.locationSettingWrap}>
        <p>운동할 장소를 선택해주세요.</p>
        <ul>
          <li className={location === 0 ? styles.hit : ''} onClick={() => setLocation(0)}>
            <div className={styles.mark}>
              <House />
            </div>
            <span>집에서</span>
          </li>
          <li className={location === 1 ? styles.hit : ''} onClick={() => setLocation(1)}>
            <div className={styles.mark}>
              <Company />
            </div>
            <span>회사에서</span>
          </li>
        </ul>
      </div>
      <div className={styles.submitBtnWrap}>
        <div onClick={handleSubmit}>
          <CustomButton height={56} isActive={!(location !== 0 && location !== 1)}>
            확인하기
          </CustomButton>
        </div>
      </div>
    </>
  );
}
export default ExerciseFacility;
