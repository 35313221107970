import { Fragment, useEffect, useState } from 'react';
import CustomButton from '../CustomButton/CustomButton';
import Loading from '../Loading/Loading';
import styles from './SmartringConnectingModal.module.scss';
import { axiosAuthInstance } from '../../api/axios';
import useUserData from '../../hooks/useUserData';

interface IPropsType {
  onSuccess: () => void;
  onFail: () => void;
  onCancel: () => void;
  connect: () => Promise<{
    result: string;
  }>;
}
function SmartringConnectingModal({ onSuccess, onFail, onCancel, connect }: IPropsType) {
  // const userData = useUserData();
  const [connectResult, setConnectResult] = useState<'SUCCESS' | 'FAIL'>();
  const [dotLength, setDotLength] = useState(1);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setDotLength((prev) => (prev === 3 ? 1 : prev + 1));
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [dotLength]);

  useEffect(() => {
    (async () => {
      try {
        const res = await connect();
        console.log(res);
        if (res.result === 'SUCCESS') setConnectResult('SUCCESS');
        else setConnectResult('FAIL');
      } catch (err) {
        setConnectResult('FAIL');
      }
    })();
  }, []);

  useEffect(() => {
    if (!connectResult) return;

    if (connectResult === 'SUCCESS') onSuccess();
    else onFail();
  }, [connectResult]);
  return (
    <div className={styles.dimed}>
      <article className={styles.modal}>
        <div className={styles.modalContent}>
          <div className={styles.smartringImageWrap}>
            <Loading />
          </div>
          <h6>
            <span>스마트링 연동 확인 중</span>
            <span>
              {new Array(dotLength).fill('').map((_, index) => (
                <Fragment key={index}>.</Fragment>
              ))}
            </span>
          </h6>
          <p>착용한 스마트링을 유지해주세요.</p>
        </div>
        <div className={styles.btnWrap}>
          <div onClick={onCancel}>
            <CustomButton>취소하기</CustomButton>
          </div>
        </div>
      </article>
    </div>
  );
}
export default SmartringConnectingModal;
