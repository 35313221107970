import React from 'react';
import { ParentSize } from '@visx/responsive';
import styles from './CurveChart.module.scss';
import { scaleLinear } from '@visx/scale';
import { Line, LinePath } from '@visx/shape';
import * as allCurves from '@visx/curve';
import { Group } from '@visx/group';
import { Axis } from '@visx/axis';
import { Text } from '@visx/text';
import { ReactComponent as BalloonMobile } from '../../assets/mobile/icons/balloon_red.svg';
import { ReactComponent as Balloon } from '../../assets/web/icons/balloon_red.svg';

interface IPropsType {
  data: number[];
  startTime: string;
  endTime: string;
  HRRest: number;
  MPHR: number;
}
function CurveChart({ data, startTime, endTime, HRRest, MPHR }: IPropsType) {
  return (
    <article className={styles.container}>
      <ParentSize debounceTime={30}>
        {(parent) => {
          const isMobile = window.innerWidth < 1024;
          const width = parent.width;
          const height = parent.height;
          const margin = {
            top: isMobile ? 21 : 36,
            left: isMobile ? 31 : 44,
            right: 0,
            bottom: isMobile ? 56 : 88,
          };

          const innerWidth = width - margin.left - margin.right;
          const innerHeight = height - margin.top - margin.bottom;
          const innerPadding = {
            left: isMobile ? 15 : 91,
            right: isMobile ? 8 : 85,
          };
          const xScale = scaleLinear({
            domain: [0, data.length - 1],
            range: [innerPadding.left, innerWidth - innerPadding.right],
          });
          const yScale = scaleLinear({
            domain: [20, 120],
            range: [innerHeight, 0],
          });
          const hrScale = scaleLinear({
            domain: [HRRest, MPHR],
            range: [20, 100],
          });
          const chartPaddingBottom = isMobile ? 15 : 34;
          return (
            <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
              <Group top={margin.top} left={margin.left}>
                <Axis
                  scale={yScale}
                  orientation='left'
                  numTicks={5}
                  hideTicks
                  tickValues={[20, 40, 60, 80, 100, 120]}
                  hideAxisLine
                  tickFormat={(val) => val + '%'}
                  tickLabelProps={{
                    fill: '#7F828A',
                    fontSize: isMobile ? 12 : 14,
                    textAnchor: 'middle',
                    verticalAnchor: 'middle',
                  }}
                />
              </Group>
              <Group top={margin.top} left={margin.left}>
                <LinePath
                  curve={allCurves.curveNatural}
                  data={data}
                  x={(datum, index) => xScale(index)}
                  y={(datum) => yScale(hrScale(datum))}
                  stroke='#B4BAC8'
                  strokeWidth={3}
                  strokeOpacity={1}
                />
                {(() => {
                  const maxValue = Math.max(...data);
                  const maxIdx = data.indexOf(maxValue);
                  const balloonWidth = isMobile ? 99 : 124;
                  const balloonHeight = isMobile ? 31 : 35;
                  return (
                    <>
                      <circle
                        cx={xScale(maxIdx)}
                        cy={yScale(hrScale(maxValue))}
                        r={6}
                        fill={'#fff'}
                        stroke={'#EF6664'}
                        strokeWidth={4}
                        className={styles.glyph}
                      ></circle>

                      <svg
                        x={xScale(maxIdx) - balloonWidth / 2}
                        y={yScale(hrScale(maxValue)) - balloonHeight - 14}
                        width={balloonWidth}
                        height={balloonHeight}
                        viewBox={`0 0 ${balloonWidth} ${balloonHeight}`}
                      >
                        {isMobile ? <BalloonMobile /> : <Balloon />}
                        <text x={'50%'} y={isMobile ? 18 : 21} textAnchor='middle' fill='#fff'>
                          <tspan fontSize={isMobile ? 12 : 16} fontWeight={'400'}>
                            최대
                          </tspan>
                          <tspan fontSize={isMobile ? 16 : 20} fontWeight={'700'}>
                            {' '}
                            {maxValue}bpm
                          </tspan>
                        </text>
                      </svg>
                    </>
                  );
                })()}
              </Group>
              <Group top={margin.top}>
                <Line
                  from={{
                    x: 0,
                    y: yScale(20) + chartPaddingBottom,
                  }}
                  to={{
                    x: width,
                    y: yScale(20) + chartPaddingBottom,
                  }}
                  stroke='#e3e3e3'
                />
              </Group>
              {new Array(2).fill('').map((val, idx) => {
                const axisWidth = isMobile ? 69 : 91;
                const axisHeight = isMobile ? 36 : 44;
                return (
                  <Group
                    key={idx}
                    top={margin.top + yScale(20) + chartPaddingBottom + 1}
                    left={
                      idx === 0
                        ? margin.left + innerPadding.left - (isMobile ? 0 : axisWidth / 2)
                        : width - axisWidth / (isMobile ? 1 : 2) - innerPadding.right
                    }
                    width={axisWidth}
                  >
                    <svg width={axisWidth} height={axisHeight} viewBox={`0 0 ${axisWidth} ${axisHeight}`}>
                      <Text
                        y={isMobile ? 8 : 13}
                        x={0}
                        fontSize={isMobile ? 12 : 16}
                        textAnchor={'start'}
                        verticalAnchor='start'
                        fontWeight={'400'}
                        fill='#000'
                      >
                        {idx === 0 ? '운동 시작 시간' : '운동 종료 시간'}
                      </Text>

                      <Text
                        y={isMobile ? 27 : 36}
                        x={'50%'}
                        fontSize={isMobile ? 12 : 14}
                        textAnchor='middle'
                        verticalAnchor='start'
                        fontWeight={'400'}
                        fill='#7F828A'
                      >
                        {idx === 0 ? startTime.split('T')[1].slice(0, 5) : endTime.split('T')[1].slice(0, 5)}
                      </Text>
                    </svg>
                  </Group>
                );
              })}
            </svg>
          );
        }}
      </ParentSize>
    </article>
  );
}
export default CurveChart;
