import { useState, useRef, MutableRefObject, useEffect } from 'react';
import ReactPlayer from 'react-player';
import styles from './CustomPlayer.module.scss';
import play from '../../assets/common/icons/btn_video_play.png';
import pause from '../../assets/common/icons/btn_video_stop.png';
import { ReactComponent as Back } from '../../assets/common/icons/ic_back_b.svg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { throttle } from 'lodash';
import Loading from '../Loading/Loading';
interface IPropsType {
  title?: string;
  onEnded?: (replay: () => void) => void;
  backFn?: () => void;
  timer?: boolean;
  FullScreenModal?: JSX.Element;
  stop?: boolean;
  url?: string;
  useReplay?: boolean;
  onPlay?: () => void;
  onStartClick?: () => Promise<{
    result: 'SUCCESS';
  }>;
  timeControlable?: boolean;
  autoPlay?: boolean;
  mute?: boolean;
}

function CustomPlayer({
  title,
  onEnded,
  backFn,
  timer,
  FullScreenModal,
  stop,
  url,
  useReplay = false,
  onPlay,
  onStartClick,
  timeControlable = true,
  autoPlay = false,
  mute = true,
}: IPropsType) {
  const containerRef = useRef<HTMLElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMute, setIsMute] = useState(true);

  useEffect(() => {
    async function checkAutoplay() {
      const video = document.createElement('video');
      video.src = url!;
      video.muted = false;
      video.playsInline = true;
      try {
        await video.play();
        video.pause();
        video.remove();
        setIsMute(mute && autoPlay);
      } catch (error) {
        setIsMute(true);
      }
      if (autoPlay) setIsPlaying(true);
    }
    checkAutoplay();
  }, []);

  const isPlayingRef = useRef(isPlaying); //timeout시 최신값 불러올 수 있도록 useRef 사용
  const [durationSeconds, setDurationSeconds] = useState(100);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const playerRef = useRef() as MutableRefObject<ReactPlayer>;

  const seek = (e: React.ChangeEvent<HTMLInputElement>) => {
    const SECONDS = durationSeconds * 0.01 * +e.target.value;
    playerRef.current.seekTo(SECONDS, 'seconds');
    setPlayedSeconds(SECONDS);
  };

  const [isControllerOpen, setIsControllerOpen] = useState(true);

  useEffect(() => {
    isPlayingRef.current = isPlaying;
    if (isPlaying) {
      setShowReplayButton(false);
      if (onPlay) onPlay();
    }
  }, [isPlaying]);
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    const timeout = () => {
      if (isPlayingRef.current) setIsControllerOpen(false);
    };
    const resetTimeout = throttle(
      () => {
        setIsControllerOpen(true);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(timeout, 2200);
      },
      500,
      { leading: true }
    );
    timeoutId = setTimeout(timeout, 2200);
    containerRef.current?.addEventListener('touchmove', resetTimeout);
    containerRef.current?.addEventListener('mousemove', resetTimeout);
  }, []);
  useEffect(() => {
    if (stop) setIsPlaying(!isPlaying);
  }, [stop]);

  const [isFullScreen, setIsFullScreen] = useState(false);

  // 프로그레스바 관련
  const progressRef = useRef<HTMLDivElement>(null);
  const [percentage, setPercentage] = useState(0);
  const onDragging = useRef(false);
  const durationSecondsRef = useRef(0);
  const handleProgress = throttle(
    (e) => {
      if (e.type !== 'click' || !progressRef.current) {
        if (!(e.type === 'touchmove' || e.type === 'mousemove') || !progressRef.current || !onDragging.current) return;
      }
      const isVertical =
        window.matchMedia('(orientation: portrait)').matches && window.matchMedia('(max-width: 1023px)').matches;
      const clientLocation = isVertical
        ? e.type === 'touchmove'
          ? e.touches[0].clientY
          : e.clientY
        : e.type === 'touchmove'
          ? e.touches[0].clientX
          : e.clientX;
      const rect = progressRef.current.getBoundingClientRect();
      const WIDTH = isVertical ? rect.height : rect.width;
      const MIN = isVertical ? rect.top : rect.x;
      const MAX = MIN + WIDTH;
      let newValue = 0;
      if (clientLocation < MIN) newValue = 0;
      else if (clientLocation > MAX) newValue = 100;
      else newValue = ((clientLocation - MIN) / (MAX - MIN)) * 100;
      setPercentage(newValue);
      const SECOND = (durationSecondsRef.current / 100) * newValue - 0.0001;
      ('seconds');
      setPlayedSeconds(SECOND);
      playerRef.current.seekTo(SECOND);
    },
    20,
    { trailing: true }
  );
  useEffect(() => {
    if (!timeControlable) return;
    window.addEventListener('mouseup', () => {
      if (onDragging.current) onDragging.current = false;
    });
    window.addEventListener('mousemove', handleProgress);
    window.addEventListener('touchend', () => {
      if (onDragging.current) onDragging.current = false;
    });
    window.addEventListener('touchmove', handleProgress);
  }, []);
  useEffect(() => {
    setPercentage((playedSeconds / durationSeconds) * 100);
  }, [playedSeconds, durationSeconds]);
  // 프로그레스바 관련

  const [showReplayButton, setShowReplayButton] = useState(false);

  function handleEnded() {
    setIsPlaying(false);
    setIsControllerOpen(true);
    if (useReplay) setShowReplayButton(true);
    function replay() {
      playerRef.current.seekTo(0);
      setIsPlaying(true);
    }
    if (onEnded) onEnded(replay);
  }

  // 전체화면에서 esc로 전체화면 취소시에도 전체화면 상태값 수정되도록
  useEffect(() => {
    function fullscreenCheck() {
      if (!document.fullscreenElement) setIsFullScreen(false);
    }
    document.addEventListener(
      'fullscreenchange',
      function (e) {
        fullscreenCheck();
      },
      false
    );

    document.addEventListener(
      'mozfullscreenchange',
      function (e) {
        fullscreenCheck();
      },
      false
    );

    document.addEventListener(
      'fullscreenchange',
      function (e) {
        fullscreenCheck();
      },
      false
    );
  }, []);

  const [onBuffer, setOnBuffer] = useState(false);
  return (
    <>
      <article
        className={styles.container}
        ref={containerRef}
        onClick={() => {
          if (isControllerOpen && window.innerWidth < 1024) setIsControllerOpen(false);
        }}
      >
        <div className={`${styles.fullScreen} ${isFullScreen ? styles.isFull : ''} fullScreen`}>
          {isFullScreen && FullScreenModal && FullScreenModal}
          {title && (
            <header className={`${styles.header} ${!isControllerOpen ? styles.hide : ''}`}>
              {backFn && <Back onClick={backFn} />}
              <span>{title}</span>
            </header>
          )}
          <ReactPlayer
            playing={isPlaying}
            muted={isMute}
            playsinline
            controls={false}
            url={url || 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4'}
            onDuration={(duration) => {
              setDurationSeconds(duration);
              durationSecondsRef.current = duration;
            }}
            onProgress={(progress) => {
              if (progress.playedSeconds < 0.5 || Math.abs(playedSeconds - progress.playedSeconds) < 1)
                setPlayedSeconds(progress.playedSeconds);
            }}
            onStart={() => setIsControllerOpen(false)}
            progressInterval={20}
            ref={playerRef}
            allowFullScreen
            className={styles.player}
            onEnded={handleEnded}
            onBuffer={() => {
              setOnBuffer(true);
            }}
            onBufferEnd={() => {
              setOnBuffer(false);
            }}
          />
          <div className={`${styles.controlWrap} ${!isControllerOpen ? styles.hide : ''} `}>
            <button
              className={styles.playBtn}
              onClick={async (e) => {
                e.stopPropagation();
                if (onStartClick) await onStartClick();
                setIsPlaying(!isPlaying);
              }}
            >
              <img src={isPlaying ? pause : play} alt='컨트롤' />
            </button>
            {showReplayButton && (
              <button
                className={styles.replayBtn}
                onClick={() => {
                  playerRef.current.seekTo(0);
                  setShowReplayButton(false);
                  setIsPlaying(true);
                }}
              >
                동작 다시보기
              </button>
            )}
            <div className={styles.optionBtns}>
              {autoPlay && (
                <button
                  className={`${styles.volumeBtn} ${isMute ? styles.volumeOff : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsMute((prev) => !prev);
                  }}
                ></button>
              )}
              <button
                className={`${styles.fullScreenBtn} ${isFullScreen ? styles.full : ''}`}
                onClick={() => {
                  const element = document.querySelector('.fullScreen') as HTMLVideoElement & {
                    mozRequestFullScreen?: () => void;
                    webkitRequestFullScreen?: () => void;
                    msRequestFullScreen?: () => void;
                    webkitEnterFullScreen?: () => void;
                  };
                  if (!document.fullscreenElement) {
                    if (element?.requestFullscreen) element?.requestFullscreen();
                    else if (element?.mozRequestFullScreen) element.mozRequestFullScreen();
                    else if (element.webkitRequestFullScreen) element.webkitRequestFullScreen();
                    else if (element.msRequestFullScreen) element.msRequestFullScreen();
                    else if (element.webkitEnterFullScreen) element.webkitEnterFullScreen();
                    setIsFullScreen(true);
                  } else {
                    if (document.exitFullscreen) {
                      document.exitFullscreen();
                    } else if (document.webkitExitFullscreen) {
                      document.webkitExitFullscreen();
                    } else if (document.mozCancelFullScreen) {
                      document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                      document.msExitFullscreen();
                    }
                    setIsFullScreen(false);
                  }
                }}
              ></button>
            </div>
            <div
              className={styles.durationBar}
              ref={progressRef}
              onMouseDown={(e) => {
                onDragging.current = true;
              }}
              onTouchStart={() => {
                onDragging.current = true;
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (!timeControlable) return;
                handleProgress(e);
              }}
            >
              <div className={styles.progressArea}>
                <div className={styles.total}>
                  <div
                    className={styles.fill}
                    style={{
                      width: `${percentage}%`,
                    }}
                  ></div>
                </div>
              </div>
              <div
                className={styles.circle}
                style={{
                  left: `calc(${percentage}% - 16px * ${percentage / 100} + (-8px + 16px * ${percentage / 100}))`,
                }}
              ></div>
            </div>
          </div>
          {durationSeconds - playedSeconds < 10 && timer && (
            <div className={styles.timerWrap}>
              <div className={styles.background}>{Math.ceil(durationSeconds - playedSeconds)}</div>
              <CircularProgressbar
                value={10 - (durationSeconds - playedSeconds)}
                minValue={0}
                maxValue={10}
                strokeWidth={14}
                className={styles.circularPrgoressbar}
                styles={buildStyles({
                  strokeLinecap: 'round',
                  textSize: '16px',
                  pathTransitionDuration: 0,
                  pathColor: `#4852EC`,
                  trailColor: 'rgba(72, 82, 236, 0.30)',
                })}
              ></CircularProgressbar>
            </div>
          )}
        </div>
        {onBuffer && <Loading useAbsolute />}
      </article>
    </>
  );
}

export default CustomPlayer;
