import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface INavigateType {
    path: null | string;
}
const initialState: INavigateType = {
    path: null,
};

export const navigateSlice = createSlice({
    name: 'navigate',
    initialState,
    reducers: {
        navigateTo(state, action: PayloadAction<INavigateType>) {
            state.path = action.payload.path;
        },
    },
});

export const { navigateTo } = navigateSlice.actions;

export default navigateSlice;
