import styles from './ExercisePlay.module.scss';
import CustomPlayer from '../../CustomPlayer/CustomPlayer';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import VerticalModal from '../../VerticalModal/VerticalModal';
import { ReactComponent as Back } from '../../../assets/mobile/icons/ic_back_b.svg';
import CustomConfirm from '../../CustomConfirm/CustomConfirm';
import CustomButton from '../../CustomButton/CustomButton';
import useBackDetect from '../../../hooks/useBackDetect';
import { IUnitType } from '../../../type/ITimelineDataType';
import { useMutation } from 'react-query';
import { axiosAuthInstance } from '../../../api/axios';
import useUserData from '../../../hooks/useUserData';
import Loading from '../../Loading/Loading';

interface IPropsType {
  nextFn: () => void;
  unitList: IUnitType[];
  useSmartring: 'Y' | 'N';
  unit: IUnitType;
  isLastUnit: boolean;
  handleRest: (repeatCount: number) => void;
}
function ExercisePlay({ nextFn, unitList, unit, isLastUnit, useSmartring, handleRest }: IPropsType) {
  const userData = useUserData();
  const navigate = useNavigate();
  const [verticalModalOpen, setVerticalModalOpen] = useState(false);
  const [stopModalOpen, setStopModalOpen] = useState(false);
  const { unitIndex } = useParams();
  const { sessionId } = useParams();

  const [isEnded, setIsEnded] = useState(false);
  const [detect, setDetected] = useBackDetect({ unitList, useSmartring });

  useEffect(() => {
    if (detect) setStopModalOpen(true);
    // setTimeout(async () => {
    //   // 지워야함
    //   await unitComplete();
    //   nextFn();
    // }, 500);
  }, [detect]);

  const { mutateAsync: unitComplete, isLoading } = useMutation(() =>
    axiosAuthInstance.post('/api/fitness/exercise/session', {
      userId: userData.data?.userId,
      unitId: unit.unitId,
    })
  );

  const { mutateAsync: smartringConnection, isLoading: smartringLoading } = useMutation<
    {
      result: 'SUCCESS';
    },
    null,
    'C' | 'D'
  >((status) =>
    axiosAuthInstance
      .post(`/api/fhir/smart-ring/subscribe?userId=${userData.data?.userId}`, {
        sessionId,
        connectionSet: status,
      })
      .then((res) => res.data)
  );

  const playedCount = useRef(
    (() => {
      let count = Number(new URLSearchParams(window.location.search).get('play_count') || 0);
      if (isNaN(count)) count = 0;
      return count;
    })()
  );

  return (
    <>
      <section className={styles.container}>
        <header className={styles.header}>
          <span>{unit.unitNameKorean}</span>
        </header>
        <div className={styles.videoWrap}>
          {/* <div className={styles.mobileHeader}>
                        <button onClick={() => setStopModalOpen(true)}>
                            <Back />
                        </button>
                        <span>고개 숙이기</span>
                    </div> */}
          <CustomPlayer
            autoPlay
            mute={false}
            url={unit.guideVideoUrl}
            timer
            title={unit.unitNameKorean}
            stop={detect}
            timeControlable={false}
            backFn={() => {
              setStopModalOpen(true);
            }}
            onEnded={async () => {
              playedCount.current = playedCount.current + 1;

              //반복횟수만큼 재생 완료 시
              if (playedCount.current >= Number(unit.unitCode.slice(-2))) {
                if (isLoading) return;
                await unitComplete();
                if (isLastUnit) {
                  if (useSmartring === 'Y') await smartringConnection('D');
                  if (window.innerWidth < 1024) setVerticalModalOpen(true);
                  else setIsEnded(true);
                } else
                  setTimeout(() => {
                    nextFn();
                  }, 3000);
              } else
                setTimeout(() => {
                  handleRest(playedCount.current);
                }, 3000);
            }}
            FullScreenModal={isEnded ? <EndModal onSubmit={nextFn} /> : undefined}
          />
        </div>
      </section>
      {verticalModalOpen && <VerticalModal onSubmit={nextFn} />}
      {stopModalOpen && (
        <CustomConfirm
          message='운동 안내 화면으로 돌아갈까요?'
          falseText='아니요'
          trueText='예, 돌아갈게요'
          falseFn={() => setStopModalOpen(false)}
          trueFn={() => {
            navigate(`/exercise/${unitIndex}/guide/${sessionId}`, {
              state: {
                unitList,
                useSmartring,
              },
            });
          }}
        />
      )}
      {isEnded && <EndModal onSubmit={nextFn} />}
      {smartringLoading && <Loading />}
    </>
  );
}
export default ExercisePlay;

function EndModal({ onSubmit }: { onSubmit: () => void }) {
  return (
    <div className={styles.endedModal}>
      <div className={styles.modal}>
        <p>이제 운동 결과를 확인해보세요!</p>
        <div onClick={onSubmit}>
          <CustomButton>확인하기</CustomButton>
        </div>
      </div>
    </div>
  );
}
