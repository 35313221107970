import styles from './CustomButton.module.scss';

interface IPropsType {
    children: React.ReactChild;
    isActive?: boolean;
    height?: number;
}
function CustomButton({ children, isActive = true, height }: IPropsType) {
    return (
        <button
            style={height ? { height: `${height}px` } : {}}
            className={`${styles.button} ${isActive ? styles.hit : ''}`}
        >
            {children}
        </button>
    );
}
export default CustomButton;
