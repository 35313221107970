import styles from './ActionDescription.module.scss';
import { ReactComponent as Back } from '../../../assets/mobile/icons/ic_back_b.svg';
import bg from '../../../assets/temporary/action_description.png';
import Checkbox from '../../CheckBox/Checkbox';
import { useEffect, useState } from 'react';
import CustomButton from '../../CustomButton/CustomButton';
import CustomConfirm from '../../CustomConfirm/CustomConfirm';
import IMskCheckType from '../../../type/IMskCheckType';
import ReactPlayer from 'react-player';

interface IPropsType {
  handleValidationCheck: () => void;
  nextFn: () => void;
  onBackClick: () => void;
  data: IMskCheckType;
  examGestureIndex: number;
}

function ActionDescription({ handleValidationCheck, nextFn, onBackClick, data, examGestureIndex }: IPropsType) {
  const [isAutoProgress, setIsAutoProgress] = useState(true);
  const [autoSecond, setAutoSecond] = useState(15);
  const [autoCancelModalOpen, setAutoCancelModalOpen] = useState(false);
  const [autoProgressModalOpen, setAutoProgressModalOpen] = useState(false);

  useEffect(() => {
    handleValidationCheck();
  }, []);
  useEffect(() => {
    if (isAutoProgress && !autoCancelModalOpen && autoSecond > 0)
      setTimeout(() => {
        setAutoSecond((prev) => prev - 1);
      }, 1000);
  }, [autoSecond, autoCancelModalOpen]);

  useEffect(() => {
    if (isAutoProgress) setAutoSecond(15);
  }, [isAutoProgress]);

  useEffect(() => {
    if (isAutoProgress && autoSecond <= 0)
      setTimeout(() => {
        nextFn();
      }, 1000);
  }, [autoSecond]);
  return (
    <>
      <section className={styles.container}>
        <header className={styles.header}>
          <button onClick={onBackClick}>
            <Back />
          </button>
          <span>{data.examParts[examGestureIndex]?.gestureName}</span>
        </header>
        <div className={styles.description}>
          <div className={styles.thumbnailWrap}>
            {/* <img
                            src={bg}
                            alt={data.examParts[examGestureIndex]?.gestureName}
                        /> */}
            <ReactPlayer
              url={data.examParts[examGestureIndex].guideVideo}
              muted
              playing={true}
              controls={false}
              playsinline
            />
          </div>
          <div className={styles.right}>
            <p
              dangerouslySetInnerHTML={{
                __html: data.examParts[examGestureIndex]?.gestureExplainText,
              }}
            ></p>
            <label className={styles.autoProgress}>
              <Checkbox
                isChecked={isAutoProgress}
                onClick={() => {
                  if (isAutoProgress) setAutoCancelModalOpen(true);
                  else setAutoProgressModalOpen(true);
                }}
              />
              <span>검사 자동으로 진행</span>
            </label>
            <div className={styles.submitBtnWrap} onClick={nextFn}>
              <CustomButton>{`진행하기${isAutoProgress ? ` (${autoSecond}초 후 자동진행)` : ''}`}</CustomButton>
            </div>
          </div>
        </div>

        <div className={styles.submitWrap}>
          <label className={styles.autoProgress}>
            <Checkbox
              isChecked={isAutoProgress}
              onClick={() => {
                if (isAutoProgress) setAutoCancelModalOpen(true);
                else setAutoProgressModalOpen(true);
              }}
            />
            <span>검사 자동으로 진행</span>
          </label>
          <div className={styles.submitBtnWrap}>
            <div onClick={nextFn}>
              <CustomButton>{`진행하기${isAutoProgress ? ` (${autoSecond}초 후 자동진행)` : ''}`}</CustomButton>
            </div>
          </div>
        </div>
      </section>
      {autoCancelModalOpen && (
        <CustomConfirm
          message='검사 자동진행을 취소할까요?'
          falseText='아니요'
          trueText='예, 취소할게요'
          falseFn={() => setAutoCancelModalOpen(false)}
          trueFn={() => {
            setAutoCancelModalOpen(false);
            setIsAutoProgress(false);
          }}
        />
      )}
      {autoProgressModalOpen && (
        <CustomConfirm
          message='검사를 자동으로 진행할까요?'
          falseText='아니요'
          trueText='예, 진행할게요'
          falseFn={() => setAutoProgressModalOpen(false)}
          trueFn={() => {
            setAutoProgressModalOpen(false);
            setIsAutoProgress(true);
          }}
        />
      )}
    </>
  );
}
export default ActionDescription;
