import axios from 'axios';
import store from '../store/store';
import { navigateTo } from '../store/navigateSlice';

const baseURL = window.location.href.includes('dev.togather')
  ? 'https://dev-api.togather.co.kr/v1'
  : process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    'Access-Control-Allow-Origin': baseURL,
  },
});
export const axiosAuthInstance = axios.create({
  baseURL: baseURL,
  headers: {
    'Access-Control-Allow-Origin': baseURL,
  },
});

axiosAuthInstance.interceptors.request.use(
  async function (config) {
    const ACCESS_TOKEN = localStorage.getItem('access-token');
    if (ACCESS_TOKEN) config.headers!.Authorization = `Bearer ${window.btoa(ACCESS_TOKEN)}`;

    return config;
  },
  function (error) {
    // 요청 오류가 있는 작업 수행
    return Promise.reject(error);
  }
);
axiosAuthInstance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (config) {
    localStorage.removeItem('access-token');
    store.dispatch(navigateTo({ path: '/' }));
    return '';
  }
);
